import { createUseStyles } from 'react-jss';
import SuccessPopupIcon from '../../../assets/images/sucessIcon.svg';
import FailurePopupIcon from '../../../assets/images/failurePopupIcon.svg';
import closeIcon from "../../../assets/images/closeRoundIcon.svg";
import ButtonV2 from '../../atoms/ButtonV2';
import TextFieldV2 from '../../atoms/TextFieldV2';

interface ButtonProps {
    text: string,
    variant: CustomVariant,
    size: CustomSize,
    onClick: () => void,
}

type Variant = "Success" | "Failure";

interface TextFieldProps {
    label: string,
    value: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface SuccessFailurePopupProps {
    width?: string,
    height?: string,
    heading: string | React.ReactNode,
    setShowPopup: (value: boolean) => void,
    button1?: ButtonProps,
    button2?: ButtonProps,
    button3?: ButtonProps,
    description?: string | React.ReactNode,
    variant?: Variant,
    textField?: TextFieldProps

}

type CustomVariant = "primaryContained" | "secondaryContained" | "tertiaryContained" | "primaryText" | "tertiaryText";
type CustomSize = "small" | "medium" | "large";

const useStyles = createUseStyles((theme: any) => ({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: `${theme.palette.background.primaryLightAccent}80`,
        zIndex: 99,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    primaryContainer: {
        width: "458px",
        background: theme.palette.buttonV2.primaryContained.text.primaryLight,
        boxShadow: `0px 5px 16px 0px ${theme.palette.backgroundV2.failure.failure1000}40`,
        zIndex: 1,
    },
    description: {
        color: "#4A4D4F",
        marginTop: "12px",
        fontSize: "18px"
    },
    btn: {
        columnGap: "16px",
        marginBottom: "48px"
    },
    heading: {
        fontSize: "40px",
        paddingTop: "24px",
        color: "#142252"
    },
    textField: {
        minWidth: "500px"
    },
    "@media (max-width: 480px)": {
        primaryContainer: {
            width: "280px",
        },
        description: {
            marginTop: "8px",
            fontSize: "12px"
        },
        btn: {
            columnGap: "12px",
            marginTop: "24px",
            marginBottom: "32px"
        },
        heading: {
            fontSize: "24px",
            lineHeight: "30px"
        },
    },
}))

const SuccessFailurePopupV2: React.FC<SuccessFailurePopupProps> = ({
    width,
    height,
    heading,
    setShowPopup,
    button1,
    button2,
    button3,
    description,
    variant,
    textField
}) => {

    const classes = useStyles();

    const divStyle = {
        height,
        width,
    };

    const handleClose = () => {
        setShowPopup(false);
    }

    const getIconByVariant = () => {
        switch (variant) {
            case "Success":
                return SuccessPopupIcon;
            case "Failure":
                return FailurePopupIcon;
            default:
                return null;
        }
    };

    return (
        <div className={classes.overlay}>
            <div className={`${classes.primaryContainer} grid p-6 gap-y-1 rounded-2xl overflow-hidden bg-white`} style={divStyle}>
                <div className={`cursor-pointer flex justify-end`} onClick={handleClose} >
                    <img src={closeIcon} alt="closeIcon" />
                </div>
                <div className="flex flex-col gap-y-6 items-center">
                    {getIconByVariant() && (
                        <img src={getIconByVariant() || ''} alt="popup-icon" />
                    )}

                    <div className='grid gap-y-4 text-center'>
                        <div className={`${classes.heading} font-medium`}>{heading}</div>
                        <p className={`${classes.description} px-4 text-center font-normal`}>{description}</p>
                    </div>
                    <div>
                        {textField &&
                            <div>
                                <div className={`${classes.description} font-normal !text-base w-full text-left`}>{textField.label}</div>
                                <div className={`${classes.textField}`}>
                                    <TextFieldV2
                                        label=""
                                        placeholder='Enter here'
                                        onChange={textField.onChange}
                                        value={textField.value}
                                        required
                                        multiline
                                        fullWidth
                                        rows={3}
                                    />
                                </div>
                            </div>}
                    </div>
                    <div className={`${classes.btn} flex justify-center`}>
                        {button1 && <ButtonV2
                            className=''
                            variant={button1.variant}
                            label={button1.text}
                            size={button1.size}
                            onClick={button1.onClick}
                        />}
                        {button2 && <ButtonV2
                            className=''
                            variant={button2.variant}
                            label={button2.text}
                            size={button2.size}
                            onClick={button2.onClick}
                        />}
                    </div>
                    {button3 && <div className={`flex w-4/5 justify-center`}>
                        <ButtonV2
                            className=''
                            variant={button3.variant}
                            label={button3.text}
                            size={button3.size}
                            onClick={button3.onClick}
                        />
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default SuccessFailurePopupV2;