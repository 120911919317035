import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../utils/types';
import { ADMIN_ROUTES, MODE, STATUS, uomMapping } from '../../../utils/constant';
import { IAttributePayload, useAttributeService } from '../../../services/useAttributeService';
import AttributeFormTemplate from '../../templates/Attribute/AttributeForm.template';

const validationSchema = Yup.object().shape({
    name: Yup.string().max(30, "Attribute Name cannot be more than 30 characters").required("Attribute Name is required"),
    attributeOptions: Yup.array().of(Yup.string()).min(1, "Field Types must not be empty").required("Field Types is required"),
    attributeType: Yup.string().typeError("Attributes Type must be a string").required("Attributes Type is required"),
    uomCheck: Yup.boolean(),
    uom: Yup.string().when('uomCheck', ([uomCheck], schema) => {
        return uomCheck ? schema.required("Please select UOM value") : schema;
    }),
    fieldType: Yup.string().typeError("Feild Type must be a string").required("Feild Type is required"),
    description: Yup.string().max(500, "Description cannot be more than 500 characters").required("Description is required"),
    attributeRank: Yup.number().typeError('Attribute rank must be a number').positive('Attribute rank must be a positive number').integer('Attribute rank must be an integer').required('Please Enter Attribute rank')
});

const AttributeUpdatePage: React.FC = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const attributeService = useAttributeService();
    const navigate = useNavigate();
    const params = useParams();
    const attributeId = Number(params.id);
    const [attribute, setAttribute] = useState<any | null>(null)
    const [existingTags, setExistingTags] = useState([]);

    const onBack = () => {
        navigate(ADMIN_ROUTES.ATTRIBUTE_LIST);
    }

    const loadAttribute = async () => {
        attributeService.getAttributeById(Number(params.id))
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    setExistingTags(JSON.parse(res.data.data.attributeOptions))
                    setAttribute(res.data.data)
                }
            }).catch((error) => {
                setAttribute(null);
                console.error("Error Fetching Machine: ", error);
                showSnackbar('error', "Error while fetching Machine data");
            })
    }

    useEffect(() => {
        loadAttribute()
    }, [Number(params.id)])

    const updateMachine = async (machine: IAttributePayload) => {
         const attributeRequestBody: any = {
            name: machine.name.trim(),
            attributeType: machine.attributeType,
            fieldType: machine.fieldType,
            attributeOptions: machine.attributeOptions,
            isUpc: machine.isUpc,
            isMpc: machine.isMpc,
            description: machine.description,
            uom: machine.uom,
            status: machine.status,
            parentId: null,
            attributeRank: machine.attributeRank
         }
         attributeService.updateAttribute(attributeId, attributeRequestBody)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "Attribute Updated");
                    onBack();
                } else if (res.data.exceptionCode === "DUPLICATE_ATTRIBUTE") {
                    showSnackbar('error', "Attribute already exists. Please try again");
                }
            }).catch((error: any) => {
                showSnackbar('error', "Failed to update Attribute");
            })
    }

    const formik = useFormik<IAttributePayload>({
        initialValues: {
            name: '',
            attributeType: '',
            fieldType: '',
            attributeOptions: [],
            isUpc: false,
            isMpc: false,
            description: '',
            uom: '',
            status: STATUS.ACTIVE,
            parentId: null,
            attributeRank: 1,
            uomCheck: false
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            updateMachine({ ...values, status: values.status })
        }
    })

    useEffect(() => {
        const uom = attribute?.uom ?? "";
        const uomKeys = Object.keys(uomMapping);
        const uomIndex = uomKeys.indexOf(uom) + 1; 
       
        const uomValue = uomMapping[uom] ? {
            label: uom,
            id: uomIndex, 
            shortcode: uomMapping[uom].shortcode,
            fullDescription: uomMapping[uom].fullDescription,
        } : null;
    
        formik.setValues({
            name: attribute?.name ?? "",
            attributeType: attribute?.attributeType ?? "",
            fieldType: attribute?.fieldType ?? "",
            attributeOptions: attribute?.attributeOptions ? JSON.parse(attribute?.attributeOptions) : [],
            isUpc: false,
            isMpc: false,
            description: attribute?.description,
            uom: uom,
            status: attribute?.status ?? STATUS.ACTIVE,
            parentId: null,
            attributeRank: attribute?.attributeRank ?? null,
            uomCheck: attribute?.uom ? true : false,
            uomValue: uomValue,
        });
    }, [attribute]);

    return (
        <div>
            {SnackBarComponent}
            <AttributeFormTemplate mode={MODE.UPDATE} onBack={onBack} formik={formik} existingTags={existingTags}  />
        </div>
    )
}

export default AttributeUpdatePage;