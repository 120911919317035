import React, { ReactNode } from 'react'

interface BundleFormTemplateProps {
    mode: string,
    onClose: () => void,
    component: ReactNode
}

const BundleFormTemplate: React.FC<BundleFormTemplateProps> = ({ component, mode, onClose }) => {
    return (
        <div>{component}</div>
    )
}

export default BundleFormTemplate