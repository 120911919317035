import React, { useEffect, useState } from 'react'
import { HTTP_STATUS, IPagination } from '../../../utils/types';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES, initialPaginationValues } from "../../../utils/constant";
import { useSnackbar } from '../../../hooks/useSnackBar';
import { IGrades, IQualityGradeFilter, useQualtiyGradeService } from '../../../services/useQualityGradesService';
import GradeListTemplate from '../../templates/Grade/GradeList.template';
import ButtonV2 from '../../atoms/ButtonV2';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary950,
    },
}));

const initialGradeFiltersValues: IQualityGradeFilter = {
    search: "",
    status: "ALL",
    sort: "createdAt,desc",
}

const GradeListPage: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const gradeService = useQualtiyGradeService();
    const [grades, setGradesTo] = useState<IGrades[]>([]);
    const [gradeFilters, setGradeFiltersTo] = useState<IQualityGradeFilter>(initialGradeFiltersValues);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);

    const refreshGrades = (page: number, size: number) => {
        const params = {
            search: gradeFilters?.search?.trim(),
            status: gradeFilters?.status === "ALL" ? "" : gradeFilters?.status,
            page: page,
            size: size,
            sort: gradeFilters.sort,
            type: "GRADE"
        }
        gradeService.getAllQualityGrades(params)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    const { totalElements, totalPages } = res?.data?.data;
                    setPaginationTo({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setGradesTo(res?.data?.data?.content)
                }
            }).catch(error => {
                showSnackbar('error', error);
                setGradesTo([]);
            })
    }

    useEffect(() => {
        refreshGrades(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize, gradeFilters])

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    const handleFiltersChange = (event: any) => {
        const { name, value } = event.target;
        setPaginationTo({ ...pagination, currentPage: 0 })
        setGradeFiltersTo({ ...gradeFilters, [name]: value ?? "" });
    }

    const handleCreateGrade = () => {
        navigate(ADMIN_ROUTES.GRADE_CREATE);
    };

    return (
        <>
            {SnackBarComponent}
            <div className='grid gap-y-3'>
                <div className='flex justify-between'>
                    <div className={`${classes.heading} text-lg font-semibold my-auto`}>Grade</div>
                    <ButtonV2 variant="contained" label={"Add New Grade"} onClick={handleCreateGrade} />
                </div>
                <GradeListTemplate grades={grades} pagination={pagination} handleFiltersChange={handleFiltersChange} handlePaginationChange={handlePaginationChange} gradeFilters={gradeFilters} handleRowsPerPageChange={handleRowsPerPageChange} />
            </div>
        </>
    )
}

export default GradeListPage;