import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../utils/types';
import { ADMIN_ROUTES, EXCEPTION_CODE, MODE, STATUS } from '../../../utils/constant';
import { IQualityGradePayload, useQualtiyGradeService } from '../../../services/useQualityGradesService';
import GradeFormTemplate from '../../templates/Grade/GradeForm.template';

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    standard: Yup.object().shape({
        id: Yup.number().required('Standard is required'),
        label: Yup.string().required('Standard is required')
    }).required('Standard is required'),
});

const GradeUpdatePage: React.FC = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const qualtiyGradeService = useQualtiyGradeService();
    const [grade, setGrade] = useState<any | null>(null)
    const navigate = useNavigate();
    const params = useParams();

    const loadGrade = async () => {
        qualtiyGradeService.getQualityGrade(Number(params.id))
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    setGrade(res.data.data)
                }
            }).catch((error) => {
                setGrade(null);
                console.error("Error Fetching Quality Grade: ", error);
                showSnackbar('error', "Error while fetching Quality Grade data");
            })
    }

    useEffect(() => {
        loadGrade()
    }, [Number(params.id)])

    const onBack = () => {
        navigate(ADMIN_ROUTES.GRADE_LIST);
    }

    const updateGrade = async (grade: IQualityGradePayload) => {
        const requestBody: any = {
            name: grade.name.trim(),
            prefix: grade.prefix,
            standardId: grade.standardId,
            bisHeader: "Bis Header",
            type: grade.type,
            status: grade.status ? 'ACTIVE' : 'INACTIVE',
            chemicalComposition: JSON.stringify(grade.chemicalComposition),
            mechanicalProperties: JSON.stringify(grade.mechanicalProperties),
            tensileProperties: JSON.stringify(grade.tensileProperties),
         }
         qualtiyGradeService.updateQualityGrade(Number(params.id), requestBody)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "Quality Grade Updated");
                    onBack();
                } else if (res.data.exceptionCode === EXCEPTION_CODE.DUPLICATE_ENTRY) {
                    showSnackbar('error', "Quality Grade already exists. Please try again");
                }
            }).catch((error) => {
                showSnackbar('error', "Failed to update Quality Grade");
            })
    }

    const formik = useFormik<IQualityGradePayload>({
        initialValues: {
            name: grade?.name ?? "",
            prefix: grade?.prefix ?? "",
            standard: {
                label: grade?.standard?.name ?? "",
                id: grade?.standard?.id ?? "",
            },
            standardId: grade?.standardId ?? null,
            bisHeader: grade?.bisHeader ?? "",
            type: grade?.type ?? "",
            status: grade?.status ?? STATUS.ACTIVE,
            chemicalComposition: grade?.chemicalComposition ? JSON.parse(grade?.chemicalComposition) : [],
            mechanicalProperties: grade?.mechanicalProperties ? JSON.parse(grade?.mechanicalProperties) : [],
            tensileProperties: grade?.tensileProperties ? JSON.parse(grade?.tensileProperties) : [],
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            updateGrade({ ...values, status: values.status })
        }
    })

    useEffect(() => {
        formik.setValues({
            name: grade?.name ?? "",
            prefix: grade?.prefix ?? "",
            standard: {
                label: grade?.standard?.name ?? "",
                id: grade?.standard?.id ?? "",
            },
            standardId: grade?.standardId ?? null,
            bisHeader: grade?.bisHeader ?? "",
            type: grade?.type ?? "",
            status: grade?.status ?? STATUS.ACTIVE,
            chemicalComposition: grade?.chemicalComposition ? JSON.parse(grade?.chemicalComposition) : [],
            mechanicalProperties: grade?.mechanicalProperties ? JSON.parse(grade?.mechanicalProperties) : [],
            tensileProperties: grade?.tensileProperties ? JSON.parse(grade?.tensileProperties) : [],
        });
    }, [grade]);

    return (
        <div>
            {SnackBarComponent}
            <GradeFormTemplate mode={MODE.UPDATE} onBack={onBack} formik={formik} />
        </div>
    )
}

export default GradeUpdatePage;