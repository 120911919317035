import { createUseStyles } from "react-jss";
import closeIcon from "../../../../../assets/icons/closeRoundIcon.svg";
import Dialog from '@mui/material/Dialog';
import { TableBody, TableCell, TableHead, TableRow, Table } from '@mui/material';
import { ICustomization } from "./SelectCustomization.template";
import { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputAdornment } from '@mui/material';
import editIcon from "../../../../../assets/images/editIcon.svg"
import deleteIcon from "../../../../../assets/images/deleteOutlinedIcon.svg"
import { ICatalogueUpc } from "../../../../pages/Vendor/Catalogue/CatalogueDetails.page";
import TextFieldV2 from "../../../../atoms/TextFieldV2";
import ButtonV2 from "../../../../atoms/ButtonV2";
import { SLITTING_HEADING } from "../../../../../utils/constant";
import { toInr } from "../../../../../utils/helper";
import SuccessFailurePopupV2 from "../../../../molecules/SuccessFailurePopup/SuccessFailurePopupV2";

interface SlittingCustomizationPopupProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    selectedUpc?: ICatalogueUpc | null;
    selectedWeight: number;
    customization: ICustomization;
    valueAddedService: any;
    setValueAddedServiceTo: any;
    balanceWeight: any;
    setBalanceWeight: any;
    editState: boolean;
    setEditState: (open: boolean) => void;
}

export interface ISlitting {
    id: number,
    width: number;
    weightOfCoil: number;
    chargesPerMT: number;
    totalCharges: number;
}

export interface ISlittingForm {
    id: number | null,
    width: number | null,
    weightOfCoil: number | null,
    chargesPerMT: number | null,
    totalCharges: number | null,
    noOfSlit: number | null;
    desiredWeight: number | null;
    widthArray: any
}


const useStyles = createUseStyles((theme: any) => ({
    dialogContainer: {
        "& .MuiDialog-paper": {
            width: "917px !important",
            maxWidth: "917px !important",
            borderRadius: "16px"
        },
    },
    webContainer: {
        display: "grid"
    },
    mobileContainer: {
        display: "none"
    },
    mainHeading: {
        color: theme.customerPalette.text.primary.primary800,
        fontSize: "28px"
    },
    heading: {
        color: theme.customerPalette.text.primary.primary900,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.customerPalette.border.neutral.neutral200}`,
    },
    attributeHeading:{
        color:theme.customerPalette.border.neutral.neutral600
    },
    attributesValue:{
        color:theme.customerPalette.text.primary.primary600
    },
    container: {
        borderTop: `1px solid ${theme.customerPalette.border.neutral.neutral100}`,
    },
    balancedWeight: {
        color: theme.customerPalette.text.complementary.complementary600,
        backgroundColor: theme.customerPalette.background.complementary.complementary50,
        border: `1px solid ${theme.customerPalette.border.complementary.complementary100}`,
    },
    selectedWidth: {
        color: theme.customerPalette.text.primary.primary600,
        backgroundColor: theme.customerPalette.background.primary.primary50,
        border: `1px solid ${theme.customerPalette.border.primary.primary100}`,
    },
    selectedWeight: {
        color: theme.customerPalette.text.neutral.neutral800,
        lineHeight: "22px"
    },
    subContainer: {
        border: `1px solid ${theme.customerPalette.border.neutral.neutral100}`,
    },
    lengthInput: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
        "& .MuiOutlinedInput-input": {
            height: "52px",
            padding: "0px 16px",
            fontSize: "16px",
        }
    },
    checkBox: {
        borderTop: `1px solid ${theme.customerPalette.border.neutral.neutral100}`,
    },
    checkBoxValue: {
        color: theme.customerPalette.text.primary.primary800,
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
        "& .MuiOutlinedInput-input": {
            height: "52px",
            padding: "0px 16px",
            fontSize: "16px",
        }
    },
    tableHead: {
        padding: "12px 16px",
        background: theme.customerPalette.background.neutral.neutral50
    },
    tableHeadCell: {
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "16px",
        padding: "12px 0px",
        color: theme.customerPalette.text.neutral.neutral900,
        textAlign: "center",
        '&:first-child': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
          },
          '&:last-child': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
          },
          "&.MuiTableCell-root":{
            borderBottom: "none"
          }
    },
    tableCell: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px",
        padding: "12px",
        color: theme.customerPalette.text.neutral.neutral700,
        textAlign: "center",
    },
    errorMessage: {
        color: theme.customerPalette.text.secondary.secondary500,
    },
    uom: {
        "& .MuiTypography-root": {
            color: theme.customerPalette.text.neutral.neutral400
        }
    },
    editContainer: {
        border: `1px solid ${theme.customerPalette.border.neutral.neutral100}`,
    },
    details: {
        color: theme.customerPalette.text.neutral.neutral600,
    },
    detailBalancedWeight: {
        color: theme.customerPalette.text.primary.primary400,
    },
    tableContaier: {
    },
    "@media (max-width: 480px)": {
        dialogContainer: {
            "& .MuiDialog-paper": {
                width: "448px !important",
                maxWidth: "448px !important",
                borderRadius: "12px"
            },
        },
        webContainer: {
            display: "none"

        },
        mobileContainer: {
            display: "grid"
        },
        tableContaier: {
            width: "840px"
        },

    },
}))


const SlittingCustomizationPopup: React.FC<SlittingCustomizationPopupProps> = ({ dialogOpen, setDialogOpen, selectedUpc, selectedWeight, customization, valueAddedService, setValueAddedServiceTo, balanceWeight, setBalanceWeight, editState, setEditState }) => {

    const classes = useStyles();
    const [slitting, setSlitting] = useState<ISlitting[]>(valueAddedService.slitting);

    const totalSlittingWidth = slitting?.reduce((totalWidth: number, item) => totalWidth + item.width, 0)
    const currentCumulativeWeight = parseFloat(slitting?.reduce((totalWeight: number, item) => totalWeight + item.weightOfCoil, 0).toFixed(3));
    const grandTotal = slitting?.reduce((totalCharges: number, item) => totalCharges + item.totalCharges, 0);

    const selectedWidth: string = String(selectedUpc?.attributes?.SPECIFICATION?.find(attr => attr.name.trim() === "Width")?.attributeOptions || "0");
    const selectedThickness: string = String(selectedUpc?.attributes?.SPECIFICATION?.find(attr => attr.name.trim() === "Thickness")?.attributeOptions || "0");
    const chargesPerMT = customization?.price || 0;
    const density = 7.850

    const [numberOfSlits, setNumberOfSlits] = useState<number | null>(null);

    const handleDialogClose: (event: any, reason: "backdropClick" | "escapeKeyDown" | "closeButtonClick") => void = (event, reason) => {
        if (reason !== "backdropClick") {
            setDialogOpen(false);
        }
    }

    const [maxNoOfSlit, setMaxNoOfSlit] = useState<number>(0)
    useEffect(() => {
        setMaxNoOfSlit(Math.floor(parseFloat(selectedWidth) / customization.minWidth))
    }, [selectedWidth])

    const validationSchema = Yup.object().shape({
        noOfSlit: Yup.number().integer('Slit must be a whole number').max(maxNoOfSlit, `Slit should not be greater then ${maxNoOfSlit}`).min(1, "Slit should be greater than zero").required('Slit of pieces is required'),
        desiredWeight: Yup.number().moreThan(0, "Desired Weight should be greater than zero").max(balanceWeight, `Desired Weight should not be greater than ${balanceWeight.toFixed(3)}`).required('Desired Weight is required'),
        widthArray: Yup.array().of(
            Yup.object().shape({
                width: Yup.number().integer('Width must be a whole number').min(customization?.minWidth, `Width should be equal to or greater than ${customization?.minWidth}`).max(customization?.maxWidth, `Width should be equal to or greater than ${customization?.maxWidth}`).required('Width  is required')
            })
        ).test('total-width', `Total width exceeds the selected width (${parseFloat(selectedWidth)} mm)`, function (value) {
            let totalWidth = 0;
            if (value) {
                value.forEach((item:any) => {
                    totalWidth += item?.width;
                });
            }
            return totalWidth <= parseFloat(selectedWidth);
        }).min(numberOfSlits ? numberOfSlits : 0, "All the field required"),
    });

    const initialFormValues: ISlittingForm = {
        id: null,
        width: null,
        weightOfCoil: null,
        chargesPerMT: null,
        totalCharges: null,
        noOfSlit: null,
        desiredWeight: null,
        widthArray: [],
    };

    const formik = useFormik<ISlittingForm>({
        enableReinitialize: true,
        initialValues: initialFormValues,
        validationSchema: validationSchema,
        validateOnMount: true,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const payload: { slitting: ISlitting[] } = { slitting: [] };
            const numberOfSlits = formik.values.noOfSlit ?? 0;
            for (let index = 0; index < numberOfSlits; index++) {
                const item = formik.values.widthArray[index];
                const id = index + 1;
                const width = item.width;
                const weightOfCoil = parseFloat((((lengthOFCoil * item.width * parseFloat(selectedThickness)) / 1000) * density).toFixed(3));
                const totalCharges = weightOfCoil * chargesPerMT;
                payload.slitting.push({ id, width, weightOfCoil, chargesPerMT, totalCharges });
            }
            setSlitting(payload.slitting);
            setBalanceWeight(parseFloat((balanceWeight - parseFloat(payload.slitting?.reduce((totalWeight: number, item) => totalWeight + item.weightOfCoil, 0).toFixed(3))).toFixed(3)))
            resetForm();
            setEditState(true)
        },
    });

    useEffect(() => {
        setNumberOfSlits(formik.values.noOfSlit);
    }, [formik.values.noOfSlit])

    const [widthArrays, setWidthArrays] = useState<any>([]);
    const handleWidtharay = (value: string, index: number) => {
        const regex = /[a-zA-Z!@#$%^&*(),.?":{}|<>]/;
        if (regex.test(value)) return;
        const newArray: any = [...widthArrays];
        newArray[index] = { width: Number(value) };
        setWidthArrays(newArray);
    };

    const [showDeletePopUp, setShowDeletePopUp] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<any>({ id: null, weight: null });

    const handleDelete = (id: number, weight: number) => {
        setItemToDelete({ id, weight });
        setShowDeletePopUp(true);
    };

    const handleDeleteConfirmed = () => {
        const updatedSlitting = slitting.filter((item) => item.id !== itemToDelete.id);
        setSlitting(updatedSlitting);
        setValueAddedServiceTo({ ...valueAddedService, slitting: valueAddedService.slitting.filter((item: any) => item.id !== itemToDelete.id) });
        setBalanceWeight(balanceWeight + itemToDelete.weight)
        setShowDeletePopUp(false);
    };

    useEffect(() => {
        if (selectedWeight === balanceWeight) {
            setEditState(false)
        }
    }, [selectedWeight, balanceWeight])

    const handleCancelDelete = () => {
        setShowDeletePopUp(false);
    };

    const handleSave = () => {
        setValueAddedServiceTo({ ...valueAddedService, slitting: slitting });
        setDialogOpen(false);
    };

    const [showDeleteAllPopUp, setShowDeleteAllPopUp] = useState(false);

    const handleDeleteAll = () => {
        setShowDeleteAllPopUp(true);
    };

    const handleDeleteAllConfirmed = () => {
        setSlitting([]);
        setValueAddedServiceTo({ ...valueAddedService, slitting: [] });
        setBalanceWeight(balanceWeight + currentCumulativeWeight)
        setShowDeleteAllPopUp(false);
        setEditState(false)
    };

    const handleCancelDeleteAll = () => {
        setShowDeleteAllPopUp(false);
    };

    const [lengthOFCoil, setLengthOFCoil] = useState<number>(0)

    useEffect(() => {
        if (formik.values.desiredWeight)
            setLengthOFCoil(parseFloat(((formik?.values?.desiredWeight / (((parseFloat(selectedWidth) * parseFloat(selectedThickness)) / 1000) * density))).toFixed(3)))
    }, [formik.values.desiredWeight])

    useEffect(() => {
        formik.setFieldValue("widthArray", widthArrays)
    }, [widthArrays])


    const handleEdit = () => {
        setEditState(false)
        setBalanceWeight(balanceWeight + currentCumulativeWeight)
        setNumberOfSlits(slitting.length)
        formik.setValues((prev) => ({
            ...prev,
            id: null,
            width: null,
            weightOfCoil: null,
            chargesPerMT: null,
            totalCharges: null,
            noOfSlit: slitting.length,
            desiredWeight: parseFloat(((currentCumulativeWeight / ((totalSlittingWidth) / 1000)) * ((parseFloat(selectedWidth)) / 1000)).toFixed(2)),
            widthArray: slitting.map(item => ({ width: item.width })),
        }), true)
    }

    const handleCancelEdit = () => {
        setEditState(true)
        setBalanceWeight(balanceWeight - currentCumulativeWeight)
    }

    const selectedHeading = useMemo(() => {
        if (balanceWeight === selectedWeight) {
            return <div className="flex justify-between">
                <div className="flex gap-x-3 text-sm font-medium">
                    <div className={`${classes.balancedWeight} py-2 px-3 rounded`}>{`Selected Weight: ${selectedWeight} MT`}</div>
                    <div className={`${classes.selectedWidth} py-2 px-3 rounded`}>{`Selected Width: ${selectedWidth} mm`}</div>
                </div>
            </div>

        }
        else if (balanceWeight !== selectedWeight) {
            return <div className="flex justify-between">
                <div className="flex gap-x-3 text-sm font-medium">
                    <div className={`${classes.balancedWeight} py-2 px-3 rounded`}>{`Balanced Weight: ${balanceWeight.toFixed(3)} MT`}</div>
                    <div className={`${classes.selectedWidth} py-2 px-3 rounded`}>{`Selected Width: ${selectedWidth} mm`}</div>
                </div>
                <div className={` ${classes.selectedWeight} my-auto text-base`}>{`Selected Weight: ${selectedWeight} MT`}</div>
            </div>
        }

    }, [balanceWeight, selectedWeight])

    const handleClose = () => {
        if (slitting !== valueAddedService.slitting) {
            setBalanceWeight(balanceWeight + currentCumulativeWeight)
        }
        setDialogOpen(false);
        setEditState(true)
    };
    return (
        <Dialog fullWidth className={classes.dialogContainer} PaperProps={{ style: { height: 'auto' } }}
            open={dialogOpen} onClose={handleDialogClose} >
            <div className={`${classes.webContainer} gap-y-8 p-6`}>
            <div className="grid gap-y-4">
            <div className={`${classes.borderBottom} flex justify-between pb-4`} >
                    <div className={`${classes.mainHeading} font-semibold`}>Slitting (CTW)</div>
                    <div className={`cursor-pointer`} onClick={(event) => handleDialogClose(event, "closeButtonClick")} >
                        <img src={closeIcon} alt="closeIcon" />
                    </div>
                </div>
                {selectedHeading}
                </div>

                <div className={`${classes.container} grid gap-y-6 pt-3`}>
           

                    {
                        slitting?.length > 0 && editState &&
                        <div className={`${classes.editContainer} flex justify-between p-4 rounded-xl`}>
                            <div className="flex gap-x-14">
                                <div className="grid gap-y-1">
                                    <div className={`${classes.attributeHeading} text-xs font-normal`}>Number of slits required</div>
                                    <div className={`${classes.attributesValue} text-sm font-semibold`}>{slitting.length}</div>
                                </div>
                                <div className="grid gap-y-1">
                                    <div className={`${classes.attributeHeading} text-xs font-normal`}>Wt. for CTW</div>
                                    <div className={`${classes.attributesValue} text-sm font-semibold`}>{currentCumulativeWeight} MT</div>
                                </div>
                            </div>
                            <div className=''>
                                <img src={editIcon} alt="editIcon" onClick={handleEdit} />
                            </div>
                        </div>
                    }

                    {!editState &&
                        <form className="grid gap-y-6" onSubmit={formik.handleSubmit}>
                            <div className={`${classes.subContainer} grid gap-y-6 p-4 rounded-xl`}>
                                <div className={`${classes.heading} text-base font-medium`}>Please share the number of slits required and the desired Wt. (MT) for CTW</div>
                                <div className="grid grid-cols-2 gap-x-6">
                                    <div className="grid gap-y-1">
                                        <TextFieldV2
                                            fullWidth
                                            type="text"
                                            label="Number of slits required"
                                            placeholder="Enter here"
                                            {...formik.getFieldProps("noOfSlit")}
                                        />
                                        {formik.touched && formik.touched?.noOfSlit && formik.errors?.noOfSlit && (
                                            <div className={classes.errorMessage}>
                                                <small>{formik.errors?.noOfSlit}</small>
                                            </div>
                                        )}
                                    </div>
                                    <div className="grid gap-y-1">
                                        <TextFieldV2
                                            label="Enter the desired Wt. (MT) for CTW"
                                            fullWidth
                                            type="number"
                                            placeholder="Enter here"
                                            {...formik.getFieldProps("desiredWeight")}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end" className={`${classes.uom} text-base font-normal`}>
                                                        MT
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {formik.touched && formik.touched?.desiredWeight && formik.errors?.desiredWeight && (
                                            <div className={classes.errorMessage}>
                                                <small>{formik.errors?.desiredWeight}</small>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className={` ${classes.checkBox} grid gap-y-1 pt-6`}>
                                    <div className="grid grid-cols-3 gap-x-3 gap-y-2">
                                        {formik.values.desiredWeight && numberOfSlits !== null && Array.from({ length: numberOfSlits }, (_, index: number) => (
                                            <div className="w-full grid gap-y-2 h-fit" key={index}>
                                                <TextFieldV2
                                                    fullWidth
                                                    label={`Enter the width of coil (${index + 1})`}
                                                    type="text"
                                                    placeholder="Enter here"
                                                    onChange={(e: any) => { handleWidtharay(e.target.value, index) }}
                                                    value={formik.values?.widthArray[index]?.width}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" className={`${classes.uom} text-base font-normal`}>
                                                                MM
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                {formik.touched && formik.touched?.widthArray && formik.errors?.widthArray && (
                                                    <div className={classes.errorMessage}>
                                                        <small>{(formik.errors?.widthArray as { [key: string]: string }[])?.[index]?.width}</small>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    {formik.touched && formik.touched?.widthArray && formik?.errors?.widthArray && (
                                        <div className={classes.errorMessage}>
                                            <small>{typeof formik?.errors?.widthArray === "string" ? formik?.errors?.widthArray : ""}</small>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {slitting?.length === 0 &&
                                <div className="flex justify-end">
                                    <div className="flex gap-x-4 items-center">
                                        <ButtonV2 label="Cancel" variant="tertiaryContained" size="large"  onClick={(event) => handleDialogClose(event, "closeButtonClick")} />
                                        <ButtonV2 label="+Add" variant="primaryContained" size="large"  type="submit" />
                                    </div>
                                </div>
                            }

                            {slitting?.length > 0 &&
                                <div className="flex justify-end">
                                    <div className="flex gap-x-4 items-center">
                                        <ButtonV2 label="Cancel" variant="tertiaryContained" size="large"  onClick={() => handleCancelEdit()} />
                                        <ButtonV2 label="Save" variant="primaryContained" size="large"  type="submit" />
                                    </div>
                                </div>
                            }

                        </form>
                    }

                    {slitting?.length > 0 &&
                        <div className="grid gap-y-6">
                            <div className="">
                                <Table className="w-full" size="small">
                                    <TableHead className={classes.tableHead}>
                                        <TableRow>
                                            {SLITTING_HEADING.map((item, index) => (
                                                <TableCell key={index} className={`${classes.tableHeadCell} first:pl-3 first:text-left last:pr-3 last:text-right`}>{item}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {slitting.map((data: any, index: number) => (
                                            <TableRow key={index} sx={{ 'border-b &:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell className={`${classes.tableCell} first:pl-8 first:text-left`}>{index + 1}</TableCell>
                                                <TableCell className={classes.tableCell}>{data.width}</TableCell>
                                                <TableCell className={classes.tableCell}>{data.weightOfCoil}</TableCell>
                                                <TableCell className={classes.tableCell}>₹{data.chargesPerMT}</TableCell>
                                                <TableCell className={`${classes.tableCell} last:pr-8 last:text-right`}>₹{data.totalCharges}</TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    <div className="flex justify-end">
                                                        <div className='' onClick={() => handleDelete(data?.id, data?.weightOfCoil)}>
                                                            <img src={deleteIcon} alt="deleteIcon" />
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>

                            <div className="grid gap-y-2 w-1/3">
                                <div className={`${classes.details} text-sm font-medium`}>Width Details</div>
                                <div className={`${classes.selectedWeight} flex justify-between text-lg font-medium`}>
                                    <div>Balance Width</div>
                                    <div className={`${classes.detailBalancedWeight}`}>{Number(selectedWidth) - totalSlittingWidth} mm</div>
                                </div>

                            </div>

                            <div className="flex justify-between">
                                <div className="grid gap-y-2 w-1/3">
                                    <div className={`${classes.details} text-sm font-medium`}>Weight Details</div>
                                    <div className={`${classes.selectedWeight} flex justify-between text-lg font-medium`}>
                                        <div>Weight Details</div>
                                        <div className={`${classes.detailBalancedWeight}`}>{balanceWeight.toFixed(3)} MT</div>
                                    </div>
                                    <div className={`${classes.selectedWeight} flex justify-between text-sm font-medium`}>
                                        <div>Current Cumulative Wt.</div>
                                        <div>{currentCumulativeWeight} MT</div>
                                    </div>
                                </div>
                                <div className="grid gap-y-2 h-fit w-1/3">
                                    <div className={`${classes.details} text-sm font-medium`}>Price Details</div>
                                    <div className={`${classes.selectedWeight} flex justify-between text-lg font-medium`}>
                                        <div>Grand Total</div>
                                        <div className={`${classes.checkBoxValue}`}>{toInr(grandTotal)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {slitting?.length > 0 && editState &&
                        <div className="flex justify-between">
                            <div>
                                <ButtonV2 className="" label="Delete All Services" variant="secondaryText"  size="large" onClick={handleDeleteAll} />
                            </div>

                            <div className="flex gap-x-4 items-center">
                                <ButtonV2 label="Cancel" variant="tertiaryContained"  size="large" onClick={handleClose} />
                                <ButtonV2 label="Save" variant="primaryContained"  size="large" onClick={handleSave} />
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className={`${classes.mobileContainer} gap-y-4 p-4`}>
                <div className="flex justify-between">
                    <div className={`${classes.mainHeading} text-2xl font-medium `}>Slitting (CTW)</div>
                    {editState &&
                        <div className={`cursor-pointer`} onClick={(event) => handleDialogClose(event, "closeButtonClick")} >
                        <img src={closeIcon} alt="closeIcon" />
                    </div>}
                </div>

                <div className={`${classes.container} grid gap-y-6 pt-3`}>
                    {balanceWeight === selectedWeight &&
                        <div className="grid gap-y-2">
                            <div className={`${classes.balancedWeight} py-2 px-3 rounded-3xl`}>{`Selected Weight: ${selectedWeight} MT`}</div>
                            <div className={`${classes.selectedWidth} py-2 px-3 rounded-3xl`}>{`Selected Width: ${selectedWidth} mm`}</div>
                        </div>
                    }

                    {balanceWeight !== selectedWeight &&
                        <div className="grid gap-y-2">
                            <div className={`${classes.balancedWeight} py-2 px-3 rounded-3xl`}>{`Balanced Weight: ${balanceWeight} MT`}</div>
                            <div className={`${classes.selectedWidth} py-2 px-3 rounded-3xl`}>{`Selected Width: ${selectedWidth} mm`}</div>
                            <div className={` ${classes.selectedWeight} py-2 px-3 text-xs`}>{`Selected Weight: ${selectedWeight} MT`}</div>
                        </div>
                    }


                    {
                        slitting?.length > 0 && editState &&
                        <div className={`${classes.editContainer} flex justify-between p-4 rounded-xl`}>
                            <div className="grid gap-y-3">
                                <div className="grid gap-y-1">
                                    <div className="text-sm ">Number of slits required</div>
                                    <div className="text-base font-medium">{slitting.length}</div>
                                </div>
                                <div className="grid gap-y-1">
                                    <div className="text-sm ">Wt. for CTW</div>
                                    <div className="text-base font-medium">{currentCumulativeWeight} MT</div>
                                </div>
                            </div>
                            <div className=''>
                                <img src={editIcon} alt="editIcon" onClick={handleEdit} />
                            </div>
                        </div>
                    }


                    {!editState &&
                        <form className="grid gap-y-6" onSubmit={formik.handleSubmit}>
                            <div className={`${classes.subContainer} grid gap-y-6 p-4 rounded-xl`}>
                                <div className={`${classes.mainHeading} text-base font-medium`}>Please share the number of slits required and the desired Wt. (MT) for CTW</div>
                                <div className="grid grid-cols-1 gap-y-3">
                                    <div className="grid gap-y-1">
                                        <TextFieldV2
                                            fullWidth
                                            type="text"
                                            label="Number of slits required"
                                            placeholder="Enter here"
                                            {...formik.getFieldProps("noOfSlit")}
                                        />
                                        {formik.touched && formik.touched?.noOfSlit && formik.errors?.noOfSlit && (
                                            <div className={classes.errorMessage}>
                                                <small>{formik.errors?.noOfSlit}</small>
                                            </div>
                                        )}
                                    </div>
                                    <div className="grid gap-y-1">
                                        <TextFieldV2
                                            label="Enter the desired Wt. (MT) for CTW"
                                            fullWidth
                                            type="number"
                                            placeholder="Enter here"
                                            {...formik.getFieldProps("desiredWeight")}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end" className={`${classes.uom} text-base font-normal`}>
                                                        MT
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {formik.touched && formik.touched?.desiredWeight && formik.errors?.desiredWeight && (
                                            <div className={classes.errorMessage}>
                                                <small>{formik.errors?.desiredWeight}</small>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className={` ${classes.checkBox} grid gap-y-1 pt-6`}>
                                    <div className="grid grid-cols-1 gap-x-3 gap-y-2">
                                        {formik.values.desiredWeight && numberOfSlits !== null && Array.from({ length: numberOfSlits }, (_, index: number) => (
                                            <div className="w-full grid gap-y-2 h-fit" key={index}>
                                                <TextFieldV2
                                                    fullWidth
                                                    label={`Enter the width of coil (${index + 1})`}
                                                    type="text"
                                                    placeholder="Enter here"
                                                    onChange={(e: any) => { handleWidtharay(e.target.value, index) }}
                                                    value={formik.values?.widthArray[index]?.width}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" className={`${classes.uom} text-base font-normal`}>
                                                                MM
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                {formik.touched && formik.touched?.widthArray && formik.errors?.widthArray && (
                                                    <div className={classes.errorMessage}>
                                                        <small>{(formik.errors?.widthArray as { [key: string]: string }[])?.[index]?.width}</small>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    {formik.touched && formik.touched?.widthArray && formik?.errors?.widthArray && (
                                        <div className={classes.errorMessage}>
                                            <small>{typeof formik?.errors?.widthArray === "string" ? formik?.errors?.widthArray : ""}</small>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {slitting?.length === 0 &&
                                <div className="flex justify-end">
                                    <div className="flex gap-x-4 items-center">
                                        <ButtonV2 label="Cancel" variant="tertiaryContained"  size="medium" onClick={(event) => handleDialogClose(event, "closeButtonClick")} />
                                        <ButtonV2 label="+Add" variant="primaryContained"  size="medium" type="submit" />
                                    </div>
                                </div>
                            }

                            {slitting?.length > 0 &&
                                <div className="flex justify-end">
                                    <div className="flex gap-x-4 items-center">
                                        <ButtonV2 label="Cancel" variant="tertiaryContained"  size="medium" onClick={() => handleCancelEdit()} />
                                        <ButtonV2 label="Save" variant="primaryContained"  size="medium" type="submit" />
                                    </div>
                                </div>
                            }

                        </form>
                    }



                    {slitting?.length > 0 &&
                        <div className="grid gap-y-6">
                            <div className="grid gap-y-2">

                                <div className={`overflow-x-auto overflow-hidden w-full`}>
                                    <div className={`${classes.tableContaier} border rounded-lg`}>
                                        <Table className="w-full" size="small">
                                            <TableHead className={classes.tableHead}>
                                                <TableRow>
                                                    {SLITTING_HEADING.map((item, index) => (
                                                        <TableCell key={index} className={`${classes.tableHeadCell} first:pl-4 first:text-left last:pr-4 last:text-right`}>{item}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {slitting.map((data: any, index: number) => (
                                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell className={`${classes.tableHeadCell} first:pl-8 first:text-left`}>{index + 1}</TableCell>
                                                        <TableCell className={classes.tableCell}>{data.width}</TableCell>
                                                        <TableCell className={classes.tableCell}>{data.weightOfCoil}</TableCell>
                                                        <TableCell className={classes.tableCell}>{data.chargesPerMT}</TableCell>
                                                        <TableCell className={`${classes.tableHeadCell} last:pr-8 last:text-right`}>{data.totalCharges}</TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <div className="flex justify-end">
                                                                <div className='' onClick={() => handleDelete(data?.id, data?.weightOfCoil)}>
                                                                    <img src={deleteIcon} alt="deleteIcon" />
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>

                                <div className="flex justify-end">
                                    <ButtonV2 className="!px-0" label="Delete All Services"  variant="primaryText" size="medium" onClick={handleDeleteAll} />
                                </div>
                            </div>

                            <div className="grid gap-y-2 w-full">
                                <div className={`${classes.details} text-sm font-medium`}>Width Details</div>
                                <div className={`${classes.selectedWeight} flex justify-between text-lg font-medium`}>
                                    <div>Balance Width</div>
                                    <div className={`${classes.detailBalancedWeight}`}>{Number(selectedWidth) - totalSlittingWidth} mm</div>
                                </div>

                            </div>

                            <div className="grid gap-y-6">
                                <div className="grid gap-y-2 w-full">
                                    <div className={`${classes.details} text-sm font-medium`}>Weight Details</div>
                                    <div className={`${classes.selectedWeight} flex justify-between text-lg font-medium`}>
                                        <div>Weight Details</div>
                                        <div className={`${classes.detailBalancedWeight}`}>{balanceWeight.toFixed(3)} MT</div>
                                    </div>
                                    <div className={`${classes.selectedWeight} flex justify-between text-sm font-medium`}>
                                        <div>Current Cumulative Wt.</div>
                                        <div>{currentCumulativeWeight} MT</div>
                                    </div>
                                </div>
                                <div className="grid gap-y-2 h-fit w-full">
                                    <div className={`${classes.details} text-sm font-medium`}>Price Details</div>
                                    <div className={`${classes.selectedWeight} flex justify-between text-lg font-medium`}>
                                        <div>Grand Total</div>
                                        <div className={`${classes.checkBoxValue}`}>{toInr(grandTotal)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }

                    {slitting?.length > 0 && editState &&
                        <div className="flex justify-end">


                            <div className="flex gap-x-4 items-center">
                                <ButtonV2 label="Close" variant="tertiaryContained"  size="medium" onClick={handleClose} />
                                <ButtonV2 label="Save" variant="primaryContained"  size="medium" onClick={handleSave} />
                            </div>
                        </div>
                    }
                </div>
            </div>

            {
                showDeletePopUp &&
                <SuccessFailurePopupV2
                    setShowPopup={setShowDeletePopUp}
                    variant="Delete"
                    heading="Delete Service?"
                    description="Are you sure you want to delete this value adding service?"
                    button1={{
                        text: "Yes",
                        variant: "tertiaryContained",
                        size: "large",
                        onClick: handleDeleteConfirmed,
                    }}
                    button2={{
                        text: "No",
                        variant: "secondaryContained",
                        size: "large",
                        onClick: handleCancelDelete,
                    }}
                />

            }
            {
                showDeleteAllPopUp &&
                <SuccessFailurePopupV2
                    setShowPopup={setShowDeleteAllPopUp}
                    variant="Delete"
                    heading="Delete All Service?"
                    description="Are you sure you want to delete all value adding services?"
                    button1={{
                        text: "Yes",
                        variant: "tertiaryContained",
                        size: "large",
                        onClick: handleDeleteAllConfirmed,
                    }}
                    button2={{
                        text: "No",
                        variant: "secondaryContained",
                        size: "large",
                        onClick: handleCancelDeleteAll,
                    }}
                />

            }


        </Dialog>
    )
}

export default SlittingCustomizationPopup;