import { Outlet } from 'react-router-dom';
import steelBazaar from '../../assets/images/steelBazaar.jpg'
import { createUseStyles } from "react-jss";
import SideBarMenu from './NavigationSideBar/SideBarMenu';
import AdminHeader from '../../App/Components/AdminPannel/Header/AdminHeader';
import Breadcrumbs from '../molecules/BreadCrumbs/BreadCrumbs';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    rightContainer: {
        width: "calc(100% - 15rem)",
        justifySelf: "flex-end",
        marginLeft: "15rem"
    },
    menuOption: {
        "&li": {
            margin: "0.62rem 0.75rem",
            fontSize: "14px"
        }
    },
    menu: {
        width: '15rem',
        position: 'fixed',
        zIndex: 99,
        height: '100vh',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        background: 'white',
        boxShadow: `0px 1px 4px 0px ${theme.palette.boxShadow.primaryDarkAccent}`,
        "&::-webkit-scrollbar": {
            width: '6px'
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.text.secondaryLight
        }
    }
}));

const AdminLayout: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={`${classes.container} flex`}>
            <div className={classes.menu}>
                <div className='flex justify-center mt-3.5 px-5'>
                    <img src={steelBazaar} alt='Steel Bazaar' className='w-full' />
                </div>
                <div className={`${classes.menuOption} mt-6 mb-6}`}>
                    <SideBarMenu />
                </div>
            </div>
            <div className={`${classes.rightContainer} p-4`}>
                <AdminHeader />
                <div>
                    <Breadcrumbs />
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default AdminLayout;
