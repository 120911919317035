import { Route, Routes } from 'react-router-dom';
import CatalogueBundlingListPage from '../../../components/pages/CatalogueBundling/CatalogueBundlingList.page';
import BundleCreatePage from '../../../components/pages/CatalogueBundling/Bundle/BundleCreate.page';
import BundleLotCreatePage from '../../../components/pages/CatalogueBundling/BundleLot/BundleLotCreate.page';
import BundleViewPage from '../../../components/pages/CatalogueBundling/Bundle/BundleView.page';
import BundleLotViewPage from '../../../components/pages/CatalogueBundling/BundleLot/BundleLotView.page';
import BundleUpdatePage from '../../../components/pages/CatalogueBundling/Bundle/BundleUpdate.page';
import BundleLotUpdatePage from '../../../components/pages/CatalogueBundling/BundleLot/BundleLotUpdate.page';

export const CATALOGUE_BUNDLING_ROUTES = {
    CATALOGUE_BUNDLING_LIST: "/catalogue-bundling",
    CATALOGUE_BUNDLE_CREATE: "/catalogue-bundling/bundle/add",
    CATALOGUE_BUNDLE_UPDATE: "/catalogue-bundling/bundle/:id/update",
    CATALOGUE_BUNDLE_VIEW: "/catalogue-bundling/bundle/:id",
    CATALOGUE_BUNDLE_LOT_CREATE: "/catalogue-bundling/lot/add",
    CATALOGUE_BUNDLE_LOT_UPDATE: "/catalogue-bundling/lot/:id/update",
    CATALOGUE_BUNDLE_LOT_VIEW: "/catalogue-bundling/lot/:id",
}

const CatalogueBundlingRoutes = () => {
    return (
        <Routes>
            <Route index element={<CatalogueBundlingListPage />} />

            <Route path="/bundle/add" element={<BundleCreatePage />} ></Route>
            <Route path="/bundle/:id" element={<BundleViewPage />} ></Route>
            <Route path="/bundle/:id/update" element={<BundleUpdatePage />} ></Route>

            <Route path="/lot/:id" element={<BundleLotViewPage />} ></Route>
            <Route path="/lot/add" element={<BundleLotCreatePage />} ></Route>
            <Route path="/lot/:id/update" element={<BundleLotUpdatePage />} ></Route>
        </Routes>
    )
}

export default CatalogueBundlingRoutes;