import React from 'react'
import { useSnackbar } from '../../../hooks/useSnackBar';
import SuperCategoryFormTemplate from '../../templates/Category/SuperCategoryForm.template';
import { ADMIN_ROUTES, MODE, STATUS } from '../../../utils/constant';
import { CategoryPayload, useCategoryService } from '../../../services/useCategoryService';
import { HTTP_STATUS } from '../../../utils/types';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { makeRoute } from '../../../utils/helper';

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Category Name is required"),
  description: Yup.string().required("Category Description is required")
  .test('no-p-br-tags', 'Defect details cannot be Empty', ((value:any)=> value !== '<p><br></p>')),
});

const SuperCategoryCreatePage: React.FC = () => {
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const categoryService = useCategoryService();
  const navigate = useNavigate();

  const onBack = () => {
    navigate(makeRoute(ADMIN_ROUTES.CATEGORY, {query: { type: 'SUPER_CATEGORY'}}));
  }

  const createCategory = async (category: CategoryPayload) => {
      categoryService.createCategory(category)
      .then(res => {
        if (res.status === HTTP_STATUS.OK) {
          onBack();
        } else if (res.status ===  HTTP_STATUS.BAD_REQUEST) {
          showSnackbar('error', res.data.statusMessage);
        }
      }).catch((error:any) => {
        showSnackbar('error', "Failed to create Super Category");
      })
  }

  const formik = useFormik<CategoryPayload>({
    initialValues: {
      name: "",
      status: STATUS.ACTIVE,
      description: "",
      level: 0,
      prefix: null,
      parentId: null
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      createCategory({ ...values, status: values.status })
    }
  })

  return (
    <div>
      {SnackBarComponent}
      <SuperCategoryFormTemplate mode={MODE.ADD} onBack={onBack} formik={formik}/>
    </div>
  )
}

export default SuperCategoryCreatePage