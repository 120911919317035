import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const CUSTOMER_GROUP_MASTER_URLS = {
  CREATE_CUSTOMER_GROUP: "/customer-group",
  GET_ALL_CUSTOMER_GROUP: "/customer-groups",
  GET_ALL_USER: "/users",
  GET_CUSTOMER_GROUP: "/customer-group/:id",
  GET_CUSTOMER_UNDER_GROUP: "/customer-group/:id/customers",
  GET_CUSTOMER: "/customer-groups/customers",
  GET_UPC: "/search/upc",
  UPDATE_CUSTOMER_GROUP: "/customer-group/:id",
  UPDATE_CUSTOMER_GROUP_MAPPING: "/customer-groups/customers/:id"
};

export interface ICustomerGroupMasterRequestBody {
  groupName: string;
  groupOwner: number;
  productCategoryId: number;
  description: string;
  groupType: string;
  industryType: string;
  customerIds: number[];
}

interface ICustomerGroupMasterSearchParams {
  page: number;
  size: number;
  sort?: string;
  groupName?: string;
}

export interface ICustomerGroupMasterQueryParams {
  groupIds?: number[] | null;
  groupType?: string[] | null;
  groupOwner?: number[] | null;
  city?: string[] | null;
  businessClassification?: string[] | null;
  turnOver?: string[] | null;
  customerId?: number | null;
}

interface IUserSearchParams {
  page: number;
  size: number;
  sort?: string;
  userType?: string;
  search?: string;
}

interface ICustomerSearchParams {
  page: number;
  size: number;
  sort?: string;
  customerName?: string;
}

interface IUpcSearchParams {
  page: number;
  size: number;
  sort?: string;
  upcCode?: string;
}

export interface ICustomerQueryParams {
  city?: string[] | null;
  businessClassification?: string[] | null;
  turnOver?: string[] | null;
  businessType?: string[] | null;
  upcCodes?: string[] | null;
  customerIds?: number[] | null;
  productCategoryIds?: number[] | null;
  industryType?: string[] | null;
}

export interface ICatalogueAttributes {
  minValue: string | null;
  maxValue: string | null;
  values: string[] | number[] | null;
}

export interface IUpcSearchQueryParams {
  superCategoriesIds?: number[] | null;
  mainCategoriesIds?: number[] | null;
  productCategoriesIds?: number[] | null;
  standardIds?: number[] | null;
  gradeIds?: number[] | null;
  brandIds?: number[] | null;
  shapes?: string[] | null;
  manufacturerIds?: number[] | null;
  classes?: string[] | null;
  attributes?: any,
  upcCodes? : string[]
}


export const useCustomerGroupMasterService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const createCustomerGroup = (data: ICustomerGroupMasterRequestBody) => {
    return request(API_METHOD.POST, CUSTOMER_GROUP_MASTER_URLS.CREATE_CUSTOMER_GROUP, authenticatedUser, data);
  };

  const getAllCustomerGroup = (params: ICustomerGroupMasterSearchParams, data: ICustomerGroupMasterQueryParams) => {
    return request(API_METHOD.POST, CUSTOMER_GROUP_MASTER_URLS.GET_ALL_CUSTOMER_GROUP, authenticatedUser, data, { params } );
  };

  const getAllUser = (params: IUserSearchParams) => {
    return request(API_METHOD.GET, CUSTOMER_GROUP_MASTER_URLS.GET_ALL_USER, authenticatedUser, null, { params } );
  };

  const getCustomerGroupById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams( CUSTOMER_GROUP_MASTER_URLS.GET_CUSTOMER_GROUP, { id } ), authenticatedUser);
  };

  const getCustomerUnderGroupById = (id: number, params: any) => {
    return request(API_METHOD.GET, replaceUrlParams( CUSTOMER_GROUP_MASTER_URLS.GET_CUSTOMER_UNDER_GROUP, { id } ), authenticatedUser, null, {params});
  };

  const getCustomer = (params: ICustomerSearchParams, data: ICustomerQueryParams) => {
    return request(API_METHOD.POST, CUSTOMER_GROUP_MASTER_URLS.GET_CUSTOMER, authenticatedUser, data, { params } );
  };

  const getUpcByMaterialFilter = (params: IUpcSearchParams, data: IUpcSearchQueryParams) => {
    return request(API_METHOD.POST, CUSTOMER_GROUP_MASTER_URLS.GET_UPC, authenticatedUser, data, { params }, null, process.env.REACT_APP_API_V3_URL);
  };

  const updateCustomerGroup = (id: number, data: ICustomerGroupMasterRequestBody) => {
    return request(API_METHOD.PUT, replaceUrlParams( CUSTOMER_GROUP_MASTER_URLS.UPDATE_CUSTOMER_GROUP, { id } ), authenticatedUser, data);
  };

  const updateCustomerGroupMapping = (id: number, data: number[]) => {
    return request(API_METHOD.PUT, replaceUrlParams( CUSTOMER_GROUP_MASTER_URLS.UPDATE_CUSTOMER_GROUP_MAPPING, { id } ), authenticatedUser, data);
  };

  return {
    createCustomerGroup,
    getAllCustomerGroup,
    getAllUser,
    getCustomerGroupById,
    getCustomerUnderGroupById,
    getCustomer,
    getUpcByMaterialFilter,
    updateCustomerGroup,
    updateCustomerGroupMapping
  };
};
