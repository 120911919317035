import { Route, Routes } from "react-router";
import PostProductListPage from "../../../../components/pages/MySteelBazaar/PostProduct/PostProductList.page";
import PostProductAddPage from "../../../../components/pages/MySteelBazaar/PostProduct/PostProductAdd.page";
import PostProductViewPage from "../../../../components/pages/MySteelBazaar/PostProduct/PostProductView.page";
import PostProductUpdatePage from "../../../../components/pages/MySteelBazaar/PostProduct/PostProductUpdate.page";

const PostProductRoutes = () => {
    return (
        <div>
            <Routes>
                <Route index element={<PostProductListPage />} />
                <Route path="add" element={<PostProductAddPage />} ></Route>
                <Route path=":vendorId/view/:vendorProductId" element={<PostProductViewPage />} />
                <Route path="edit/:vendorProductId" element={<PostProductUpdatePage />} />
            </Routes>
        </div>
    )
}

export default PostProductRoutes;
