import React from 'react'
import { createUseStyles } from 'react-jss';
import { FormikProps } from 'formik';
import { SERVICE_STATUS_OPTIONS } from '../../../../utils/constant';
import { makeRoute, toInr } from '../../../../utils/helper';
import ButtonV2 from '../../../atoms/ButtonV2';
import StatusV2 from '../../../atoms/Status/StatusV2';
import { useNavigate, useParams } from 'react-router-dom';
import editIcon from "../../../../assets/icons/editPenWhiteOutlinedIcon.svg"
import { ILotFormikPayload } from '../../../../services/useCatalogueBundlingService';
import { CATALOGUE_BUNDLING_ROUTES } from '../../../../App/Core/Routes/CatalogueBundlingRoutes';
import TableV2, { ColumnType } from '../../../organisms/TableV2';

interface BundleViewTemplateProps {
    mode: string,
    formik: FormikProps<ILotFormikPayload>,
    onBack: () => void
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    textColor: {
        color: theme.palette.text.primaryDarkLight
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    property: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    location: {
        color: theme.palette.textV2.tertiary.tertiary900,
        "& span": {
            color: theme.palette.textV2.tertiary.tertiary600
        }
    },
}));

const BundleLotViewTemplate: React.FC<BundleViewTemplateProps> = ({ mode, onBack, formik }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const params = useParams();

    const handleActionRoute = (id: number) => () => {
        navigate(makeRoute(CATALOGUE_BUNDLING_ROUTES.CATALOGUE_BUNDLE_UPDATE, { params: { id: id } }));
    };

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: 0,
            currentPage: 0,
            isVisible: false,
            limit: 0,
        },
        columns: [
            { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Product Code", key: "name", type: "string" as ColumnType, props: { className: '' } },
            { label: "Start Date", key: "startDate", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Expiry Date", key: "expiryDate", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Bundle MOQ", key: "bundleMoq", type: "number" as ColumnType, props: { className: '' } },
            { label: "Price", key: "price", type: "number" as ColumnType, props: { className: '' } },
            { label: "Bundle Lot MOQ", key: "bundleLotMoq", type: "number" as ColumnType, props: { className: '' } },
        ],
    };

    const records = formik.values.productDetails?.map((bundle: any, index: number) => [
        index + 1,
        bundle?.name,
        bundle?.startDate,
        bundle?.expiryDate,
        `${bundle?.bundleMoq} MT`,
        toInr(bundle?.price),
        `${bundle?.bundleLotMoq} MT`,
    ]);

    return (
        <form className="grid gap-y-8" >
            <div className='flex justify-between'>
                <div className={`${classes.mainHeading} text-lg font-semibold my-auto`}>{formik.values?.name}</div>
                <ButtonV2 onClick={handleActionRoute(Number(params.id))} variant='primaryContained' label={"Edit Capability"} startIcon={<img src={editIcon} alt="editIcon" />} />
            </div>

            <div className='grid gap-y-4'>
                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium my-auto`}>Lot Details</div>
                    <div className='grid grid-cols-5 gap-x-4'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Bundle Lot Name</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.name}</div>
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className='grid gap-y-1'>
                        <div className={`${classes.heading} text-base font-medium`}>Selected Bundles</div>
                        <div className={`${classes.location} text-base font-medium`}>
                            Source Location: <span className='text-sm '>New Delhi</span>
                        </div>
                    </div>
                    <TableV2 schema={schema} records={records} />
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Bundle Lot Details</div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Bundle Lot MOQ*</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.moq} MT</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Bundle Lot Price*</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.price && toInr(formik.values?.price)}</div>
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Valid From & Valid Until</div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Start Date</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.startDate}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Expiry Date</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.expiryDate}</div>
                        </div>
                    </div>
                </div>

                <StatusV2 heading="Service Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" mode={mode} />
            </div>

            <div className="flex justify-end gap-4">
                <ButtonV2 variant="tertiaryContained" label='Back' onClick={onBack} />
            </div>
        </form>
    )
}

export default BundleLotViewTemplate;