import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { initialPaginationValues, statusOptions, statusOptionsApproval } from '../../../utils/constant';
import FilterChipV2 from '../../molecules/FilterChip/FilterChipV2';
import ButtonV2 from '../../atoms/ButtonV2';
import { IMultiSelectOption } from '../../atoms/MultiSelectInput2';
import { IPagination, option } from '../../../utils/types';
import TextFieldV2 from '../../atoms/TextFieldV2';
import search from '../../../assets/images/search.svg'
import { InputAdornment } from '@mui/material';
import { useSecondaryInventoryService } from '../../../services/useSecondaryInventoryService';
import { ICatalogueApprovalListParams, useCatalogueApprovalService } from '../../../services/useCatalogueApprovalService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import CatalogueApprovalListTemplate from '../../templates/CatalogueApproval/CatalogueApprovalList.template';

export interface IVendorCatalogue {
    vendorId: number;
    vendorName: string;
    vendorStatus: string;
    categoryLinkedCount: string;
    totalProductLinkedCount: number;
    activeProductLinkedCount: number;
    pendingProductLinkedCount: number;
    awaitingProductLinkedCount: number;
    inActiveProductLinkedCount: number;
}

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: "#050915",
    },
}));


const initialFilterValues = {
    productCategory: null,
    status: "",
    totalProductCount: "",
    search: ""
};

const CatalogueApprovalListPage: React.FC = () => {

    const classes = useStyles();

    const catalogueApprovalService = useCatalogueApprovalService();
    const secondaryInventoryService = useSecondaryInventoryService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const [status, setStatus] = useState<IMultiSelectOption[]>([]);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [productCategory, setProductCategory] = useState<IMultiSelectOption[]>([]);
    const [productCategories, setProductCategoriesTo] = useState<option[]>([]);
    const [catalogueToBeApproved, setCatalogueToBeApproved] = useState<IVendorCatalogue[]>([]);
    const [catalogueApprovalFilters, setcatalogueApprovalFilters] = useState<ICatalogueApprovalListParams>(initialFilterValues);

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage,
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage,
        }));
    };

    const handleStatusChange = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setStatus(selected);
        clearWithCrossIcon && handleSave(selected, "status");
    };

    const handleProductCategoryChange = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setProductCategory(selected);
        clearWithCrossIcon && handleSave(selected, "category");
    };

    const handleSave = (data: IMultiSelectOption[], type: string) => {
        const valueString = data.length > 0 ? data[0].value : '';
        switch (type) {
            case 'status':
                handleFiltersChange("status", valueString);
                break;
            case 'category':
                handleFiltersChange("productCategory", valueString);
                break;
        }
    };


    const handleFiltersChange = (name: string, value: any) => {
        setPaginationTo({ ...pagination, currentPage: 0 });
        setcatalogueApprovalFilters({ ...catalogueApprovalFilters, [name]: value ?? "" });
    };

    const handleClearClick = (filterType: string) => {
        switch (filterType) {
            case 'status':
                setStatus([]);
                handleFiltersChange("status", '');
                break;
            case 'category':
                setProductCategory([]);
                handleFiltersChange("productCategory", '');
                break;
            default:
                setStatus([]);
                setProductCategory([]);                
        }
    };

    const getAllCategories = () => {
        let queryParams = {
            name: '',
            level: 2,
            parentId: null,
            page: 0,
            size: 100
        };
        secondaryInventoryService.getCategories(queryParams)
            .then((response) => {
                if (response.data) {
                    let productCategoryFilterValues: option[] = [];
                    response?.data?.data?.content?.forEach((productCategory: any) => {
                        productCategoryFilterValues.push({ value: productCategory.id, label: productCategory.name });
                    });
                    setProductCategoriesTo(productCategoryFilterValues);
                }
            })
            .catch((error) => {
                setProductCategoriesTo([]);
            });
    };

    const getCataloguesToBeApproved = (page: number, size: number) => {
        let queryParams = {
            page: page,
            size: size,
            productCategory: catalogueApprovalFilters.productCategory,
            status: catalogueApprovalFilters.status,
            totalProductCount: catalogueApprovalFilters.totalProductCount,
            search: catalogueApprovalFilters.search
        };
        catalogueApprovalService.getCataloguesToBeApproved(queryParams)
            .then((response) => {
                if (response.data.data) {
                    setCatalogueToBeApproved(response.data.data.content);
                    const { totalElements, totalPages, content } = response.data.data;
                    setPaginationTo(prevPagination => ({
                        ...prevPagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    }));
                }
            })
            .catch((error) => {
                showSnackbar("error", error)
            });
    };

    useEffect(() => {
        getAllCategories();
    }, [])

    useEffect(() => {
        getCataloguesToBeApproved(pagination.currentPage, pagination.pageSize);
    }, [catalogueApprovalFilters, pagination.currentPage, pagination.pageSize])

    return (
        <div>
            <div className={`${classes.heading} text-2xl mb-3 font-medium`}>Vendor Catalogue Approval</div>
            <div className='flex gap-x-3'>
                <div>
                    <FilterChipV2
                        options={statusOptionsApproval}
                        label=" "
                        value={status}
                        onchange={handleStatusChange}
                        placeholder="Status"
                        ClearButtonComponent={ButtonV2}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('status')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleSave(status, "status")}
                        isSingleSelect={true}
                    />
                </div>
                <div>
                    <FilterChipV2
                        options={productCategories}
                        label=" "
                        value={productCategory}
                        onchange={handleProductCategoryChange}
                        placeholder="Product Category"
                        ClearButtonComponent={ButtonV2}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('category')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        isSingleSelect={true}
                        saveButtonOnClick={() => handleSave(productCategory, "category")}
                    />
                </div>
                <div className='flex ml-auto '>
                    <TextFieldV2
                        label=''
                        variant="outlined"
                        placeholder="Search...."
                        name='search'
                        onChange={(event) => {
                            handleFiltersChange("search", event.target.value)
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start" > <img src={search} alt="icon" /></InputAdornment>,
                        }}
                    />
                </div>
            </div>
            <CatalogueApprovalListTemplate catalogueToBeApproved={catalogueToBeApproved} handlePaginationChange={handlePaginationChange} handleRowsPerPageChange={handleRowsPerPageChange} pagination={pagination} />
        </div>
    );
};

export default CatalogueApprovalListPage;
