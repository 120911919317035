import React from 'react'
import { useSnackbar } from '../../../hooks/useSnackBar';
import { Category, CategoryPayload, useCategoryService } from '../../../services/useCategoryService';
import { useNavigate, useParams } from 'react-router-dom';
import { ADMIN_ROUTES, STATUS } from '../../../utils/constant';
import { HTTP_STATUS } from '../../../utils/types';
import MainCategoryFormTemplate from '../../templates/Category/MainCategoryForm.template';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { makeRoute } from '../../../utils/helper';

interface MainCategoryUpdatePageProps {
  mode: string
  category: Category
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Category Name is required"),
  description: Yup.string().required("Category Description is required")
  .test('no-p-br-tags', 'Defect details cannot be Empty', ((value:any)=> value !== '<p><br></p>')),
  parentId: Yup.number().required("Super Category is required")
    .notOneOf([0], "Super Category is required"),
});

const MainCategoryUpdatePage: React.FC<MainCategoryUpdatePageProps> = ({ mode, category }) => {
  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const categoryService = useCategoryService();
  const navigate = useNavigate();
  const params = useParams();

  const categoryId = Number(params.id);

  const onBack = () => {
    navigate(makeRoute(ADMIN_ROUTES.CATEGORY, {query: { type: 'MAIN_CATEGORY'}}));
  }

  const updateMainCategory = async (category: CategoryPayload) => {
    categoryService.updateCategory(categoryId, category)
      .then(res => {
        if (res.status === HTTP_STATUS.OK) {
          onBack();
        } else if (res.status ===  HTTP_STATUS.BAD_REQUEST) {
          showSnackbar('error', "Category already exists. Please try again");
        }
      }).catch((error) => {
        showSnackbar('error', "Failed to update Main Category");
      })
  }

  const formik = useFormik<CategoryPayload>({
    initialValues: {
      name: category.name ?? "",
      status: category.status ?? STATUS.INACTIVE,
      description: category?.description ?? "",
      parentId: category?.parentId ?? null,
      level: 1,
      prefix: null,
      parentCategory: {label: category?.parentCategory?.name, id: category?.parentCategory?.id},
    },
    validationSchema,
    onSubmit: async (values) => {
      updateMainCategory({ ...values, status: values.status })
    }
  })

  return (
    <div>
      {SnackBarComponent}
      <MainCategoryFormTemplate mode={mode} onBack={onBack} formik={formik} />
    </div>
  )
}

export default MainCategoryUpdatePage