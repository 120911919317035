import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { GENERIC_FILTER_SECTIONS, MODE, PRODUCT_SECTION } from '../../../../utils/constant';
import { titleModification } from '../../../../utils/helper';
import ButtonV2 from '../../../atoms/ButtonV2';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import { BUNDLE_FORM_STATE } from '../../../pages/CatalogueBundling/Bundle/BundleCreate.page';
import UpcMpcSearchDialogModal, { IMaterialFilterData } from '../../GenericSearchDialog/GenericSerachDialog.template';
import { HTTP_STATUS } from '../../../../utils/types';
import { IProductSearchParams, useGenericSearchService } from '../../../../services/useGenericSearchService';

export interface BundleDetailsFormTemplateProps {
    mode: string;
    onClose: () => void;
    formik: any;
    setFormStateTo: (state: BUNDLE_FORM_STATE) => () => void;
}

export interface IProductDetails {
    productId: number;
    productName: string;
    productCode: string;
    moq: number | null;
    availableStock: number | null;
    price: number | null;
    bundleMoq: number | null;
    classType: string;
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    inActiveProgressBar: {
        backgroundColor: theme.palette.backgroundV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    checkBox: {
        color: theme.palette.textV2.tertiary.tertiary300,
        '&.Mui-checked': {
            color: theme.palette.textV2.primary.primary400,
        },
    },
    value: {
        color: theme.palette.textV2.primary.primary950
    },
}));

const BundleDetailFormTemplate: React.FC<BundleDetailsFormTemplateProps> = ({ mode, onClose, formik, setFormStateTo }) => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [upcFilterData, setUpcFilterData] = useState<IMaterialFilterData>({});

    const handleNext = () => {
        formik.setTouched({ name: true }, true)
        const errors = Object.keys(formik.errors).find(key => ['name'].includes(key))
        if (errors) return;
        setFormStateTo(BUNDLE_FORM_STATE.EDIT_PRODUCTS)();
    }

    const handleFilterClear = () => {
        setUpcFilterData({});
    }

    useEffect(() => {
        formik.setFieldValue('warehouseId', upcFilterData?.warehouse?.id);
        formik.setFieldValue('warehouse', upcFilterData?.warehouse?.label);
    }, [upcFilterData?.warehouse])

    useEffect(() => {
        if (!upcFilterData?.warehouse?.id && formik.values?.warehouseId) {
            upcFilterData.warehouse = {
                label: formik.values?.warehouse || '',
                id: formik.values?.warehouseId,
            };
        }
        formik.setFieldValue('productDetails', []);
    }, [formik.values?.warehouseId]);

    const genericSearchService = useGenericSearchService();

    const refreshProducts = () => {
        let params: IProductSearchParams = {
            page: 0,
            size: 100,
            sort: 'createdAt,desc',
            sectionType: PRODUCT_SECTION.BRAND_UPC
        }
        genericSearchService.searchProduct(params, {
            productCode: upcFilterData?.productIds?.length ? upcFilterData.productIds : null,
            warehouseId: formik.values?.warehouseId,
            isCataloguesPresent: true,
            getStockDetails: true,

        })
            .then((response) => {
                if (response.data.data && response.status === HTTP_STATUS.OK) {
                    const newProducts = response?.data?.data?.content
                    .filter((product: any) => upcFilterData?.productIds?.includes(product.productId))
                        .map((product: any) => ({
                            productId: product.productId,
                            productName: product.title,
                            productCode: product.productCode,
                            moq: product.stockDetails?.minimumOrderQuantity || 0,
                            availableStock: product.stockDetails?.stock || 0,
                            price: product.stockDetails?.productPrice || 0,
                            bundleMoq: null,
                            classType: product.stockDetails?.classType || "",
                        }));
        
                        const updatedProductDetails = [
                            ...formik.values.productDetails,
                            ...newProducts.filter((newProduct: any) =>
                                !formik.values.productDetails.some(
                                    (existingProduct: any) => existingProduct.productId === newProduct.productId
                                )
                            )
                        ];
                    formik.setFieldValue('productDetails', updatedProductDetails);
                    setFormStateTo(BUNDLE_FORM_STATE.EDIT_PRODUCTS)();
                }
            })
            .catch((error) => {
                console.error("Product fetching error - ", error);
            });
    }

    const onFilterSubmit = () => {
        refreshProducts()
    }

    return (
        <div className="grid gap-y-8" >
            {SnackBarComponent}
            <div className={`w-full m-auto flex justify-between gap-x-2 text-center text-sm `}>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto text-sm font-semibold`}>Bundle Details</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Edit Products</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>
            <div className='grid gap-y-4'>
                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Bundle Details</div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            <TextFieldV2
                                label="Bundle Name*"
                                placeholder='Enter Bundle Name'
                                {...formik.getFieldProps("name")}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 100 }}
                                onBlur={(event) => {
                                    const newValue = titleModification(event.target.value);
                                    formik.setFieldValue('name', newValue);
                                }}
                                disabled={mode === MODE.UPDATE}

                            />
                            {formik.errors.name && formik.touched.name && (
                                <div className={`${classes.errorMessage} text-xs`}>
                                    {formik.errors.name}
                                </div>
                            )}
                        </div>
                        {
                            mode === MODE.UPDATE &&
                            <div className="grid gap-y-1">
                                <TextFieldV2
                                    label="Bundle Code"
                                    {...formik.getFieldProps("bundleCode")}
                                    error={formik.touched.uscCode && Boolean(formik.errors.bundleCode)}
                                    inputProps={{ readOnly: mode === MODE.UPDATE }}
                                    disabled
                                />
                                {formik.errors.bundleCode && formik.touched.bundleCode && (
                                    <div className={`${classes.errorMessage} text-xs`}>
                                        {formik.errors.bundleCode}
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="flex justify-end gap-4">
                <ButtonV2 variant="tertiaryContained" label='Cancel' onClick={onClose} />
                {
                    mode === MODE.UPDATE ? (<ButtonV2 variant="primaryContained" disabled={formik.errors.name} onClick={handleNext} label={"Next"} />) : (
                        <UpcMpcSearchDialogModal
                            sections={[GENERIC_FILTER_SECTIONS.WAREHOUSE, GENERIC_FILTER_SECTIONS.MATERIAL_FILTER]}
                            productType={PRODUCT_SECTION.BRAND_UPC}
                            filterData={upcFilterData}
                            setFilterData={setUpcFilterData}
                            handleFilterClear={handleFilterClear}
                            onFilterSubmit={onFilterSubmit}
                            isCataloguesPresent={true}
                            triggerButton={
                                <ButtonV2 variant="primaryContained" disabled={formik.errors.name} label={"Select Product"} />
                            }
                        />
                    )
                }
            </div>
        </div>
    )
}

export default BundleDetailFormTemplate;