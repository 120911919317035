import percentIcon from "./../../Assets/Icons/percentageIcon.svg";
import cancelIcon from "./../../Assets/Icons/cancelIcon.svg";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import UpcPriceRangeDetails from "./UpcPriceRangeDetails";
import { CATALOGUE_SHAPES_TYPES, showAttributesUom } from "../../../utils/helper";

const UpcDetailItem = ({
    mode,
    index,
    upcDetail,
    priceDetails,
    formErrors,
    handleRemoveUPCDetails,
    handleStatusChange,
    handleMarginChange,
    handleMoqChange,
    type
}) => {

    if (!upcDetail || !upcDetail.upcDetails) {
        return;
    }

    const displayProductCode = (data) => {
        if (data === "STANDARD") {
            return "Universal Product Code"
        } else {
            return "Master Product Code"
        }
    }


    const DisplayProductSpecificationDetails = ({ productSpecificationDetails }) => {
        try {
            const attributes = JSON.parse(productSpecificationDetails);
            if (attributes?.SPECIFICATION) {
                return attributes.SPECIFICATION.map((specificationItem, index) => (
                    <div className="flex flex-col items-start justify-start gap-[6px]">
                        <div className="relative font-semibold">
                            {specificationItem?.name || '-'}
                        </div>
                        <div className="relative">
                        {showAttributesUom(specificationItem.attributeOptions, specificationItem.attributeOptions , specificationItem.uom )}
                        </div>
                    </div>
                ));
            } else {
                return <div>No specification data available.</div>;
            }
        } catch (error) {
            console.error('Error parsing attributes:', error);
            return <div>No Specification Details</div>;
        }
    };


   

    return (
        <div className="relative bg-ghostwhite shadow-[0px_1px_4px_rgba(0,_0,_0,_0.12)] w-full flex flex-col pt-3 px-6 pb-6 box-border items-start justify-start gap-[24px] text-left text-base text-text-color font-small-text">
            <div className="self-stretch flex flex-row items-center justify-start gap-[24px]">
                <div className="flex-1 relative font-semibold text-16px">
                    {displayProductCode(type)} - {upcDetail.upcDetails.upcCode ? upcDetail.upcDetails.upcCode : "JSW"}
                </div>

                {/* <UpcPriceRangeDetails priceDetails={priceDetails} mode={"VIEW"} profit={upcDetail.profitMargin}/> */}


                {mode === "EDIT" && (
                    <div className="bg-oreng flex flex-row p-1.5 items-center justify-center">
                        <button
                            className="relative w-3 h-3"
                            onClick={() => handleRemoveUPCDetails(index)}
                        >
                            <img className="relative w-3 h-3" alt="" src={cancelIcon} />
                        </button>
                    </div>
                )}


            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[12px] border-[1px] border-solid border-input-border">
                <div className="self-stretch flex-1 flex flex-row p-3 items-start justify-start text-sm border-r-[1px] border-solid border-input-border">
                    <div className="flex-1 flex flex-col items-start justify-start">
                        <div className="self-stretch flex flex-col items-start justify-center gap-[16px]">
                            <div className="flex flex-col items-start justify-start text-base">
                                <div className="relative leading-[20px] font-semibold">
                                    Category
                                </div>
                            </div>
                            <div className="flex flex-col items-start justify-start gap-[6px]">
                                <div className="relative font-semibold">Super Category</div>
                                <div className="relative ">{upcDetail.upcDetails.superCategory ? upcDetail.upcDetails.superCategory : "JSW"}</div>
                            </div>
                            <div className="flex flex-col items-start justify-start gap-[6px]">
                                <div className="relative font-semibold">Main Category</div>
                                <div className="relative ">{upcDetail.upcDetails.mainCategory ? upcDetail.upcDetails.mainCategory : "JSW"}</div>
                            </div>
                            <div className="flex flex-col items-start justify-start gap-[6px]">
                                <div className="relative font-semibold">Product Category</div>
                                <div className="relative">{upcDetail.upcDetails.productCategory ? upcDetail.upcDetails.productCategory : "JSW"}</div>
                            </div>
                            <div className="flex flex-col items-start justify-start gap-[6px]">
                                <div className="relative font-semibold">Manufacturer</div>
                                <div className="relative ">{upcDetail.upcDetails.manufactureName ? upcDetail.upcDetails.manufactureName : "JSW"}</div>
                            </div>
                            <div className="flex flex-col items-start justify-start gap-[6px]">
                                <div className="relative font-semibold">Brand</div>
                                <div className="relative ">{upcDetail.upcDetails.brand ? upcDetail.upcDetails.brand : "JSW"}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="self-stretch flex-1 flex flex-row py-3 px-0 items-start justify-start border-r-[1px] border-solid border-input-border">
                    <div className="flex-1 flex flex-col items-start justify-start gap-[12px]">
                        <div className="relative leading-[20px] font-semibold">
                            Standard
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[23px] text-sm">
                            <div className="flex flex-col items-start justify-start gap-[6px]">
                                <div className="relative font-semibold">Grade</div>
                                <div className="relative">{upcDetail.upcDetails.grade ? upcDetail.upcDetails.grade : "JSW"}</div>
                            </div>
                            <div className="flex flex-col items-start justify-start gap-[6px]">
                                <div className="relative font-semibold">Standard</div>
                                <div className="relative">{upcDetail.upcDetails.standard ? upcDetail.upcDetails.standard : "JSW"}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="self-stretch flex-1 flex flex-col py-3 px-0 items-start justify-start border-r-[1px] border-solid border-input-border">
                    <div className="w-[169px] flex flex-col items-start justify-start gap-[12px]">
                        <div className="relative leading-[20px] font-semibold">
                            General Info
                        </div>
                        <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[45px] text-sm">
                            <div className="flex flex-col items-start justify-start gap-[6px]">
                                <div className="relative  font-semibold">Lab</div>
                                <div className="relative">IS 1993</div>
                            </div>
                            <div className="flex flex-col items-start justify-start gap-[6px]">
                                <div className="relative  font-semibold">MOQ</div>
                                <div className="relative">Double Reduced</div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="self-stretch flex-1 flex flex-col py-3 px-0 items-start justify-start border-r-[1px] border-solid border-input-border">
                    <div className="w-[169px] flex flex-col items-start justify-start gap-[12px]">
                        <div className="relative leading-[20px] font-semibold">
                            Product Classification
                        </div>
                        <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[45px] text-sm">
                            <div className="flex flex-col items-start justify-start gap-[6px]">
                                <div className="relative  font-semibold">Shape</div>
                                <div className="relative">{ upcDetail.upcDetails.shape ? CATALOGUE_SHAPES_TYPES[upcDetail.upcDetails.shape] : CATALOGUE_SHAPES_TYPES.COIL }</div>
                            </div>
                            <div className="flex flex-col items-start justify-start gap-[6px]">
                                <div className="relative  font-semibold">Class</div>
                                <div className="relative">{ upcDetail.upcDetails.classType ? upcDetail.upcDetails.classType : "COIL" }</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-1 flex flex-col py-3 pr-3 pl-0 items-start justify-start">
                    <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
                        <div className="relative leading-[20px] font-semibold">
                            Product Specification
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[23px] text-sm">

                            <DisplayProductSpecificationDetails productSpecificationDetails={upcDetail?.upcDetails?.attributes} />

                        </div>
                    </div>
                </div>
            </div>

            <div className="self-stretch flex flex-row items-start justify-start gap-[24px]">

                {/* UPC Price */}
                <div className="flex-1 flex flex-col items-center justify-start">
                    <div className="self-stretch h-12 flex flex-row py-[8.5px] items-center justify-start gap-[8px] ">
                        <FormControl fullWidth className="bg-white">

                            <TextField
                                className={`h-[3.5rem] bg-white block w-full p-1 border ${formErrors &&
                                    formErrors[index] &&
                                    formErrors[index].upcPrice
                                    ? "border-red-500"
                                    : "border-gray-300"
                                    } rounded-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                                id="outlined-basic"
                                label={type === "STANDARD" ? "UPC Price":"MPC Price"}
                                variant="outlined"
                                fullWidth
                                value={
                                    upcDetail.upcPrice != null
                                        ? upcDetail.upcPrice
                                        : ''
                                }
                                onChange={(e) =>
                                    handleMarginChange(index, 'upcPrice', e.target.value)
                                }
                                error={
                                    formErrors &&
                                    formErrors[index] &&
                                    formErrors[index].upcPrice
                                }
                                helperText={
                                    formErrors &&
                                        formErrors[index] &&
                                        formErrors[index].upcPrice
                                        ? "UPC Price is required"
                                        : ''
                                }
                            disabled={mode === "VIEW"}
                            />
                        </FormControl>
                    </div>
                </div>

                {/* Minimum Selling Price */}
                <div className="flex-1 flex flex-col items-center justify-start">
                    <div className="self-stretch h-12 flex flex-row py-[8.5px]  items-center justify-start gap-[8px] ">
                        <FormControl fullWidth className="bg-white">
                            <TextField
                                className={`h-[3.5rem] bg-white block w-full p-1 border ${formErrors &&
                                    formErrors[index] &&
                                    formErrors[index].minimumSellingPrice
                                    ? "border-red-500"
                                    : "border-gray-300"
                                    } rounded-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                                id="outlined-basic"
                                label="Minimum Selling Price"
                                variant="outlined"
                                fullWidth
                                value={
                                    upcDetail.minimumSellingPrice != null
                                        ? upcDetail.minimumSellingPrice
                                        : ''
                                }
                                onChange={(e) =>
                                    handleMarginChange(index, 'minimumSellingPrice', e.target.value)
                                }
                                error={
                                    formErrors &&
                                    formErrors[index] &&
                                    formErrors[index].minimumSellingPrice
                                }
                                helperText={
                                    formErrors &&
                                        formErrors[index] &&
                                        formErrors[index].minimumSellingPrice
                                        ? "Minimum Selling Price is required"
                                        : ''
                                }
                                disabled={mode === "VIEW"}
                            />

                        </FormControl>
                    </div>
                </div>
                    {/* Minimum Selling Price */}
                    <div className="flex-1 flex flex-col items-center justify-start">
                        <div className="self-stretch h-12 flex flex-row py-[8.5px]  items-center justify-start gap-[8px] ">
                        <FormControl fullWidth className="bg-white">
                            <TextField
                                className={`h-[3.5rem] bg-white block w-full p-1 border ${formErrors &&
                                    formErrors[index] &&
                                    formErrors[index].minimumOrderQuantity
                                    ? "border-red-500"
                                    : "border-gray-300"
                                    } rounded-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                                id="outlined-basic"
                                label="Minimum Order Quantity"
                                variant="outlined"
                                fullWidth
                                value={
                                    upcDetail.minimumOrderQuantity != null
                                        ? upcDetail.minimumOrderQuantity
                                        : ''
                                }
                                onChange={(e) =>
                                    handleMoqChange(index, 'minimumOrderQuantity', e.target.value)
                                }
                                error={
                                    formErrors &&
                                    formErrors[index] &&
                                    formErrors[index].minimumOrderQuantity
                                }
                                helperText={
                                    formErrors &&
                                        formErrors[index] &&
                                        formErrors[index].minimumOrderQuantity
                                        ? "Minimum Order Quantity is required"
                                        : ''
                                }
                                disabled={mode === "VIEW"}
                            />

                        </FormControl>
                    </div>
                </div>

                {/* Select Status */}
                <div className="flex-1 flex flex-col items-center justify-start">

                    <div className="self-stretch h-12 flex flex-row py-[8.5px]  items-center justify-start gap-[8px] ">
                        <FormControl fullWidth className="bg-white">
                            <InputLabel id="label">Select Catalogue UPC Status</InputLabel>
                            <Select
                                labelId="label"
                                id="select"
                                label="Select Catalogue UPC Status"
                                value={upcDetail.status}
                                onChange={(e) =>
                                    handleStatusChange(index, e.target.value)
                                }
                                disabled={mode === "VIEW"}
                            >
                                <MenuItem value={"ACTIVE"}>Active</MenuItem>
                                <MenuItem value={"INACTIVE"}>Inactive</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="self-stretch flex flex-col py-0 px-3.5 items-start justify-start">
                        <div className="relative bg-white w-[42px] h-0 overflow-hidden shrink-0" />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default UpcDetailItem;
