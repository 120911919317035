import SelectUpcAttributes from '../../SelectUpcAttributes';
import React, {  useState } from "react";

const SHAPES = [
  // {
  //   label: "Single Length Coil",
  //   id: 'SINGLE_LENGTH_COIL'
  // },
  // {
  //   label: "Multi Welded Coil ",
  //   id: 'MULTI_WELDED_COIL'
  // },
  // {
  //   label: "Leader End Coil",
  //   id: 'LEADER_END_COIL'
  // },
  // {
  //   label: "Uniform Assorted Bundle Sheets",
  //   id: 'UNIFORM_ASSORTED_BUNDLE_SHEETS'
  // },
  // {
  //   label: "Mix Assorted Bundle Sheets",
  //   id: 'MIX_ASSORTED_BUNDLE_SHEETS'
  // },
  // {
  //   label: "Unassorted Bundle Sheets",
  //   id: 'UNASSORTED_BUNDLE_SHEETS'
  // },
  {
    label: "Scroll",
    id: 'SCROLL'
  },
  {
    label: "Bar",
    id: 'BAR'
  },
  {
    id: 'PLATE',
    label: "Plate"
  },
  {
    id: "COIL",
    name: "Coil"
  },
  {
    id: "SHEET",
    name: "Sheet"
  },
  {
    id: "STRIP",
    name: "Strip"
  },
  {
    id: "ANGLE",
    name: "Angle"
  },
  {
    id: "CHANNEL",
    name: "Channel"
  },
  {
    id: "BEAM",
    name: "Beam"
  },
  {
    id: 'PIPE',
    name: "Pipe"
  },
  {
    id: 'TUBE',
    name: "Tube"
  },
  {
    id: 'ROUND',
    name: "Round"
  },
  {
    id: 'SQUARE',
    name: "Square"
  },
  {
    id: 'RECTANGLE',
    name: "Rectangle"
  },
  {
    id: 'OTHERS',
    name: "Others"
  }
]
const CLASSES = [
  {
    label: "Standard",
    id: 'STANDARD'
  },
]
const CLASSESMPC = [
 
  {
    label: "Non Standard",
    id: 'NONSTANDARD'
  },
 
]

const ShapeClassSearch = ({ updateSearchParams, searchPayload , type }) => {

  const [categoryOptions, setCategoryOptions] = useState({
    shapes:  SHAPES,
    classTypes: (type === 'MPC' | type === 'NONSTANDARD' )? CLASSESMPC: CLASSES
  });
  
  const [selectedCategories, setSelectedCategories] = useState({
    shape: searchPayload?.shape ?? null,
    classType: searchPayload?.classType ?? null,
  });

  const updateCategoryOptions = (type, data) => {
    setCategoryOptions(prevState => ({
      ...prevState,
      [type]: data,
    }));
  };

  const updateCategoryInput = (type, event, options) => {

    if (Array.isArray(options)) {
      const foundData = options.find(item => item.id === event.target.value);
      if (foundData) {
        setSelectedCategories(prevState => ({
          ...prevState,
          [type]: foundData.id,
        }));
        
        // USING THIS LOGIC SO THAT IT WORKS FOR UPCS AS WELL
        if (searchPayload) updateSearchParams(type, foundData.id);
        else updateSearchParams(type, foundData.id);


      }
    }


  };

  const convertToOptions = (data) => {
    if (!Array.isArray(data)) {
      throw new Error('Input data should be an array.');
    }

    return data.map(item => {
      if (!item || typeof item !== 'object' || !item.hasOwnProperty('id') || !item.hasOwnProperty('name')) {
        throw new Error('Invalid item in data array.');
      }

      return {
        label: item.name,
        id: item.id
      };
    });
  };

  return (
    <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px]">
      <div className="flex-1 flex flex-col items-center justify-start">
        <SelectUpcAttributes
          label="Shape"
          value={selectedCategories?.shape}
          onChange={event => updateCategoryInput('shape', event, categoryOptions.shapes)}
          options={categoryOptions.shapes}
        // disabled={false} 
        />
      </div>


      <div className="flex-1 flex flex-col items-center justify-start">
        <SelectUpcAttributes
          label="Class"
          value={selectedCategories?.classType}
          onChange={event => updateCategoryInput('classType', event, categoryOptions.classTypes)}
          options={categoryOptions.classTypes}
          disabled={!selectedCategories.shape}
        />
      </div>
    </div>
  )
};

export default ShapeClassSearch;
