import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { HTTP_STATUS } from "../../../utils/types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IMaterialFilterData } from "./GenericSerachDialog.template";
import { IWarehouseFilterRequest, useWarehouseService } from "../../../services/useWarehouseService";
import AutoCompleteInputV2, { AutoCompleteOption } from "../../molecules/AutoCompleteInput/AutoCompleteInputV2";

interface WarehouseSearchTemplateProps {
    materialFilters: IMaterialFilterData;
    sectionType: string;
    setFilterData: (materialFilters: IMaterialFilterData) => void;
    onFilterSubmit: () => void;
    handleClose: () => void;
    handleFilterClear: () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid #EBEFFA`
    },
    filterHeading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
}));

const materialFilterData = {
    warehouse: {
        label: "",
        id: null
    },
}

const WarehouseSearchTemplate: React.FC<WarehouseSearchTemplateProps> = ({ materialFilters, sectionType, setFilterData, onFilterSubmit, handleClose, handleFilterClear }) => {
    const classes = useStyles();
    const [warehouse, setWarehouseTo] = useState<any[]>([]);
    const warehouseService = useWarehouseService()

    const validationSchema = Yup.object().shape({
        warehouse: Yup.object().shape({
            id: Yup.number().required('Warehouse is required'),
            label: Yup.string().required('Warehouse is required')
        }).required('Warehouse is required'),
    });

    const formik = useFormik<any>({
        initialValues: materialFilterData,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            onFilterSubmit()
            handleClose()
        }
    });

    const refreshWarehouse = async (inputValue?: string) => {
        try {
            let params: IWarehouseFilterRequest = {
                page: 0,
                size: 10,
                search: 'AVIGHNA',
            }
            const warehouseResponse = await warehouseService.getAllWarehouse(params);
            if (
                warehouseResponse?.status === HTTP_STATUS.OK &&
                warehouseResponse?.data?.data?.content?.length
            ) {
                setWarehouseTo(warehouseResponse.data.data.content);
            } else {
                throw new Error("Warehouse fetch failed");
            }

        } catch (error) {
            setWarehouseTo([]);
        }
    };

    const warehouseOptions = useMemo(() => {
        return warehouse?.map((warehouse: any) => ({ label: warehouse.name, id: warehouse.id })) ?? []
    }, [warehouse])


    useEffect(() => {
        refreshWarehouse();
    }, []);

    const updateSelectedWarehouse = (key: string) => (option: AutoCompleteOption | null) => {
        if (option) {
            formik.setFieldValue("warehouse", option);
            setFilterData({
                ...materialFilters,
                warehouse: option
            })
        }
    };

    useEffect(() => {
        formik.setFieldValue('warehouse', {id: materialFilters?.warehouse?.id, label: materialFilters?.warehouse?.label || "" });
    }, [materialFilters?.warehouse])

    return (
        <div className={`${classes.container} m-4 p-4 grid rounded-lg  border-b`}>
            <div className="flex justify-between">
                <div className={`${classes.filterHeading} text-lg font-medium my-auto`}>Search By Warehouse</div>
            </div>
            <div className={`grid gap-y-2 pt-2 w-1/2`}>
                <AutoCompleteInputV2
                    options={warehouseOptions}
                    label="Select Warehouse"
                    placeHolder="Select Warehouse"
                    onChange={updateSelectedWarehouse("warehouse")}
                    onSearch={refreshWarehouse}
                    value={formik.values.warehouse}
                    error={!!(formik.errors?.warehouse as any)?.label && (formik.touched?.warehouse as any)?.label}
                />

                {(formik.errors?.warehouse as any)?.label && formik.touched.warehouse && (
                    <div className={`${classes.errorMessage} text-xs`}>
                        {(formik.errors?.warehouse as any)?.label}
                    </div>
                )}
            </div>
        </div>
    );
};

export default WarehouseSearchTemplate;