import React, { useState } from 'react'
import { ADMIN_ROUTES, sortOptions, statusOptions, uomMapping } from '../../../utils/constant';
import { capitalizeFirstLetter, enumToString, makeRoute } from '../../../utils/helper';
import { IPagination } from '../../../utils/types';
import { useNavigate } from 'react-router-dom';
import { ColumnType } from '../../organisms/Table';
import TableV2 from '../../organisms/TableV2';
import ButtonV2 from '../../atoms/ButtonV2';
import TextFieldV2 from '../../atoms/TextFieldV2';
import viewEyeIcon from "../../../App/Assets/Icons/viewEyeIcon.svg";
import editPenIcon from "../../../App/Assets/Icons/editPenIcon.svg";
import FilterChipV2 from '../../molecules/FilterChip/FilterChipV2';
import { IMultiSelectOption } from '../../atoms/MultiSelectInput2';
import moment from 'moment';
import ResourceStatusV2 from '../../atoms/ResourceStatus/ResourceStatusV2';
import { IAttributes } from '../../../services/useAttributeService';

interface AttributeListTemplateProps {
    attributes: IAttributes[];
    pagination: IPagination;
    handlePaginationChange: any;
    handleRowsPerPageChange: any;
    handleFiltersChange: (name: string, value: any) => void;
    filters: any;
}

const AttributeListTemplate: React.FC<AttributeListTemplateProps> = ({ attributes, pagination, handlePaginationChange, handleRowsPerPageChange, handleFiltersChange, filters }) => {

    const navigate = useNavigate();

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination?.totalRecords,
            currentPage: pagination?.currentPage,
            isVisible: true,
            limit: pagination?.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Attribute Name", key: "name", type: "string" as ColumnType, props: { className: '' } },
            { label: "Attribute Type", key: "attributeType", type: "string" as ColumnType, props: { className: '' } },
            { label: "UOM", key: "uom", type: "string" as ColumnType, props: { className: '' } },
            { label: "UOM Code", key: "uom", type: "string" as ColumnType, props: { className: '' } },
            { label: "Created Date", key: "createdAt", type: "string" as ColumnType, props: { className: '' } },
            { label: "Updated Date", key: "updatedAt", type: "string" as ColumnType, props: { className: '' } },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ],
    };

    const handleEdit = (id: number) => () => {
        navigate(makeRoute(ADMIN_ROUTES.ATTRIBUTE_UPDATE, { params: { id: id } }));
    }

    const handleView = (id: number) => () => {
        navigate(makeRoute(ADMIN_ROUTES.ATTRIBUTE_VIEW, { params: { id: id } }));
    }

    const Action = (id: number) => {
        return (
            <div className='flex justify-center gap-x-2'>
                <img className="" alt="" src={viewEyeIcon} onClick={handleView(id)} />
                <img className="" alt="" src={editPenIcon} onClick={handleEdit(id)}/>
            </div>
        );
    };

    const records = attributes?.map((attribute: IAttributes, index: number) => {
        const uomCode = uomMapping[attribute?.uom]?.shortcode || attribute?.uom;
        const uom = uomMapping[attribute?.uom]?.fullDescription || attribute?.uom;
        return [
            pagination.currentPage * pagination.pageSize + index + 1,
            attribute?.name,
            capitalizeFirstLetter(attribute?.attributeType),
            uom,
            uomCode,
            moment(attribute.createdAt).format("DD-MM-YYYY HH:mm:ss"),
            moment(attribute.updatedAt).format("DD-MM-YYYY HH:mm:ss"),
            enumToString(attribute?.status),
            Action(attribute?.id),
        ];
    });

    const initialSelections = {
        status: [{ label: 'ALL', value: 'ALL' }],
        sortDate: [{ label: 'createdAt,desc', value: 'createdAt,desc' }]
    };

    const [status, setStatus] = useState<IMultiSelectOption[]>(initialSelections.status);
    const [sortDate, setSortDate] = useState<IMultiSelectOption[]>(initialSelections.sortDate);

    const handleSelectionChangeStatus = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setStatus(selected);
        clearWithCrossIcon && handleStatusSaveClick(selected);
    };

    const handleSelectionChangeSort = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSortDate(selected);
        clearWithCrossIcon && handleSortSaveClick(selected);
    };

    const handleStatusSaveClick = (status: IMultiSelectOption[]) => {
        const valueString = status.length > 0 ? status[0].value : 'ALL';
        handleFiltersChange("status", valueString);
    };

    const handleSortSaveClick = (sortDate: IMultiSelectOption[]) => {
        const sortString = sortDate.length > 0 ? sortDate[0].value : 'createdAt,desc';
        handleFiltersChange("sort", sortString);
    };

    const handleClearClick = (filterType: string) => {
        switch (filterType) {
            case 'status':
                setStatus([{ label: 'ALL', value: 'ALL' }]);
                handleFiltersChange("status", 'ALL');
                break;
            case 'sortDate':
                setSortDate([{ label: 'createdAt,desc', value: 'createdAt,desc' }]);
                handleFiltersChange("sort", 'createdAt,desc');
                break;
            default:
                setStatus([{ label: 'ALL', value: 'ALL' }]);
                setSortDate([{ label: 'createdAt,desc', value: 'createdAt,desc' }]);
        }
    };

    return (
        <div className='grid gap-y-6'>
            <div className='flex justify-between'>
                <div className='flex flex-wrap gap-x-3 my-auto'>
                    <FilterChipV2
                        options={statusOptions}
                        label=" "
                        value={status}
                        onchange={handleSelectionChangeStatus}
                        placeholder="Status"
                        ClearButtonComponent={ButtonV2}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('status')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleStatusSaveClick(status)}
                        isSingleSelect={true}
                    />
                    <FilterChipV2
                        options={sortOptions}
                        label=" "
                        value={sortDate}
                        onchange={handleSelectionChangeSort}
                        placeholder="Sort Date"
                        ClearButtonComponent={ButtonV2}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('sortDate')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleSortSaveClick(sortDate)}
                        isSingleSelect={true}
                    />
                </div>

                <div className='w-[324px]'>
                    <TextFieldV2
                        className="w-full"
                        placeholder='Search'
                        type="text"
                        name="name"
                        value={filters.search}
                        onChange={(e) => handleFiltersChange('name', e.target.value)}
                    />
                </div>
            </div>
            <TableV2 schema={schema} records={records} />
        </div>
    )
}

export default AttributeListTemplate