import React, { useEffect, useState } from "react";
import { IGrade, useQualtiyGradeService } from "../../../services/useQualityGradesService";
import { HTTP_STATUS } from "../../../utils/types";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { createUseStyles } from "react-jss";
import ChemicalCompositionListTemplate from "./ChemicalCompositionList.template";
import MechanicalPropertiesListTemplate from "./MechanicalPropertiesList.template";

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
}));

type GradePropertiesListTemplateProps = {
    gradeId?: number | null;
};

const GradePropertiesListTemplate: React.FC<GradePropertiesListTemplateProps> = ({ gradeId }) => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [grade, setGrade] = useState<IGrade | null>(null);
    const gradeService = useQualtiyGradeService();

    const loadGrade = async () => {
        if(gradeId)
        gradeService.getQualityGrade(gradeId)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    setGrade(res.data.data)
                }
            }).catch((error) => {
                setGrade(null);
                console.error("Error Fetching Quality Grade: ", error);
                showSnackbar('error', "Error while fetching Quality Grade data");
            })
    }

    useEffect(() => {
        loadGrade();
    }, [gradeId])

    const updateGradeProperties = (data: any) => {
        setGrade(data);
    }

    return (
        <div className="grid">
            <div className={`${classes.mainHeading} text-lg font-semibold`}>Grade: <span className="font-normal">{grade?.name}</span></div>

            {gradeId && grade && (<div className="grid gap-y-3">
                <div className="w-full">
                    <ChemicalCompositionListTemplate
                        records={grade?.chemicalComposition ? JSON.parse(grade?.chemicalComposition) : []}
                        associatedGrade={grade}
                        updateGradeProperties={updateGradeProperties}
                    />
                </div>
                <div className="w-full">
                    <MechanicalPropertiesListTemplate
                        records={grade?.mechanicalProperties ? JSON.parse(grade?.mechanicalProperties) : []}
                        associatedGrade={grade}
                        updateGradeProperties={updateGradeProperties}
                    />
                </div>
            </div>
            )}
        </div>
    );
};

export default GradePropertiesListTemplate;