
import { createUseStyles } from "react-jss";
// import { CART_LIFE_CYCLE, FAVOURITE } from "../../../utils/constant";
import favouriteIcon from "../../../assets/icons/favourite.svg";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useContext, useState, useEffect } from "react";
// import { useWishlistService } from "../../../services/useWishlistService";
// import { CLASS_STATES, HTTP_STATUS } from "../../../utils/types";
// import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
// import { WishlistContext } from "../../../contexts/WishlistContext";

interface AddToFavouriteTemplateProps {
    id?: number;
    catalogueType?: string;
    disableButton?: boolean
}

const useStyles = createUseStyles((theme: any) => ({
    favouriteWrapper: {
        borderRadius: "12px",
        border: `1px solid ${theme.customerPalette.border.primary.primary800}`,
        color: theme.customerPalette.text.primary.primary800,
    },
    selectedFavourite: {
        border: `1px solid ${theme.customerPalette.border.primary.primary800}`,
        background: theme.customerPalette.status.draft.draft100,
    },
    selectedText: {
        color: theme.customerPalette.text.primary.primary800,
    }
}))

const AddToFavouriteTemplate: React.FC<AddToFavouriteTemplateProps> = ({ id, catalogueType, disableButton }) => {

    const classes = useStyles();
    // const { user } = useAuthenticatedUser();
    // const wishlistContextData = useContext(WishlistContext);
    // const wishlistService = useWishlistService();
    // const [selectedFavourite, setSelectedFavourite] = useState<boolean>(false);

    // useEffect(() => {
    //     if (id && wishlistContextData.favourites) {
    //         if (catalogueType === CLASS_STATES.STANDARD || catalogueType === CLASS_STATES.NONSTANDARD) {
    //             setSelectedFavourite(wishlistContextData?.favourites?.catalogueIds?.includes(id));
    //         } else if (catalogueType === CLASS_STATES.SECONDARY) {
    //             setSelectedFavourite(wishlistContextData?.favourites?.secondaryCatalogueIds?.includes(id));
    //         }
    //     }
    // }, [id, catalogueType, wishlistContextData?.favourites]);

    // const handleFavouriteClicked = () => {
    //     setSelectedFavourite(!selectedFavourite);
    //     updateWishlist();
    // }

    // const updateWishlist = async () => {
    //     try {
    //         if(id === null) return
    //         const payload: any = {
    //             userId: user?.id,
    //             favourites: {
    //                 catalogueIds: [...wishlistContextData.favourites.catalogueIds],
    //                 secondaryCatalogueIds: [...wishlistContextData.favourites.secondaryCatalogueIds]
    //             }
    //         };
    //         if (catalogueType === CLASS_STATES.STANDARD || catalogueType === CLASS_STATES.NONSTANDARD) {
    //             if (selectedFavourite) {
    //                 payload.favourites.catalogueIds = payload.favourites.catalogueIds.filter((cId: number) => cId !== id);
    //             } else {
    //                 payload.favourites.catalogueIds.unshift(id);
    //             }
    //         } else if (catalogueType === CLASS_STATES.SECONDARY) {
    //             if (selectedFavourite) {
    //                 payload.favourites.secondaryCatalogueIds = payload.favourites.secondaryCatalogueIds.filter((cId: number) => cId !== id);
    //             } else {
    //                 payload.favourites.secondaryCatalogueIds.unshift(id);
    //             }
    //         }

    //         const wishlistResponse = await wishlistService.updateWishlist(payload);
    //         if (wishlistResponse?.status === HTTP_STATUS.OK) {
    //             await wishlistContextData.syncWishlist(CART_LIFE_CYCLE.REMOVE_PRODUCT);
    //             return true;
    //         } else {
    //             throw new Error("Wishlist Details fetch failed");
    //         }
    //     } catch (error) {
    //         return false;
    //     }
    // };

    return (
        // <button className={`flex justify-center items-center cursor-pointer gap-2 py-3 px-6 w-full ${classes.favouriteWrapper} ${selectedFavourite ? classes.selectedFavourite : ""}`} onClick={handleFavouriteClicked} disabled={disableButton}>
        //     {selectedFavourite ? <img width={"20px"} height={"20px"} src={favouriteIcon} alt="fav" /> : <img width={"20px"} height={"20px"} src={favouriteIcon} alt="fav" /> }
        //     <span className={`text-lg font-medium ${selectedFavourite ? classes.selectedText : ""}`}>{FAVOURITE.ADD_TO_FAVOURITE}</span>
        // </button>
        <></>
    )
}

export default AddToFavouriteTemplate;
