import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import ButtonV2 from "../../atoms/ButtonV2";
import SelectV2 from "../../atoms/SelectV2";
import { CATEGORY_TYPE, CUSTOMER_GROUP_SECTIONS, HTTP_STATUS } from "../../../utils/types";
import TextFieldV2 from "../../atoms/TextFieldV2";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROUTES, industryOptions } from "../../../utils/constant";
import { ICategory } from "../../pages/AdminMaster/Brand/BrandList.page";
import { useCategoryService } from "../../../services/useCategoryService";
import { FormControl } from '@mui/material';
import AutoCompleteTextField, { AutoCompleteOption } from '../../molecules/AutoCompleteInput/AutoCompleteInputV2';
import { useCustomerGroupMasterService } from "../../../services/useCustomerGroupMasterService";

interface CustomerGroupMasterFormTemplateProps {
    setCurrentSectionTo: (section: CUSTOMER_GROUP_SECTIONS) => void;
    formik: any;
    mode?: string;
}


const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid #EBEFFA`
    },
    filterContainer: {
        borderTop: `1px solid #EBEFFA`
    },
    filterHeading: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    selectLabel: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    configure: {
        color: theme.palette.textV2.primary.primary500,
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    activeProgressBar: {
        backgroundColor: "#FD6202"
    },
    inActiveProgressBar: {
        backgroundColor: "#EBEFFA"
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
    errorMessage: {
        color: theme.palette.textV2.failure.failure600,
    },
}));

const CustomerGroupMasterFormTemplate: React.FC<CustomerGroupMasterFormTemplateProps> = ({ setCurrentSectionTo, formik , mode}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const categoryService = useCategoryService();
    const customerGroupService = useCustomerGroupMasterService()
    const [productCategoryData, setProductCategoryData] = useState<ICategory[] | null>([]);
    const [userData, setUserDataTo] = useState<any>()

    const handleSave = (event: React.FormEvent) => {
        event.preventDefault();
        formik.handleSubmit(); 
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const requiredFields = ["groupName", "productCategoryId", "description", "groupType", "productCategory", "industryType", "groupOwner","groupOwnerId"];
        let fieldError = false;
        for (const key of requiredFields) {
            if (key in formik.errors) {
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
        }
        if (fieldError === true) return;
        formik.setValues((prevValues: any) => ({
            ...prevValues,
            groupName: formik.values.groupName,
            productCategoryId: formik.values.productCategory.id,
            description: formik.values.description,
            groupType: formik.values.groupType,
            industryType: formik.values.industryType,
            groupOwnerId: formik.values.groupOwner.id,
        }));

        setCurrentSectionTo(CUSTOMER_GROUP_SECTIONS.CUSTOMER_GROUP_CONFIGURATION_FORM);
    };

    const loadProductCategory = (inputValue?: string) => {
        categoryService.getAllCategories({ level: CATEGORY_TYPE.PRODUCT_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: '', name: inputValue ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setProductCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const productCategoryOptions = useMemo(() => {
        return productCategoryData?.map((productCategory: ICategory) => ({ label: productCategory.name, id: productCategory.id })) ?? []
    }, [productCategoryData])

    useEffect(() => {
        loadProductCategory();
    }, []);

    const loadUser = (inputValue?: string) => {
        customerGroupService.getAllUser({ page: 0, size: 10, sort: 'createdAt,desc', userType: 'ADMIN' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                setUserDataTo(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const userOptions = useMemo(() => {
        return userData?.map((user: any) => ({ label: user.name, id: user.userId })) ?? []
    }, [userData])

    useEffect(() => {
        loadUser();
    }, []);

    const updateSelectedCategory = (key: string) => (option: AutoCompleteOption | null) => {
        formik.setFieldValue(key, option);
    };

    const handleBack = () => {
        navigate(`${ADMIN_ROUTES.CUSTOMER_GROUP_MASTER_LIST}`);
    };

    return (
        <div className="grid gap-y-4">
            <div className={`w-full m-auto flex justify-between gap-x-2 text-center text-sm `}>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-1 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 mx-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto font-semibold`}>Customer Group Master</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-1 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 mx-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Customer Group Configuration</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className={`${classes.container} p-6 grid gap-y-5 rounded-lg`}>
                <div className="flex justify-between">
                    <div className={`${classes.filterHeading} text-2xl font-medium my-auto`}>{mode === "UPDATE" ? "Update Customer Group":"Add Customer Group"}</div>
                </div>

                <div className="grid gap-y-5" >
                    <div className={`${classes.filterContainer} pt-5 grid gap-y-6`}>
                        <div className="grid gap-y-6 w-1/2">
                            <div className="grid gap-y-1">
                                <TextFieldV2
                                    className="bg-white rounded-lg"
                                    variant="outlined"
                                    label="Group Name"
                                    placeholder="Group Name"
                                    fullWidth
                                    {...formik.getFieldProps("groupName")}
                                />
                                {formik.errors.groupName && formik.touched.groupName && (
                                    <div className={`${classes.errorMessage} text-xs`}>
                                        {formik.errors.groupName}
                                    </div>
                                )}
                            </div>
                            <div className="grid gap-y-2">
                                <FormControl fullWidth className={classes.input}>
                                    <AutoCompleteTextField
                                        options={productCategoryOptions}
                                        label="Main Product Category"
                                        onChange={updateSelectedCategory('productCategory')}
                                        onSearch={loadProductCategory}
                                        value={formik.values.productCategory}
                                        error={!!formik.errors.productCategory && formik.touched.productCategory}
                                        isDisabled={false}
                                        placeHolder="Select Category"
                                    />
                                    {formik.errors.productCategory?.id && formik.touched.productCategory && (
                                        <div className={`${classes.errorMessage} text-xs mt-1 ml-4`}>
                                            {formik.errors.productCategory?.id}
                                        </div>
                                    )}
                                </FormControl>
                            </div>
                            <div className="grid gap-y-1">
                                <TextFieldV2
                                    className="bg-white rounded-lg"
                                    variant="outlined"
                                    label="Description"
                                    placeholder="Description"
                                    fullWidth
                                    multiline
                                    maxRows={4}
                                    {...formik.getFieldProps("description")}
                                />
                                {formik.errors.description && formik.touched.description && (
                                    <div className={`${classes.errorMessage} text-xs`}>
                                        {formik.errors.description}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex gap-x-6 ">
                            <div className="grid gap-y-1.5 w-full h-fit">
                                <div className="grid gap-y-1">
                                    <SelectV2
                                        variant="outlined"
                                        label="Group Type"
                                        fullWidth
                                        {...formik.getFieldProps("groupType")}
                                        options={[
                                            { value: "PRIORITY", label: "Priority" },
                                            { value: "NORMAL", label: "Normal" },
                                            { value: "LEAST", label: "Least" },
                                        ]}
                                    />
                                    {formik.errors.groupType && formik.touched.groupType && (
                                        <div className={`${classes.errorMessage} text-xs`}>
                                            {formik.errors.groupType}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="grid gap-y-1.5 w-full h-fit">
                                <div className="grid gap-y-1">
                                    <SelectV2
                                        variant="outlined"
                                        label="Industry Type"
                                        fullWidth
                                        {...formik.getFieldProps("industryType")}
                                        options={industryOptions}
                                    />
                                    {formik.errors.industryType && formik.touched.industryType && (
                                        <div className={`${classes.errorMessage} text-xs`}>
                                            {formik.errors.industryType}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="grid gap-y-2 w-full h-fit">
                                <FormControl fullWidth className={classes.input}>
                                    <AutoCompleteTextField
                                        options={userOptions}
                                        label="Group Owner"
                                        onChange={updateSelectedCategory('groupOwner')}
                                        onSearch={loadUser}
                                        value={formik.values.groupOwner}
                                        error={!!formik.errors.groupOwner && formik.touched.groupOwner}
                                        isDisabled={false}
                                        placeHolder="Group Owner"
                                    />
                                    {formik.errors.groupOwner?.id && formik.touched.groupOwner && (
                                        <div className={`${classes.errorMessage} text-xs mt-1 ml-4`}>
                                            {formik.errors.groupOwner?.id}
                                        </div>
                                    )}
                                </FormControl>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between">
                        <ButtonV2 variant="secondaryContained" size="large" label={"Cancel"} onClick={handleBack} />
                        <div className="flex gap-x-4">
                        {
                            mode === "UPDATE" && <ButtonV2 variant="primaryContained" size="large" label={"Save"} onClick={handleSave} />
                        }
                        <ButtonV2 variant="primaryContained" size="large" label={"Next"} onClick={handleSubmit} />
                    </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerGroupMasterFormTemplate;