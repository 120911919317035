import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { ADMIN_ROUTES, MODE, SERVICE_STATUS_OPTIONS, STATUS, uomMapping } from '../../../utils/constant';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../utils/types';
import { IAttributePayload, useAttributeService } from '../../../services/useAttributeService';
import ButtonV2 from '../../atoms/ButtonV2';
import { makeRoute } from '../../../utils/helper';
import { createUseStyles } from 'react-jss';
import StatusV2 from '../../atoms/Status/StatusV2';
import { useFormik } from 'formik'
import editIcon from "../../../assets/icons/editPenWhiteOutlinedIcon.svg"

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    textColor: {
        color: theme.palette.text.primaryDarkLight
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    property: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    image: {
        width: "141px",
        height: "159px"
    },
    value: {
        color: theme.palette.textV2.primary.primary950
    },
    number: {
        backgroundColor: theme.palette.backgroundV2.primary.primary100,
        color: theme.palette.textV2.primary.primary800,
        width: "54px",
        height: "54px"
    }
}));

const AttributeViewPage: React.FC = () => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const attributeService = useAttributeService();
    const navigate = useNavigate();
    const params = useParams();
    const attributeId = Number(params.id);
    const [attribute, setAttribute] = useState<any | null>(null)

    const loadAttribute = async () => {
        attributeService.getAttributeById(Number(params.id))
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    setAttribute(res.data.data)
                }
            }).catch((error) => {
                setAttribute(null);
                console.error("Error Fetching Machine: ", error);
                showSnackbar('error', "Error while fetching Machine data");
            })
    }

    useEffect(() => {
        loadAttribute()
    }, [Number(params.id)])

    const onBack = () => {
        navigate(ADMIN_ROUTES.ATTRIBUTE_LIST);
    }

    const handleActionRoute = (id: number) => () => {
        navigate(makeRoute(ADMIN_ROUTES.ATTRIBUTE_UPDATE, { params: { id: id } }));
    }

    const updateMachine = async (machine: IAttributePayload) => {
        const attributeRequestBody: any = {
            name: machine.name.trim(),
            attributeType: machine.attributeType,
            fieldType: machine.fieldType,
            attributeOptions: machine.attributeOptions,
            isUpc: machine.isUpc,
            isMpc: machine.isMpc,
            description: machine.description,
            uom: machine.uom,
            status: machine.status,
            parentId: null,
            attributeRank: machine.attributeRank
        }
        attributeService.updateAttribute(attributeId, attributeRequestBody)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "New Attribute Created");
                    onBack();
                } else if (res.data.exceptionCode === "DUPLICATE_ATTRIBUTE") {
                    showSnackbar('error', "Attribute already exists. Please try again");
                }
            }).catch((error: any) => {
                showSnackbar('error', "Failed to create Machine");
            })
    }

    const formik = useFormik<IAttributePayload>({
        initialValues: {
            name: '',
            attributeType: '',
            fieldType: '',
            attributeOptions: [],
            isUpc: false,
            isMpc: false,
            description: '',
            uom: '',
            status: STATUS.ACTIVE,
            parentId: null,
            attributeRank: 1,
            uomCheck: false
        },
        onSubmit: async (values, { setSubmitting }) => {
            updateMachine({ ...values, status: values.status })
        }
    })

    useEffect(() => {
        const uom = attribute?.uom ?? "";
        const uomKeys = Object.keys(uomMapping);
        const uomIndex = uomKeys.indexOf(uom) + 1;

        const uomValue = uomMapping[uom] ? {
            label: uom,
            id: uomIndex,
            shortcode: uomMapping[uom].shortcode,
            fullDescription: uomMapping[uom].fullDescription,
        } : null;

        formik.setValues({
            name: attribute?.name ?? "",
            attributeType: attribute?.attributeType ?? "",
            fieldType: attribute?.fieldType ?? "",
            attributeOptions: attribute?.attributeOptions ? JSON.parse(attribute?.attributeOptions) : [],
            isUpc: false,
            isMpc: false,
            description: attribute?.description,
            uom: uom,
            status: attribute?.status ?? STATUS.ACTIVE,
            parentId: null,
            attributeRank: attribute?.attributeRank ?? null,
            uomCheck: attribute?.uom ? true : false,
            uomValue: uomValue,
        });
    }, [attribute]);

    useEffect(() => {
        console.log(formik)
    }, [formik]);

    const SortAttributeOptions = (a: string, b: string) => {
        const isNumericA = !isNaN(parseInt(a));
        const isNumericB = !isNaN(parseInt(b));
        if (isNumericA && isNumericB) {
            return parseInt(a) - parseInt(b);
        } else if (isNumericA) {
            return -1;
        } else if (isNumericB) {
            return 1;
        }
        return a.localeCompare(b, 'en', { numeric: true });
    };

    const sortTags = (tags: any[]) => {
        return tags.sort(SortAttributeOptions);
    };

    return (
        <div>
            {SnackBarComponent}
            <form className="grid gap-y-8" >
                <div className='flex justify-between'>
                    <div className={`${classes.mainHeading} text-lg font-semibold my-auto`}>Attribute View: {formik.values?.name}</div>
                    <ButtonV2 onClick={handleActionRoute(Number(params.id))} variant='primaryContained' label={"Edit Attribute"} startIcon={<img src={editIcon} alt="editIcon" />} />
                </div>

                <div className='grid gap-y-4'>
                    <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                        <div className={`${classes.heading} text-base font-medium`}>Attribute Details</div>
                        <div className='grid grid-cols-4'>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Name</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.name}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Type</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.attributeType}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Rank</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.attributeRank}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Feild Type</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.fieldType}</div>
                            </div>
                        </div>
                    </div>

                    <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                        <div className={`${classes.heading} text-base font-medium`}>Attribute Options</div>
                        {formik.values?.uomValue?.fullDescription &&
                            <div className='grid grid-cols-4'>
                                <div className="grid gap-y-1">
                                    <div className={`${classes.property}`}>Unit Of Measurement(UOM)</div>
                                    <div className={`${classes.heading} text-base font-medium`}>{formik.values?.uomValue?.fullDescription}</div>
                                </div>

                                <div className="grid gap-y-1">
                                    <div className={`${classes.property}`}>ShortCode</div>
                                    <div className={`${classes.heading} text-base font-medium`}>{formik.values?.uom}</div>
                                </div>
                            </div>
                        }

                        <div className='grid '>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Options</div>
                                <div className='flex flex-wrap gap-3'>
                                    {attribute?.attributeOptions &&
                                        sortTags(JSON.parse(attribute.attributeOptions)).map((option: any, index: number) => (
                                            <div key={index} className={`${classes.heading} ${classes.border} px-3 rounded text-base font-medium`}>
                                                {option}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                        <div className={`${classes.heading} text-base font-medium`}>Attribute Description</div>
                        <div className="grid gap-y-1">
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Description</div>
                                <div className={`${classes.heading} text-base font-medium`}>{attribute?.description}</div>
                            </div>
                        </div>
                    </div>

                    <StatusV2 heading="Service Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" mode={MODE.VIEW} />

                </div>

                <div className="flex justify-end gap-4">
                    <ButtonV2 variant="tertiaryContained" label='Back' onClick={onBack} />
                </div>
            </form>
        </div>
    )
}

export default AttributeViewPage