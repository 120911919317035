import React from 'react'
import { createUseStyles } from 'react-jss';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { DELIVERY_INFORMATION, MODE, PACKAGING_TYPES, SERVICE_STATUS_OPTIONS } from '../../../../utils/constant';
import ButtonV2 from '../../../atoms/ButtonV2';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import { BUNDLE_FORM_STATE } from '../../../pages/CatalogueBundling/Bundle/BundleCreate.page';
import StatusV2 from '../../../atoms/Status/StatusV2';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TableV2, { ColumnType } from '../../../organisms/TableV2';
import { IProductDetails } from './BundleDetailForm.template';
import { FormikErrors } from "formik";
import deleteIcon from '../../../../assets/icons/deleteIcon.svg';
import SelectV2 from '../../../atoms/SelectV2';
import { InputAdornment } from '@mui/material';
import { toInr } from '../../../../utils/helper';

export interface ProductFormTemplateProps {
    mode: string;
    onClose: () => void;
    formik: any;
    setFormStateTo: (state: BUNDLE_FORM_STATE) => () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
        lineHeight: "22px"
    },
    desc: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    previousProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary600
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    inActiveProgressBar: {
        backgroundColor: theme.palette.backgroundV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    location: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        color: theme.palette.textV2.tertiary.tertiary900,
        "& span": {
            color: theme.palette.textV2.tertiary.tertiary600
        }
    },
    label: {
        color: theme.palette.textV2.tertiary.tertiary600,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "16.1px"
    },
    datePicker: {
        "& .MuiInputBase-root": {
            border: "1px solid",
            borderColor: theme.palette.borderV2.tertiary.tertiary200,
            fontSize: "16px",
            fontWeight: 500,
            borderRadius: 8,
            color: theme.palette.textV2.primary.primary900,
            "&:hover": {
                borderColor: theme.palette.borderV2.primary.primary500,
                borderWidth: 2,
                outline: "none",
            },
            "& .MuiInputBase-input": {
                "&::placeholder": {
                    color: `${theme.palette.textV2.tertiary.tertiary900} !important`,
                    fontWeight: 400
                }
            },
            "& .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: theme.palette.border.secondaryDark,
            },
            "&:focus-within": {
                outline: "none",
                borderColor: theme.palette.borderV2.primary.primary500,
                borderWidth: 2,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: theme.palette.borderV2.primary.primary500,
            },
            "& .Mui-disabled": {
                backgroundColor: theme.palette.background.secondaryDarkAccent,
                fontWeight: 500,
                borderColor: theme.palette.textV2.tertiary.tertiary200,
                borderRadius: "8px",
                fontSize: "16px"
            },
        },
    },
    value: {
        "& .MuiTypography-root": {
            color: theme.palette.textV2.tertiary.neutral400
        }
    }
}));

const ProductFormTemplate: React.FC<ProductFormTemplateProps> = ({ mode, onClose, formik, setFormStateTo }) => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const handleNext = () => {
        const touchedProductDetails = formik.values.productDetails.map(() => ({ bundleMoq: true }));

        formik.setTouched({
            name: true,
            moq: true,
            price: true,
            startDate: true,
            expiryDate: true,
            productDetails: touchedProductDetails,
            packagingType: true,
            deliveryTerm: true
        }, true);

        const errors = Object.keys(formik.errors).find(key => ['name', 'moq', 'price', 'startDate', 'expiryDate', 'productDetails', 'packagingType', 'deliveryTerm'].includes(key));
        if (errors) return;

        setFormStateTo(BUNDLE_FORM_STATE.REVIEW)();
    };

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: 0,
            currentPage: 0,
            isVisible: false,
            limit: 0,
        },
        columns: [
            { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Product Code", key: "productCode", type: "string" as ColumnType, props: { className: '' } },
            { label: "Product Name", key: "productCode", type: "string" as ColumnType, props: { className: '' } },
            { label: "MOQ", key: "moq", type: "string" as ColumnType, props: { className: '' } },
            { label: "Available Stock", key: "availableStock", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "PDP Price (Ex. tax)", key: "price", type: "string" as ColumnType, props: { className: '' } },
            { label: "Bundle MOQ", key: "bundleMoq", type: "string" as ColumnType, props: { className: '' } },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ],
    };

    const Action = (id: number, index: number) => {
        const isLastProduct = formik.values.productDetails.length === 1;
        return (
            <div className="flex justify-center">
                <img
                    src={deleteIcon}
                    alt="Actions"
                    className={ mode === MODE.UPDATE && isLastProduct  ? "cursor-none" : "cursor-pointer" }
                    onClick={() => {
                        if (!(mode === MODE.UPDATE && isLastProduct)) {
                            deleteProduct(index);
                        }
                    }}
                />
            </div>
        );
    };

    const BundleMOQ = (id: number, index: number) => {
        return (
            <div className='grid gap-y-1 mx-auto my-2 w-[180px]'>
                <TextFieldV2
                    type='number'
                    placeholder='Enter Bundle MOQ'
                    {...formik.getFieldProps(`productDetails[${index}].bundleMoq`)}
                    inputProps={{ readOnly: mode === MODE.VIEW }}
                    error={formik.touched?.productDetails?.[index]?.bundleMoq &&
                        Boolean((formik.errors?.productDetails as FormikErrors<IProductDetails>[])?.[index]?.bundleMoq)
                    }
                />
                {formik.errors.productDetails?.[index]?.bundleMoq && formik.touched?.productDetails?.[index]?.bundleMoq && (
                    <div className={`${classes.errorMessage} text-xs`}>
                        {formik.errors.productDetails?.[index]?.bundleMoq}
                    </div>
                )}
            </div>
        );
    };

    const records = formik.values.productDetails?.map((product: any, index: number) => [
        index + 1,
        product?.productCode,
        product?.productName,
        `${product?.moq} MT`,
        `${product?.availableStock} MT`,
        toInr(product?.price),
        BundleMOQ(product?.id, index),
        Action(product?.id, index),
    ]);

    const deleteProduct = async (index: number) => {
        const updatedProductDetails = [...formik.values.productDetails];
        updatedProductDetails.splice(index, 1);
        formik.setFieldValue('productDetails', updatedProductDetails);
        if (updatedProductDetails.length === 0) {
            setFormStateTo(BUNDLE_FORM_STATE.BUNDLE_DETAILS)();
        }
    };

    return (
        <div className="grid gap-y-8" >
            {SnackBarComponent}
            <div className={`w-full m-auto flex justify-between gap-x-2 text-center text-sm `}>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.previousProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto text-sm font-semibold`}>Bundle Details</div>
                    </div>
                    <div className={`${classes.previousProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Edit Products</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className='grid gap-y-6'>
                <div className='flex justify-between'>
                    <div className='grid gap-y-2 h-fit my-auto'>
                        <div className={`${classes.mainHeading} text-lg font-semibold`}>Selected Products</div>
                        <div className={`${classes.desc} text-sm `}>Enter the Bundle MOQ for all the product selected</div>
                    </div>
                    <div className={`${classes.location} text-base font-medium p-4 rounded-xl`}>
                        Source Location: <span className='text-sm '>{formik.values?.warehouse}</span>
                    </div>
                </div>

                <TableV2 schema={schema} records={records} />
            </div>

            <div className='grid gap-y-4'>
                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Bundle Details</div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            <TextFieldV2
                                label="Bundle MOQ*"
                                placeholder='Enter Bundle MOQ'
                                {...formik.getFieldProps("moq")}
                                error={formik.touched.moq && Boolean(formik.errors.moq)}
                                inputProps={{ readOnly: mode === MODE.VIEW }}
                                type="number"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" className={`${classes.value} text-base font-normal`}>
                                            MT
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {formik.errors.moq && formik.touched.moq && (
                                <div className={`${classes.errorMessage} text-xs`}>
                                    {formik.errors.moq}
                                </div>
                            )}
                        </div>

                        <div className="grid gap-y-1">
                            <TextFieldV2
                                label="Bundle Price*"
                                placeholder='Enter Bundle Price'
                                {...formik.getFieldProps("price")}
                                error={formik.touched.price && Boolean(formik.errors.price)}
                                inputProps={{ readOnly: mode === MODE.VIEW }}
                                type="number"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" className={`${classes.value} text-base font-normal`}>
                                            Rs
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {formik.errors.price && formik.touched.price && (
                                <div className={`${classes.errorMessage} text-xs`}>
                                    {formik.errors.price}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Valid From & Valid Until</div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className='grid gap-y-1'>
                            <div className='grid gap-y-1 h-fit'>
                                <div className={`${classes.label}`}>Start Date*</div>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        value={formik?.values?.startDate ? moment(formik.values.startDate, 'YYYY-MM-DD HH:mm:ss') : null}
                                        format="DD-MM-YYYY"
                                        onChange={(newValue) => {
                                            if (newValue) {
                                                const currentTime = moment();
                                                const selectedDateWithCurrentTime = moment(newValue)
                                                    .set({
                                                        hour: currentTime.hour(),
                                                        minute: currentTime.minute(),
                                                        second: currentTime.second(),
                                                    })
                                                    .format('YYYY-MM-DD HH:mm:ss');
                                                formik.setFieldValue('startDate', selectedDateWithCurrentTime);
                                            }
                                        }}
                                        className={classes.datePicker}
                                        disablePast
                                        slotProps={{
                                            textField: { 
                                                inputProps: { readOnly: mode === MODE.UPDATE },
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                            {formik.touched && formik.touched?.startDate && formik.errors?.startDate && (
                                <div className={classes.errorMessage}>
                                    <small>{formik.errors?.startDate}</small>
                                </div>
                            )}
                        </div>


                        <div className='grid gap-y-1'>
                            <div className='grid gap-y-1 h-fit'>
                                <div className={`${classes.label}`}>Expiry Date*</div>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        value={formik?.values?.expiryDate ? moment(formik.values.expiryDate, 'YYYY-MM-DD HH:mm:ss') : null}
                                        format="DD-MM-YYYY"
                                        onChange={(newValue) => {
                                            const formattedDate = moment(newValue)
                                                .endOf('day')
                                                .format('YYYY-MM-DD HH:mm:ss');
                                            formik.setFieldValue('expiryDate', formattedDate);
                                        }}
                                        className={classes.datePicker}
                                        disablePast
                                    />
                                </LocalizationProvider>
                            </div>
                            {formik.touched && formik.touched?.expiryDate && formik.errors?.expiryDate && (
                                <div className={classes.errorMessage}>
                                    <small>{formik.errors?.expiryDate}</small>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Other Details</div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className='grid gap-y-1'>
                            <SelectV2
                                placeholder='Select Packaging Type'
                                label="Select Packaging Type"
                                {...formik.getFieldProps("packagingType")}
                                name="packagingType"
                                id="packagingType"
                                options={PACKAGING_TYPES}
                            />
                            {formik.touched && formik.touched?.packagingType && formik.errors?.packagingType && (
                                <div className={classes.errorMessage}>
                                    <small>{formik.errors?.packagingType}</small>
                                </div>
                            )}
                        </div>

                        <div className='grid gap-y-1'>
                            <SelectV2
                                placeholder='Select Delivery Term'
                                label="Select Delivery Term"
                                value={""}
                                {...formik.getFieldProps("deliveryTerm")}
                                name="deliveryTerm"
                                id="deliveryTerm"
                                options={DELIVERY_INFORMATION}
                            />
                            {formik.touched && formik.touched?.deliveryTerm && formik.errors?.deliveryTerm && (
                                <div className={classes.errorMessage}>
                                    <small>{formik.errors?.deliveryTerm}</small>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <StatusV2 heading="Bundle Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" />
            </div>

            <div className='flex justify-between'>
                <ButtonV2 variant="tertiaryContained" label='Cancel' onClick={onClose} />
                <div className="flex justify-end gap-4">
                    <ButtonV2 variant="tertiaryContained" label='Back' onClick={setFormStateTo(BUNDLE_FORM_STATE.BUNDLE_DETAILS)} />
                    {mode !== MODE.VIEW && <ButtonV2 variant="primaryContained" onClick={handleNext} label={"Review"} />}
                </div>
            </div>
        </div>
    )
}

export default ProductFormTemplate;