import React from 'react'
import { createUseStyles } from 'react-jss';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { MODE, SERVICE_STATUS_OPTIONS } from '../../../../utils/constant';
import ButtonV2 from '../../../atoms/ButtonV2';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import StatusV2 from '../../../atoms/Status/StatusV2';
// import { IProductDetails } from './BundleDetailForm.template';
import { FormikErrors } from 'formik';
import TableV2, { ColumnType } from '../../../organisms/TableV2';
import { BUNDLE_LOT_FORM_STATE } from '../../../pages/CatalogueBundling/BundleLot/BundleLotCreate.page';
import { IBundle } from './ProductForm.template';
import { toInr } from '../../../../utils/helper';


export interface ReviewFormTemplateProps {
    mode: string;
    onClose: () => void;
    formik: any;
    setFormStateTo: (state: BUNDLE_LOT_FORM_STATE) => () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
        lineHeight: "22px"
    },
    desc: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    property: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    previousProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary600
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    location: {
        color: theme.palette.textV2.tertiary.tertiary900,
        "& span": {
            color: theme.palette.textV2.tertiary.tertiary600
        }
    },
}));

const ReviewFormTemplate: React.FC<ReviewFormTemplateProps> = ({ mode, onClose, formik, setFormStateTo }) => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const handleNext = () => {
        formik.setTouched({ name: true, moq: true, price: true, startDate: true, expiryDate: true }, true)
        const errors = Object.keys(formik.errors).find(key => ['name', 'moq', 'price', 'startDate', 'expiryDate'].includes(key))
        if (errors) return;
        formik.handleSubmit()
    }

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: 0,
            currentPage: 0,
            isVisible: false,
            limit: 0,
        },
        columns: [
            { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Product Code", key: "name", type: "string" as ColumnType, props: { className: '' } },
            { label: "Start Date", key: "startDate", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Expiry Date", key: "expiryDate", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Bundle MOQ", key: "bundleMoq", type: "number" as ColumnType, props: { className: '' } },
            { label: "Price", key: "price", type: "number" as ColumnType, props: { className: '' } },
            { label: "Bundle Lot MOQ", key: "bundleLotMoq", type: "number" as ColumnType, props: { className: '' } },
        ],
    };

    const records = formik.values.productDetails?.map((bundle: any, index: number) => [
        index + 1,
        bundle?.name,
        bundle?.startDate,
        bundle?.expiryDate,
        `${bundle?.bundleMoq} MT`,
        toInr(bundle?.price),
        `${bundle?.bundleLotMoq} MT`,
    ]);

    return (
        <div className="grid gap-y-8" >
            {SnackBarComponent}
            <div className={`w-full m-auto flex justify-between gap-x-2 text-center text-sm `}>
                <div className="grid gap-y-3 w-1/4">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.previousProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto text-sm font-semibold`}>Lot Details</div>
                    </div>
                    <div className={`${classes.previousProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/4">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.previousProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Select Bundles for the Lot</div>
                    </div>
                    <div className={`${classes.previousProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/4">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.previousProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Edit Bundle MOQ</div>
                    </div>
                    <div className={`${classes.previousProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/4">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className='grid gap-y-4'>
                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className='flex justify-between'>
                        <div className={`${classes.heading} text-base font-medium my-auto`}>Lot Details</div>
                        <ButtonV2 variant="secondaryContained" size="small" label={'Edit'} onClick={setFormStateTo(BUNDLE_LOT_FORM_STATE.LOT_INFORMATION)} />
                    </div>
                    <div className='grid grid-cols-5 gap-x-4'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Bundle Lot Name</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.name}</div>
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className='flex justify-between'>
                        <div className={`${classes.heading} text-base font-medium my-auto`}>Selected Bundles</div>
                        <ButtonV2 variant="secondaryContained" size="small" label={'Edit'} onClick={setFormStateTo(BUNDLE_LOT_FORM_STATE.EDIT_BUNDLE_MOQ)} />
                    </div>
                    <TableV2 schema={schema} records={records} />
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className='flex justify-between'>
                        <div className={`${classes.heading} text-base font-medium`}>Bundle Lot Details</div>
                        <ButtonV2 variant="secondaryContained" size="small" label={'Edit'} onClick={setFormStateTo(BUNDLE_LOT_FORM_STATE.EDIT_BUNDLE_MOQ)} />
                    </div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Bundle Lot MOQ</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.moq} MT</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Bundle Lot Price</div>
                            <div className={`${classes.heading} text-base font-medium`}>{toInr(formik.values?.price)}</div>
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className='flex justify-between'>
                        <div className={`${classes.heading} text-base font-medium`}>Valid From & Valid Until</div>
                        <ButtonV2 variant="secondaryContained" size="small" label={'Edit'} onClick={setFormStateTo(BUNDLE_LOT_FORM_STATE.EDIT_BUNDLE_MOQ)} />
                    </div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Start Date</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.startDate}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Expiry Date</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.expiryDate}</div>
                        </div>
                    </div>
                </div>

                <StatusV2 heading="Bundle Lot Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" />
            </div>

            <div className='flex justify-between'>
                <ButtonV2 variant="tertiaryContained" label='Cancel' onClick={onClose} />
                <div className="flex justify-end gap-4">
                    <ButtonV2 variant="tertiaryContained" label='Back' onClick={setFormStateTo(BUNDLE_LOT_FORM_STATE.EDIT_BUNDLE_MOQ)} />
                    {mode !== MODE.VIEW && <ButtonV2 variant="primaryContained" onClick={handleNext} label={"Save Bundle"} />}
                </div>
            </div>
        </div>
    )
}

export default ReviewFormTemplate;