import React, { useEffect, useMemo, useState } from 'react'
import { useSnackbar } from '../../../hooks/useSnackBar';
import { Category, CategoryPayload, useCategoryService } from '../../../services/useCategoryService';
import { useNavigate, useParams } from 'react-router-dom';
import { ADMIN_ROUTES, RESOURCE_TYPE } from '../../../utils/constant';
import { HTTP_STATUS, PRODUCT_CATEGORY_FORM_STATE } from '../../../utils/types';
import { useFormik } from 'formik';
import * as Yup from "yup";
import AttributeManagementFormTemplate from '../../templates/Category/AttributeManagementForm.template';
import StandardManagementFormTemplate from '../../templates/Category/StandardManagementForm.template';
import ProductCategoryDetailsFormTemplate from '../../templates/Category/ProductCategoryDetailsForm.template';
import { makeRoute } from '../../../utils/helper';
import CategoryFormTemplate from '../../templates/Category/CategoryForm.template';
import { ImageData } from '../../organisms/ImageUploader';
import { useFileService } from '../../../services/useFileService';

interface ProductCategoryUpdatePageProps {
	mode: string
	category: Category
}

const ProductCategoryUpdatePage: React.FC<ProductCategoryUpdatePageProps> = ({ mode, category }) => {
	const { showSnackbar, SnackBarComponent } = useSnackbar();
	const categoryService = useCategoryService();
	const navigate = useNavigate();
	const params = useParams();

	const [formState, setFormState] = useState<PRODUCT_CATEGORY_FORM_STATE>(1);
	const [image, setImagesTo] = useState<(ImageData | File)[]>([]);
	const fileService = useFileService();
	const [imageIdsToDelete, setImageIdsToDelete] = useState<number[]>([]);

	const categoryId = Number(params.id);
	const onClose = () => {
		navigate(makeRoute(ADMIN_ROUTES.CATEGORY, { query: { type: 'PRODUCT_CATEGORY' } }));
	}
	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Category Name is required"),
		description: Yup.string().required("Category Description is required")
		.test('no-p-br-tags', 'Defect details cannot be Empty', ((value:any)=> value !== '<p><br></p>')),
		parentId: Yup.number().required("Main Category is required"),
		superParentId: Yup.number().required("Super Category is required"),
		prefix: Yup.string().required('Product Category Code is required').matches(/^[A-Z]{2}$/, 'Code must be exactly two alphabetic characters'),
		customization: Yup.array().of(Yup.string()).min(1, 'At least one Customization is required').required('Customization are required'),
	});

	const updateProductCategory = async (category: CategoryPayload) => {
		try {
			if(imageIdsToDelete.length){
		     fileService.deleteImagesByIds(imageIdsToDelete.join(","));		
			}
			const productCategoryResponse = await categoryService.updateCategory(categoryId, category);
			if (productCategoryResponse.status === HTTP_STATUS.OK) {
				const resourceId: number = productCategoryResponse.data.data.id;
				await fileService.uploadImage(image, resourceId, RESOURCE_TYPE.PRODUCT_CATEGORY);
				onClose();
			} else if (productCategoryResponse.status === HTTP_STATUS.BAD_REQUEST) {
				setFormState(PRODUCT_CATEGORY_FORM_STATE.PRODUCT_CATEGORY)
				showSnackbar('error', "Category already exists. Please try again");
			}
		} catch (error) {
			showSnackbar('error', "Failed to update Product Category");
		}
	}

	const fetchCategoryImage = async (categoryId: number) => {
		try {
			const fileResponse = await fileService.getFilesById(categoryId, RESOURCE_TYPE.PRODUCT_CATEGORY);
			(fileResponse.status === HTTP_STATUS.OK) && setImagesTo(fileResponse?.data?.data);
		} catch (error) {
			showSnackbar("error", 'Standard fetch failed');
			setImagesTo([]);
		}
	}

	useEffect(() => {
		fetchCategoryImage(categoryId);
	}, [categoryId]);

	const formik = useFormik<CategoryPayload>({
		initialValues: {
			name: category?.name ?? "",
			status: category?.status ?? "",
			description: category?.description ?? "",
			parentId: category?.parentId,
			superParentId: category?.superParent?.id,
			level: 2,
			prefix: category?.prefix ?? "",
			parentCategory: { label: category?.parentCategory?.name, id: category?.parentCategory?.id },
			superParentCategory: { label: category.superParent?.name, id: category?.superParent?.id },
			attributes: category?.attributeDetails,
			standards: category?.standards,
			standardOptions: category.standardDetails.map(standard => ({ label: standard.name, id: standard.id })),
			customization: category?.customization
		},
		validationSchema,
		onSubmit: (values) => updateProductCategory(values)
	})

	const setFormStateTo = (formState: PRODUCT_CATEGORY_FORM_STATE) => () => {
		setFormState(formState)
	}

	const activeFormStep = useMemo(() => {
		const props = { mode, onClose, formik, setFormStateTo, image, setImagesTo, imageIdsToDelete, setImageIdsToDelete  }
		switch (formState) {
			case PRODUCT_CATEGORY_FORM_STATE.PRODUCT_CATEGORY: return <ProductCategoryDetailsFormTemplate {...props} />;
			case PRODUCT_CATEGORY_FORM_STATE.ATTRIBUTE_MANAGEMENT: return <AttributeManagementFormTemplate {...props} />;
			default: return <StandardManagementFormTemplate {...props} />;
		}
	}, [formState, formik])

	return (
		<div>
			{SnackBarComponent}
			<CategoryFormTemplate {...{ mode, onClose, component: activeFormStep }} />
		</div>
	)
}

export default ProductCategoryUpdatePage