import { request } from "."
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const AUTH_URLS = {
    LOGIN: "/auth/sign-in",
    SINGUP: "/auth/sign-up",
    GET_SIDE_BAR_SCHEMA: "/auth/sign-in/schema/:userId",
}

export const useAuthService = () => {

    const login = (data: { email: string; password: string } | { mobileNumber: string; otp?: string }) => {
        return request(API_METHOD.POST, AUTH_URLS.LOGIN, null, data);
    }

    const signUp = (data: { mobileNumber: string, otp?: string }) => {
        return request(API_METHOD.POST, AUTH_URLS.SINGUP, null, data);
    }

    const getSideBarSchema = (userId: number) => {
        return request(API_METHOD.GET, replaceUrlParams( AUTH_URLS.GET_SIDE_BAR_SCHEMA, { userId } ), null);
    }
    
    return {
        login, 
        signUp,
        getSideBarSchema
    }
};