import React, { useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { MODE, initialPaginationValues, sortOptions, statusOptions } from '../../../../utils/constant';
import ButtonV2 from '../../../atoms/ButtonV2';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import TableV2, { ColumnType } from '../../../organisms/TableV2';
import { BUNDLE_LOT_FORM_STATE } from '../../../pages/CatalogueBundling/BundleLot/BundleLotCreate.page';
import { IBundleRequestParams, IBundleSearchParams, IBundles, useCatalogueBundlingService } from '../../../../services/useCatalogueBundlingService';
import { HTTP_STATUS, IPagination, VAS_MASTER_CATEGORY_TYPE } from '../../../../utils/types';
import { IMultiSelectOption } from '../../../atoms/MultiSelectInput2';
import { Service, useVASMasterCategoryService } from '../../../../services/useVASMasterCategoryService';
import FilterChipV2 from '../../../molecules/FilterChip/FilterChipV2';
import FilterChipV3 from '../../../molecules/FilterChip/FilterChipV3';
import { Checkbox } from '@mui/material';
import { toInr } from '../../../../utils/helper';

export interface SelectBundleFormTemplateProps {
    mode: string;
    onClose: () => void;
    formik: any;
    setFormStateTo: (state: BUNDLE_LOT_FORM_STATE) => () => void;
}

export interface IBundle {
    id: number;
    bundleName: string;
    startDate: string;
    expiryDate: string;
    bundleMoq: number | null;
    price: number | null;
    bundleLotMoq: number | null;
}

const initialBundleFiltersValues: IBundleSearchParams = {
    search: "",
    // status: "ALL",
    sort: "createdAt,desc",
};

const initialBundleRequestFilters: IBundleRequestParams = {
    catalogBundleIds: [],
    productIds: [],
};

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
        lineHeight: "22px"
    },
    desc: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    previousProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary600
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    inActiveProgressBar: {
        backgroundColor: theme.palette.backgroundV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    location: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        color: theme.palette.textV2.tertiary.tertiary900,
        "& span": {
            color: theme.palette.textV2.tertiary.tertiary600
        }
    },
    label: {
        color: theme.palette.textV2.tertiary.tertiary600,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "16.1px"
    },
    datePicker: {
        "& .MuiInputBase-root": {
            border: "1px solid",
            borderColor: theme.palette.borderV2.tertiary.tertiary200,
            fontSize: "16px",
            fontWeight: 500,
            borderRadius: 8,
            color: theme.palette.textV2.primary.primary900,
            "&:hover": {
                borderColor: theme.palette.borderV2.primary.primary500,
                borderWidth: 2,
                outline: "none",
            },
            "& .MuiInputBase-input": {
                "&::placeholder": {
                    color: `${theme.palette.textV2.tertiary.tertiary900} !important`,
                    fontWeight: 400
                }
            },
            "& .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: theme.palette.border.secondaryDark,
            },
            "&:focus-within": {
                outline: "none",
                borderColor: theme.palette.borderV2.primary.primary500,
                borderWidth: 2,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: theme.palette.borderV2.primary.primary500,
            },
            "& .Mui-disabled": {
                backgroundColor: theme.palette.background.secondaryDarkAccent,
                fontWeight: 500,
                borderColor: theme.palette.textV2.tertiary.tertiary200,
                borderRadius: "8px",
                fontSize: "16px"
            },
        },
    },
    checkBox: {
        color: theme.palette.textV2.tertiary.tertiary300,
        '&.Mui-checked': {
            color: theme.palette.textV2.primary.primary400,
        },
    },
}));

const SelectBundleFormTemplate: React.FC<SelectBundleFormTemplateProps> = ({ mode, onClose, formik, setFormStateTo }) => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const handleNext = () => {
        formik.setTouched({ name: true }, true)
        const errors = Object.keys(formik.errors).find(key => ['name'].includes(key))
        if (errors) return;
        setFormStateTo(BUNDLE_LOT_FORM_STATE.EDIT_BUNDLE_MOQ)();
    }

    const catalogueBundlingService = useCatalogueBundlingService();
    const [filters, setFilters] = useState<IBundleSearchParams>(initialBundleFiltersValues);
    const [bundleRequestFilters, setBundleRequestFiltersTo] = useState<any>(initialBundleRequestFilters);
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const [bundles, setBundles] = useState<IBundles[]>([])

    const refreshBundle = async () => {
        const payload: IBundleRequestParams = {
            catalogBundleIds: bundleRequestFilters?.catalogBundleIds?.length > 0 ? bundleRequestFilters?.catalogBundleIds : null,
            productIds: bundleRequestFilters?.productIds?.length > 0 ? bundleRequestFilters?.productIds : null,
        };
        await catalogueBundlingService.getBundles(
            {
                page: pagination.currentPage,
                size: pagination.pageSize,
                sort: filters?.sort,
                search: filters?.search?.trim(),
                // status: filters?.status === "ALL" ? "" : filters?.status,
            },
            payload
        )
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    const { totalElements, totalPages } = res?.data?.data;
                    setPagination({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setBundles(res?.data?.data?.content)
                }
            }).catch(error => {
                showSnackbar('error', error);
                setBundles([]);
            })
    }

    const handleFiltersChange = (name: string, value: any) => {
        setPagination({ ...pagination, currentPage: 0 });
        setFilters({ ...filters, [name]: value ?? "" });
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 0,
            size: parseInt(event.target.value, 10)
        }));
    };

    useEffect(() => {
        refreshBundle();
    }, [pagination.currentPage, pagination.pageSize, filters, bundleRequestFilters]);

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination?.totalRecords,
            currentPage: pagination?.currentPage,
            isVisible: true,
            limit: pagination?.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Name", key: "name", type: "string" as ColumnType, props: { className: '' } },
            { label: "Start Date", key: "uniqueCode", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Expiry Date", key: "warehouse", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Bundle MOQ", key: "moq", type: "number" as ColumnType, props: { className: '' } },
            { label: "Price", key: "price", type: "number" as ColumnType, props: { className: '' } },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ],
    };

    const handleCheckBoxChange = (bundle: any) => () => {

    }

    const Action = (bundle: any) => {
        return (
            <div>
                <Checkbox
                    sx={{ padding: 0, width: "18px", height: "18px", }}
                    className={classes.checkBox}
                    checked={Boolean(formik.values.productDetails.id)}
                    onChange={handleCheckBoxChange(bundle)}
                />
            </div>
        );
    };

    const records = bundles?.map((bundle: any, index: number) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        bundle?.name,
        bundle?.startDate,
        bundle?.expiryDate,
        `${bundle?.moq} MT`,
        toInr(bundle?.price),
        Action(bundle),
    ]);

    const [bundleData, setBundleData] = useState<IBundles[]>([])
    // const [productData, setProductData] = useState<any[] | null>(null);

    const initialSelections = {
        // status: [{ label: 'ALL', value: 'ALL' }],
        sortDate: [{ label: 'createdAt,desc', value: 'createdAt,desc' }]
    };

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedfilters, setSelectedFilters] = useState<any>(initialBundleRequestFilters);

    // const [status, setStatus] = useState<IMultiSelectOption[]>(initialSelections.status);
    const [sortDate, setSortDate] = useState<IMultiSelectOption[]>(initialSelections.sortDate);

    const refreshCatalogueBundle = (inputValue?: string) => {
        catalogueBundlingService.getBundles({ page: 0, size: 10, sort: 'createdAt,desc', search: inputValue ?? '' },{})
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                setBundleData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Service: ", error);
                showSnackbar('error', "Error while fetching Super Service data");
            })
    }

    const fetchProduct = (inputValue?: string) => {
        // vasMasterCategoryService.getAllService({ level: VAS_MASTER_CATEGORY_TYPE.MAIN_SERVICE, page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', status: 'ACTIVE' },{})
        //     .then(res => {
        //         if (res.status === HTTP_STATUS.OK)
        //             setProductData(res?.data?.data?.content)
        //     }).catch((error) => {
        //         console.error("Error Fetching Service: ", error);
        //         showSnackbar('error', "Error while fetching Product data");
        //     })
    }

    useEffect(() => {
        refreshCatalogueBundle(searchTerm);
    }, [searchTerm]);

    // useEffect(() => {
    //     fetchProduct(searchTerm);
    // }, [searchTerm]);

    const bundleOptions = useMemo(() => {
        return bundleData?.map((bundle: IBundles) => ({ label: bundle.name, value: bundle.id })) ?? []
    }, [bundleData])

    // const productOptions = useMemo(() => {
    //     return productData?.map((product: Service) => ({ label: product.name, value: product.id })) ?? []
    // }, [productData])


    // const handleSelectionChangeStatus = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
    //     setStatus(selected);
    //     clearWithCrossIcon && handleStatusSaveClick(selected);
    // };

    const handleSelectionChangeSort = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSortDate(selected);
        clearWithCrossIcon && handleSortSaveClick(selected);
    };

    const handleSelectionChange = (name: string, selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSelectedFilters((previousSelectedFilters: any) => ({
            ...previousSelectedFilters,
            [name]: selected
        }));
        clearWithCrossIcon && handleDeleteClick(name, selected);
    };

    // const handleStatusSaveClick = (status: IMultiSelectOption[]) => {
    //     const valueString = status.length > 0 ? status[0].value : 'ALL';
    //     handleFiltersChange("status", valueString);
    // };

    const handleSortSaveClick = (sortDate: IMultiSelectOption[]) => {
        const sortString = sortDate.length > 0 ? sortDate[0].value : 'createdAt,desc';
        handleFiltersChange("sort", sortString);
    };

    const handleSaveClick = (name: string, selectedValues: IMultiSelectOption[]) => {
        const updatedFilters = { ...bundleRequestFilters };
        const newValues = selectedValues.map(option => option.value);

        if (updatedFilters[name]) {
            updatedFilters[name] = Array.from(new Set([...updatedFilters[name], ...newValues]));
        } else {
            updatedFilters[name] = newValues;
        }
        setBundleRequestFiltersTo(updatedFilters);
    };

    const handleDeleteClick = (name: string, selectedValues: IMultiSelectOption[]) => {
        const selectedValueIds = selectedValues.map(option => option.value);
        const updatedFilters = {
            ...bundleRequestFilters,
            [name]: bundleRequestFilters[name].filter((id: number) => selectedValueIds.includes(id))
        };
        setBundleRequestFiltersTo(updatedFilters);
    };

    const handleClearClick = (filterType: string) => {
        switch (filterType) {
            // case 'status':
            //     setStatus([{ label: 'ALL', value: 'ALL' }]);
            //     handleFiltersChange("status", 'ALL');
            //     break;
            case 'sortDate':
                setSortDate([{ label: 'createdAt,desc', value: 'createdAt,desc' }]);
                handleFiltersChange("sort", 'createdAt,desc');
                break;
            case 'catalogBundleIds':
                setSelectedFilters((previousSelectedFilters: any) => ({
                    ...previousSelectedFilters,
                    catalogBundleIds: []
                }));
                setBundleRequestFiltersTo((prevState: any) => ({
                    ...prevState,
                    catalogBundleIds: []
                }));
                break;
            // case 'productIds':
            //     setSelectedFilters((previousSelectedFilters: any) => ({
            //         ...previousSelectedFilters,
            //         productIds: []
            //     }));
            //     setBundleRequestFiltersTo((prevState: any) => ({
            //         ...prevState,
            //         productIds: []
            //     }));

            //     break;
            default:
                // setStatus([{ label: 'ALL', value: 'ALL' }]);
                setSortDate([{ label: 'createdAt,desc', value: 'createdAt,desc' }]);
                setSelectedFilters(initialBundleRequestFilters);
        }
    };

    return (
        <div className="grid gap-y-8" >
            {SnackBarComponent}
            <div className={`w-full m-auto flex justify-between gap-x-2 text-center text-sm `}>
                <div className="grid gap-y-3 w-1/4">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.previousProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto text-sm font-semibold`}>Lot Details</div>
                    </div>
                    <div className={`${classes.previousProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/4">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto text-sm font-semibold`}>Select Bundles for the Lot</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/4">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Edit Bundle MOQ</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/4">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                <div className={`${classes.heading} text-base font-medium`}>Select Bundle</div>
                <div className='grid gap-y-4'>
                    <div className='flex justify-between'>
                        <div className='flex flex-wrap gap-x-3 my-auto'>
                            {/* <FilterChipV2
                                options={statusOptions}
                                label=" "
                                value={status}
                                onchange={handleSelectionChangeStatus}
                                placeholder="Status"
                                ClearButtonComponent={ButtonV2}
                                buttonLabel={"Clear all"}
                                buttonOnClick={() => handleClearClick('status')}
                                SaveButton={ButtonV2}
                                saveButtonLabel='Apply'
                                saveButtonOnClick={() => handleStatusSaveClick(status)}
                                isSingleSelect={true}
                            /> */}
                            <FilterChipV2
                                options={sortOptions}
                                label=" "
                                value={sortDate}
                                onchange={handleSelectionChangeSort}
                                placeholder="Sort Date"
                                ClearButtonComponent={ButtonV2}
                                buttonLabel={"Clear all"}
                                buttonOnClick={() => handleClearClick('sortDate')}
                                SaveButton={ButtonV2}
                                saveButtonLabel='Apply'
                                saveButtonOnClick={() => handleSortSaveClick(sortDate)}
                                isSingleSelect={true}
                            />
                            <FilterChipV3
                                options={bundleOptions}
                                label=" "
                                value={selectedfilters?.catalogBundleIds}
                                onchange={handleSelectionChange}
                                name="catalogBundleIds"
                                placeholder="Catalogue ID"
                                ClearButtonComponent={ButtonV2}
                                textFieldPlaceholder='Search...'
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                buttonLabel={"Clear all"}
                                buttonOnClick={() => handleClearClick('catalogBundleIds')}
                                SaveButton={ButtonV2}
                                saveButtonLabel='Apply'
                                saveButtonOnClick={() => handleSaveClick('catalogBundleIds', selectedfilters?.catalogBundleIds)}
                            />
                            {/* <FilterChipV3
                                options={mainServiceOptions}
                                label=" "
                                value={selectedfilters?.productIds}
                                onchange={handleSelectionChange}
                                name="productIds"
                                placeholder="Product Code"
                                ClearButtonComponent={ButtonV2}
                                textFieldPlaceholder='Search...'
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                buttonLabel={"Clear all"}
                                buttonOnClick={() => handleClearClick('productIds')}
                                SaveButton={ButtonV2}
                                saveButtonLabel='Apply'
                                saveButtonOnClick={() => handleSaveClick('productIds', selectedfilters?.productIds)}
                            /> */}
                        </div>

                        <div className='w-[324px]'>
                            <TextFieldV2
                                className="w-full"
                                placeholder='Search'
                                type="text"
                                name="search"
                                value={filters.search}
                                onChange={(e) => handleFiltersChange('search', e.target.value)}
                            />
                        </div>
                    </div>
                    <TableV2 schema={schema} records={records} />
                </div>
            </div>

            <div className='flex justify-between'>
                <ButtonV2 variant="tertiaryContained" label='Cancel' onClick={onClose} />
                <div className="flex justify-end gap-4">
                    <ButtonV2 variant="tertiaryContained" label='Back' onClick={setFormStateTo(BUNDLE_LOT_FORM_STATE.LOT_INFORMATION)} />
                    {mode !== MODE.VIEW && <ButtonV2 variant="primaryContained" onClick={handleNext} label={"Next"} />}
                </div>
            </div>
        </div>
    )
}

export default SelectBundleFormTemplate;