import React, { ReactNode } from 'react';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { createUseStyles } from 'react-jss';
import styled from '@emotion/styled';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { SvgIcon } from '@mui/joy';

import { theme } from '../../../utils/theme';
import deleteIconOutlinedPrimary500 from "../../../assets/icons/deleteIconOutlinedPrimary500.svg"

type StatusType = 'success' | 'error';

export interface IStatus {
    message: string,
    type: StatusType
}

interface CustomAccordionProps {
    showStatus?: Boolean,
    isFilled?: Boolean,
    index: number,
    expanded: number,
    component: ReactNode,
    heading: string,
    status?: IStatus,
    onChange: (index: number) => void;
    onDelete: (index: number) => void;
    isDeletable?: boolean
}

const useStyles = createUseStyles((theme: any) => ({
    primary500: {
        color: theme.palette.text.primary.primary500,
    },
    headingColor: {
        color: theme.palette.text.primary.primary900
    },
    border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    borderPrimary500: {
        border: `1px solid ${theme.palette.border.primary.primary500}`
    },
    isFilledContainer: {
        backgroundColor: theme.palette.background.success.success700,
        border: `1px solid ${theme.palette.border.success.success800}`,
        borderRadius: "4px"
    },
    emptyContainer: {
        backgroundColor: theme.palette.background.neutral.neutral100,
        border: `1px solid ${theme.palette.border.neutral.neutral50}`,
        borderRadius: "4px"
    },
    filledText: {
        color: theme.palette.text.success.success50
    },
    emptyText: {
        color: theme.palette.text.neutral.neutral600
    },

}));

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    border: 'none',
    borderRadius: "12px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    marginTop: '24px',
}));

export const CustomAccordionV2: React.FC<CustomAccordionProps> = ({ index, expanded, component, heading, status = null, onChange, onDelete, isDeletable = false, showStatus = false, isFilled = false }) => {
    const classes = useStyles();

    const AccordionSummary = styled((props: AccordionSummaryProps & { status: IStatus | null }) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))<AccordionSummaryProps & { status: IStatus | null }>(({ status }) => ({
        margin: "24px 24px 0px 24px",
        padding: "0px 0px 12px 0px",
        zIndex: 1,
        background: status?.type === 'success' ? theme.customerPalette.background.neutral.neutral50 : 'inherit',
    }));

    const handleChange =
        (id: number) => (event: React.SyntheticEvent, isExpanded: boolean) => onChange(id);

    const handleDelete =
        (id: number) => (event: React.MouseEvent) => {
            event.stopPropagation();
            onDelete(id);
        };

    return (
        <div className={`${classes.border} rounded-xl`}>
            <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                <AccordionSummary
                    expandIcon={
                        <SvgIcon className={`${classes.borderPrimary500} rounded-lg`} sx={{ width: "48px", height: "48px" }}>
                            <ArrowDropDownIcon />
                        </SvgIcon>
                    }
                    aria-controls="panel1bh-content"
                    id={`accordion-${index}`}
                    status={status}
                >
                    <div className='flex justify-between w-full'>
                        <div className={`flex items-center`}>
                            <span className={`text-lg font-medium ${classes.headingColor}`}>{heading}</span>
                            {showStatus &&
                                <div className='flex items-center ml-4'>
                                    <div className={`${isFilled ? classes.isFilledContainer : classes.emptyContainer}`}>
                                        <div className='flex items-center'>
                                            <div className={`${isFilled ? classes.filledText : classes.emptyText} text-xs px-2 py-1`}>
                                                {isFilled ? "Filled" : "Empty"}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            {/* {(status && !!status.message) && (
                                <div className={`${classes[`${status.type}Status`]} rounded-3xl text-xs font-medium`}>
                                    <span>{status.message}</span>
                                </div>
                            )} */}
                        </div>
                        {isDeletable && <span onClick={handleDelete(index)} className={`${classes.borderPrimary500} z-10 p-3 rounded-lg mr-3`}>
                            <img src={deleteIconOutlinedPrimary500} className='w-[22px] h-[22px]' alt='' />
                        </span>}
                    </div>

                </AccordionSummary>
                <AccordionDetails>
                    {component}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}


