import React, { useEffect, useMemo, useState } from 'react'
import { ADMIN_ROUTES, secondaryInventorySortOptions, statusOptions } from '../../../utils/constant';
import { makeRoute } from '../../../utils/helper';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { CATEGORY_TYPE, HTTP_STATUS, IPagination } from '../../../utils/types';
import { useNavigate } from 'react-router-dom';
import { ColumnType } from '../../organisms/Table';
import TableV2 from '../../organisms/TableV2';
import ButtonV2 from '../../atoms/ButtonV2';
import TextFieldV2 from '../../atoms/TextFieldV2';
import viewEyeIcon from "../../../assets/images/viewOutlined.svg";
import FilterChipV2 from '../../molecules/FilterChip/FilterChipV2';
import { IMultiSelectOption } from '../../atoms/MultiSelectInput2';
import FilterChipV3 from '../../molecules/FilterChip/FilterChipV3';
import moment from 'moment';
import { IRfqs } from '../../../services/useRfqService';
import { Category, useCategoryService } from '../../../services/useCategoryService';

interface MachineListTemplateProps {
    rfqs: any[];
    pagination: IPagination;
    handlePaginationChange: any;
    handleRowsPerPageChange: any;
    handleFiltersChange: (name: string, value: any) => void;
    filters: any;
    rfqRequestFilters: any;
    setRfqRequestFiltersTo: any;
}

const RfqListTemplate: React.FC<MachineListTemplateProps> = ({ rfqs, pagination, handlePaginationChange, handleRowsPerPageChange, handleFiltersChange, filters, rfqRequestFilters, setRfqRequestFiltersTo }) => {

    const navigate = useNavigate();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination?.totalRecords,
            currentPage: pagination?.currentPage,
            isVisible: true,
            limit: pagination?.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Customer Name", key: "customerName", type: "string" as ColumnType, props: { className: '' } },
            { label: "Product Category", key: "productCategory", type: "string" as ColumnType, props: { className: '' } },
            { label: "Grade", key: "grade", type: "string" as ColumnType, props: { className: '' } },
            { label: "Standard", key: "standard", type: "string" as ColumnType, props: { className: '' } },
            { label: "Payment Term", key: "paymentTerm", type: "string" as ColumnType, props: { className: '' } },
            { label: "Manufacturer Name", key: "manufacturerName", type: "string" as ColumnType, props: { className: '' } },
            { label: "Specific Requirements", key: "specificRequirements", type: "string" as ColumnType, props: { className: '' } },
            { label: "Required Quantity (MT)", key: "requiredQuantity", type: "string" as ColumnType, props: { className: '' } },
            { label: "Expected Delivery Date", key: "expectedDeliveryDate", type: "string" as ColumnType, props: { className: '' } },
            { label: "Created Date", key: "createdAt", type: "string" as ColumnType, props: { className: '' } },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ],
    };

    const handleActionRoute = (id: number) => () => {
        navigate(makeRoute(ADMIN_ROUTES.RFQ_VIEW, { params: { id: id } }));
    }

    const Action = (id: number) => {
        return (
            <div>
                <ButtonV2 variant='primaryText' size='small' label={"View"} startIcon={<img src={viewEyeIcon} alt={viewEyeIcon} />} onClick={handleActionRoute(id)} />
            </div>
        );
    };

    const records = rfqs?.map((rfq: IRfqs, index: number) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        rfq?.customerName,
        rfq?.productCategory,
        rfq?.grade,
        rfq?.standard,       
        rfq?.paymentTerm,
        rfq?.manufacturerName,
        rfq?.specificRequirements?.trim() === "" ? "None" : rfq?.specificRequirements,
        rfq?.requiredQuantity,
        rfq?.expectedDeliveryDate,
        moment(rfq.createdAt).format("DD-MM-YYYY"),
        Action(rfq?.id),
    ]);

    const categoryService = useCategoryService();
    const [productCategoryData, setProductCategoryData] = useState<Category[] | null>(null);

    const initialSelections = {
        // status: [{ label: 'ALL', value: 'ALL' }],
        sortDate: [{ label: 'createdAt,desc', value: 'createdAt,desc' }]
    };

    const initialmachineRequestFilters: any = {
        productCategoryIds: [],
    };

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedfilters, setSelectedFilters] = useState<any>(initialmachineRequestFilters);

    // const [status, setStatus] = useState<IMultiSelectOption[]>(initialSelections.status);
    const [sortDate, setSortDate] = useState<IMultiSelectOption[]>(initialSelections.sortDate);

    const fetchProductCategory = (inputValue?: string) => {
        categoryService.getAllCategories({ level: CATEGORY_TYPE.PRODUCT_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: '', name: inputValue ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                setProductCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Service: ", error);
                showSnackbar('error', "Error while fetching Product Category data");
            })
    }

    useEffect(() => {
        fetchProductCategory(searchTerm);
    }, [searchTerm]);


    const productCategoryOptions = useMemo(() => {
        return productCategoryData?.map((category: Category) => ({ label: category.name, value: category.id })) ?? []
    }, [productCategoryData])

    // const handleSelectionChangeStatus = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
    //     setStatus(selected);
    //     clearWithCrossIcon && handleStatusSaveClick(selected);
    // };

    const handleSelectionChangeSort = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSortDate(selected);
        clearWithCrossIcon && handleSortSaveClick(selected);
    };

    const handleSelectionChange = (name: string, selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSelectedFilters((previousSelectedFilters: any) => ({
            ...previousSelectedFilters,
            [name]: selected
        }));
        clearWithCrossIcon && handleDeleteClick(name, selected);
    };

    // const handleStatusSaveClick = (status: IMultiSelectOption[]) => {
    //     const valueString = status.length > 0 ? status[0].value : 'ALL';
    //     handleFiltersChange("status", valueString);
    // };

    const handleSortSaveClick = (sortDate: IMultiSelectOption[]) => {
        const sortString = sortDate.length > 0 ? sortDate[0].value : 'createdAt,desc';
        handleFiltersChange("sort", sortString);
    };

    const handleSaveClick = (name: string, selectedValues: IMultiSelectOption[]) => {
        const updatedFilters = { ...rfqRequestFilters };
        const newValues = selectedValues.map(option => option.value);
        if (updatedFilters[name]) {
            updatedFilters[name] = Array.from(new Set([...updatedFilters[name], ...newValues]));
        } else {
            updatedFilters[name] = newValues;
        }
        setRfqRequestFiltersTo(updatedFilters);
    };

    const handleDeleteClick = (name: string, selectedValues: IMultiSelectOption[]) => {
        const selectedValueIds = selectedValues.map(option => option.value);
        const updatedFilters = {
            ...rfqRequestFilters,
            [name]: rfqRequestFilters[name].filter((id: number) => selectedValueIds.includes(id))
        };
        setRfqRequestFiltersTo(updatedFilters);
    };

    const handleClearClick = (filterType: string) => {
        switch (filterType) {
            // case 'status':
            //     setStatus([{ label: 'ALL', value: 'ALL' }]);
            //     handleFiltersChange("status", 'ALL');
            //     break;
            case 'sortDate':
                setSortDate([{ label: 'createdAt,desc', value: 'createdAt,desc' }]);
                handleFiltersChange("sort", 'createdAt,desc');
                break;
            case 'productCategoryIds':
                setSelectedFilters((previousSelectedFilters: any) => ({
                    ...previousSelectedFilters,
                    productCategoryIds: []
                }));
                setRfqRequestFiltersTo((prevState: any) => ({
                    ...prevState,
                    productCategoryIds: []
                }));
                break;
            default:
                // setStatus([{ label: 'ALL', value: 'ALL' }]);
                setSortDate([{ label: 'createdAt,desc', value: 'createdAt,desc' }]);
                setSelectedFilters(initialmachineRequestFilters);
        }
    };

    return (
        <div className='grid gap-y-6'>
            <div className='flex justify-between'>
                <div className='flex flex-wrap gap-x-3 my-auto'>
                    {/* <FilterChipV2
                        options={statusOptions}
                        label=" "
                        value={status}
                        onchange={handleSelectionChangeStatus}
                        placeholder="Status"
                        ClearButtonComponent={ButtonV2}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('status')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleStatusSaveClick(status)}
                        isSingleSelect={true}
                    /> */}
                    <FilterChipV2
                        options={secondaryInventorySortOptions}
                        label=" "
                        value={sortDate}
                        onchange={handleSelectionChangeSort}
                        placeholder="Sort Date"
                        ClearButtonComponent={ButtonV2}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('sortDate')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleSortSaveClick(sortDate)}
                        isSingleSelect={true}
                    />
                    {/* <FilterChipV3
                        options={productCategoryOptions}
                        label=" "
                        value={selectedfilters?.productCategoryIds}
                        onchange={handleSelectionChange}
                        name="serviceCategoryIds"
                        placeholder="Service Category"
                        ClearButtonComponent={ButtonV2}
                        textFieldPlaceholder='Search...'
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('productCategoryIds')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleSaveClick('productCategoryIds', selectedfilters?.productCategoryIds)}
                    /> */}
                </div>

                <div className='w-[324px]'>
                    <TextFieldV2
                        className="w-full"
                        placeholder='Search'
                        type="text"
                        name="search"
                        value={filters.search}
                        onChange={(e) => handleFiltersChange('search', e.target.value)}
                    />
                </div>
            </div>
            <TableV2 schema={schema} records={records} />
        </div>
    )
}

export default RfqListTemplate