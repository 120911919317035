
import { CUSTOMER_GROUP_SECTIONS } from '../../../utils/types';
import { useMemo, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import * as Yup from "yup";
import { REGEX, industryOptionsV2 } from '../../../utils/constant';
import CustomerGroupConfigurationFormTemplate from './CustomerGroupConfigurationForm.template';
import CustomerGroupMasterFormTemplate from './CustomerGroupMasterForm.template';
import UpcSearchFormTemplate from './UpcSearchForm.template';
import CustomerGroupPreviewTemplate from './CustomerGroupPreview.template';
import AdditionalCustomerFormTemplate from './AdditionalCustomerForm.template';
import { ICustomerGroupMasterRequestBody } from '../../../services/useCustomerGroupMasterService';

interface AddCustomerGroupFormTemplateProps {
    onSubmit: (requestBody: any) => void;
    mode?: string;
    customerGroupMaster?: any; 
    customerIds?: number[];
}

export interface ICustomerGroupForm {
    groupName: string;
    productCategoryId: string;
    description: string;
    groupType: string;
    industryType: string;
    groupOwnerId: string;
    productCategory: {
        label: string;
        id: string;
    };
    groupOwner: {
        label: string;
        id: string;
    };
    selectedCard: string;
    upcCodes: string[];
    upcCode: {
        label: string;
        id: string;
    };
    filters: any[];
    otherFilter: string;
    options: any;
    cityArray: any[];
    city?: {
        label: string;
        id: string;
    };
    turnOverArray: any[];
    turnOver?: {
        label: string;
        id: string;
    },
    businessClassificationArray: any[];
    businessClassification?: {
        label: string;
        id: string;
    },
    importExportArray: any[];
    importExport?: {
        label: string;
        id: string;
    },
    selectedCustomer: any[];
    [key: string]: any;
}

interface CustomerGroupSectionProps {
    setCurrentSectionTo: (section: CUSTOMER_GROUP_SECTIONS) => void;
    formik: FormikProps<any>;
    mode?: string;
}

const additionalCustomerOptions = [
    { value: "additionalCustomerCity", label: "City" },
    { value: "additionalCustomerBusinessClassification", label: "Business Classification" },
    { value: "additionalCustomerTurnOver", label: "Turn Over" },
    { value: "additionalCustomerImportExport", label: "Import/Export" },
    { value: "additionalCustomerProductCategory", label: "ProductCategory" },
    { value: "additionalCustomerIndustryType", label: "Industry Type" },
];

const options = [
    { value: "city", label: "City" },
    { value: "businessClassification", label: "Business Classification" },
    { value: "turnOver", label: "Turn Over" },
    { value: "importExport", label: "Import/Export" },
    { value: "industryType", label: "Industry Type" }
];

const AddCustomerGroupFormTemplate: React.FC<AddCustomerGroupFormTemplateProps> = ({ onSubmit, mode, customerGroupMaster, customerIds }) => {
    const [currentSection, setCurrentSectionTo] = useState<CUSTOMER_GROUP_SECTIONS>(CUSTOMER_GROUP_SECTIONS.CUSTOMER_GROUP_MASTER_FORM);

    const initialProductDetailsValues = {
        groupName: customerGroupMaster?.groupName || "",
        productCategoryId: customerGroupMaster?.productCategoryId || "",
        description: customerGroupMaster?.description || "",
        groupType: customerGroupMaster?.groupType || "",
        industryType: customerGroupMaster?.industryType || "",
        groupOwnerId: customerGroupMaster?.groupOwnerId || "",
        productCategory: {
            label: customerGroupMaster?.productCategoryName || "",
            id: customerGroupMaster?.productCategoryId || "",
        },
        groupOwner: {
            label:customerGroupMaster?.groupOwner || "",
            id: customerGroupMaster?.groupOwnerId || "",
        },
        selectedCard: "",
        upcCodes: [],
        upcCode: {
            label: "",
            id: "",
        },
        filters: [],
        additionalCustomerFilters: [],
        otherFilter: "",
        options: options,
        additionalCustomerOptions: additionalCustomerOptions,
        industryOptions: industryOptionsV2,
        cityArray: [],
        city: {
            label: "",
            id: "",
        },
        additionalCustomerCity: {
            label: "",
            id: "",
        },
        additionalCustomerCityArray: [],
        turnOverArray: [],
        turnOver: {
            label: "",
            id: ""
        },
        additionalCustomerTurnOverArray: [],
        additionalCustomerTurnOver: {
            label: "",
            id: "",
        },
        businessClassificationArray: [],
        businessClassification: {
            label: "",
            id: ""
        },
        additionalCustomerBusinessClassificationArray: [],
        additionalCustomerBusinessClassification: {
            label: "",
            id: "",
        },
        importExportArray: [],
        importExport: {
            label: "",
            id: ""
        },
        additionalCustomerImportExportArray: [],
        additionalCustomerImportExport: {
            label: "",
            id: "",
        },
        additionalCustomerProductCategoryArray: [],
        additionalCustomerProductCategory: {
            label: "",
            id: "",
        },
        additionalCustomerIndustryTypeArray: [],
        additionalCustomerIndustryType: {
            label: "",
            id: "",
        },
        selectedCustomer: customerIds || [],
        customersFromUpcs: [],
        additionalCustomers: [],
        materialFilterFlag: false,
        superCategoryArray: [],
        superCategoryValue: {
            label: "",
            id: ""
        },
        mainCategoryArray: [],
        mainCategoryValue: {
            label: "",
            id: ""
        },
        productCategoryArray: [],
        productCategoryValue: {
            label: "",
            id: ""
        },
        manufacturerArray: [],
        manufacturerValue: {
            label: "",
            id: ""
        },
        brandArray: [],
        brandValue: {
            label: "",
            id: ""
        },
        standardArray: [],
        standardValue: {
            label: "",
            id: ""
        },
        gradeArray: [],
        gradeValue: {
            label: "",
            id: ""
        },
        shapeArray: [],
        shapeValue: {
            label: "",
            id: ""
        },
        classesArray: [],
        classesValue: {
            label: "",
            id: ""
        },
        lengthArray: [],
        lengthValue: {
            label: "",
            id: ""
        },
        thicknessArray: [],
        thicknessValue: {
            label: "",
            id: ""
        },
        widthArray: [],
        widthValue: {
            label: "",
            id: ""
        },
    };

    const validationSchema = Yup.object().shape({
        groupName: Yup.string().matches(REGEX.NAME, 'Enter valid Group Name').required('Group Name is required'),
        productCategory: Yup.object().shape({
            id: Yup.number().required('Product Category is required'),
            label: Yup.string().required('Product Category is required')
        }).required('Product Category is required'),
        groupOwner: Yup.object().shape({
            id: Yup.number().required('Group Owner is required'),
            label: Yup.string().required('Group Owner is required')
        }).required('Group Owner is required'),
        description: Yup.string().required('Description is required'),
        groupType: Yup.string().required('Group Type is required'),
        industryType: Yup.string().required('Industry Type is required'),
    });

    const formik = useFormik<ICustomerGroupForm>({
        validateOnMount: true,
        enableReinitialize: true,
        initialValues: initialProductDetailsValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            const requestBody: ICustomerGroupMasterRequestBody = {
                groupName: values?.groupName,
                groupOwner: Number(values?.groupOwnerId),
                productCategoryId: Number(values?.productCategoryId),
                description: values?.description,
                groupType: values?.groupType,
                industryType: values?.industryType,
                customerIds: formik.values.selectedCustomer.map((customer: any) => customer.id), 
            }
            onSubmit(requestBody)
        }
    });

    const customerGroupSectionProps: CustomerGroupSectionProps = {
        setCurrentSectionTo: setCurrentSectionTo,
        formik: formik,
        mode: mode ?? "ADD"
    }

    const addCustomerGroupSectionView = useMemo(() => {
        switch (currentSection) {
            case CUSTOMER_GROUP_SECTIONS.CUSTOMER_GROUP_MASTER_FORM:
                return <CustomerGroupMasterFormTemplate {...customerGroupSectionProps} />;

            case CUSTOMER_GROUP_SECTIONS.CUSTOMER_GROUP_CONFIGURATION_FORM:
                return <CustomerGroupConfigurationFormTemplate {...customerGroupSectionProps} />;

            case CUSTOMER_GROUP_SECTIONS.UPC_SEARCH_FORM:
                return <UpcSearchFormTemplate {...customerGroupSectionProps} />; 

            case CUSTOMER_GROUP_SECTIONS.ADDITIONAL_CUSTOMER_FORM:
                return <AdditionalCustomerFormTemplate {...customerGroupSectionProps} />; 

            case CUSTOMER_GROUP_SECTIONS.CUSTOMER_GROUP_PREVIEW:
                return <CustomerGroupPreviewTemplate {...customerGroupSectionProps} />;

            default:
                return <CustomerGroupPreviewTemplate {...customerGroupSectionProps} />;
        }
    }, [currentSection, formik]);

    return <div>  {addCustomerGroupSectionView} </div>;
}

export const changeSectionTo = (setCurrentSectionTo: (section: CUSTOMER_GROUP_SECTIONS) => void) => (section: CUSTOMER_GROUP_SECTIONS) => {
    return (event: React.MouseEvent<HTMLButtonElement>) => {
        setCurrentSectionTo(section);
    };
};

export default AddCustomerGroupFormTemplate