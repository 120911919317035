
import { useEffect, useState } from "react";
import gstSvg from "../../../assets/icons/gst.svg";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useNavigate, useParams } from "react-router-dom";
import CustomerDetailTemplate from "../../templates/Lead/CustomerDetail.template";
import Button from "../../atoms/Button";
import { Charges, SkuData } from "../Lead/QuotationView.page";
import PaymentInfoTemplate, { IPriceDetails } from "../../templates/Lead/PaymentInfo.template";
import { CATALOGUE_TYPES, LEAD_CATALOGUE_TYPES, PRICE_DETAILS } from "../../../utils/constant";
import { createUseStyles } from "react-jss";
import { getKeyByValue, toInr } from "../../../utils/helper";
import { useUpcService } from "../../../services/useUpcService";
import { useSecondaryUpcService } from "../../../services/useSecondaryUpcService";
import ProductDetailTemplate from "../../templates/Order/ProductDetail.template";
import SecondaryProductDetailTemplate from "../../templates/Order/SecondaryProductDetail.template";
import { useOrderService } from "../../../services/useOrderService";
import { IOrder } from "./OrderList.page";

export interface IProductUpc {
	id: number;
	upcCode: string;
	manufactureName: string;
	brand: string;
	superCategory: string;
	mainCategory: string;
	productCategory: string;
	grade: string;
	standard: string;
	productProperties: string;
	classType: string;
	keywords: string;
	shape: string;
	status: string;
	attributes: string;
	catalogueImages: IImage[];
}

export interface IImage {
	id: number;
	location: string;
	mimeType: string;
	path: string;
	resourceId: number;
	resourceType: string;
}
interface ICustomerDetails {
	name: string;
	companyName: string;
	email: string;
	phoneNumber: string;
	gstn: string;
}

const initialDetails: ICustomerDetails = {
	name: "",
	companyName: "",
	email: "",
	phoneNumber: "",
	gstn: "",
};

const useStyles = createUseStyles((theme: any) => ({
	productDetailContainer: {
		border: `1px solid ${theme.palette.border.primaryLight}`,
		boxShadow: `0px 4px 45px 1px ${theme.palette.boxShadow.primaryLight}`,
	},
	quantityContainer: {
		borderBottom: `1px solid ${theme.palette.border.primaryLight}`,
	},
	detailContainer: {
		color: theme.palette.text.primaryDarkLight
	},
	priceTitle: {
		color: theme.palette.text.secondaryLigthAccent,
		background: theme.palette.background.secondaryLight,
	}
}));

const OrderView: React.FC = () => {
	const classes = useStyles();
	const params = useParams();
	const navigate = useNavigate()
	const orderService = useOrderService();
	const [order, setOrderTo] = useState<IOrder | null>(null);
	const [charges, setCharges] = useState<Charges | null>(null);
	const [detail, setDetailTo] = useState<ICustomerDetails>(initialDetails);
	const [checkUpc, setCheckUpc] = useState<boolean>(false);
	const [skuData, setSkuData] = useState<SkuData[]>([]);
	const [classType,setClassType] = useState<String>();
	const [productChargesRatio, setProductChargesRatio] = useState<number>(0);
	const loadOrderMaster = async () => {
		try {
			const orderMasterResponse = await orderService.getOrderMasterById(Number(params.id));
			if (orderMasterResponse?.data?.data) {
				setClassType(orderMasterResponse?.data?.data?.catalogueType)
				setDetailTo(orderMasterResponse?.data?.data?.customerDetails);
				setCharges(orderMasterResponse.data.data.charges[0]);
				const orderItem = orderMasterResponse.data.data.product.find((item: IOrder) => item.id === Number(params.orderId));
				setProductChargesRatio(((orderItem?.quantity) / (orderMasterResponse?.data?.data?.totalQuantity || 1)));
				setOrderTo(orderItem);
				setSkuData(orderItem?.skuData);
				if (orderItem?.upcId != null) {
					setCheckUpc(true)
				}
			} else {
				throw new Error("Order Master fetch failed");
			}
		} catch (error) {
			setDetailTo(initialDetails);
			setCharges(null);
			setOrderTo(null);
			setSkuData([]);
		}
	};

	useEffect(() => {
		loadOrderMaster()
	}, [params.id, params.orderId]);

	const upcService = useUpcService();
	const secondaryUpcService = useSecondaryUpcService();
	const [orderProductUpc, setOrderProductUpc] = useState<IProductUpc | null>(null);

	const loadPrimeProduct = async () => {
		const data = {
			upcId: order?.upcId
		}
		try {
			const primeProductResponse = await upcService.searchUpc(data);
			if (primeProductResponse?.data?.data) {
				setOrderProductUpc(primeProductResponse.data.data[0]);
			} else {
				throw new Error("Lead fetch failed");
			}
		} catch (error) {
			setOrderProductUpc(null);
		}
	};

	const loadSecondaryProduct = async () => {
		if (order?.secondarySkuId) {
			try {
				const catalogueClass = classType === LEAD_CATALOGUE_TYPES.MAKE_TO_ORDER ? LEAD_CATALOGUE_TYPES.MAKE_TO_ORDER : getKeyByValue(CATALOGUE_TYPES,CATALOGUE_TYPES.CURRENT_INVENTORY);
				const secondaryProductResponse = await secondaryUpcService.getSecondaryProductUpc(order?.secondarySkuId,{classType: catalogueClass});
				if (secondaryProductResponse?.data?.data) {
					setOrderProductUpc(secondaryProductResponse.data.data);
				} else {
					throw new Error("Lead fetch failed");
				}
			} catch (error) {
				setOrderProductUpc(null);
			}
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			if (checkUpc) {
				loadPrimeProduct();
			} else {
				await loadSecondaryProduct();
			}
		};
		fetchData();
	}, [checkUpc, order]);

	const detailItems = [
		{ icon: <PersonIcon />, value: detail?.name },
		{ icon: <EmailIcon />, value: detail?.email },
		{ icon: <ApartmentIcon />, value: detail?.companyName },
		{ icon: <PhoneIcon />, value: detail?.phoneNumber },
		{ icon: <img className="w-4" src={gstSvg} alt="gstIcon" />, value: detail?.gstn },
	];

	const [totalQuantity, setTotalQuantity] = useState<number>(0);
	const [grandTotal, setGrandTotalTo] = useState<number>(0);
	const [paymentInfoDetails, setPaymentInfoDetailsTo] = useState<IPriceDetails>(() => PRICE_DETAILS({}));
	const calculatePaymentInfoDetails = (upcSkuEditDetails: any) => {
		let grandTotal = 0;
		let totalQuantity = 0;
		let productSubTotal = 0;
		let paymentInfoDetails: IPriceDetails = PRICE_DETAILS({});
		const warehouseCharges = charges?.warehouseCharges ?? 0;
		const handlingCharges = charges?.handlingCharges ?? 0;
		const packagingCharges = charges?.packagingCharges ?? 0;
		const otherCharges = charges?.otherCharges ?? 0;
		for (const key in upcSkuEditDetails) {
			const item = upcSkuEditDetails[key];
			const quantity = parseFloat(item.quantity);
			const price = parseFloat(item.price);
			totalQuantity += quantity;
			const totalPrice = price * quantity;
			productSubTotal += totalPrice;
		}
		paymentInfoDetails.subTotal.price = productSubTotal;
		paymentInfoDetails.warehouseCharges.price = warehouseCharges * totalQuantity;
		paymentInfoDetails.handlingCharges.price = handlingCharges * totalQuantity;
		paymentInfoDetails.packagingCharges.price = packagingCharges * totalQuantity;
		paymentInfoDetails.otherCharges.price = (otherCharges*productChargesRatio);
		let totalProductPrice = paymentInfoDetails.subTotal.price + ((warehouseCharges + handlingCharges + packagingCharges) * totalQuantity) + (otherCharges*productChargesRatio);
		paymentInfoDetails.GST.price = ((charges?.gst ?? 0) * totalProductPrice) / 100;
		paymentInfoDetails.TCS.price = ((charges?.tcs ?? 0) * totalProductPrice) / 100;
		grandTotal = totalProductPrice + paymentInfoDetails.GST.price + paymentInfoDetails.TCS.price;
		setGrandTotalTo(grandTotal);
		setPaymentInfoDetailsTo(paymentInfoDetails);
		setTotalQuantity(totalQuantity);
	};

	useEffect(() => {
		if (order?.skuData) calculatePaymentInfoDetails(order?.skuData);
	}, [order]);

	return (
		<div className="grid gap-y-3">
			<div className="flex justify-between gap-x-3 py-3 ">
				<div className={`${classes.detailContainer} grid grid-cols-2 gap-y-1 w-3/4`}>
					{detailItems.map((item: any) => (
						<CustomerDetailTemplate
							key={item.key}
							icon={item.icon}
							value={item.value}
						/>
					))}
				</div>
				<div className="w-1/4 my-auto flex justify-end">
					<Button variant="outlined" onClick={() => { navigate(-1) }} label="Back" />
				</div>
			</div>

			<div className={`${classes.productDetailContainer} grid gap-y-6 px-3 pt-3 pb-6 `}>
				<div className={`${classes.quantityContainer} flex justify-end pb-3`}>
					<div className="flex gap-x-3">
						<div className={`${classes.detailContainer} text-base my-auto`}>Quantity :{" "}
							<span className="font-medium">
								{order?.quantity && order?.quantity.toFixed(3)}/MT
							</span>
						</div>
						<div className={`${classes.priceTitle} py-3 px-5 flex gap-x-3 rounded`}>
							<div className="text-base font-bold">Price:</div>
							<div className="text-lg font-bold">{toInr(paymentInfoDetails?.subTotal.price)} </div>
						</div>
					</div>
				</div>

				<div className={`${classes.detailContainer} font-semibold text-xl`}>{order?.catalogueName}</div>

				{checkUpc ? (
					<ProductDetailTemplate orderProductUpc={orderProductUpc} skuData={skuData} />
				) : (
					<SecondaryProductDetailTemplate orderProductUpc={orderProductUpc} skuData={skuData} />
				)}

			</div>

			<PaymentInfoTemplate
				label={"Payment Information"}
				showStatus={false}
				priceDetails={paymentInfoDetails}
				quantity={totalQuantity}
				uom={"MT"}
				totalPrice={grandTotal}
			/>
		</div>
	);
};

export default OrderView;