import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { MODE } from '../../../../utils/constant';
import { titleModification } from '../../../../utils/helper';
import ButtonV2 from '../../../atoms/ButtonV2';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import { BUNDLE_LOT_FORM_STATE } from '../../../pages/CatalogueBundling/BundleLot/BundleLotCreate.page';

export interface LotDetailsFormTemplateProps {
    mode: string;
    onClose: () => void;
    formik: any;
    setFormStateTo: (state: BUNDLE_LOT_FORM_STATE) => () => void;
}

export interface IProductDetails {
    productId: number;
    productCode: string;
    moq: number | null;
    availableStock: number | null;
    price: number | null;
    bundleMoq: number | null;
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    inActiveProgressBar: {
        backgroundColor: theme.palette.backgroundV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    checkBox: {
        color: theme.palette.textV2.tertiary.tertiary300,
        '&.Mui-checked': {
            color: theme.palette.textV2.primary.primary400,
        },
    },
    value: {
        color: theme.palette.textV2.primary.primary950
    },
}));

const LotDetailsFormTemplate: React.FC<LotDetailsFormTemplateProps> = ({ mode, onClose, formik, setFormStateTo }) => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const handleNext = () => {
        formik.setTouched({ name: true }, true)
        const errors = Object.keys(formik.errors).find(key => ['name'].includes(key))
        if (errors) return;
        setFormStateTo(BUNDLE_LOT_FORM_STATE.BUNDLE_FOR_LOT)();
    }

    return (
        <div className="grid gap-y-8" >
            {SnackBarComponent}
            <div className={`w-full m-auto flex justify-between gap-x-2 text-center text-sm `}>
                <div className="grid gap-y-3 w-1/4">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto text-sm font-semibold`}>Lot Details</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/4">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Select Bundles for the Lot</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/4">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Edit Bundle MOQ</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/4">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>
            <div className='grid gap-y-4'>
                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Lot Details</div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            <TextFieldV2
                                label="Bundle Lot Name*"
                                placeholder='Enter Bundle Lot Name'
                                {...formik.getFieldProps("name")}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 100 }}
                                onBlur={(event) => {
                                    const newValue = titleModification(event.target.value);
                                    formik.setFieldValue('name', newValue);
                                }}
                                disabled = {mode === MODE.UPDATE}
                            />
                            {formik.errors.name && formik.touched.name && (
                                <div className={`${classes.errorMessage} text-xs`}>
                                    {formik.errors.name}
                                </div>
                            )}
                        </div>
                        {
                            mode === MODE.UPDATE &&
                            <div className="grid gap-y-1">
                                <TextFieldV2
                                    label="Bundle Lot Code"
                                    {...formik.getFieldProps("bundleLotCode")}
                                    error={formik.touched.uscCode && Boolean(formik.errors.bundleLotCode)}
                                    inputProps={{ readOnly: mode === MODE.UPDATE }}
                                    onBlur={(event) => {
                                        const newValue = titleModification(event.target.value);
                                        formik.setFieldValue('bundleLotCode', newValue);
                                    }}
                                    disabled
                                />
                                {formik.errors.bundleLotCode && formik.touched.bundleLotCode && (
                                    <div className={`${classes.errorMessage} text-xs`}>
                                        {formik.errors.bundleLotCode}
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="flex justify-end gap-4">
                <ButtonV2 variant="tertiaryContained" label='Cancel' onClick={onClose} />
                {mode !== MODE.VIEW && <ButtonV2 variant="primaryContained" onClick={handleNext} label={"Next"} />}
            </div>
        </div>
    )
}

export default LotDetailsFormTemplate