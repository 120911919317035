import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../../utils/types';
import { EXCEPTION_CODE, MODE, STATUS } from '../../../../utils/constant';
import { ILotFormikPayload, useCatalogueBundlingService } from '../../../../services/useCatalogueBundlingService';
import { makeRoute } from '../../../../utils/helper';
import { CATALOGUE_BUNDLING_ROUTES } from '../../../../App/Core/Routes/CatalogueBundlingRoutes';
import { createUseStyles } from 'react-jss';
import BundleLotFormTemplate from '../../../templates/CatalogueBundling/BundleLot/BundleLotForm.template';
import LotDetailsFormTemplate from '../../../templates/CatalogueBundling/BundleLot/LotDetailsForm.template';
import ProductFormTemplate from '../../../templates/CatalogueBundling/BundleLot/ProductForm.template';
import ReviewFormTemplate from '../../../templates/CatalogueBundling/BundleLot/ReviewForm.template';
import SelectBundleFormTemplate from '../../../templates/CatalogueBundling/BundleLot/SelectBundleForm.template';
import { validationSchema } from './BundleLotCreate.page';

export enum BUNDLE_LOT_FORM_STATE {
    LOT_INFORMATION = 1,
    BUNDLE_FOR_LOT = 2,
    EDIT_BUNDLE_MOQ = 3,
    REVIEW = 4
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
        lineHeight: "30px"
    },
    desc: {
        color: theme.palette.textV2.tertiary.tertiary700,
        lineHeight: "14px"
    }
}));

const BundleLotUpdatePage: React.FC = () => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const catalogueBundlingService = useCatalogueBundlingService();
    const [bundleLot, setBundleLot] = useState<any | null>(null)
    const navigate = useNavigate();
    const params = useParams();

    const [formState, setFormState] = useState<BUNDLE_LOT_FORM_STATE>(1);

    const loadBundleLot = async () => {
        catalogueBundlingService.getBundleById(Number(params.id))
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    setBundleLot(res.data.data)
                }
            }).catch((error) => {
                setBundleLot(null);
                console.error("Error Fetching Catalogue Bundle Lot: ", error);
                showSnackbar('error', "Error while fetching Catalogue Bundle Lot data");
            })
    }

    useEffect(() => {
        loadBundleLot()
    }, [Number(params.id)])

    const onClose = () => {
        navigate(makeRoute(CATALOGUE_BUNDLING_ROUTES.CATALOGUE_BUNDLING_LIST, { query: { type: 'CATALOGUE_BUNDLE_LOT' } }));
    }

    const updateBundleLot = async (requestBody: ILotFormikPayload) => {
        const bundleLotRequestBody: any = {

        }
        catalogueBundlingService.updateLot(Number(params.id), bundleLotRequestBody)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "Status Updated");
                    onClose();
                } else if (res.data.exceptionCode === EXCEPTION_CODE.DUPLICATE_ENTRY) {
                    showSnackbar('error', "Catalogue Bundle Lot already exists. Please try again");
                }
            }).catch((error) => {
                showSnackbar('error', "Failed to update Catalogue Bundle Lot");
            })
    }

    const formik = useFormik<ILotFormikPayload>({
        initialValues: {
            name: bundleLot?.name ?? "",
            moq:  bundleLot?.moq ?? null,
            price:  bundleLot?.price ?? null,
            startDate:  bundleLot?.startDate ?? "",
            expiryDate:  bundleLot?.expiryDate ?? "",
            productDetails:  bundleLot?.productDetails ?? [],
            status: bundleLot?.status ?? STATUS.ACTIVE,
        },
        validationSchema,
        validateOnChange: true,
        validateOnMount: true,
        validateOnBlur: true,
        onSubmit: (values) => { updateBundleLot(values) }
    })

    useEffect(() => {
        formik.setValues({
            name: bundleLot?.name ?? "",
            moq:  bundleLot?.moq ?? null,
            price:  bundleLot?.price ?? null,
            startDate:  bundleLot?.startDate ?? "",
            expiryDate:  bundleLot?.expiryDate ?? "",
            productDetails:  bundleLot?.productDetails ?? [],
            status: bundleLot?.status ?? STATUS.ACTIVE,
        });
    }, [bundleLot]);

    const setFormStateTo = (formState: BUNDLE_LOT_FORM_STATE) => () => {
        setFormState(formState)
    }

    const activeFormStep = useMemo(() => {
        const props = { mode: MODE.ADD, onClose, formik, setFormStateTo }
        switch (formState) {
            case BUNDLE_LOT_FORM_STATE.BUNDLE_FOR_LOT: return <SelectBundleFormTemplate {...props} />;
            case BUNDLE_LOT_FORM_STATE.EDIT_BUNDLE_MOQ: return <ProductFormTemplate {...props} />;
            case BUNDLE_LOT_FORM_STATE.REVIEW: return <ReviewFormTemplate {...props} />;
            default: return <LotDetailsFormTemplate {...props} />;
        }
    }, [formState, formik])

    return (
        <div>
            {SnackBarComponent}
            <div className="grid gap-y-6" >
                <div className='grid gap-y-2'>
                    <div className={`${classes.mainHeading} text-lg font-semibold`}>Update Bundle Lot</div>
                    <div className={`${classes.desc} text-xs `}>The form will help you create a new product bundle by select|ng and configuring product. Please fill out each section carefully.</div>
                </div>
                <BundleLotFormTemplate {...{ mode: MODE.UPDATE, onClose, component: activeFormStep }} />
            </div>
        </div>
    )
}

export default BundleLotUpdatePage;