import { FormikProps } from 'formik';
import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss';
import ButtonV2 from '../../atoms/ButtonV2';
import { IInstantQuotation } from '../../pages/ManageQuotation/QuoteCreate.page';
import moment from 'moment';
import { MODE } from '../../../utils/constant';

interface IQuotationReview {
    formik: FormikProps<IInstantQuotation>;
    setCurrentStep: (details: any) => void;
    saveInstantQuotation: () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {

    },
    heading: {
        color: theme.palette.textV2.tertiary.tertiary900,
    },
    primaryContainer: {

    },
    supplierContainer: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        "& p": {
            fontSize: '14px',
            fontWeight: '400',
            color: theme.palette.textV2.primary.primary870,
            lineHeight: '19.6px',
        },

    },
    text: {
        color: theme.palette.textV2.primary.primary900,
    },
    total: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,

        "& p": {
            padding: '12px 24px',
            fontSize: '16px',
            fontWeight: '600',
            borderRight: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
            color: theme.palette.textV2.tertiary.tertiary600,
        },
        "& span": {
            padding: '12px 24px',
            color: theme.palette.textV2.tertiary.tertiary600,
            fontSize: '14px',
        },
        "& span:nth-child(2)": {
            borderRight: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        },
    },
    additionalHeading: {
        color: theme.palette.textV2.tertiary.tertiary900,
        fontSize: '14px',
        fontWeight: '500',
        padding: '2px',
    },
    tableHeading: {
        color: theme.palette.textV2.tertiary.tertiary900,
    },
    message: {
        color: theme.palette.textV2.tertiary.tertiary900,
    },
    messageDescription: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    partTable: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        borderRadius: '12px',
        "& thead": {
            background: theme.palette.background._primaryLightAccent,
            color: theme.palette.textV2.tertiary.tertiary700,
            fontWeight: '700',
        },
        "& td": {
            padding: '16px 10px',
            color: theme.palette.textV2.primary.primary870,
            fontSize: '14px',
        },
    },
    additionalTableContainer: {
        minWidth: '730px',
        border: `0.5px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        "& th": {
            border: `0.5px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
            color: theme.palette.textV2.tertiary.tertiary900,
            fontSize: '14px',
            fontWeight: '500',
            padding: '2px',
        },
        "& td": {
            border: `0.5px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
            fontSize: '12px',
            color: theme.palette.textV2.tertiary.tertiary600,
            padding: '2px',
            minWidth: '60px',
        },
    },
    tableHeadingSecondary: {
        color: theme.palette.textV2.tertiary.tertiary900,
    },
    subTotalTable: {
        width: '324px',
        border: `0.5px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        "& td": {
            border: `0.5px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
            fontSize: '14px',
            fontWeight: '700',
            color: theme.palette.textV2.tertiary.tertiary600,
            padding: '3px',
        },
    },
    totalAmt: {
        borderTop: `0.5px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        "& p": {
            color: theme.palette.textV2.tertiary.tertiary900,
        },
        "& span": {
            color: theme.palette.textV2.tertiary.tertiary600,
        },
    },
    commonTable: {
        border: `0.5px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        "& th": {
            border: `0.5px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
            padding: '3px',
        },
        "& td": {
            border: `0.5px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
            padding: '3px',
        },
    },
    termsContainer: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        fontFamily: 'Roboto'
    },
    termsHeading: {
        color: theme.palette.textV2.primary.primary900,
    },
    description: {
        color: theme.palette.textV2.tertiary.tertiary600,
        lineHeight: '20px',
    },
    contactContainer: {
        border: `0.5px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
    },
    contactTable: {
        border: `0.5px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        "& th": {
            border: `0.5px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
            padding: '3px',
        },
        "& td": {
            border: `0.5px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
            padding: '3px',
        },
    },
    author: {
        height: '150px',
        border: `0.5px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
    },
}));

export const QuotationReviewPage: React.FC<IQuotationReview> = ({ formik, setCurrentStep, saveInstantQuotation }) => {
    const classes = useStyles();

    return (
        <div className={`${classes.container}`}>
            <div>
                <h1 className={`${classes.heading} text-2xl font-medium mb-4 text-center`}>Quotation</h1>
            </div>
            <div className={`${classes.primaryContainer} flex flex-col`}>
                <div className='flex justify-between'>
                    <div className={`${classes.supplierContainer} p-4 w-full`}>
                        <h4 className={`${classes.text} text-base font-medium mb-4`}>Supplier</h4>
                        <h4 className={`${classes.text} text-base font-medium`}>FUTURECOM TECHNOLOGIES PRIVATE LIMITED</h4>
                        <p>2nd Floor, C-196, Sector 63</p>
                        <p>Noida, Uttar Pradesh 201301</p>
                        <p>Phone: +91 9717817357</p>
                        <p>Email: info@steelbazaar.com</p>
                        <p>GSTIN: 09AAECF3774F1Z0</p>
                    </div>
                    <div className={`${classes.supplierContainer} flex gap-4 p-4 w-full items-center`}>
                        <div className='flex flex-col font-bold'>
                            <span>Quotation Start Date:</span>
                            <span>Expected Closure Date:</span>
                            <span>Quotation Validity Date:</span>
                        </div>
                        <div className='flex flex-col'>
                            <span>{moment(formik.values?.quotationDate).format("DD-MM-YYYY")}</span>
                            <span>{moment(formik.values?.closureDate).format("DD-MM-YYYY")}</span>
                            <span>{moment(formik.values?.validityDate).format("DD-MM-YYYY")}</span>
                        </div>
                    </div>
                </div>
                <div className='flex justify-between'>
                    <div className={`${classes.supplierContainer} p-4 w-full`}>
                        <h4 className={`${classes.text} text-base font-medium mb-4`}>Bill To</h4>
                        <h4 className={`${classes.text} text-base font-medium`}>{formik.values?.billToAddressDetails?.companyName}</h4>
                        <p>{formik.values?.billToAddressDetails?.addressLine1}</p>
                        <p>{formik.values?.billToAddressDetails?.addressLine2}</p>
                        <p>{formik.values?.billToAddressDetails?.pincode} {formik.values?.billToAddressDetails?.city} {formik.values?.billToAddressDetails?.state}</p>
                        <p>Phone: {formik.values?.billToAddressDetails?.phoneNumber}</p>
                        <p>Email: {formik.values?.billToAddressDetails?.emailId}</p>
                        <p>GSTIN: {formik.values?.billToAddressDetails?.gstIn}</p>
                    </div>
                    <div className={`${classes.supplierContainer} p-4 w-full`}>
                        <h4 className={`${classes.text} text-base font-medium mb-4`}>Ship To</h4>
                        <h4 className={`${classes.text} text-base font-medium`}>{formik.values?.shipToAddressDetails?.companyName}</h4>
                        <p>{formik.values?.shipToAddressDetails?.addressLine1}</p>
                        <p>{formik.values?.shipToAddressDetails?.addressLine2}</p>
                        <p>{formik.values?.shipToAddressDetails?.pincode} {formik.values?.shipToAddressDetails?.city} {formik.values?.shipToAddressDetails?.state}</p>
                        <p>Phone: {formik.values?.shipToAddressDetails?.phoneNumber}</p>
                        <p>Email: {formik.values?.shipToAddressDetails?.emailId}</p>
                        <p>GSTIN: {formik.values?.shipToAddressDetails?.gstIn}</p>
                    </div>
                </div>
            </div>
            {/* <---------welcome message------------> */}
            <div className='py-6'>
                <p className={`${classes.messageDescription} text-base`}>{formik.values?.message}</p>
            </div>
            {/* <-------------Part A table-------------> */}
            <div>
                <p className={`${classes.tableHeadingSecondary} text-base font-medium`}>Part A</p>
                <div className={`${classes.partTable} rounded-xl overflow-hidden mt-3`} >
                    <table className={`${classes.partTable} w-full text-center`}>
                        <thead className='p-4'>
                            <th className='p-4'>Sno.</th>
                            <th className='p-4 w-64'>Product Description</th>
                            <th className='p-4'>Location</th>
                            <th className='p-4'>HSN Code</th>
                            <th className='p-4'>Qty. (MT)</th>
                            <th className='p-4'>Unit Price/MT</th>
                            <th className='p-4'>Discount</th>
                            <th className='p-4'>Taxable Amnt.</th>
                        </thead>
                        <tbody>
                            {formik.values?.productDetails?.map((row, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td className="text-left">{row.productCode}</td>
                                    <td>{row.location}</td>
                                    <td>{row.hsnCode}</td>
                                    <td>{row.quantity}</td>
                                    <td>{row.unitPrice}</td>
                                    <td>{row.discount}</td>
                                    <td>{row.taxableAmount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className='w-full flex justify-end'>
                    <div className={`${classes.total} flex rounded-xl w-max mt-3`}>
                        <p>Total Qty. (MT)</p>
                        <span>{formik?.values?.totalCharges?.totalProductQuantity}</span>
                        <p>Sub-Total (A)</p>
                        <span>₹{formik?.values?.totalCharges?.totalProductTaxableCharges}</span>
                    </div>
                </div>

            </div>
            <div className='mt-6'>
                <p className={`${classes.tableHeadingSecondary} text-base font-medium`}>Part B</p>
                <div className={`${classes.partTable} rounded-xl overflow-hidden mt-3`} >
                    <table className={`${classes.partTable} w-full text-center`}>
                        <thead className='p-4'>
                            <th className='p-4'>Sno.</th>
                            <th className='p-4 w-64 text-left'>Service Description</th>
                            <th className='p-4'>Location</th>
                            <th className='p-4'>HSN Code</th>
                            <th className='p-4'>Qty. (MT)</th>
                            <th className='p-4'>Unit Price/MT</th>
                            <th className='p-4'>Discount</th>
                            <th className='p-4'>Taxable Amnt.</th>
                        </thead>
                        <tbody>
                            {formik.values?.vasDetailsExtracted?.map((row, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td className="text-left">{row.productCode}</td>
                                    <td>{row.location}</td>
                                    <td>{row.hsnCode}</td>
                                    <td>{row.quantity}</td>
                                    <td>{row.unitPrice}</td>
                                    <td>{row.discount}</td>
                                    <td>{row.taxableAmount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className='w-full flex justify-end'>
                    <div className={`${classes.total} flex rounded-xl w-max mt-3`}>
                        <p>Total Qty. (MT)</p>
                        <span>{formik?.values?.totalCharges?.totalVASQuantity}</span>
                        <p>Sub-Total (B)</p>
                        <span>₹{formik?.values?.totalCharges?.totalVASTaxableCharges}</span>
                    </div>
                </div>
            </div>
            {/* <-------------Additional Table--------------> */}
            <div className='mt-6'>
                <div className='flex justify-between'>
                    <div className={`${classes.additionalTableContainer} text-center`}>
                        <h3 className={`${classes.additionalHeading} text-sm font-medium`}>Additional Charges</h3>
                        <table className={`${classes.additionalTableContainer}`}>
                            <thead>
                                <th className={`${classes.tableHeading} text-sm font-medium`}>S.No.</th>
                                <th className={`${classes.tableHeading} text-sm font-medium`}>Particulars</th>
                                <th className={`${classes.tableHeading} text-sm font-medium`}>Qty (MT)</th>
                                <th className={`${classes.tableHeading} text-sm font-medium`}>Charges/MT</th>
                                <th className={`${classes.tableHeading} text-sm font-medium`}>Total Amount</th>
                            </thead>
                            <tbody>
                                {formik.values?.additionalCharges?.map((row, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td className="text-left">{row.particulars}</td>
                                        <td>{row.quantity ? row.quantity : '-'}</td>
                                        <td>{row.charge ? row.charge : '-'}</td>
                                        <td>{row.totalAmount ? row.totalAmount : '-'}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>₹<span>{formik.values?.totalCharges?.totalAdditionalCharges}</span></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table className={`${classes.subTotalTable} text-center`}>
                            <tr>
                                <td>Sub-Total (A+B)</td>
                                <td>₹<span>{formik.values?.totalCharges?.totalProductAndVASAmount}</span></td>
                            </tr> <tr>
                                <td>Charges</td>
                                <td>₹<span>{formik.values?.totalCharges?.totalAdditionalCharges}</span></td>
                            </tr>
                            <tr>
                                <td>Total Tax Amt.</td>
                                <td>₹<span>{formik.values?.totalCharges?.totalTaxAmount}</span></td>
                            </tr>
                            <tr>
                                <td>TCS (1%)</td>
                                <td>₹<span>{formik.values?.totalCharges?.tcs}</span></td>
                            </tr>
                            <tr>
                                <td>Grand Total</td>
                                <td>₹<span>{formik.values?.totalCharges?.grandTotal}</span></td>
                            </tr>

                        </table>
                    </div>
                </div>

                <div className={`${classes.additionalTableContainer} text-center w-max`}>
                    <h3 className={`${classes.additionalHeading} text-sm font-medium text-center`}>GST Tax Details</h3>
                    <table className={`${classes.additionalTableContainer} text-center`}>
                        <thead>
                            <tr>
                                <th className={`${classes.tableHeading} text-sm font-medium`} rowSpan={2}>Particulars</th>
                                <th className={`${classes.tableHeading} text-sm font-medium`} rowSpan={2}>Taxable Amount</th>
                                <th className={`${classes.tableHeading} text-sm font-medium`} colSpan={2}>CGST</th>
                                <th className={`${classes.tableHeading} text-sm font-medium`} colSpan={2}>SGST</th>
                                <th className={`${classes.tableHeading} text-sm font-medium`} colSpan={2}>IGST</th>
                                <th className={`${classes.tableHeading} text-sm font-medium`}>Total Tax Amount</th>
                            </tr>

                            <tr>
                                <th className={`${classes.tableHeading} text-sm font-medium`}>Rate</th>
                                <th className={`${classes.tableHeading} text-sm font-medium`}>Amount</th>
                                <th className={`${classes.tableHeading} text-sm font-medium`}>Rate</th>
                                <th className={`${classes.tableHeading} text-sm font-medium`}>Amount</th>
                                <th className={`${classes.tableHeading} text-sm font-medium`}>Rate</th>
                                <th className={`${classes.tableHeading} text-sm font-medium`}>Amount</th>
                            </tr>

                        </thead>
                        <tbody>
                            {formik.values?.gstDetails?.map((row, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td className="text-left">{row.particulars}</td>
                                    <td>{row?.cgstRate ? row?.cgstRate : '-'}<span>%</span></td>
                                    <td>₹<span>{row?.cgstAmount ? row?.cgstAmount : '-'}</span></td>
                                    <td>{row?.sgstRate ? row?.sgstRate : '-'}<span>%</span></td>
                                    <td>₹<span>{row?.sgstRate ? row?.sgstRate : '-'}</span></td>
                                    <td>{row?.igstRate ? row?.igstRate : '-'}<span>%</span></td>
                                    <td>₹<span>{row?.igstRate ? row?.igstAmount : '-'}</span></td>
                                    <td>₹<span>{row?.totalTaxAmount}</span></td>
                                </tr>
                            ))}
                            <tr>
                                <td>Total</td>
                                <td></td>
                                <td></td>
                                <td>₹<span>{formik.values?.totalCharges?.totalCGST}</span></td>
                                <td></td>
                                <td>₹<span>{formik.values?.totalCharges?.totalSGST}</span></td>
                                <td></td>
                                <td>₹<span>{formik.values?.totalCharges?.totalIGST}</span></td>
                                <td>₹<span>{formik.values?.totalCharges?.totalTaxAmount}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* <----------Delivery info table----------> */}

            <div className='mt-6 mb-6 flex gap-3 '>
                <div className='w-full'>
                    <h3 className={`${classes.tableHeadingSecondary} text-base font-semibold`}>Delivery Information*</h3>
                    <table className={`${classes.commonTable} w-full text-center`}>
                        <thead>
                            <th className='py-3'>Aspect</th>
                            <th className='py-3'>Details</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='py-1'>Delivery Time Frame</td>
                                <td className='py-1'>{formik.values?.deliveryInformation?.timeFrame}</td>
                            </tr>
                            <tr>
                                <td className='py-1'>Estimated Delivery Date</td>
                                <td className='py-1'>{moment(formik.values?.deliveryInformation?.deliveryDate).format("DD-MM-YYYY")}</td>
                            </tr>
                            <tr>
                                <td>Delivery Mode</td>
                                <td>{formik.values?.deliveryInformation?.deliveryMode}</td>
                            </tr>
                            <tr>
                                <td>Transport Arrangement</td>
                                <td>{formik.values?.deliveryInformation?.transportArrangement}</td>
                            </tr>
                            <tr>
                                <td>Material Lifting</td>
                                <td>{formik.values?.deliveryInformation?.materialLifting}</td>
                            </tr>
                            <tr>
                                <td>INCO/ Shipping Terms</td>
                                <td>{formik.values?.deliveryInformation?.inco}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='w-full'>
                    <h3 className={`${classes.tableHeadingSecondary} text-base font-semibold`}>Payment*</h3>
                    <table className={`${classes.commonTable} w-full text-center`}>
                        <thead>
                            <th colSpan={2}>Aspect</th>
                            <th>Details</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={2}>Payment</td>
                                <td>{formik.values?.paymentTerms?.paymentTerms}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Payment Method</td>
                                <td>{formik.values?.paymentTerms?.paymentMethods}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Payment Schedule</td>
                                <td>Upfront: <span>{formik.values?.upfrontPaymentInformation?.percentage}</span>% Delivery: <span>{formik.values?.deliveryPaymentInformation?.percentage}</span>%</td>
                            </tr>
                            <tr>
                                <td className='w-4'></td>
                                <td>Upfront Payment</td>
                                <td> <span>Amount: ₹</span><span>{formik.values?.upfrontPaymentInformation?.amount}</span> <br /><span>Due Date: <span>{moment(formik.values?.upfrontPaymentInformation?.dueDate).format("DD-MM-YYYY")}</span></span></td>
                            </tr>
                            <tr>
                                <td className='w-4'></td>
                                <td>Delivery Payment</td>
                                <td> <span>Amount: ₹</span><span>{formik.values?.deliveryPaymentInformation?.amount}</span> <br /><span>Due Date: <span>{moment(formik.values?.deliveryPaymentInformation?.dueDate).format("DD-MM-YYYY")}</span></span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='mt-6 mb-6 flex gap-3 '>
                <div className='w-full'>
                    <h3 className={`${classes.tableHeadingSecondary} text-base font-semibold`}>Packaging Terms</h3>
                    <table className={`${classes.commonTable} w-full text-center`}>
                        <thead>
                            <th className='!py-3'>Aspect</th>
                            <th className='!py-3'>Details</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='!py-3'>Packaging Type</td>
                                <td>{formik.values?.packaging?.packagingTerms}</td>
                            </tr>
                            <tr>
                                <td className='!py-2'>Packaging Material</td>
                                <td>{formik.values?.packaging?.packagingMaterials}</td>
                            </tr>
                            <tr>
                                <td className='!py-2'>Packaging Dimensions</td>
                                <td>{formik.values?.packaging?.packagingDimensions}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='w-full'>
                    <h3 className={`${classes.tableHeadingSecondary} text-base font-semibold`}>Inspection and Acceptance Terms</h3>
                    <table className={`${classes.commonTable} w-full text-center`}>
                        <thead>
                            <th>Aspect</th>
                            <th>Details</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Inspection Period</td>
                                <td>{formik.values?.inspectionAndAcceptanceTerm?.inspectionPeriod}</td>
                            </tr>
                            <tr>
                                <td>Inspection Location</td>
                                <td>{formik.values?.inspectionAndAcceptanceTerm?.inspectionLocation}</td>
                            </tr>
                            <tr>
                                <td>Acceptance Criteria</td>
                                <td>{formik.values?.inspectionAndAcceptanceTerm?.acceptanceCriteria}</td>
                            </tr>
                            <tr>
                                <td>Notification of Non-Conformance</td>
                                <td>{formik.values?.inspectionAndAcceptanceTerm?.notification}</td>
                            </tr>
                            <tr>
                                <td>Remedies for Non-Conformance</td>
                                <td>{formik.values?.inspectionAndAcceptanceTerm?.remedies}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Terms and Conditions  */}
            <div className={`${classes.termsContainer} rounded-xl p-4`}>
                <h4 className={`${classes.termsHeading} text-base font-medium mb-4`}>Terms and Condition</h4>
                <pre
                    className={`${classes.description} text-sm`}
                    style={{
                        fontFamily: 'Roboto',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        whiteSpace: 'pre-wrap'
                    }}
                >
                    {formik.values?.termAndCondition}
                </pre>
            </div>


            <div className='flex gap-4 mt-6 items-center'>
                <div className={`${classes.contactContainer} text-center w-full`}>
                    <h3 className={`${classes.tableHeadingSecondary} text-base font-medium`}>Point of Contact</h3>
                    <table className={`${classes.contactTable} w-full`}>
                        <thead>
                            <th>Details</th>
                            <th>Primary</th>
                            <th>Secondary</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Contact Person</td>
                                <td>{formik.values?.pocInformation?.[0]?.name}</td>
                                <td>{formik.values?.pocInformation?.[1]?.name}</td>
                            </tr>
                            <tr>
                                <td>Contact Person No.</td>
                                <td>{formik.values?.pocInformation?.[0]?.number}</td>
                                <td>{formik.values?.pocInformation?.[1]?.number}</td>
                            </tr>
                            <tr>
                                <td>Contact Person Email ID</td>
                                <td>{formik.values?.pocInformation?.[0]?.emailId}</td>
                                <td>{formik.values?.pocInformation?.[1]?.emailId}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={`${classes.author} relative w-full text-center`}>
                    <p className='absolute bottom-0 right-0 left-0 text-center'>Authorised By</p>
                </div>
            </div>
            <div className="flex mt-6 relative">
                <div className="!absolute right-0 top-0 flex items-center gap-2 pb-4">
                    <ButtonV2
                        variant="tertiaryContained"
                        label="Back"
                        onClick={() => {
                            setCurrentStep(MODE.ADD);
                        }}
                    />
                    <ButtonV2
                        variant="contained"
                        label="Save"
                        onClick={saveInstantQuotation}
                    />
                </div>
            </div>

        </div>
    )
}
