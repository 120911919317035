import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";
import { IAddress } from "./useManufacturerService";

export const SERVICE_URLS = {
    GET_RFQS: "/rfq",
    GET_RFQ: "/rfq/:id",
};

export interface IRfqSearchParams {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
}

export interface IRfqRequestParams {
    productCategoryIds?: number[] | null;
}

export interface IRfqs {
    id: number;
    customerName: string;
    expectedDeliveryDate: string;
    manufacturerName: string;
    productCategory: string;
    grade: string;
    standard: string;
    requiredQuantity: number;
    specificRequirements: string;
    paymentTerm: string;
    createdAt: string;
}

export interface IRfq {
    id: number;
    customerName: string;
    expectedDeliveryDate: string;
    manufacturerName: string;
    productCategory: string;
    gradeValue: string;
    standard: string;
    requiredQuantity: number;
    specificRequirements: string;
    paymentTerms: string;
    createdAt: string;
    attributes: any;
    shape: string; 
    userDetails: RfqUserDetails;
}

interface RfqUserDetails {
    id: number,
    name: string,
    userId: number,
    gstin: string,
    pan: string,
    user: IUser,
    address: IAddress
}

export interface IUser{
    id: number;
    email: string;
    mobileNumber: string;
    whatsappNumber?: string | null;
    fullName: string;
    countryCode: string;
    userType: string;
    gstin: string;
    department?: string | null;
    designation?: string | null;
    alternativeEmail?: string | null;
    socialLinks?: string | null;
    isAuthorized?: boolean | null;
    prefix?: string | null;
    whatsAppLink?: string | null;
    alternateMobile?: string | null;
    status: string;
    comments?: string | null;
    createdAt: string;
    updatedAt: string;
    password: boolean;
    emailVerified: boolean;
    phoneNumberVerified: boolean;
}

export const useRfqService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    // const getRfqs = (params: IRfqSearchParams, data: IRfqRequestParams) => {
    //     return request(API_METHOD.POST, SERVICE_URLS.GET_RFQS, authenticatedUser, data, { params });
    // };

    const getRfqs = (params: IRfqSearchParams) => {
        return request(API_METHOD.GET, SERVICE_URLS.GET_RFQS, authenticatedUser, null, { params });
    };

    const getRfqById = (id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(SERVICE_URLS.GET_RFQ, { id }), authenticatedUser);
    };

    return {
        getRfqs,
        getRfqById
    };
};
