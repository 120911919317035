import React from 'react'
import { useSnackbar } from '../../../hooks/useSnackBar';
import { CategoryPayload, useCategoryService } from '../../../services/useCategoryService';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES, MODE, STATUS } from '../../../utils/constant';
import { CATEGORY_TYPE, HTTP_STATUS } from '../../../utils/types';
import MainCategoryFormTemplate from '../../templates/Category/MainCategoryForm.template';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { makeRoute } from '../../../utils/helper';

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Category Name is required"),
    description: Yup.string().required("Category Description is required")
    .test('no-p-br-tags', 'Defect details cannot be Empty', ((value:any)=> value !== '<p><br></p>')),
    parentId: Yup.number().required("Super Category is required")
});

const MainCategoryCreatePage: React.FC = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const categoryService = useCategoryService();
    const navigate = useNavigate();

    const onBack = () => {
        navigate(makeRoute(ADMIN_ROUTES.CATEGORY, {query: { type: 'MAIN_CATEGORY'}}));
    }

    const createCategory = async (category: CategoryPayload) => {
        categoryService.createCategory(category)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    onBack();
                } else if (res.status ===  HTTP_STATUS.BAD_REQUEST) {
                    showSnackbar('error', "Category already exists. Please try again");
                }
            }).catch((error) => {
                showSnackbar('error', "Failed to crate Main Category");
            })
    }

    const formik = useFormik<CategoryPayload>({
        initialValues: {
            name: "",
            status: STATUS.ACTIVE,
            description: "",
            parentId: null,
            level: CATEGORY_TYPE.MAIN_CATEGORY,
            prefix: null
        },
        validationSchema,
        onSubmit: async (values) => {
            createCategory({ ...values, status: values.status })
        }
    })

    return (
        <div>
            {SnackBarComponent}
            <MainCategoryFormTemplate mode={MODE.ADD} onBack={onBack} formik={formik} />
        </div>
    )
}

export default MainCategoryCreatePage