import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { CATALOGUE_BUNDLING_ROUTES } from '../../../../App/Core/Routes/CatalogueBundlingRoutes';
import ButtonV2 from '../../../atoms/ButtonV2';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { initialPaginationValues } from '../../../../utils/constant';
import { IBundleRequestParams, IBundleSearchParams, IBundles, useCatalogueBundlingService } from '../../../../services/useCatalogueBundlingService';
import { HTTP_STATUS, IPagination } from '../../../../utils/types';
import BundleListTemplate from '../../../templates/CatalogueBundling/Bundle/BundleList.template';

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary950,
        lineHeight: "22px",
    },
}));

const initialBundleFiltersValues: IBundleSearchParams = {
    search: "",
    status: "ALL",
    sort: "createdAt,desc",
};

const initialBundleRequestFilters: IBundleRequestParams = {
    catalogBundleIds: [],
    productIds: [],
};

const BundleListPage: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const catalogueBundlingService = useCatalogueBundlingService();
    const [filters, setFilters] = useState<IBundleSearchParams>(initialBundleFiltersValues);
    const [bundleRequestFilters, setBundleRequestFiltersTo] = useState<any>(initialBundleRequestFilters);
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const [bundles, setBundles] = useState<IBundles[]>([])
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const refreshBundle = async () => {
        const payload: IBundleRequestParams = {
            catalogBundleIds: bundleRequestFilters?.catalogBundleIds?.length > 0 ? bundleRequestFilters?.catalogBundleIds : null,
            productIds: bundleRequestFilters?.productIds?.length > 0 ? bundleRequestFilters?.productIds : null,
        };
        await catalogueBundlingService.getBundles(
            {
                page: pagination.currentPage,
                size: pagination.pageSize,
                sort: filters?.sort,
                search: filters?.search?.trim(),
                status: filters?.status === "ALL" ? "" : filters?.status,
            },
            payload
        )
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    const { totalElements, totalPages } = res?.data?.data;
                    setPagination({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setBundles(res?.data?.data?.content)
                }
            }).catch(error => {
                showSnackbar('error', error);
                setBundles([]);
            })
    }

    const handleFiltersChange = (name: string, value: any) => {
        setPagination({ ...pagination, currentPage: 0 });
        setFilters({ ...filters, [name]: value ?? "" });
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPagination((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    useEffect(() => {
        refreshBundle();
    }, [pagination.currentPage, pagination.pageSize, filters, bundleRequestFilters]);

    const handleCreate = () => {
        navigate(`${CATALOGUE_BUNDLING_ROUTES.CATALOGUE_BUNDLE_CREATE}`);
    };

    return (
        <div className='grid gap-y-2.5 mt-6'>
            <div className='flex justify-between'>
                <div className={`${classes.heading} text-lg font-semibold my-auto`}>Catalogue Bundle</div>
                <ButtonV2 variant='primaryContained' label={"+Create Catalogue Bundle"} onClick={handleCreate}/>
            </div>
            {SnackBarComponent}

            <BundleListTemplate
                bundles={bundles}
                pagination={pagination}
                handlePaginationChange={handlePaginationChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handleFiltersChange={handleFiltersChange}
                filters={filters}
                bundleRequestFilters={bundleRequestFilters}
                setBundleRequestFiltersTo={setBundleRequestFiltersTo}
            />
        </div>
    )
}

export default BundleListPage;