import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { createUseStyles } from "react-jss";
import { usePostProductService } from '../../../../services/usePostProductService';
import { HTTP_STATUS } from "../../../../utils/types";
import { getEnumValue } from "../../../../utils/helper";
import ButtonV2 from "../../../atoms/ButtonV2";

interface ViewProductModalTemplateProps {
  product: any;
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
  container: {
    "& .MuiDialog-paper": {
      width: "783px !important",
      maxWidth: "783px !important",
      borderRadius: "12px",
    },
  },
  mainContainer: {
    padding: "24px",
  },
  productName: {
    fontSize: "24px",
    fontWeight: "500",
  },
  skuID: {
    color: theme.customerPalette.text.neutral.neutral700,
    fontSize: "16px",
    fontWeight: "400",
    marginTop: "8px",
  },
  hr: {
    marginTop: "16px",
  },
  detailsContainer: {
    padding: "24px",
    borderRadius: "12px",
    border: `1px solid ${theme.customerPalette.text.neutral.neutral100}`,
    marginTop: "16px"
  },
  detailHeading: {
    color: theme.customerPalette.text.neutral.neutral900,
    fontWeight: "500"
  },
  detailSubHeading: {
    color: theme.customerPalette.text.neutral.neutral700,
  },
  closeIcon: {
    color: theme.customerPalette.text.primary.primary700,
  },
}));

const ViewProductModalTemplate: React.FC<ViewProductModalTemplateProps> = ({ product, dialogOpen, setDialogOpen }) => {

  const classes = useStyles();

  const postProductService = usePostProductService();

  const [productDetails, setProductDetails] = useState<any>({})

  const getProductDetails = async () => {
    try {
      const response = await postProductService.getProductDetailsById(product.productId)
      if (response.status === HTTP_STATUS.OK) {
        setProductDetails(response.data)
      }
    }
    catch (error) {
      console.error("Error", error)
      setProductDetails({});
    }
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    getProductDetails()
  }, [product?.productId])

  return (
    <div>
      <Dialog
        fullWidth
        className={classes.container}
        open={dialogOpen && product?.productId}
        onClose={handleDialogClose}
      >
        <div className={classes.mainContainer}>
          <div className="flex justify-between items-center">
            <div className={classes.productName}>
              {productDetails?.data?.productName ? productDetails?.data?.productName : ""}
            </div>
            <div className={`cursor-pointer`} onClick={handleDialogClose}>
              <CloseIcon className={`${classes.closeIcon}`} />
            </div>
          </div>
          <div className={classes.skuID}>SKU ID: {productDetails?.data?.upcCode}</div>
          <hr className={classes.hr} />
          <div className={`${classes.detailsContainer} flex flex-col gap-4`}>
            <div className={`${classes.detailHeading} text-base`}>Category</div>
            <div className="flex gap-20">
              <div className="flex flex-col gap-1">
                <div className={`${classes.detailSubHeading} text-sm font-normal`}>Super Category</div>
                <div className={`${classes.detailSubHeading} text-base font-medium`}>{productDetails?.data?.superCategory}</div>
              </div>
              <div className="flex flex-col gap-1">
                <div className={`${classes.detailSubHeading} text-sm font-normal`}>Main Category</div>
                <div className={`${classes.detailSubHeading} text-base font-medium`}>{productDetails?.data?.mainCategory}</div>
              </div>
              <div className="flex flex-col gap-1">
                <div className={`${classes.detailSubHeading} text-sm font-normal`}>Product Category</div>
                <div className={`${classes.detailSubHeading} text-base font-medium`}>{productDetails?.data?.productCategory}</div>
              </div>
            </div>
          </div>
          <div className="flex gap-4">
            <div className={`${classes.detailsContainer} flex flex-col gap-4 w-6/12`}>
              <div className={`${classes.detailHeading} text-base`}>Standards</div>
              <div className="flex gap-20">
                <div className="flex flex-col gap-1">
                  <div className={`${classes.detailSubHeading} text-sm font-normal`}>Standard</div>
                  <div className={`${classes.detailSubHeading} text-base font-medium`}>{productDetails?.data?.standard}</div>
                </div>
                <div className="flex flex-col gap-1">
                  <div className={`${classes.detailSubHeading} text-sm font-normal`}>Grade</div>
                  <div className={`${classes.detailSubHeading} text-base font-medium`}>{productDetails?.data?.grade}</div>
                </div>
              </div>
            </div>
            <div className={`${classes.detailsContainer} flex flex-col gap-4 w-6/12`}>
              <div className={`${classes.detailHeading} text-base`}>Product Classification</div>
              <div className="flex gap-20">
                <div className="flex flex-col gap-1">
                  <div className={`${classes.detailSubHeading} text-sm font-normal`}>Shape</div>
                  <div className={`${classes.detailSubHeading} text-base font-medium`}>{getEnumValue(productDetails?.data?.shape)}</div>
                </div>
                <div className="flex flex-col gap-1">
                  <div className={`${classes.detailSubHeading} text-sm font-normal`}>Class</div>
                  <div className={`${classes.detailSubHeading} text-base font-medium`}>{getEnumValue(productDetails?.data?.classType)}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${classes.detailsContainer} flex flex-col gap-4 mb-6`}>
            <div className={`${classes.detailHeading} text-base`}>Product Specification</div>
            <div className="flex justify-between">
              {productDetails?.data?.attributesMap?.SPECIFICATION?.slice(0, 6).map((el: any) => (
                el && <div className="flex flex-col gap-1">
                  <div className={`${classes.detailSubHeading} text-sm font-normal`}>{el.uom ? `${el.name} (${el.uom.toLowerCase()})` : el.name}</div>
                  <div className={`${classes.detailSubHeading} text-base font-medium`}>{`${el.attributeOptions} ${el.uom?.toLowerCase() ?? ""}`}</div>
                </div>
              ))}
            </div>
          </div>
          <ButtonV2
            variant="primaryContained"
            label="Go Back"
            className="float-right"
            size="medium"
            onClick={handleDialogClose}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default ViewProductModalTemplate;
