import React, { useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
// import ButtonV2 from "../../atoms/ButtonV2/ButtonV2";
// import { toInr } from "../../../utils/helper";
// import { ICatalogueUpc } from "../../pages/CatalogueDetail/CatalogueDetails.page";
// import { IWarehouse } from "./WarehouseLocation.template";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
// import { UNIT } from "../../../utils/constant";
// import emailIcon from "../../../assets/images/emailIcon.svg";
// import MailerModalTemplate from "./MailerModal.template";
// import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
// import { ICatalogue } from "../../pages/CatalogueDetail/MultipleUpcCatalogueDetails.page";
// import AddToCartIcon from '../../../assets/images/addToCart.svg';
import AddToCartIcon from '../../../../assets/images/addToCart.svg';
import AddToCartDisabledIcon from '../../../../assets/images/addToCartDisabled.svg';
// import Whatsapp from "../../atoms/Whatsapp/Whatsapp";
import SubjectToTermsConditionTemplate from "./SubjectToTermsCondition.template";
// import QuantityDiscountTemplate from "./QuantityDiscount.template";
// import emailIconWhiteOutlined from "../../../assets/images/emailIconWhiteOutlined.svg"

import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { ICatalogue, ICatalogueUpc, IWarehouse } from "../../../pages/Vendor/Catalogue/CatalogueDetails.page";
import ButtonV2 from "../../../atoms/ButtonV2";
import Whatsapp from "../../../atoms/Whatsapp/Whatsapp";
import { UNIT } from "../../../../utils/constant";
import { toInr } from "../../../../utils/helper";
import MailerModalTemplate from "./MailerModal.template";

const useStyles = createUseStyles((theme: any) => ({
	title: {
		background: "white",
		boxShadow: `0px 0px 16px 0px rgba(0, 0, 0, 0.09)`,
		padding: "20px 24px",
		borderRadius: "16px"
	},
	priceText: {
		color: theme.customerPalette.text.primary.primary700,
	},
	priceValue: {
		color: theme.customerPalette.text.neutral.neutral700,
		fontSize: "28px"
	},
	quantityText: {
		color: theme.customerPalette.text.neutral.neutral900,
	},
	cartIcon: {
		position: "absolute",
		zIndex: 1,
		padding: "12px 0px 0px 60px",
	},
	uom: {
		color: theme.customerPalette.text.neutral.neutral600,
		fontSize: "14px",
	},
	gst: {
		color: theme.customerPalette.text.neutral.neutral600,
		fontSize: "14px",
	},
	selectedUpc: {
		color: theme.customerPalette.text.neutral.neutral800,
		border: `1px solid ${theme.customerPalette.border.neutral.neutral200}`,
		background: "white",
	},
	incrementDecrement: {
		background: "white",
		color: theme.customerPalette.text.primary.primary700,
		border: `1px solid ${theme.customerPalette.border.primary.primary800}`,
		borderRadius: "10px",
	},
	basePrice: {
		textDecoration: "line-through",
		textDecorationColor: theme.customerPalette.border.secondary.secondary400,
		color: "inherit",
	},
	price: {
		color: theme.customerPalette.text.success.success600,
	},
	count: {
		background: 'white',
		color: theme.customerPalette.text.neutral.neutral800,
		border: `1px solid ${theme.customerPalette.border.neutral.neutral200}`,
		fontSize: "16px",
		fontWeight: 500,
	},
	specialPrice: {
		color: theme.customerPalette.text.neutral.neutral800,
	},
	minimumQuantity: {
		color: theme.customerPalette.text.neutral.neutral500,
	},
	finalPriceText: {
		color: theme.customerPalette.text.primary.primary400
	},
	email: {
		borderRadius: "12px",
		border: `1px solid ${theme.customerPalette.border.primary.primary800}`,
		width: "48px",
		height: "48px",
	},
	emailIcon: {
		width: "48px",
		height: "48px",
		border: `1px solid ${theme.customerPalette.border.primary.primary800}`,
	},
	arrow: {
		display: "none"
	},
	termAndCondition: {
		display: "flex"
	},
	webContainer: {
		display: "grid"
	},
	mobileContainer: {
		display: "none"
	},
	webButton: {
		display: "flex"
	},
	mobileButton: {
		display: "none"
	},
	"@media (max-width: 480px)": {
		arrow: {
			display: "flex"
		},
		termAndCondition: {
			display: "none"
		},
		webContainer: {
			display: "none"
		},
		mobileContainer: {
			display: "grid"
		},
		webButton: {
			display: "none"
		},
		mobileButton: {
			display: "flex"
		},
		email: {
			width: "48px",
			height: "48px",
		},
		emailIcon: {
			width: "49px",
			height: "43px",
		},
		gst: {
			fontSize: "12px",
		},
		priceValue: {
			fontSize: "18px"
		},
		uom: {
			fontSize: "12px",
		},
		title: {
			borderRadius: "0px",
			boxShadow: `none`,
			padding: "16px",
			border: `1px solid ${theme.customerPalette.border.neutral.neutral100}`,
		},
	},
}));

export interface PriceDisplayProps {
	price: string | number;
	uom?: string;
	gstText: string;
	classes: {
		priceValue: string;
		uom: string;
		gst: string;
	};
}
export interface PriceDetailsProps {
	minimumPrice: number;
	maximumPrice: number;
	quantity: string;
	moq: string;
	warehouse: IWarehouse | null;
	gst: number;
	selectedUpc?: ICatalogueUpc | null;
	uom: string;
	handleAddToCart: () => void;
	handleQuantityChange: any;
	catalogueType: String;
	catalogue?: ICatalogue;
	paymentType?: string | null;
	productCombination: boolean | null;
}

const PriceDetailsStoreFrontTemplate: React.FC<PriceDetailsProps> = ({ handleAddToCart, paymentType, minimumPrice, handleQuantityChange, maximumPrice, moq, quantity, warehouse, gst, selectedUpc, uom, catalogueType, catalogue, productCombination }) => {
	const classes = useStyles();
	// const { user } = useAuthenticatedUser();
	const alterQuantity = (alterQuantityUnit: number) => () => {
		const currentQuantity = (parseFloat(quantity) || 0).toString();
		handleQuantityChange(String(parseFloat(Math.max(parseFloat(currentQuantity) + alterQuantityUnit, parseFloat(moq)).toFixed(2))));
	};

	const increaseQuantity = () => {
		if (warehouse) {
			alterQuantity(1)();
		}
	};

	const decreaseQuantity = () => {
		if (warehouse) {
			alterQuantity(-1)();
		}
	};

	const [dialogOpen, setDialogOpen] = useState<boolean>(false);

	const handleRfqForm = () => {

		setDialogOpen(true);
	}

	const [showAll, setShowAll] = useState(false);
	const handleClick = () => {
		setShowAll(!showAll);
	};

	const PriceDisplay: React.FC<PriceDisplayProps> = ({ price, uom, gstText, classes }) => (
		<div>
			<div className="flex text-2xl font-semibold whitespace-nowrap">
				<div className="flex">
					<span className={`${classes.priceValue} font-bold`}>{price}</span>
				</div>
				<span className={`${classes.uom} font-bold mt-0 mx-1`}>*</span>
				<span className={`${classes.uom} font-normal my-auto mr-2`}>{`/${uom || "MT"}`}</span>

			</div>
			<div className={`${classes.gst} font-medium my-auto`}>
				<span className="mr-1">{gstText}</span>
				<span>GST@18%</span>
			</div>
		</div>
	);

	const productPriceDisplay = useMemo(() => {
		const gstText = "exclu.";
		if (productCombination === false) {
			return (
				<PriceDisplay
					price="₹ - "
					uom={uom}
					gstText={gstText}
					classes={classes}
				/>
			);
		}

		const outOfStock = selectedUpc && Object.keys(selectedUpc).length > 1 && !warehouse;

		if (outOfStock) {
			return (
				<PriceDisplay
					price="₹ - "
					uom={uom}
					gstText={gstText}
					classes={classes}
				/>
			);
		}

		if (selectedUpc && Object.keys(selectedUpc).length > 0) {
			const price = toInr(selectedUpc.upcPrice || 0);
			return (
				<PriceDisplay
					price={price}
					uom={uom}
					gstText={gstText}
					classes={classes}
				/>
			);
		}

		const priceRange = minimumPrice === maximumPrice
			? toInr(minimumPrice || 0)
			: toInr(maximumPrice || 0);

		return (
			<PriceDisplay
				price={priceRange}
				uom={uom}
				gstText={gstText}
				classes={classes}
			/>
		);
	}, [selectedUpc, warehouse, minimumPrice, maximumPrice, toInr, uom, classes]);


	const FinalPriceText = useMemo(() => {
		let message = "For Final Price/Quotation, Click Here.";
		const quantityValid = quantity && parseFloat(quantity) >= parseFloat(moq);
		let rfqFlag = false;
		if (productCombination === false) {
			message = "Product not available for this selection";
			rfqFlag = true;
		} else if (!warehouse || !selectedUpc || !quantity || !quantityValid) {
			if (!warehouse && selectedUpc && Object.keys(selectedUpc).length > 0) {
				message = "Out of Stock";
				rfqFlag = true;
			} else {
				message = "Please select specification to add to cart.";
			}
		}

		return (
			<>
				<div className={`${classes.finalPriceText} text-sm font-medium mt-4`}>
					{message}
				</div>
				<div className={`${classes.finalPriceText} text-sm font-medium mt-4`}>
					{rfqFlag ? `Please refer to 'Request for Quotation'` : null}
				</div>
			</>
		);
	}, [warehouse, selectedUpc, quantity, moq, productCombination, classes.finalPriceText]);



	return (
		<div className={`grid ${classes.title} gap-y-4`}>
			<div className={`${classes.webContainer}  gap-y-4 pb-4`}>
				<div className="flex flex-col pb-4">
					<span className={`${classes.priceText} text-lg font-medium`}>Steel Bazaar Price</span>
					{productPriceDisplay}
				</div>
				<div className={`${classes.quantityText} text-lg font-medium`}>Enter Quantity (MT)</div>
				<div className="grid gap-y-2">
					<div className='flex gap-x-4'>
						<div className={`w-10 h-10 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} style={{ cursor: (warehouse && (parseFloat(quantity) > parseFloat(moq))) ? 'not-allowed' : 'not-allowed' }}><RemoveIcon /></div>
						<input type="text" className={`w-24 h-10 rounded-lg flex text-center justify-center items-center gap-2  ${moq === "" ? classes.selectedUpc : classes.count}`} value={moq} disabled onChange={event => handleQuantityChange(event.target.value)} />
						<div className={`w-10 h-10 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} style={{ cursor: warehouse ? 'not-allowed' : 'not-allowed' }}><AddIcon /></div>
					</div>
					{!(moq === "") && <div className={`font-inter text-xs font-normal mt-1 mb-1 ${classes.minimumQuantity}`}>Minimum Order Quantity : {moq}{UNIT.UOM} </div>}
				</div>
			</div>
			{showAll &&
				<div className={`${classes.mobileContainer}  gap-y-4 pb-3`}>
					<div className="flex flex-col pb-4">
						<span className={`${classes.priceText} text-xl font-medium mb-3`}>Steel Bazaar Price</span>
						{productPriceDisplay}
					</div>
					<div className={`${classes.quantityText} text-xl font-medium`}>Enter Quantity (MT)</div>
					<div className="grid gap-y-2">
						<div className='flex gap-x-4'>
							<div className={`w-8 h-8 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} style={{ cursor: (warehouse && (parseFloat(quantity) > parseFloat(moq))) ? 'pointer' : 'not-allowed' }}><RemoveIcon /></div>
							<input type="text" className={`w-24 h-9 rounded-lg flex text-center justify-center items-center gap-2  ${moq === "" ? classes.selectedUpc : classes.count}`} value={moq === "" ? "" : quantity} disabled={moq === ""} onChange={event => handleQuantityChange(event.target.value)} />
							<div className={`w-8 h-8 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} style={{ cursor: warehouse ? 'pointer' : 'not-allowed' }}><AddIcon /></div>
						</div>
						{!(moq === "") && <div className={`font-inter text-xs font-semibold mt-1 mb-1 ${classes.minimumQuantity}`}>Minimum Order Quantity : {moq}{UNIT.UOM} </div>}
					</div>
				</div>
			}
			<div>
				<div className="flex justify-between">
					<div className={`text-base font-medium mt-1 ${classes.specialPrice}`}>Your Price (After Discounts)</div>
					<div className={`${classes.arrow} gap-x-2 cursor-pointer`} onClick={handleClick} >
						{showAll ? <ArrowDropUp /> : <ArrowDropDown />}
					</div>
				</div>
				{productPriceDisplay}
				{FinalPriceText}
				<div className={`${classes.finalPriceText} text-sm font-medium mb-2`}>{(!paymentType) ? "Please select payment method to add to cart." : null}</div>
				<div className="flex justify-between gap-x-2">
					<div className={`${classes.webButton} flex-1`}>
						<ButtonV2 variant="primaryContained" disabled={(!warehouse || !selectedUpc || !quantity || (parseFloat(quantity) < parseFloat(moq)) || !paymentType) ?? false} fullWidth label="Get Best Quote" startIcon={
							(!warehouse || !selectedUpc || !quantity || (parseFloat(quantity) < parseFloat(moq))) ?
								<img src={AddToCartDisabledIcon} alt="icon" /> :
								<img src={AddToCartIcon} alt="icon" />}
						/>
					</div>
					<div className={`${classes.mobileButton} flex-1`}>
						<ButtonV2 variant="primaryContained" size="small" disabled={(!warehouse || !selectedUpc || !quantity || (parseFloat(quantity) < parseFloat(moq)) || !paymentType) ?? false} fullWidth label="Get Best Quote" startIcon={
							(!warehouse || !selectedUpc || !quantity || (parseFloat(quantity) < parseFloat(moq))) ?
								<img src={AddToCartDisabledIcon} alt="icon" /> :
								<img src={AddToCartIcon} alt="icon" />}
						/>
					</div>
					<Whatsapp />
					{/* {user?.email && selectedUpc && (
						<div className={`${dialogOpen ? classes.emailIcon : classes.email} cursor-pointer grid justify-center content-center`}>
							<img className="" src={dialogOpen ? emailIcon : emailIcon} alt="" />
						</div>
					)} */}
					<MailerModalTemplate dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} selectedUpc={selectedUpc} warehouse={warehouse && warehouse?.warehouseAddress} catalogue={catalogue} />
				</div>
			</div>
			<div className={`${classes.termAndCondition}`}>
				<SubjectToTermsConditionTemplate />
			</div>
		</div>
	);
};

export default PriceDetailsStoreFrontTemplate;