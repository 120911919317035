import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { Checkbox, FormControl } from '@mui/material';
import ButtonV2 from "../../atoms/ButtonV2";
import SelectV2 from "../../atoms/SelectV2";
import TextFieldV2 from "../../atoms/TextFieldV2";
import ChipsV2, { ChipItem } from "../../molecules/Chips/ChipsV2";
import TableV2, { ColumnType } from "../../organisms/TableV2";
import { CATEGORY_TYPE, CUSTOMER_GROUP_SECTIONS, HTTP_STATUS, IPagination } from "../../../utils/types";
import { ADMIN_ROUTES, businessClassificationOptions, cityOptions, importExportOptions, industryOptionsV2, initialPaginationValues, turnOverOptions } from "../../../utils/constant";
import { ICustomerQueryParams, useCustomerGroupMasterService } from "../../../services/useCustomerGroupMasterService";
import AutoCompleteTextField, { AutoCompleteOption } from '../../molecules/AutoCompleteInput/AutoCompleteInputV2';
import { changeSectionTo } from "./AddCustomerGroup.template";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { getProfileDisplayData } from "../../../utils/helper";
import { ICategory } from "../../pages/AdminMaster/Brand/BrandList.page";
import { useCategoryService } from "../../../services/useCategoryService";

interface AdditionalCustomerFormTemplateProps {
    setCurrentSectionTo: (section: CUSTOMER_GROUP_SECTIONS) => void;
    formik: any
}

export interface ICustomer {
    city: string;
    businessClassification: string;
    turnOver: string;
    businessType: string;
    customerId: number;
    customerName: string;
    companyName: string;
}

export interface ICutomerSearchFilter {
    sort: string;
    customerName: string;
}

const initialCutomerSearchFiltersValues: ICutomerSearchFilter = {
    sort: "createdAt,desc",
    customerName: "",
}


const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid #EBEFFA`
    },
    filterContainer: {
        borderTop: `1px solid #EBEFFA`
    },
    filterHeading: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    selectLabel: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    configure: {
        color: theme.palette.textV2.primary.primary500,
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600,
    },
    activeProgressBar: {
        backgroundColor: "#CA4F02"
    },
    inActiveProgressBar: {
        backgroundColor: "#FD6202"
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
    errorMessage: {
        color: theme.palette.textV2.failure.failure600,
    },
}));

const AdditionalCustomerFormTemplate: React.FC<AdditionalCustomerFormTemplateProps> = ({ setCurrentSectionTo, formik }) => {
    const classes = useStyles();
    const navigate = useNavigate()
    const customerGroupService = useCustomerGroupMasterService()
    const [customers, setCustomersTo] = useState<ICustomer[]>([]);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [cutomerSearchFilters, setCutomerSearchFiltersTo] = useState<ICutomerSearchFilter>(initialCutomerSearchFiltersValues);
    const [showTable, setShowTable] = useState<boolean>(false)
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const refreshCustomer = async (page: number, size: number) => {
        try {
            const payload: ICustomerQueryParams = {
                city: formik.values?.additionalCustomerCityArray?.length > 0 ? formik.values?.additionalCustomerCityArray.map((city: any) => city.id) : null,
                businessClassification: formik.values?.additionalCustomerBusinessClassificationArray?.length > 0 ? formik.values?.additionalCustomerBusinessClassificationArray.map((businessClassification: any) => businessClassification.id) : null,
                turnOver: formik.values?.additionalCustomerTurnOverArray?.length > 0 ? formik.values?.additionalCustomerTurnOverArray.map((turnOver: any) => turnOver.id) : null,
                businessType: formik.values?.additionalCustomerImportExportArray?.length > 0 ? formik.values?.additionalCustomerImportExportArray.map((businessType: any) => businessType.id) : null,
                upcCodes: null,
                productCategoryIds: formik.values?.additionalCustomerProductCategoryArray?.length > 0 ? formik.values?.additionalCustomerProductCategoryArray.map((productCategory: any) => productCategory.id) : null,
                industryType: formik?.values?.additionalCustomerIndustryTypeArray?.length > 0 ? formik.values?.additionalCustomerIndustryTypeArray.map((industryType: any) => industryType.id) : null,
            };
            const customerGroupMasterResponse = await customerGroupService.getCustomer({
                page: page,
                size: size,
                sort: "createdAt,desc",
                customerName: cutomerSearchFilters?.customerName.trim()
            },
                payload
            );
            if (
                customerGroupMasterResponse?.status === HTTP_STATUS.OK &&
                customerGroupMasterResponse?.data?.data?.content?.length
            ) {
                setCustomersTo(customerGroupMasterResponse.data.data.content);
                setPaginationTo((prevPagination: IPagination) => ({
                    ...prevPagination,
                    totalRecords: customerGroupMasterResponse.data.data.totalElements,
                    totalPages: customerGroupMasterResponse.data.data.totalPages,
                }));
            } else {
                setCustomersTo([]);
            }
        } catch (error) {
            showSnackbar("error", "Customers fetch failed");
            setCustomersTo([]);
        }
    };

    const handleFiltersChange = (event: any) => {
        const { name, value } = event.target;
        setPaginationTo({ ...pagination, currentPage: 0 })
        setCutomerSearchFiltersTo({ ...cutomerSearchFilters, [name]: value ?? "" });
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    useEffect(() => {
        refreshCustomer(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize, cutomerSearchFilters.customerName]);

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination?.totalRecords,
            currentPage: pagination?.currentPage,
            isVisible: true,
            limit: pagination?.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Customer Name- ID", key: "name", type: "custom" as ColumnType, props: { className: '' } },
            { label: "Company Name", key: "companyName", type: "string" as ColumnType, props: { className: '' } },
            { label: "Location", key: "city", type: "string" as ColumnType, props: { className: '' } },
            { label: "Business Classification", key: "businessClassification", type: "string" as ColumnType, props: { className: '' } },
            { label: "Turnover", key: "turnover", type: "string" as ColumnType, props: { className: '' } },
            { label: "Select Customer", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ],
    };

    const handleCheckBoxChange = (name: string, id: number) => () => {
        const isAlreadySelected = formik.values.additionalCustomers.some((selected: any) => selected.label === name && selected.id === id) || formik.values.selectedCustomer.some((selected: any) => selected.label === name && selected.id === id);
        if (isAlreadySelected) {
            const newSelectedCustomer = formik.values.selectedCustomer.filter((selected: any) => !(selected.label === name && selected.id === id));
            formik.setFieldValue("selectedCustomer", newSelectedCustomer);
            const newAdditionalCustomers = formik.values.additionalCustomers.filter((selected: any) => !(selected.label === name && selected.id === id));
            formik.setFieldValue("additionalCustomers", newAdditionalCustomers);
            const newCustomersFromUpcs = formik.values.customersFromUpcs.filter((selected: any) => !(selected.label === name && selected.id === id));
            formik.setFieldValue("customersFromUpcs", newCustomersFromUpcs);
        } else {
            formik.setFieldValue("selectedCustomer", [...formik.values.selectedCustomer, { label: name, id: id }]);
            formik.setFieldValue("additionalCustomers", [...formik.values.additionalCustomers, { label: name, id: id }]);
        }
    }

    const handleCustomerChipDelete = (value: ChipItem) => {
        formik.setFieldValue("selectedCustomer", formik.values.selectedCustomer.filter((customer: any) => customer.id !== value.id));
        formik.setFieldValue("additionalCustomers", formik.values.additionalCustomers.filter((customer: any) => customer.id !== value.id));
    }

    const Action = (name: string, id: number) => {
        return (
            <div className={`flex items-center justify-center gap-x-3`}>
                <Checkbox
                    sx={{ padding: 0, color: "#C4C4C4" }}
                    checked={formik.values.additionalCustomers.some((selected: any) => selected.label === name && selected.id === id) || formik.values.selectedCustomer.some((selected: any) => selected.label === name && selected.id === id)}
                    onChange={handleCheckBoxChange(name, id)}
                />
            </div>
        );
    };

    const Name = (name: string, id: number) => {
        return (
            <div className={`flex items-center justify-center text-base`}>
                {name}-{id}
            </div>
        );
    }

    const records = customers?.map((customer: ICustomer, index: number) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        Name(customer?.customerName, customer?.customerId),
        getProfileDisplayData(customer?.companyName),
        getProfileDisplayData(customer?.city),
        getProfileDisplayData(customer?.businessClassification),
        getProfileDisplayData(customer?.turnOver),
        Action(customer?.customerName, customer?.customerId),
    ]);

    const handleSubmit = (event: React.FormEvent) => {
        refreshCustomer(pagination.currentPage, pagination.pageSize);
        setShowTable(true)
    };

    const categoryService = useCategoryService();
    const [productCategoryData, setProductCategoryData] = useState<ICategory[] | null>([]);

    const loadProductCategory = (inputValue?: string) => {
        categoryService.getAllCategories({ level: CATEGORY_TYPE.PRODUCT_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: '', name: inputValue ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setProductCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    useEffect(() => {
        loadProductCategory();
    }, []);

    const productCategoryOptions = useMemo(() => {
        return productCategoryData?.map((category: any) => ({ label: category.name, id: category.id })) ?? []
    }, [productCategoryData])

    const updateSelectedFilters = (formik: any, key: string) => (event: any) => {
        const selectedOption = formik.values.additionalCustomerOptions.find((option: any) => option.value === event.target.value);
        const value = selectedOption.value;
        const label = selectedOption.label;
        formik.setFieldValue(key, label);
        if (value && label) {
            const filterExists = formik.values.additionalCustomerFilters.some((filter: any) => filter.value === value);
            if (!filterExists) {
                formik.setFieldValue("additionalCustomerFilters", [...formik.values.additionalCustomerFilters, { value: value, label: label }]);
            }
        }
    };

    const handleChipDelete = (value: any) => {
        formik.setFieldValue("additionalCustomerFilters", formik.values.additionalCustomerFilters.filter((e: any) => e.label !== value.label));
        formik.setFieldValue(`${value.value}Array`, []);
    }

    const optionsMap: { [key: string]: any[] } = {
        additionalCustomerCity: cityOptions,
        additionalCustomerBusinessClassification: businessClassificationOptions,
        additionalCustomerTurnOver: turnOverOptions,
        additionalCustomerImportExport: importExportOptions,
        additionalCustomerProductCategory: productCategoryOptions,
        additionalCustomerIndustryType: industryOptionsV2
    };

    const updateSelected = (key: string) => (option: AutoCompleteOption | null) => {
        if (option) {
            const filterExists = formik.values[`${key}Array`].some((groupId: any) => groupId.id === option?.id);
            if (!filterExists) {
                formik.setFieldValue(`${key}Array`, [...formik.values[`${key}Array`], option]);
            }
        }
        formik.setFieldValue(key, { label: "", id: "" });
    };

    const handleFilterChipDelete = (value: ChipItem, key: string) => {
        formik.setFieldValue(`${key}Array`, formik.values[`${key}Array`].filter((e: any) => e.id !== value.id));
    }

    const handleReset = () => {
        formik.resetForm();
        setShowTable(false)
    }

    const handleBack = () => {
        navigate(`${ADMIN_ROUTES.CUSTOMER_GROUP_MASTER_LIST}`);
    };

    const handleResetAll = () => {
        const fields = [
            "additionalCustomerFilters",
            "additionalCustomerCityArray",
            "additionalCustomerTurnOverArray",
            "additionalCustomerBusinessClassificationArray",
            "additionalCustomerImportExportArray",
            "additionalCustomerProductCategoryArray"
        ];

        fields.forEach(field => formik.setFieldValue(field, []));
    }

    return (
        <div className={`${classes.container} p-6 grid gap-y-5 rounded-lg`}>
            <div className="flex justify-between">
                <div className={`${classes.filterHeading} text-2xl font-medium my-auto`}>Add Customer</div>
            </div>

            <div className={`${classes.filterContainer} pt-5 `}>
                <div className={`${classes.container} rounded-lg grid gap-y-6 p-6`}>
                    <div className="grid gap-y-6">
                        <div className={`${classes.filterHeading} text-base font-medium my-auto`}>For add on filter use other filter</div>
                        <div className="grid gap-y-2 h-fit">
                                <div className="grid gap-y-1 w-1/3">
                                    <SelectV2
                                        variant="outlined"
                                        label="Add Other Filter"
                                        fullWidth
                                        value={""}
                                        onChange={updateSelectedFilters(formik, 'otherFilter')}
                                        options={formik.values.additionalCustomerOptions}
                                    />
                                </div>
                                {
                                    formik.values.additionalCustomerFilters.length > 0 &&
                                    <ChipsV2 values={formik.values.additionalCustomerFilters ?? []} onDelete={handleChipDelete} />
                                }
                            </div>

                        {
                            formik.values.additionalCustomerFilters.length > 0 &&
                            <div className="grid grid-cols-3 gap-x-6 gap-y-6">
                                {
                                    formik.values.additionalCustomerFilters.map((filter: { value: string, label: string }) => (
                                        <div className="grid gap-y-2  h-fit">
                                            <FormControl fullWidth className={classes.input}>
                                                <AutoCompleteTextField
                                                    options={optionsMap[filter.value]}
                                                    label={`Select ${filter.label}`}
                                                    onChange={updateSelected(filter.value)}
                                                    onSearch={loadProductCategory}
                                                    value={formik.values[filter.value]}
                                                    isDisabled={false}
                                                    placeHolder={filter.label}
                                                />
                                            </FormControl>
                                            <ChipsV2 values={formik.values[`${filter.value}Array`] ?? []} onDelete={(value) => handleFilterChipDelete(value, filter.value)}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>

                    <div className="flex justify-end">
                        <div className="flex gap-x-5">
                            <ButtonV2 variant="tertiaryContained" size="large" label={"Reset"} onClick={handleReset} />
                            <ButtonV2 variant="primaryContained" size="large" label={"Search"} onClick={handleSubmit} />
                        </div>
                    </div>

                    {showTable &&
                        <div className={`${classes.filterContainer} pt-5 grid gap-y-6`}>
                            <div className="flex justify-between">
                                <div className={`${classes.filterHeading} text-base font-medium my-auto`}>Customer list as per selected UPC</div>
                                <div className="w-80">
                                    <TextFieldV2
                                        className="w-80"
                                        placeholder="Search by Customer Name"
                                        variant="outlined"
                                        type="text"
                                        name="customerName"
                                        value={cutomerSearchFilters.customerName}
                                        onChange={handleFiltersChange}
                                    />
                                </div>
                            </div>

                            <ChipsV2 values={formik.values.additionalCustomers ?? []} onDelete={handleCustomerChipDelete} />

                            <div>
                                <TableV2 schema={schema} records={records} />
                            </div>
                        </div>
                    }
                </div>
            </div>




            <div className="flex justify-between">
                <ButtonV2 variant="secondaryContained" size="large" label={"Cancel"} onClick={handleBack} />
                <div className="flex gap-x-5">
                    <ButtonV2 variant="tertiaryContained" size="large" label={"Back"} onClick={changeSectionTo(setCurrentSectionTo)(CUSTOMER_GROUP_SECTIONS.CUSTOMER_GROUP_CONFIGURATION_FORM)} />
                    <ButtonV2 variant="tertiaryContained" size="large" label={"Reset All"} onClick={handleResetAll} />
                    <ButtonV2 variant="primaryContained" size="large" label={"Review"} onClick={changeSectionTo(setCurrentSectionTo)(CUSTOMER_GROUP_SECTIONS.CUSTOMER_GROUP_PREVIEW)} />
                </div>
            </div>

        </div>

    );
};

export default AdditionalCustomerFormTemplate;