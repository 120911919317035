import React from 'react'
import { createUseStyles } from 'react-jss';
import ButtonV2 from '../../atoms/ButtonV2';
import { getShapeName } from '../../../utils/helper';
import { IRfq } from '../../../services/useRfqService';

interface RfqViewTemplateProps {
    rfq: IRfq,
    onBack: () => void
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    property: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    value: {
        color: theme.palette.textV2.primary.primary950
    },
}));

const RfqViewTemplate: React.FC<RfqViewTemplateProps> = ({ onBack, rfq }) => {
    const classes = useStyles();

    return (
        <div className="grid gap-y-8" >
            <div className='flex justify-between'>
                <div className={`${classes.mainHeading} text-lg font-semibold my-auto`}>Rfq: {rfq?.id}</div>
            </div>

            <div className='grid gap-y-4'>
                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Basic Information</div>
                    <div className='grid grid-cols-5 gap-x-6 gap-y-3'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Product Category</div>
                            <div className={`${classes.heading} text-base font-medium`}>{rfq?.productCategory}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Grade</div>
                            <div className={`${classes.heading} text-base font-medium`}>{rfq?.gradeValue}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Shape</div>
                            <div className={`${classes.heading} text-base font-medium`}>{getShapeName(rfq?.shape)}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Expected Delivery Date</div>
                            <div className={`${classes.heading} text-base font-medium`}>{rfq?.expectedDeliveryDate}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Payment Term</div>
                            <div className={`${classes.heading} text-base font-medium`}>{rfq?.paymentTerms}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Customer Name</div>
                            <div className={`${classes.heading} text-base font-medium`}>{rfq?.userDetails?.user?.fullName}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Manufacturer</div>
                            <div className={`${classes.heading} text-base font-medium`}>{rfq?.manufacturerName}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Standard</div>
                            <div className={`${classes.heading} text-base font-medium`}>{rfq?.standard}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Required Quantity</div>
                            <div className={`${classes.heading} text-base font-medium`}>{rfq?.requiredQuantity} MT</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Specific Requirement</div>
                            <div className={`${classes.heading} text-base font-medium`}>{rfq?.specificRequirements}</div>
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Attributes</div>
                    <div className='grid grid-cols-5 gap-y-3'>
                        {
                            rfq?.attributes?.map((attribute: any, key: any) => (
                                <div key={key} className="grid gap-y-1">
                                    <div className={`${classes.property}`}>{attribute?.name}   {attribute.uom && (
                                        <span> ({attribute.uom})</span>
                                    )}</div>
                                    <div className={`${classes.heading} text-base font-medium`}>{attribute?.value}</div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-4'>
                    <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl h-fit`}>
                        <div className={`${classes.heading} text-base font-medium`}>User Details</div>
                        <div className='grid grid-cols-2 gap-x-6 gap-y-3'>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Full Name</div>
                                <div className={`${classes.heading} text-base font-medium`}>{rfq?.userDetails?.user?.fullName}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Company Name</div>
                                <div className={`${classes.heading} text-base font-medium`}>{rfq?.userDetails?.name}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>GST</div>
                                <div className={`${classes.heading} text-base font-medium`}>{rfq?.userDetails?.gstin}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Email</div>
                                <div className={`${classes.heading} text-base font-medium`}>{rfq?.userDetails?.user?.email}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Phone Number</div>
                                <div className={`${classes.heading} text-base font-medium`}>{rfq?.userDetails?.user?.mobileNumber}</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                        <div className={`${classes.heading} text-base font-medium`}>Address Details</div>
                        <div className='grid grid-cols-2 gap-x-6 gap-y-3'>
                            <div className="grid gap-y-1 h-fit">
                                <div className={`${classes.property} h-fit`}>Address Line 1</div>
                                <div className={`${classes.heading} text-base font-medium`}>{rfq?.userDetails?.address?.line1}</div>
                            </div>
                            <div className="grid gap-y-1 h-fit ">
                                <div className={`${classes.property} h-fit`}>Address Line 2</div>
                                <div className={`${classes.heading} text-base font-medium truncate h-fit`}>{rfq?.userDetails?.address?.line2}</div>
                            </div>

                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Pin Code</div>
                                <div className={`${classes.heading} text-base font-medium`}>{rfq?.userDetails?.address?.pincode}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>City</div>
                                <div className={`${classes.heading} text-base font-medium`}>{rfq?.userDetails?.address?.city}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Country</div>
                                <div className={`${classes.heading} text-base font-medium`}>{rfq?.userDetails?.address?.country}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-4">
                <ButtonV2 variant="tertiaryContained" label='Back' onClick={onBack} />
            </div>
        </div>
    )
}

export default RfqViewTemplate;