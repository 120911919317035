import { Route, Routes } from "react-router"
import PostProductRoutes from "./PostProductRoutes";

const SteelBazaarRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path="product/*" element={<PostProductRoutes />} />
            </Routes>
        </div>
    )
}

export default SteelBazaarRoutes;
