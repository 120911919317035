import CloseIcon from '../../../assets/images/cross.svg';
import React, { useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import AutoCompleteTextField from "../../../App/Pages/AdminMaster/Product Standard/Standard/AutoCompleteInputField";
import debounce from 'lodash/debounce';
import { toCamelCase } from "../../../utils/helper";
import { createUseStyles } from "react-jss";
import moment from "moment";
import { MODE, uomMapping } from "../../../utils/constant";
import Dialog from '@mui/material/Dialog';
import TextFieldV2 from "../../atoms/TextFieldV2";
import ButtonV2 from "../../atoms/ButtonV2";

interface ChemicalCompositionFormTemplate {
    type: string;
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    record: any;
    handleAction: any;
    associatedGrade: any;
    fullData: any;
}

const useStyles = createUseStyles((theme: any) => ({
    mainContainer: {
        "& .MuiDialog-paper": {
            width: "917px !important",
            maxWidth: "917px !important",
            borderRadius: "16px"
        }
    },
    container: {
        padding: "24px"
    },
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    label: {
        color: theme.palette.textV2.tertiary.tertiary600,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "16.1px"
    },
}));

const MechanicalPropertiesFormTemplate: React.FC<ChemicalCompositionFormTemplate> = ({ type, dialogOpen, setDialogOpen, record, handleAction, associatedGrade = null, fullData = [] }) => {
    const classes = useStyles();
    const maxId = fullData.reduce((max: any, obj: any) => (obj.id > max ? obj.id : max), 0);

    const validateFormikFields = yup.object({
        name: yup.string().required("Name is required"),
        uom: yup.string().required("UOM is required"),
        roomTemperature: yup.number().typeError("Room Temperature must be a number").required("Room Temperature is required"),
        otherTemperature: yup.number().typeError("Other Temperature must be a number").required("Other Temperature is required"),
    });

    const mpFormik = useFormik({
        initialValues: {
            type: "MECHANICAL",
            id: record ? record?.id : maxId + 1,
            name: record ? record.name : "",
            uom: record ? record.uom : "",
            roomTemperature: record ? record.roomTemperature : "",
            otherTemperature: record ? record.otherTemperature : "",
            createdAt: record?.createdAt || moment().format('YYYY-MM-DD'),
            updatedAt: moment().format('YYYY-MM-DD'),
        },
        validationSchema: validateFormikFields,
        onSubmit: (values) => {
            handleAction(values, values?.id);
        },
    });

    useEffect(() => {
        mpFormik.setValues({
            type: "MECHANICAL",
            id: record ? record?.id : maxId + 1,
            name: record ? record.name : "",
            uom: record ? record.uom : "",
            roomTemperature: record ? record.roomTemperature : "",
            otherTemperature: record ? record.otherTemperature : "",
            createdAt: record?.createdAt || moment().format('YYYY-MM-DD'),
            updatedAt: moment().format('YYYY-MM-DD'),
        });
    }, [record, dialogOpen]);

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const fetchUOMData = async () => {
        try {
            const convertedDetails: any = convertUOMSuggestions(uomMapping)
            setUOMSuggestions(convertedDetails);
        } catch (error) {
            setUOMSuggestions([]);
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchUOMData()
    }, []);

    const convertUOMSuggestions = (suggestions: any) => {
        return Object.keys(suggestions).map((key, index) => ({
            label: key,
            id: index + 1,
            shortcode: suggestions[key].shortcode,
            fullDescription: suggestions[key].fullDescription,
        }));
    };


    const updateUOM = (uom: any) => {
        mpFormik.setFieldValue('uom', uom.label);
    }

    const [UOMSuggestions, setUOMSuggestions] = useState([]);
    const delayedSearchUOMFetch = debounce((value) => {
        fetchUOMData();
    }, 700);

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <Dialog fullWidth className={classes.mainContainer} open={dialogOpen} onClose={handleDialogClose}>
            <form onSubmit={mpFormik.handleSubmit} onKeyDown={handleKeyDown} className="grid p-6 gap-y-6">
                <div className="flex justify-between pb-3 border-b border-solid border-input-border">
                    <div className={`${classes.mainHeading} text-lg font-semibold`}>{type === MODE.EDIT ? "Edit" : type === MODE.VIEW ? "View" : "Add New"} Element</div>
                    <img src={CloseIcon} alt="icon" className='w-6 h-6 cursor-pointer' onClick={handleDialogClose} />
                </div>
                <div className="grid gap-y-4">
                    <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                        <div className={`${classes.heading} text-base font-medium`}>Element Details</div>
                        <div className='grid gap-y-3'>
                            <TextFieldV2
                                label="Grade"
                                fullWidth
                                value={associatedGrade.name}
                                disabled={true}
                            />
                            <div className='grid grid-cols-2 gap-x-4'>
                                <TextFieldV2
                                    label="Property Name"
                                    placeholder='Enter Property Name'
                                    fullWidth
                                    name="name"
                                    value={mpFormik.values.name}
                                    onChange={mpFormik.handleChange}
                                    onBlur={(event) => {
                                        const camelCasedValue = toCamelCase(
                                            event.target.value
                                        );
                                        mpFormik.setFieldValue("name", camelCasedValue);
                                        mpFormik.handleBlur(event);
                                    }}
                                    error={mpFormik.touched.name && Boolean(mpFormik.errors.name)}
                                    InputProps={{ readOnly: type === MODE.VIEW }}
                                />

                                <div className='grid gap-y-1'>
                                    <div className={classes.label}>UOM</div>
                                    <FormControl fullWidth>
                                        <AutoCompleteTextField
                                            suggestions={UOMSuggestions}
                                            updateDataSelection={updateUOM}
                                            onSearch={delayedSearchUOMFetch}
                                            textLable={"Select UOM"}
                                            enableFlag={type === MODE.VIEW}
                                            displayValue={mpFormik.values.uom}
                                        />
                                    </FormControl>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                        <div className={`${classes.heading} text-base font-medium`}>Temperature</div>
                        <div className='grid grid-cols-2 gap-x-4'>
                            <TextFieldV2
                                label="Room Temperature (0 - 9999.99)"
                                placeholder="Enter Room Temperature (0 - 9999.99)"
                                fullWidth
                                name="roomTemperature"
                                value={mpFormik.values.roomTemperature}
                                onChange={(event) => {
                                    if (/^\d{0,4}(\.\d{0,2})?$/.test(event.target.value)) {
                                        mpFormik.handleChange(event);
                                    } else {
                                        mpFormik.setErrors({
                                            ...mpFormik.errors,
                                            [event.target.name]: 'Invalid format',
                                        });
                                    }
                                }}
                                helperText={ mpFormik.touched.roomTemperature ? mpFormik.errors.roomTemperature as any : "" }
                                onBlur={mpFormik.handleBlur}
                                error={ mpFormik.touched.roomTemperature && Boolean(mpFormik.errors.roomTemperature) }
                                InputProps={{ readOnly: type === MODE.VIEW }}
                            />

                            <TextFieldV2
                                label="Other Temperature  (0 - 9999.99)"
                                placeholder="Enter Other Temperature  (0 - 9999.99)"
                                fullWidth
                                name="otherTemperature"
                                value={mpFormik.values.otherTemperature}
                                onChange={(event) => {
                                    if (/^\d{0,4}(\.\d{0,2})?$/.test(event.target.value)) {
                                        mpFormik.handleChange(event);
                                    } else {
                                        mpFormik.setErrors({
                                            ...mpFormik.errors,
                                            [event.target.name]: 'Invalid format',
                                        });
                                    }
                                }}
                                helperText={ mpFormik.touched.otherTemperature ? mpFormik.errors.otherTemperature as any : "" }
                                onBlur={mpFormik.handleBlur}
                                error={ mpFormik.touched.otherTemperature && Boolean(mpFormik.errors.otherTemperature) }
                                InputProps={{ readOnly: type === MODE.VIEW }}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex justify-end gap-x-3">
                    <ButtonV2 variant="tertiaryContained" onClick={handleDialogClose} label={"Cancel"} />
                    {type !== "VIEW" && (<ButtonV2 variant="secondaryContained" type="submit" label={type === "ADD" ? "Add Element" : "Update Element"} />)}
                </div>
            </form>
        </Dialog >
    );
};

export default MechanicalPropertiesFormTemplate;