import { Route, Routes } from 'react-router-dom';
import GradeListPage from '../../../components/pages/Grade/GradeList.page';
import GradeCreatePage from '../../../components/pages/Grade/GradeCreate.page';
import GradeUpdatePage from '../../../components/pages/Grade/GradeUpdate.page';
import GradeViewPage from '../../../components/pages/Grade/GradeView.page';

const GradeRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path="" element={<GradeListPage />} />
                <Route path='/add' element={<GradeCreatePage />} />
                <Route path='/:id/update' element={<GradeUpdatePage />} />
                <Route path='/:id' element={<GradeViewPage />} />
            </Routes>
        </div>
    )
}

export default GradeRoutes;