import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../utils/types';
import { ADMIN_ROUTES, EXCEPTION_CODE, MODE, STATUS } from '../../../utils/constant';
import { useQualtiyGradeService } from '../../../services/useQualityGradesService';
import GradeFormTemplate from '../../templates/Grade/GradeForm.template';

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    standard: Yup.object().shape({
        id: Yup.number().required('Standard is required'),
        label: Yup.string().required('Standard is required')
    }).required('Standard is required'),
});

const GradeCreatePage: React.FC = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const qualtiyGradeService = useQualtiyGradeService();
    const navigate = useNavigate();

    const onBack = () => {
        navigate(ADMIN_ROUTES.GRADE_LIST);
    }

    const createGrade = async (grade: any) => {
         const qualityGradeRequestBody: any = {
            name: grade.name.trim(),
            prefix: grade.prefix,
            standardId: grade.standardId,
            bisHeader: "Bis Header",
            type: grade.type,
            status: grade.status ? 'ACTIVE' : 'INACTIVE',
            chemicalComposition: JSON.stringify(grade.chemicalComposition),
            mechanicalProperties: JSON.stringify(grade.mechanicalProperties),
            tensileProperties: JSON.stringify(grade.tensileProperties),
         }
         qualtiyGradeService.createQualityGrade(qualityGradeRequestBody)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "New Quality Grade Created");
                    onBack();
                } else if (res.data.exceptionCode === EXCEPTION_CODE.DUPLICATE_ENTRY) {
                    showSnackbar('error', "Quality Grade already exists. Please try again");
                }
            }).catch((error: any) => {
                showSnackbar('error', "Failed to create Quality Grade");
            })
    }

    const formik = useFormik<any>({
        initialValues: {
            name: '',
            prefix: '',
            standard: {
                label: "",
                id: ""
            },
            standardId: null,
            bisHeader: "Bis Header",
            type: 'GRADE',
            status: STATUS.ACTIVE,
            chemicalComposition: [],
            mechanicalProperties: [],
            tensileProperties: [],
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            createGrade({ ...values, status: values.status })
        }
    })

    return (
        <div>
            {SnackBarComponent}
            <GradeFormTemplate mode={MODE.ADD} onBack={onBack} formik={formik} />
        </div>
    )
}

export default GradeCreatePage;