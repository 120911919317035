import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { CATALOGUE_BUNDLING_ROUTES } from '../../../../App/Core/Routes/CatalogueBundlingRoutes';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { EXCEPTION_CODE, MODE, PRODUCT_SECTION, STATUS } from '../../../../utils/constant';
import { makeRoute } from '../../../../utils/helper';
import { HTTP_STATUS } from '../../../../utils/types';
import { IBundleFormikPayload, IBundleRequestPayload, useCatalogueBundlingService } from '../../../../services/useCatalogueBundlingService';
import BundleFormTemplate from '../../../templates/CatalogueBundling/Bundle/BundleForm.template';
import BundleDetailFormTemplate, { IProductDetails } from '../../../templates/CatalogueBundling/Bundle/BundleDetailForm.template';
import ProductFormTemplate from '../../../templates/CatalogueBundling/Bundle/ProductForm.template';
import ReviewFormTemplate from '../../../templates/CatalogueBundling/Bundle/ReviewForm.template';
import moment from 'moment';
import { useMasterUpcService } from '../../../../services/useMasterUpcService';
import { IProductSearchParams, useGenericSearchService } from '../../../../services/useGenericSearchService';

export enum BUNDLE_FORM_STATE {
    BUNDLE_DETAILS = 1,
    EDIT_PRODUCTS = 2,
    REVIEW = 3
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
        lineHeight: "30px"
    },
    desc: {
        color: theme.palette.textV2.tertiary.tertiary700,
        lineHeight: "14px"
    }
}));

export const productSchema = Yup.object().shape({
    bundleMoq: Yup.number().moreThan(0, "Bundle MOQ should be more than zero").required('Bundle MOQ is required'),
});

export const validationSchema = Yup.object().shape({
    name: Yup.string().max(30, "Bundle Name cannot be more than 30 characters").required("Bundle Name is required"),
    moq: Yup.number().moreThan(0, "Minimum Order Quantity should be more than zero").required("Minimum Order Quantity is required"),
    price: Yup.number().moreThan(0, "Price should be more than zero").required("Price is required"),
    startDate: Yup.date().required('Start Date is required'),
    expiryDate: Yup.date().min(Yup.ref('startDate'), 'Expiry Date must be after Start Date').required('Expiry Date is required').nullable(),
    productDetails: Yup.array().of(productSchema),
    packagingType: Yup.string().required("Packaging Type is required"),
    deliveryTerm: Yup.string().required("Delivery Term  is required"),
});

const BundleUpdatePage: React.FC = () => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const catalogueBundlingService = useCatalogueBundlingService();
    const masterUpcService = useMasterUpcService();
    const [bundle, setBundle] = useState<any | null>(null)
    const navigate = useNavigate();
    const params = useParams();

    const [formState, setFormState] = useState<BUNDLE_FORM_STATE>(1);

    const loadBundle = async () => {
        catalogueBundlingService.getBundleById(Number(params.id))
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    setBundle(res.data.data)
                }
            }).catch((error) => {
                setBundle(null);
                console.error("Error Fetching Catalogue Bundle: ", error);
                showSnackbar('error', "Error while fetching Catalogue Bundle data");
            })
    }

    useEffect(() => {
        loadBundle()
    }, [Number(params.id)])

    const genericSearchService = useGenericSearchService();

    const refreshProducts = () => {
        if (bundle?.referenceDetails && bundle.referenceDetails.length > 0 && formik?.values?.warehouseId) {
            const productDetailsMap = new Map<number, any>(bundle.referenceDetails.map((ref: { productId: number }) => [ref.productId, ref]));
            
            const productIds = Array.from(productDetailsMap.keys());
    
            let params: IProductSearchParams = {
                page: 0,
                size: 100,
                sort: 'createdAt,desc',
                sectionType: PRODUCT_SECTION.BRAND_UPC,
            };
    
            genericSearchService.searchProduct(params, 
                {
                    productCode: productIds?.length ? productIds : null,
                    warehouseId: formik.values?.warehouseId,
                    isCataloguesPresent: true,
                    getStockDetails: true,
                })
                .then((response) => {
                    if (response.data.data && response.status === HTTP_STATUS.OK) {
                        const newProducts = response.data.data.content
                            .filter((product: any) => productIds.includes(product.productId))
                            .map((product: any) => {
                                const refDetail = productDetailsMap.get(product.productId);
                                return {
                                    productId: product.productId,
                                    productName: product.title,
                                    productCode: product.productCode,
                                    moq: product.stockDetails?.minimumOrderQuantity || 0,
                                    availableStock: product.stockDetails?.stock || 0,
                                    price: product.stockDetails?.productPrice || 0,
                                    bundleMoq: refDetail?.moq || null, 
                                    classType: refDetail?.classType || "",
                                };
                            });
    
                        const updatedProductDetails = [
                            ...formik.values.productDetails,
                            ...newProducts.filter((newProduct: any) =>
                                !formik.values.productDetails.some(
                                    (existingProduct: any) =>
                                        existingProduct.productId === newProduct.productId
                                )
                            ),
                        ];
    
                        formik.setFieldValue('productDetails', updatedProductDetails);
                    }
                })
                .catch((error) => {
                    console.error("Product fetching error - ", error);
                });
        }
    };
    
    const onClose = () => {
        navigate(makeRoute(CATALOGUE_BUNDLING_ROUTES.CATALOGUE_BUNDLING_LIST, { query: { type: 'CATALOGUE_BUNDLE' } }));
    }

    const updateBundle = async (requestBody: IBundleFormikPayload) => {
        const bundleRequestBody: IBundleRequestPayload = {
            name: requestBody?.name,
            referenceDetails: requestBody?.productDetails?.map((product: any) => ({
                productId: product?.productId,
                classType: product?.classType,
                moq: product?.bundleMoq,
            })),
            type: "BUNDLE",
            moq: requestBody?.moq,
            price: requestBody?.price,
            startDate: moment(requestBody?.startDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss"),
            expiryDate: moment(requestBody?.expiryDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss"),
            status: requestBody?.status,
            packagingType: requestBody?.packagingType,
            deliveryTerm: requestBody?.deliveryTerm,
            warehouseId: requestBody?.warehouseId
        }
        catalogueBundlingService.updateBundle(Number(params.id), bundleRequestBody)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "Catalogue Bundle Updated");
                    onClose();
                } else if (res.data.exceptionCode === EXCEPTION_CODE.DUPLICATE_ENTRY) {
                    showSnackbar('error', "Catalogue Bundle already exists. Please try again");
                }
            }).catch((error) => {
                showSnackbar('error', "Failed to update Catalogue Bundle");
            })
    }

    const formik = useFormik<IBundleFormikPayload>({
        initialValues: {
            name: bundle?.name ?? "",
            bundleCode: bundle?.bundleCode ?? "",
            moq: bundle?.moq ?? null,
            price: bundle?.price ?? null,
            startDate: moment(bundle?.startDate, 'ddd MMM DD HH:mm:ss Z YYYY').format('YYYY-MM-DD HH:mm:ss') ?? "",
            expiryDate: moment(bundle?.expiryDate, 'ddd MMM DD HH:mm:ss Z YYYY').format('YYYY-MM-DD HH:mm:ss') ?? "",
            productDetails: [],
            status: bundle?.status ?? STATUS.ACTIVE,
            packagingType: bundle?.packagingType ?? "",
            deliveryTerm: bundle?.deliveryTerm ?? "",
            warehouseId: bundle?.warehouse?.id ?? null,
            warehouse: bundle?.warehouse?.name ?? ""
        },
        validationSchema,
        validateOnChange: true,
        validateOnMount: true,
        validateOnBlur: true,
        onSubmit: (values) => { updateBundle(values) }
    })

    useEffect(() => {
        formik.setValues({
            name: bundle?.name ?? "",
            bundleCode: bundle?.bundleCode ?? "",
            moq: bundle?.moq ?? null,
            price: bundle?.price ?? null,
            startDate: moment(bundle?.startDate, 'ddd MMM DD HH:mm:ss Z YYYY').format('YYYY-MM-DD HH:mm:ss') ?? "",
            expiryDate: moment(bundle?.expiryDate, 'ddd MMM DD HH:mm:ss Z YYYY').format('YYYY-MM-DD HH:mm:ss') ?? "",
            productDetails: [],
            status: bundle?.status ?? STATUS.ACTIVE,
            packagingType: bundle?.packagingType ?? "",
            deliveryTerm: bundle?.deliveryTerm ?? "",
            warehouseId: bundle?.warehouse?.id ?? null,
            warehouse: bundle?.warehouse?.name ?? ""
        });
    }, [bundle]);

    const setFormStateTo = (formState: BUNDLE_FORM_STATE) => () => {
        setFormState(formState)
    }

    const activeFormStep = useMemo(() => {
        const props = { mode: MODE.UPDATE, onClose, formik, setFormStateTo }
        switch (formState) {
            case BUNDLE_FORM_STATE.EDIT_PRODUCTS: return <ProductFormTemplate {...props} />;
            case BUNDLE_FORM_STATE.REVIEW: return <ReviewFormTemplate {...props} />;
            default: return <BundleDetailFormTemplate {...props} />;
        }
    }, [formState, formik])

    useEffect(() => {
        refreshProducts()
    }, [bundle, formik.values.warehouseId])

    return (
        <div>
            {SnackBarComponent}
            <div className="grid gap-y-6" >
                <div className='grid gap-y-2'>
                    <div className={`${classes.mainHeading} text-lg font-semibold`}>Update Bundle</div>
                    <div className={`${classes.desc} text-xs `}>The form will help you create a new product bundle by selecting and configuring product. Please fill out each section carefully.</div>
                </div>
                <BundleFormTemplate {...{ mode: MODE.UPDATE, onClose, component: activeFormStep }} />
            </div>
        </div>
    )
}

export default BundleUpdatePage;