import React, { useEffect, useMemo, useState } from 'react'
import ButtonV2 from '../../atoms/ButtonV2';
import CustomerListTemplate from '../../templates/Customer-Management/CustomerList.template';
import { CATEGORY_TYPE, HTTP_STATUS, IPagination } from '../../../utils/types';
import { ADMIN_ROUTES, cityOptions, industryOptions, industryOptionsV2, initialPaginationValues, turnOverOptions } from '../../../utils/constant';
import { ICustomerManagementQueryParams, useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import activeCustomers from '../../../assets/images/activeCustomers.svg'
import inactiveCustomers from '../../../assets/images/inactiveCustomers.svg'
import totalCustomers from '../../../assets/images/totalCustomers.svg'
import newCustomers from '../../../assets/images/newCustomers.svg'
import { Dayjs } from 'dayjs';
import StatusCard from '../../atoms/Cards/StatusCard';
import { useNavigate } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import GenericDateRangePicker from '../../molecules/DatePicker/CustomizedDatePicker';
import { useFormik } from "formik";
import { FormControl } from '@mui/material';
import AutoCompleteTextField, { AutoCompleteOption } from '../../molecules/AutoCompleteInput/AutoCompleteInputV2';
import ChipsV2, { ChipItem } from "../../molecules/Chips/ChipsV2";
import searchOutlinedIcon from "../../../assets/icons/searchOutlinedIcon.svg"
import { useCustomerGroupMasterService } from '../../../services/useCustomerGroupMasterService';
import { useCategoryService } from '../../../services/useCategoryService';
import { ICategory } from '../AdminMaster/Brand/BrandList.page';


const useStyles = createUseStyles((theme: any) => ({
    container: {
        borderBottom: `1px solid ${theme.palette.border.tritiaryLight}`
    },
    text: {
        color: theme.palette.textV2.primary.primary900
    },
    filterContainer: {
        border: `1px solid #EBEFFA`
    },
    filterHeading: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
}));

export interface ICustomerTemplate {
    id: number;
    customerID: string;
    companyName: string;
    customerType: string;
    location: string;
    relationshipManager: {
        fullName: string;
        designation: string | null;
        department: string | null;
    }[] | [];
    createdBy: string;
    status: string;
}

export interface ICustomerFilter {
    search: string;
    sort: string;
    startDate: string;
    endDate: string;
    customerType: string;
}

const initialCustomerFilterValues: ICustomerFilter = {
    search: "",
    sort: "createdAt,desc",
    startDate: '',
    endDate: '',
    customerType: ''
}

interface ICustomerCounts {
    totalUsers: number,
    activeUsers: number,
    inActiveUsers: number,
    newUsers: number,
}

const initialCustomerManagementFilters: ICustomerManagementQueryParams = {
    productCategoryIds: [],
    groupName: [],
    city: [],
    industry: [],
    turnOver: [],
};

interface IFilterForm {
    productCategoryArray: any[];
    productCategory?: {
        label: string;
        id: string;
    };
    groupNameArray: any[];
    groupName?: {
        label: string;
        id: string;
    };
    cityArray: any[];
    city?: {
        label: string;
        id: string;
    };
    industryArray: any[];
    industry?: {
        label: string;
        id: string;
    };
    turnOverArray: any[];
    turnOver?: {
        label: string;
        id: string;
    },
    [key: string]: any;
}

const CustomerListingPage: React.FC = () => {

    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [customers, setCustomersTo] = useState<ICustomerTemplate[]>([]);
    const [customerFilters, setCustomerFiltersTo] = useState<ICustomerFilter>(initialCustomerFilterValues);
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const businessProfileService = useBusinessProfileService();
    const navigate = useNavigate();
    const classes = useStyles();

    const [customerManagementFilters, setCustomerManagementFiltersTo] = useState<any>(initialCustomerManagementFilters);

    const [customerCount, setCustomerCountTo] = useState<ICustomerCounts>({
        totalUsers: 0,
        activeUsers: 0,
        inActiveUsers: 0,
        newUsers: 0,
    });

    const customerStatus = () => {
        const params = {
            startDate: customerFilters?.startDate,
            endDate: customerFilters?.endDate
        }
        businessProfileService.getCustomerStatus(params)
            .then((statusResponse) => {
                if (statusResponse?.data?.data) {
                    setCustomerCountTo(statusResponse?.data?.data)
                }
                else {
                    setCustomerCountTo(customerCount);
                }
            })
            .catch((error) => {
                setCustomerCountTo(customerCount);
                showSnackbar('error', 'Users fetch failed')
            });
    }

    const fetchProfile = () => {

        const payload: ICustomerManagementQueryParams = {
            productCategoryIds: customerManagementFilters?.productCategoryIds?.length > 0 ? customerManagementFilters?.productCategoryIds : null,
            groupName: customerManagementFilters?.groupName?.length > 0 ? customerManagementFilters?.groupName : null,
            city: customerManagementFilters?.city?.length > 0 ? customerManagementFilters?.city : null,
            industry: customerManagementFilters?.industry?.length > 0 ? customerManagementFilters?.industry : null,
            turnOver: customerManagementFilters?.turnOver?.length > 0 ? customerManagementFilters?.turnOver : null,
        };

        const params = {
            search: customerFilters?.search.trim(),
            page: pagination.currentPage,
            size: pagination.pageSize,
            customerType: customerFilters?.customerType,
            sort: customerFilters?.sort,
            startDate: customerFilters?.startDate,
            endDate: customerFilters?.endDate
        }
        businessProfileService.getAllCustomerV3(params, payload)
            .then((customerResponse) => {
                if (customerResponse?.data?.data) {
                    const { totalElements, totalPages, content } = customerResponse?.data?.data;
                    setPaginationTo({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setCustomersTo(content)
                }
                else {
                    setCustomersTo([]);
                }
            })
            .catch((error) => {
                setCustomersTo([]);
                showSnackbar('error', 'Users fetch failed')
            });
    }

    useEffect(() => {
        fetchProfile()
    }, [pagination.currentPage, pagination.pageSize, customerFilters.search, customerFilters.sort, customerFilters.startDate, customerFilters.endDate, customerManagementFilters])

    useEffect(() => {
        customerStatus()
    }, [customerFilters.startDate, customerFilters.endDate])


    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    const handleFiltersChange = (event: any) => {
        const { name, value } = event.target;
        setPaginationTo({ ...pagination, currentPage: 0 })
        setCustomerFiltersTo({ ...customerFilters, [name]: value ?? "" });
    }

    const handleDataFilter = (startDate: Dayjs, endDate: Dayjs) => {
        setCustomerFiltersTo((prevFilters: any) => ({
            ...prevFilters,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
        }));
    };

    const handleAddNewCustomers = () => {
        navigate(ADMIN_ROUTES.CUSTOMER_MANAGEMENT_CREATE)
    }

    const customStats = [
        {
            icon: totalCustomers,
            title: "Total Customers",
            value: customerCount.totalUsers ? customerCount.totalUsers : 0,
        },
        {
            icon: activeCustomers,
            title: "Active Customers",
            value: customerCount.activeUsers ? customerCount.activeUsers : 0,
        },
        {
            icon: inactiveCustomers,
            title: "Inactive Customers",
            value: customerCount.inActiveUsers ? customerCount.inActiveUsers : 0,
        },
        {
            icon: newCustomers,
            title: "New Customers",
            value: customerCount.newUsers ? customerCount.newUsers : 0,
        },
    ];

    const filter = [
        { label: "Product Category", value: "productCategory" },
        { label: "Group Name", value: "groupName" },
        { label: "City", value: "city" },
        { label: "Industry", value: "industry" },
        { label: "Turn Over", value: "turnOver" },
    ]

    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const toggleMoreFilters = () => {
        setShowMoreFilters(prevState => !prevState);
    };

    const initialFormValues: IFilterForm = {
        productCategoryArray: [],
        productCategory: {
            label: "",
            id: "",
        },
        groupNameArray: [],
        groupName: {
            label: "",
            id: "",
        },
        cityArray: [],
        city: {
            label: "",
            id: "",
        },
        industryArray: [],
        industry: {
            label: "",
            id: ""
        },
        turnOverArray: [],
        turnOver: {
            label: "",
            id: ""
        }
    };

    const formik = useFormik<IFilterForm>({
        enableReinitialize: true,
        initialValues: initialFormValues,
        validateOnMount: true,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const filterRequestBody: ICustomerManagementQueryParams = {
                productCategoryIds: values.productCategoryArray.map(productCategory => productCategory.id),
                groupName: values.groupNameArray.map(groupName => groupName.id),
                city: values.cityArray.map(city => city.id),
                industry: values.industryArray.map(industry => industry.id),
                turnOver: values.turnOverArray.map(turnOver => turnOver.id),
            }
            setCustomerManagementFiltersTo(filterRequestBody)
        },
    });

    const customerGroupService = useCustomerGroupMasterService()
    const [customerGroupData, setCustomerGroupDataTo] = useState<any>()

    const loadGroupName = (inputValue?: string) => {
        customerGroupService.getAllCustomerGroup({ page: 0, size: 10, sort: 'createdAt,desc', groupName: "" }, {})
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    setCustomerGroupDataTo(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
                showSnackbar("error", "Error Fetching Category");
            })
    }

    useEffect(() => {
        loadGroupName();
    }, []);

    const categoryService = useCategoryService();
    const [productCategoryData, setProductCategoryData] = useState<ICategory[] | null>([]);

    const loadProductCategory = (inputValue?: string) => {
        categoryService.getAllCategories({ level: CATEGORY_TYPE.PRODUCT_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: '', name: inputValue ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setProductCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    useEffect(() => {
        loadProductCategory();
    }, []);

    const customerGroupOptions = useMemo(() => {
        return customerGroupData?.map((group: any) => ({ label: group.groupName, id: group.groupId })) ?? []
    }, [customerGroupData])


    const productCategoryOptions = useMemo(() => {
        return productCategoryData?.map((category: any) => ({ label: category.name, id: category.id })) ?? []
    }, [productCategoryData])


    const optionsMap: { [key: string]: any[] } = {
        productCategory: productCategoryOptions,
        groupName: customerGroupOptions,
        city: cityOptions,
        industry: industryOptionsV2,
        turnOver: turnOverOptions,
    };

    const searchMap: { [key: string]: any } = {
        productCategory: loadProductCategory,
        groupName: loadGroupName,
        city: loadGroupName,
        industry: loadGroupName,
        turnOver: loadGroupName,
    };

    const updateSelected = (key: string) => (option: AutoCompleteOption | null) => {
        if (option) {
            const filterExists = formik.values[`${key}Array`].some((groupId: any) => groupId.id === option?.id);
            if (!filterExists) {
                formik.setFieldValue(`${key}Array`, [...formik.values[`${key}Array`], option]);
            }
        }
        formik.setFieldValue(key, { label: "", id: "" });
    };

    const handleFilterChipDelete = (value: ChipItem, key: string) => {
        formik.setFieldValue(`${key}Array`, formik.values[`${key}Array`].filter((e: any) => e.id !== value.id));
    }

    const handleResetAll = () => {
        formik.resetForm()
        fetchProfile()
        setCustomerManagementFiltersTo(initialCustomerManagementFilters)
    }

    return (
        <>
            <div className={`flex justify-between py-8 items-center ${classes.container}`}>
                <span className={`font-medium text-2xl	${classes.text}`}>Customer Management</span>
                <ButtonV2
                    label="+ Add New Customer"
                    variant='primaryContained'
                    onClick={handleAddNewCustomers}
                />
            </div>
            <div className='py-8'>
                <GenericDateRangePicker
                    label=""
                    onDataFilter={handleDataFilter}
                />
            </div>
            <div className='grid'>
                <StatusCard cards={customStats} />

                <form onSubmit={formik.handleSubmit}>
                    <div className={`${classes.filterContainer} mt-4 p-4 rounded-lg grid gap-y-5`}>
                        <div className="grid grid-cols-3 gap-x-4 gap-y-5">
                            {
                                filter.slice(0, showMoreFilters ? filter.length : 3).map((filter: { value: string, label: string }) => (
                                    <div className="grid gap-y-2  h-fit">
                                        <FormControl fullWidth className={classes.input}>
                                            <AutoCompleteTextField
                                                options={optionsMap[filter.value]}
                                                label={`Select ${filter.label}`}
                                                onChange={updateSelected(filter?.value)}
                                                onSearch={searchMap[filter?.value]}
                                                value={formik.values[filter.value]}
                                                isDisabled={false}
                                                placeHolder={filter.label}
                                            />
                                        </FormControl>
                                        <ChipsV2 values={formik.values[`${filter.value}Array`] ?? []} onDelete={(value) => handleFilterChipDelete(value, filter.value)}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                        <div className="flex justify-end">
                            <div className="flex gap-x-5">
                                <ButtonV2 variant="primaryContained" size="large" label={"Search"} type="submit" startIcon={< img src={searchOutlinedIcon} alt="searchIcon" />} />
                                <ButtonV2 variant="tertiaryContained" size="large" label={"Reset All"} disabled={!formik.dirty} onClick={handleResetAll} />
                                <ButtonV2 variant="tertiaryContained" size="large" label={showMoreFilters ? "Hide Filters" : "More Filters"} onClick={toggleMoreFilters} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <CustomerListTemplate customerTemplate={customers} pagination={pagination} handleFiltersChange={handleFiltersChange} handleRowsPerPageChange={handleRowsPerPageChange} handlePaginationChange={handlePaginationChange} customerFilter={customerFilters} />
        </>
    )
}
export default CustomerListingPage;