import React, { useState } from 'react';
import VendorDetailsTemplate from './VendorDetails.template';
import TabsV2, { ITabsSchema } from '../../molecules/TabsV2/TabsV2';
import CatalogueApprovalProductListingTemplate from './CatalogueApprovalProductListing.template';
import { useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: "#050915",
    }
}));

const VendorProfileTemplate: React.FC = () => {
    const  classes = useStyles();
    const [activeTab, setActiveTab] = useState<string>("1");
    const { id } = useParams();
    const vendorId = id ? Number(id) : undefined;
    const handleTabChange = (tab: string | number) => {
        setActiveTab(tab.toString());
    };

    const schema: ITabsSchema[] = [
        { label: 'Pending Products', component: <CatalogueApprovalProductListingTemplate  type={"PENDING"} id={vendorId} /> },
        { label: 'Published Products', component: <CatalogueApprovalProductListingTemplate  type={"PUBLISHED"} id={vendorId}/> },
        { label: 'Unpublished Products', component:<CatalogueApprovalProductListingTemplate  type={"UNPUBLISHED"} id={vendorId}/> },
        { label: 'Awaiting Products', component: <CatalogueApprovalProductListingTemplate  type={"REJECTED"} id={vendorId}/> },
    ];

    return (
        <div>
            <div className={`${classes.heading}  font-medium text-2xl mb-6`}>Vendor Profile</div>
            <VendorDetailsTemplate id={vendorId}/>
            <div className='mt-6'>
                <TabsV2 schema={schema} value={activeTab} setValue={handleTabChange} />
            </div>
        </div>
    );
};

export default VendorProfileTemplate;
