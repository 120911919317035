import React from "react";
import { createUseStyles } from "react-jss";
import {  useEffect, useState } from "react";
import axios from "axios";
import { ResourceFilter } from "./ResourceFilter";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {
  Button
} from "@mui/material";
import { useAuthenticatedUser } from "../../../../hooks/useAuthenticatedUser";
const BaseUrl = process.env.REACT_APP_API_V1_URL;

const catalogueFilterSchema = [

  {
    label: "Brands",
    isSearchable: true,
    defaultSize: 4,
    key: "brands",
    isExclusive: false
  },
  {
    label: "Manufacturers",
    isSearchable: true,
    defaultSize: 4,
    key: "manufacturers",
    isExclusive: false
  },
  {
    label: "Standards",
    isSearchable: true,
    defaultSize: 4,
    key: "standards",
    isExclusive: false
  },
  {
    label: "Grades",
    isSearchable: true,
    defaultSize: 4,
    key: "grades",
    isExclusive: false
  },
  {
    label: "Shapes",
    isSearchable: false,
    defaultSize: 4,
    key: "shapes",
    isExclusive: false
  }
];

export const SHAPES = [
  // {"id": "SINGLE_LENGTH_COIL", "name": "Single Length Coil"},
  // {"id": "MULTI_WELDED_COIL", "name": "Multi Welded Coil"},
  // {"id": "LEADER_END_COIL", "name": "Leader End Coil"},
  // {"id": "UNIFORM_ASSORTED_BUNDLE_SHEETS", "name": "Uniform Assorted Bundle Sheets"},
  // {"id": "MIX_ASSORTED_BUNDLE_SHEETS", "name": "Mix Assorted Bundle Sheets"},
  // {"id": "UNASSORTED_BUNDLE_SHEETS", "name": "Unassorted Bundle Sheets"},
  {"id": "SCROLL", "name": "Scroll"},
  {"id": "BAR", "name": "Bar"},
  {"id": "PLATE", "name": "Plate"},
  {"id": "COIL", "name": "Coil"},
  {"id": "SHEET", "name": "Sheet"},
  {"id": "STRIP", "name": "Strip"},
  {"id": "ANGLE", "name": "Angle"},
  {"id": "CHANNEL", "name": "Channel"},
  {"id": "BEAM", "name": "Beam"},
  {"id": "PIPE", "name": "Pipe"},
  {"id": "TUBE", "name": "Tube"},
  {"id": "ROUND", "name": "Round"},
  {"id": "SQUARE", "name": "Square"},
  {"id": "RECTANGLE", "name": "Rectangle"},
  {"id": "OTHERS", "name": "Others"},
]
const useStyles = createUseStyles(() => ({
  applyFiltersText:{
    color: "#4D4D4D",
    margin:"8px 0px 0px 10px "
  },
  containerHeader: {
    color: "#4D4D4D",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin:"8px 10px"
  },
  applyFiltersButton: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    border: `1px solid #EDEDED`,
    borderBottom: "none",
  },
}));

const  InventoryFilters = ({filtersData, updateFilters, onClose}) => {
  const classes = useStyles();
  const { user } = useAuthenticatedUser();
  const [filterData, setFilterDataTo] = useState({})
  const [selectedIds, setSelectedIdsTo] = useState({
    standards: [],
    grades: [],
    manufacturers: [],
    brands: [],
    shapes: []
  })

  useEffect(() => {
    axios
    .get(`${BaseUrl}/filters`, {
      headers: {
        Authorization: `${user.token}`,
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      const updatedFilterData = {
        ...response?.data?.data,
        shapes: SHAPES,
      };
      setFilterDataTo(updatedFilterData);
      setSelectedIdsTo(filtersData);
    })
    .catch((error) => {
      console.error('Error fetching product categories:', error);
    });
  }, [])

  const handleApplyFilters = () => {
    updateFilters(selectedIds);
    onClose();
  }

  const handleChange = (type, isExclusive) => (id, isSelected) => {
    let selectedTypeValues = [];
    if (isExclusive) {
      selectedTypeValues = [id];
    } else {
      selectedTypeValues = isSelected
        ? [...selectedIds[type], id]
        : (selectedIds[type]).filter(item => item !== id);
    }
    setSelectedIdsTo({ ...selectedIds, [type]: selectedTypeValues });
  };

  const initialSelectedIds = {
    standards: [],
    grades: [],
    manufacturers: [],
    brands: [],
    shapes: [],
  };

  const clearFilters = () => {
    setSelectedIdsTo(initialSelectedIds);
  };

  return (
    <div className="grid gap-y-3 ">
      <div className={`${classes.applyFiltersText} text-xl font-black`}>
        Filters
      </div>
      <div className={classes.containerHeader}>
        <div className={classes.applyFiltersButton} onClick={clearFilters}>
          <Button variant="outlined" >
            Clear Filters
          </Button>
        </div>
        <div className={classes.applyFiltersButton} onClick={handleApplyFilters}>
          <Button variant="outlined" startIcon={<ManageSearchIcon />} >
            Apply Filters
          </Button>
        </div>
      </div>
      <div className={`${classes.container} rounded-md bg-white `}>
        {catalogueFilterSchema.map((filter) => (
          <ResourceFilter
            key={filter.key}
            label={filter.label}
            isSearchable={filter.isSearchable}
            defaultSize={filter.defaultSize}
            resourceOptions={filterData && filterData[filter.key]}
            onChange={handleChange(filter.key, filter.isExclusive)}
            selectedOptions={selectedIds[filter.key]}
          />
        ))}
      </div>
    </div>
  );
};

export default InventoryFilters;
