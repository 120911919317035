import React, { useState } from 'react';
import bussinessIcon from '../../../assets/icons/bussinessIcon.svg';
import userIcon from '../../../assets/icons/userIcon.svg';
import { createUseStyles } from 'react-jss';
import { IApprovalHistory } from './ViewHistoryDialog.template';
import { enumToString, formatTimeInIST } from '../../../utils/helper';
import ResourceStatusV2 from '../../atoms/ResourceStatus/ResourceStatusV2';

interface IVendorViewHistoryTemplateProps {
    historyItem: IApprovalHistory;
}

const useStyles = createUseStyles((theme: any) => ({
    date: {
        color: "#4A4D4F"
    },
    border: {
        borderBottom: `1px solid #D6DDF5 !important`,
    },
    activatedProgressBar: {
        backgroundColor: "#E5E6E6",
        height: "158px"
    },
    activeProgressBar: {
        backgroundColor: "#ADBBEB"
    },
    dateZone: {
        color: "#96999C"
    },
    name: {
        color: "#636669"
    },
    content: {
        color: "#313335",
        width: "674px",
        height: "74px"
    }
}));

const VendorViewHistoryTemplate: React.FC<IVendorViewHistoryTemplateProps> = ({ historyItem }) => {
    const classes = useStyles();
    const formattedDate = new Date(historyItem.updatedAt);

    const formatted = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
    }).format(formattedDate);

    const updatedAt = new Intl.DateTimeFormat("en-GB", {
        day: "numeric",
        month: "long"
    }).format(new Date(historyItem.updatedAt));

    const createdAt = new Intl.DateTimeFormat("en-GB", {
        day: "numeric",
        month: "long"
    }).format(new Date(historyItem.createdAt));

    return (
        <div>
            <div className={`${classes.date} font-semibold text-base mb-6 mt-6`}>{formatted}</div>
            <div className='flex gap-x-3'>
                <div className='flex flex-col items-center justify-start'>
                    <div className={`${classes.activeProgressBar} w-3 h-3 mt-1 rounded-full`}></div>
                    <div className={`${classes.activatedProgressBar} w-0.5  rounded `}></div>
                    <div className={`${classes.activeProgressBar} w-3 h-3 rounded-full`}></div>
                </div>
                <div>
                    <div className='flex  gap-x-6'>
                        <div className={`${classes.dateZone}  whitespace-nowrap mb-6`}>{updatedAt}</div>
                        <div>
                            <div className='flex gap-x-2'>
                                <img src={bussinessIcon} alt="closeIcon" />
                                <div className={`${classes.name}`}>Steel Bazaar</div>
                                <div className={classes.dateZone}>|</div>
                                <div>
                                    <ResourceStatusV2 status={enumToString(historyItem.status)} />
                                </div>
                            </div>
                            <div className={`${classes.content} my-3 overflow-hidden line-clamp-3`}>{historyItem.adminComment}</div>
                            <div className={`${classes.dateZone}  `}>{formatTimeInIST(historyItem.updatedAt)}</div>
                        </div>
                    </div>
                    <div className='flex gap-x-6 mt-6'>
                        <div className={`${classes.dateZone}  whitespace-nowrap mb-6`}>{createdAt}</div>
                        <div>
                            <div className='flex items-center gap-x-2'>
                                <img src={userIcon} alt="userIcon" />
                                <div className={`${classes.name}`}>Customer Name</div>
                                <div className={classes.dateZone}>|</div>
                                <ResourceStatusV2 status={enumToString(historyItem.status)} />
                                </div>
                            <div className={`${classes.content} my-3 overflow-hidden line-clamp-3`}>{historyItem.vendorComment}</div>
                            <div className={`${classes.dateZone}`}>{formatTimeInIST(historyItem.createdAt)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VendorViewHistoryTemplate;
