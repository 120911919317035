import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { ADMIN_ROUTES, EXCEPTION_CODE, MODE, SERVICE_STATUS_OPTIONS, STATUS } from '../../../utils/constant';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../utils/types';
import ButtonV2 from '../../atoms/ButtonV2';
import { makeRoute } from '../../../utils/helper';
import { createUseStyles } from 'react-jss';
import StatusV2 from '../../atoms/Status/StatusV2';
import editIcon from "../../../assets/icons/editPenWhiteOutlinedIcon.svg"
import { IQualityGradePayload, useQualtiyGradeService } from '../../../services/useQualityGradesService';

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    standard: Yup.object().shape({
        id: Yup.number().required('Standard is required'),
        label: Yup.string().required('Standard is required')
    }).required('Standard is required'),
});

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    textColor: {
        color: theme.palette.text.primaryDarkLight
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    property: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    image: {
        width: "141px",
        height: "159px"
    },
    value: {
        color: theme.palette.textV2.primary.primary950
    },
    number: {
        backgroundColor: theme.palette.backgroundV2.primary.primary100,
        color: theme.palette.textV2.primary.primary800,
        width: "54px",
        height: "54px"
    }
}));

const GradeViewPage: React.FC = () => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const qualtiyGradeService = useQualtiyGradeService();
    const [grade, setGrade] = useState<any | null>(null)
    const navigate = useNavigate();
    const params = useParams();

    const loadGrade = async () => {
        qualtiyGradeService.getQualityGrade(Number(params.id))
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    setGrade(res.data.data)
                }
            }).catch((error) => {
                setGrade(null);
                console.error("Error Fetching Quality Grade: ", error);
                showSnackbar('error', "Error while fetching Quality Grade data");
            })
    }

    useEffect(() => {
        loadGrade()
    }, [Number(params.id)])

    const onBack = () => {
        navigate(ADMIN_ROUTES.GRADE_LIST);
    }

    const handleActionRoute = (id: number) => () => {
        navigate(makeRoute(ADMIN_ROUTES.GRADE_UPDATE, { params: { id: id } }));
    }

    const updateGrade = async (grade: IQualityGradePayload) => {
        const requestBody: any = {
            name: grade.name.trim(),
            prefix: grade.prefix,
            standardId: grade.standardId,
            bisHeader: "Bis Header",
            type: grade.type,
            status: grade.status ? 'ACTIVE' : 'INACTIVE',
            chemicalComposition: JSON.stringify(grade.chemicalComposition),
            mechanicalProperties: JSON.stringify(grade.mechanicalProperties),
            tensileProperties: JSON.stringify(grade.tensileProperties),
         }
         qualtiyGradeService.updateQualityGrade(Number(params.id), requestBody)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "Quality Grade Updated");
                    onBack();
                } else if (res.data.exceptionCode === EXCEPTION_CODE.DUPLICATE_ENTRY) {
                    showSnackbar('error', "Quality Grade already exists. Please try again");
                }
            }).catch((error) => {
                showSnackbar('error', "Failed to update Quality Grade");
            })
    }

    const formik = useFormik<IQualityGradePayload>({
        initialValues: {
            name: grade?.name ?? "",
            prefix: grade?.prefix ?? "",
            standard: {
                label: grade?.standard?.name ?? "",
                id: grade?.standard?.id ?? "",
            },
            standardId: grade?.standardId ?? null,
            bisHeader: grade?.bisHeader ?? "",
            type: grade?.type ?? "",
            status: grade?.status ?? STATUS.ACTIVE,
            chemicalComposition: grade?.chemicalComposition ? JSON.parse(grade?.chemicalComposition) : [],
            mechanicalProperties: grade?.mechanicalProperties ? JSON.parse(grade?.mechanicalProperties) : [],
            tensileProperties: grade?.tensileProperties ? JSON.parse(grade?.tensileProperties) : [],
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            updateGrade({ ...values, status: values.status })
        }
    })

    useEffect(() => {
        formik.setValues({
            name: grade?.name ?? "",
            prefix: grade?.prefix ?? "",
            standard: {
                label: grade?.standard?.name ?? "",
                id: grade?.standard?.id ?? "",
            },
            standardId: grade?.standardId ?? null,
            bisHeader: grade?.bisHeader ?? "",
            type: grade?.type ?? "",
            status: grade?.status ?? STATUS.ACTIVE,
            chemicalComposition: grade?.chemicalComposition ? JSON.parse(grade?.chemicalComposition) : [],
            mechanicalProperties: grade?.mechanicalProperties ? JSON.parse(grade?.mechanicalProperties) : [],
            tensileProperties: grade?.tensileProperties ? JSON.parse(grade?.tensileProperties) : [],
        });
    }, [grade]);

    return (
        <div>
            {SnackBarComponent}
            <form className="grid gap-y-8" >
                <div className='flex justify-between'>
                    <div className={`${classes.mainHeading} text-lg font-semibold my-auto`}>Quality Grade View: {formik.values?.name}</div>
                    <ButtonV2 onClick={handleActionRoute(Number(params.id))} variant='primaryContained' label={"Edit Attribute"} startIcon={<img src={editIcon} alt="editIcon" />} />
                </div>

                <div className='grid gap-y-4'>
                    <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                        <div className={`${classes.heading} text-base font-medium`}>Quality Grade Details</div>
                        <div className='grid grid-cols-3'>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Name</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.name}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Associated Grade Name Code</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.prefix}</div>
                            </div>
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Standard</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.standard.label}</div>
                            </div>
                        </div>
                    </div>

                    <StatusV2 heading="Service Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" mode={MODE.VIEW} />

                </div>

                <div className="flex justify-end gap-4">
                    <ButtonV2 variant="tertiaryContained" label='Back' onClick={onBack} />
                </div>
            </form>
        </div>
    )
}

export default GradeViewPage