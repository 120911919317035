import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const QUALITY_GRADE_URLS = {
    CREATE_QUALITY_GRADE: "/quality-grade",
    GET_ALL_QUALITY_GRADE: "/quality-grades",
    GET_QUALITY_GRADE: "/quality-grade/:id",
    UPDATE_QUALITY_GRADE: "/quality-grade/:id",
};

export interface IQualityGradeFilter {
    standardId?: number;
    search?: string;
    standardName?: string;
    type?: string;
    page?: number;
    size?: number;
    sort?: string;
    status?: string
}

export interface IGrades {
    id: number;
    name: string;
    prefix: string;
    standardId: number;
    bisHeader: string;
    type: string;
    status: string;
    chemicalComposition: string;
    mechanicalProperties: string;
    tensileProperties: string;
    createdBy: number;
    updatedBy: number;
    createdAt: string;
    updatedAt: string;
    standardName: string;
}

export interface IGrade {
    id: number;
    name: string;
    prefix: string;
    standardId: number;
    bisHeader: string;
    type: string;
    createdBy: number;
    updatedBy: number;
    createdAt: string;
    updatedAt: string;
    standard: IStandard;
    status: string;
    chemicalComposition: string;
    mechanicalProperties: string;
    tensileProperties: string;
}

export interface IStandard {
    id: number;
    name: string;
    prefix: string;
    yearInUse: number;
    yearLastUpdated: number;
    hsnCodes: string;
    bisHeading: string;
    equivalentTags: string;
    status: string;
    createdBy: number;
    updatedBy: number;
    application: string;
    features: string;
    description: string;
    createdAt: string;
    updatedAt: string;
}

export interface IQualityGradePayload {
    name: string;
    prefix: string;
    standard: {
        label: string;
        id: string | number;
    };
    standardId: number | null;
    bisHeader: string;
    type: string;
    status: string;
    chemicalComposition?: any;
    mechanicalProperties?: any;
    tensileProperties?: any;
}

export const useQualtiyGradeService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const createQualityGrade = (data: IQualityGradePayload) => {
        return request(API_METHOD.POST, QUALITY_GRADE_URLS.CREATE_QUALITY_GRADE, authenticatedUser, data);
    };

    const getAllQualityGrades = (params: IQualityGradeFilter) => {
        return request(API_METHOD.GET, QUALITY_GRADE_URLS.GET_ALL_QUALITY_GRADE, authenticatedUser, null, { params });
    };

    const getQualityGrade = (id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(QUALITY_GRADE_URLS.GET_QUALITY_GRADE, { id }), authenticatedUser);
    };

    const updateQualityGrade = (id: number, data: IQualityGradePayload) => {
        return request(API_METHOD.PUT, replaceUrlParams(QUALITY_GRADE_URLS.UPDATE_QUALITY_GRADE, { id }), authenticatedUser, data);
    };

    return {
        createQualityGrade,
        getAllQualityGrades,
        getQualityGrade,
        updateQualityGrade
    };
};