import React, { useEffect, useState } from 'react'
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { HTTP_STATUS } from '../../../utils/types';
import  ProfileOverviewTemplate, { Address } from '../../templates/CustomerManagement/ProfileOverview.template';
import { useParams } from 'react-router-dom';
import { ICIN } from '../../templates/CustomerManagement/CINUpdate.template';

export interface IGstnState {
    gstin: string;
    state: string;
}

export interface ILocationGstinForm {
    locations: IGstnState[];
    noOfRows: number;
    newLocations: IGstnState[];
}

export interface Udhyam {
    businessType: string;
    udhyamNumber: number;
}

export interface Location {
    gstin: string;
    state: string;
}

export interface TurnOver {
    year: string;
    amount: number;
}

export interface BankAccount {
    ifsc: string;
    branch: string;
    bankName: string;
    isDefault: boolean;
    accountName: string;
    accountNumber: string;
}

export interface Finance {
    turnOvers: TurnOver[];
    bankAccounts: BankAccount[];
}

export interface SocialLinks {
    linkedin: string;
}

export interface Poc {
    name: string;
    email: string;
    mobile: string;
    prefix: string;
    department: string;
    profilePic: string;
    countryCode: string;
    designation: string;
    socialLinks: SocialLinks;
    isAuthorized: boolean;
    whatsAppLink: string;
    alternateEmail: string;
    alternateMobile: string;
    modeOfCommunication: string;
}

export interface Authority {
    authority: string;
}

export interface User {
    id: number;
    email: string;
    password: string;
    mobileNumber: string;
    fullName: string;
    countryCode: string;
    userType: string;
    gstin: string;
    department: string;
    designation: string
    alternativeEmail: string;
    socialLinks: SocialLinks;
    isAuthorized: boolean;
    prefix: string;
    whatsAppLink: string;
    alternateMobile: string;
    createdAt: string;
    updatedAt: string;
    enabled: boolean;
    phoneNumberVerified: boolean;
    authorities: Authority[];
    accountNonExpired: boolean;
    accountNonLocked: boolean;
    credentialsNonExpired: boolean;
    emailVerified: boolean;
    username: string;
    status: string;
    comments: string;
}

export interface IUserProfileEntity {
    id: number;
    userId: number;
    status: string;
    comments: string;
}

export interface IBusinessProfile {
    id: number;
    name: string;
    userId: number;
    description: string;
    gstin: string;
    pan: string;
    cin: ICIN;
    udhyam: Udhyam;
    yearOfEstablishment: number;
    numberOfEmployees: number;
    websiteUrl: string;
    industryType: string;
    businessType: string;
    brand: string;
    locations: Location[];
    finance: Finance;
    pocs: Poc[];
    communicationChannel: string;
    language: string;
    contactMode: string;
    paymentMode: string;
    whatsappUpdates: boolean;
    whatsappCommunity: boolean;
    emailUpdates: boolean;
    docsAccess: boolean;
    createdAt: string;
    status?: string;
    updatedAt: string;
    user: User;
    address: Address;
    cinRegisteredAddress: Address;
    docs: any[];
    entityType: string;
    industry: string;
    dateOfEstablishment: any;
    totalEmployees: any;
    dateOfIncorportation: any;
    userProfileTypeEntity: IUserProfileEntity;
}



const initialValues = {
    locations: [],
    noOfRows: null,
    newLocations: []
}

const ProfileOverviewPage: React.FC = () => {

    const params = useParams();
    const businessProfileId = Number(params.id);

    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const bussinessProfileService = useBusinessProfileService();

    const [businessProfile, setBusinessProfileTo] = useState<IBusinessProfile | null>(null);
    const [userStatusDetails, setUserStatusDetailsTo] = useState<{ status: string; comments: string }>({ status: '', comments: '' })

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await bussinessProfileService.getBusinessProfile(businessProfileId);
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setBusinessProfileTo(profileData);
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetch failed');
        }
    };

    const updateStatus = async () => {
        const requestBody = {
            status: userStatusDetails?.status,
            comments: userStatusDetails?.comments
        }
        if (businessProfile?.id) {
            const businessProfileResponse = await bussinessProfileService.updateStatus(businessProfile?.id, requestBody);
            if (businessProfileResponse?.status === HTTP_STATUS.OK) {
                showSnackbar("success", "Status updated successfully");
            }
            else{
                showSnackbar("error", "Status update failed");
            }
        }
    }

    useEffect(() => {
        getBusinessProfile();
    }, []);

    return (
        <div>
            {SnackBarComponent}
            {businessProfile && <ProfileOverviewTemplate businessProfile={businessProfile} updateStatus = {updateStatus} statusDetails = {userStatusDetails} setStatusDetailsTo = {setUserStatusDetailsTo} />}
        </div>
    )
}

export default ProfileOverviewPage;
