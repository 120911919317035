import React, { useContext, useEffect, useState } from "react";
import "./Preview.css";

import closePng from "../../../Assets/Images/close.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import InventoryContext from "../../../Core/Context/inventory/NewInventoryContext";
import axios from "axios";
import { FilesWrapper } from "../../../Shared/Files/DisplayFiles";
import { DisplayDocuments } from "../../../Shared/Files/FileItem";
import Chip from '@mui/material/Chip';
import { SET_INVENTORY_FORM_DATA } from "../../../Core/Context/inventory/NewInventoryProvider";
import { useAuthenticatedUser } from "../../../../hooks/useAuthenticatedUser";
import { showAttributesUom } from "../../../../utils/helper";

const BaseUrl = process.env.REACT_APP_API_V1_URL;

export const convertToReadableFormat = (inputString) => {
  if (!inputString) return null;
  const words = inputString.split('_');
  const result = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  return result.join(' ');
};

const DisplayProductSpecificationDetails = ({ productSpecificationDetails }) => {
  try {
    const attributes = JSON.parse(productSpecificationDetails);

    if (attributes?.SPECIFICATION) {
      return attributes.SPECIFICATION.map((specificationItem, index) => (

        <div key={index} className="grid gap-y-1.5">

          <div className="m-auto">{specificationItem.name}</div>
          <div className="mx-4 px-1.5 rounded-full border border-input-border">
          {showAttributesUom(specificationItem.attributeOptions, specificationItem.attributeOptions , specificationItem.uom )}
          </div>
        </div>
      ));
    } else {
      return <div>No specification data available.</div>;
    }
  } catch (error) {
    console.error('Error parsing attributes:', error);
    return <div>No Specification Details</div>;
  }
};
function DisplayKeywords({ keywords }) {

  if (!keywords) {
    return null;
  }
  var arrayOfValues = []
  try {
    arrayOfValues = keywords.split(',');
  } catch (error) {
    return null;
  }

  return (
    <div className="flex gap-x-3">
      {arrayOfValues.map((keyword, index) => (
        <Chip
          variant="outlined"
          key={index}
          label={keyword}
        />
      ))}
    </div>
  );
}
const DisplayProductGeneralizationDetails = ({ productSpecificationDetails }) => {
  try {
    const attributes = JSON.parse(productSpecificationDetails);

    if (attributes?.GENERALIZATION) {
      return attributes.GENERALIZATION.map((specificationItem, index) => (

        <div key={index} className="grid gap-y-1.5">

          <div className="m-auto">{specificationItem.name}</div>
          <div className="mx-4 px-1.5 rounded-full border border-input-border">
            {specificationItem.attributeOptions}
          </div>
        </div>
      ));
    } else {
      return <div>No Generalization data available.</div>;
    }
  } catch (error) {
    console.error('Error parsing attributes:', error);
    return <div>No Generalization Details</div>;
  }
};

const Preview = (props) => {
  const { user } = useAuthenticatedUser();
  const navigate = useNavigate();
  const location = useLocation();
  const inventoryContextData = useContext(InventoryContext);
  const [selectedUpcDetails, setSelectedUpcDetails] = useState([]);
  const [upcCode, setUpcCode] = useState();
  const [imageData, setImageData] = useState([]);
  const [warehouseDetails, setWarehouseDetails] = useState();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");

  const handleView = async (id) => {
    let res = await axios.get(`${BaseUrl}/inventory/${id}`, {
      headers: {
         Authorization: `${user.token}`,
         "Content-Type": "application/json",
      }
      }
    );
    let formData = res.data.data;

    inventoryContextData.updateInventoryDetailsState(
      formData,
      SET_INVENTORY_FORM_DATA
    );
    setFormData(formData);
    fetchWarehouseDetails(formData.warehouseId);
    fetchUpcDetails(formData.upcId, urlParams.get("skuMode"));
  };
  let [formData, setFormData] = useState({});
  let [skuMode, setSkuMode] = useState("");

  useEffect(() => {
    handleView(id);
  }, []);
  const fetchDocuments = async (resId, resType) => {
    try {
      if (!resId || !resType) {
        return;
      }
      const apiUrl = `${BaseUrl}/files/${resType}/${resId}`;

      const response = await axios.get(apiUrl, {
        headers: {
           Authorization: `${user.token}`
        }
     }).then((res) => {
        setImageData(res?.data?.data);
        return;
      });
    } catch (err) {
      console.log("Image Fetch Error - ", err);
      return;
    }
  };

  const handleRedirect = (event) => {
    if (urlParams.get("skuMode") === "STANDARD") {
      navigate(-1);
    } else if (urlParams.get("skuMode") === "NONSTANDARD") {
      navigate(-1);
    }
  };
  const fetchWarehouseDetails = (warehouseId) => {
    if (id === undefined) {
      return;
    } else {
      axios
        .get(`${BaseUrl}/warehouse/${warehouseId}` ,{
          headers: {
             Authorization: `${user.token}`,
             "Content-Type": "application/json",
          }
       })
        .then((response) => {
          setWarehouseDetails(response?.data?.name);
        })
        .catch((error) => {
          console.error("Error fetching UPC details:", error);
          setWarehouseDetails(null);
        });
    }
  };
  const fetchUpcDetails = (upcId) => {
    axios
      .post(`${BaseUrl}/search/upc`, {
        upcId: upcId,
        classType: urlParams.get("skuMode"),
      }, {
        headers: {
           Authorization: `${user.token}`,
           "Content-Type": "application/json",
        }
     })
      .then((response) => {
        setUpcCode(response?.data?.data[0]?.upcCode);
        setSelectedUpcDetails(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching UPC details:", error);
        setSelectedUpcDetails(null);
      });
  };
  useEffect(() => {
    setFormData(inventoryContextData?.inventoryDetailState?.inventoryFormData);
    setSkuMode(inventoryContextData?.inventoryDetailState?.skuMode);
    fetchDocuments(urlParams.get("id"), "INVENTORY");
  }, [inventoryContextData]);


  useEffect(() => {
    if(inventoryContextData?.inventoryDetailState?.inventoryFormData?.upcId)
    fetchUpcDetails(
      inventoryContextData?.inventoryDetailState?.inventoryFormData?.upcId
    );
  }, [inventoryContextData?.inventoryDetailState?.inventoryFormData?.upcId]);

  return (
    <div className="search-upc-container1  mt-4 m-auto">
      <div className="grid gap-y-3 mx-6 pb-6">
        <div className="search-upc-heading flex justify-between mb-5 py-5">
          <h1 className="text-black">SKU CODE: {formData?.skuCode}</h1>
          <button onClick={handleRedirect}>
              <img src={closePng} alt="close png" />
          </button>
        </div>
        <div className="product-description">
          {/**<h2>{formData?.upcId}</h2> */}
        </div>
        <div className="grid gap-y-4">
          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              Category
            </div>
            <div className="flex gap-x-6">
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Super Category
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  {selectedUpcDetails[0]?.superCategory}
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Main Category
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  {selectedUpcDetails[0]?.mainCategory}
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Product Category
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  {selectedUpcDetails[0]?.productCategory}
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Manufacturer
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  {selectedUpcDetails[0]?.manufactureName}
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Brand
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  {selectedUpcDetails[0]?.brand}
                </div>
              </div>
            </div>
          </div>

          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              Standard
            </div>
            <div className="flex gap-x-6">
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Standard
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  {selectedUpcDetails[0]?.standard}
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Grade
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  {selectedUpcDetails[0]?.grade}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              General Info
            </div>
            <div className="flex gap-x-6">
              <DisplayProductGeneralizationDetails productSpecificationDetails={selectedUpcDetails[0]?.attributes} />
            </div>
          </div> */}

          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              Product Classification
            </div>
            <div className="flex gap-x-6">
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Shape
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  { convertToReadableFormat(selectedUpcDetails[0]?.shape) }
                </div>
              </div>
              <div className="grid gap-y-1.5">
                <div className="text-text-color font-roboto text-base font-normal">
                  Class
                </div>
                <div className="text-text-color font-roboto text-base font-medium">
                  {selectedUpcDetails[0]?.classType}
                </div>
              </div>
            </div>
          </div>

          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              Product Specification
            </div>
            <div className="flex gap-x-6">

            <DisplayProductSpecificationDetails productSpecificationDetails={selectedUpcDetails[0]?.attributes} />

          </div>
          </div>

          <div className="grid gap-y-4 p-6  bg-gray-300 bg-opacity-30 rounded ">
            <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              Keywords
            </div>
            <DisplayKeywords keywords={selectedUpcDetails[0]?.keywords} />

            {/**   <div className="text-var(--text-color, #5B5B5B) font-roboto font-semibold text-base">
              Status:Active
            </div>*/}
          </div>
        </div>

        <div className="flex justify-between ">
          <div className="grid grid-cols-2 grid-rows-2 gap-6 my-6 ">
            <div>
              <div className="properties-names">Warehouse</div>
              <div className="properties-values">
                {formData && warehouseDetails}
              </div>
            </div>
            <div>
              <div className="properties-names">Warehouse Placement</div>
              <div className="properties-values">
                {formData?.warehousePlacement}
              </div>
            </div>

            <div>
              <div className="properties-names">Net Weight </div>
              <div className="properties-values">{formData?.netWeight} MT</div>
            </div>
            <div>
              <div className="properties-names">Gross Weight </div>
              <div className="properties-values">
                {formData?.grossWeight} MT
              </div>
            </div>

            <div>
              <div className="properties-names">Per MT Price </div>
              <div className="properties-values">
                {formData?.basePrice} 
                Rs
              </div>
            </div>

          </div>
          <div className=" px-14 py-14 bg-gray-300">
          <div className="properties-names">Total Price</div>
            <div className="properties-values">
              {new Intl.NumberFormat().format(
                formData?.basePrice * formData?.netWeight
              )}
              Rs
            </div>
          </div>
        </div>
        <div>
          <div className="product-description">
            <h2>Uploaded Product Images & Video</h2>
          </div>
          <FilesWrapper heading={""}>
            <DisplayDocuments
              documentsData={imageData}
              isEdit={false}
              isForm={false}
              // deleteDocument={deleteDocument}
            />
          </FilesWrapper>
        </div>

        <div className="flex gap-5 justify-end">
          <div className="grid   mt-1 ">
            <button
              onClick={handleRedirect}
              className="preview-draft1 px-6 py-3"
              type="submit"
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
