import ProductCatalogueViewTemplate from "../../../templates/MySteelBazaar/ExistingProduct/ProductCatalogueView.template";

const PostProductViewPage = () => {

    return (
        <div>
            <ProductCatalogueViewTemplate />
        </div>
    )
}

export default PostProductViewPage;
