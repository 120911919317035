import { useCallback, useEffect, useState } from 'react';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { MenuItem, routesSchema } from './SideBarMenuSchema';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { createUseStyles } from 'react-jss';
import { RESOURCES } from '../../../utils/constant';
import Button from '../../atoms/Button';
import { useAuthService } from '../../../services/useAuthService';
import { HTTP_STATUS } from '../../../utils/types';
import { getResourceKeyFromValue } from '../../../utils/helper';

const useStyles = createUseStyles((theme: any) => ({
    //Todo change active-0 active-1 active-2 as per the refactored style
    // "active-0": {
    //     backgroundColor: theme.pallete.sidebarBackground.primaryLight,
    // },
    // "active-1": {
    //     backgroundColor: theme.pallete.sidebarBackground.primaryDark,
    // },
    // "active-2": {
    //     backgroundColor: theme.palette.sidebarBackground.primaryLight,
    // },
    customListItemButton: {
        width: "236px",
    },
    logoutWrapper: {
        borderTop: `1px solid ${theme.palette.border.primaryLight}`,
        backgroundColor: theme.palette.border.primaryLight,
        marginTop: "8px",
        textAlign: "center",
        position: "sticky",
        bottom: 0,
        zIndex: 1,
    },
    logoutText: {
        color: theme.palette.text.primaryDarkLight,
    }
}));

interface SidebarSubList {
    key: string;
    disabled: boolean;
    icon?: string;
    route?: string;
    subList?: SidebarSubList[];
}

interface SidebarItem {
    key: string;
    disabled: boolean;
    icon?: string;
    route?: string;
    subList?: SidebarSubList[];
}

const SideBarMenu = () => {

    const navigate = useNavigate();
    const classes = useStyles();
    const { user, setAuthenticatedUser } = useAuthenticatedUser();
    const authenticationService = useAuthService();

    const [expandedMenu, setExpandedMenu] = useState<{ [key: string]: boolean }>({});
    const [allowedMenuOptions, setAllowedMenuOptionsTo] = useState<SidebarItem[] | []>([]);
    const [sideBarMenuOptionsTo, setSideBarMenuOptionsTo] = useState<SidebarItem[] | []>([]);

    useEffect(() => {
        if (user?.email == null && user?.token != null) {
            navigate('/client-details', { state: { flag: false } });
        } else if (user == null) {
            navigate('/sign-in');
        }
    }, [user]);

    const handleMenuChange = (keys: string[]) => () => {
        if (keys.every(key => expandedMenu[key])) {
            delete expandedMenu[keys.pop() as string];
            setExpandedMenu({ ...expandedMenu });
        }
        else {
            const updatedExpandedMenu: { [key: string]: boolean } = keys.reduce(
                (previousValue: { [key: string]: boolean }, currentValue: string) => (
                    { ...previousValue, [currentValue]: true }
                ), {})
            setExpandedMenu(updatedExpandedMenu);
        }
    };

    const handleLogout = () => {
        setAuthenticatedUser(null);
    };

    const renderMenuItems = useCallback((menuItems: MenuItem[], parent: string[] = []) => {
        return menuItems.map(({ key, route, icon, subList, disabled }) => {
            return (
                <div key={key}>
                    <ListItemButton
                        component={route ? Link : "span"}
                        to={route}
                        selected={!!expandedMenu[key]}
                        onClick={handleMenuChange([...parent, key])}
                        className={`${classes.customListItemButton}`}
                        //Todo need to refactor style
                        style={{
                            padding: "12px",
                            backgroundColor: expandedMenu[key] ? (
                                parent.length === 0 ? "#E9ECEF" :
                                    parent.length === 1 ? "#FFF1DB" :
                                        parent.length === 2 ? "#DDE5FF" :
                                            "none"
                            ) : ""
                        }}
                        disabled={disabled}
                    >
                        {icon && <ListItemIcon style={{ marginRight: "-28px" }}>
                            <img src={icon} alt="icon" />
                        </ListItemIcon>}
                        <ListItemText primary={key} />
                        {subList?.length && <div>{expandedMenu[key] ? <ExpandLess /> : <ExpandMore />}</div>}
                    </ListItemButton>
                    <Collapse className={parent.length === 1 ? "bg-slate-100" : ""} in={expandedMenu[key]} timeout="auto" unmountOnExit>
                        {subList && renderMenuItems(subList, [...parent, key])}
                    </Collapse>
                </div>
            );
        });
    }, [expandedMenu]);

    //TODO NEEDS TO BE DELETED LATER ON
    const generateSideBarMenuOptions = (frontendSchema: MenuItem[] | [], backendSchema: SidebarItem[] | []) => {
        const buildOutput = (frontList: any[], backList: any[]): any[] => {
            return frontList.map(frontItem => {
                const backItem = backList.find(back => {
                    return (back.key) === getResourceKeyFromValue(frontItem.key);
                });
                const outputItem: any = { key: frontItem.key };
                if (frontItem.icon) {
                    outputItem.icon = frontItem.icon;
                }
                if (frontItem.route) {
                    outputItem.route = frontItem.route;
                }
                outputItem.disabled = backItem?.disabled || false;

                if (frontItem.subList) {
                    outputItem.subList = buildOutput(frontItem.subList, backItem?.subList || []);
                }

                return outputItem;
            });
        };
        setSideBarMenuOptionsTo(buildOutput(frontendSchema, backendSchema));
    };

    //TODO NEEDS TO BE DELETED LATER ON
    const getAllowedSideBarMenuOptions = async (userId: number) => {
        const response = await authenticationService.getSideBarSchema(userId);
        if (response?.status === HTTP_STATUS.OK) {
            const sideBarSchema = response?.data?.data?.sideBarSchema;
            if (sideBarSchema.length > 0) {
                setAllowedMenuOptionsTo(sideBarSchema);
            }
            else {
                setAllowedMenuOptionsTo([]);
            }
        }
        else {
            setAllowedMenuOptionsTo([]);
        }
    }

    //TODO NEEDS TO BE DELETED LATER ON
    useEffect(() => {
        if (user) {
            const userId = parseInt(user.id, 10);
            getAllowedSideBarMenuOptions(userId);
        }
    }, [user?.id])

    useEffect(() => {
        if (allowedMenuOptions) {
            generateSideBarMenuOptions(routesSchema, allowedMenuOptions);
        }
    }, [allowedMenuOptions]);

    return (
        <>
            <List component="nav">
                {renderMenuItems(sideBarMenuOptionsTo)}
            </List>
            <div className={classes.logoutWrapper}>
                <Button
                    onClick={handleLogout}
                    label={RESOURCES.LOG_OUT}
                    variant="outlined"
                    className={classes.logoutText}
                />
            </div>
        </>
    );
};
export default SideBarMenu;