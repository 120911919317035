import React, { useState } from 'react'
import { FormikErrors, FormikProps } from 'formik';
import { createUseStyles } from 'react-jss';
import { AddVendorOwnProductSectionProps, IAddOwnProductFormik } from './AddOwnProduct.template';
import OwnProductReviewDetailsTemplate from './OwnProductReviewDetails.template';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { IMaterialAttribute, IMaterialRequirement } from '../../../pages/CustomerManagement/MyMaterialProduct.page';
import { IStandards } from '../../Customer-Management/MyMaterials/AddMaterialForm.template';
import { ADD_OWN_PRODUCT } from '../../../../utils/types';
import { CustomAccordionV2, IStatus } from '../../../molecules/Accordion/CustomAccordionV2';
import ScrollToTop from '../../../atoms/ScrollToTop/ScrollToTop';
import ButtonV2 from '../../../atoms/ButtonV2';
import { MODE } from '../../../../utils/constant';

const useStyles = createUseStyles((theme: any) => ({
    highlightedText: {
        color: theme.customerPalette.text.primary.primary400
    },
    tertiary100Border: {
        border: `1px solid ${theme.customerPalette.border.neutral.neutral100}`
    },
    darkHeading: {
        color: theme.customerPalette.text.primary.primary400
    },
    borderTop: {
        borderTop: `1px solid ${theme.customerPalette.border.neutral.neutral100}`
    },
    activatedProgressBar: {
        backgroundColor: theme.customerPalette.background.primary.primary500
    },
    activeProgressBar: {
        backgroundColor: theme.customerPalette.background.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.customerPalette.border.neutral.neutral100
    },
    barHeading: {
        color: theme.customerPalette.text.primary.primary500
    },
    unTouchedBarHeading: {
        color: theme.customerPalette.text.neutral.neutral200,
    },
    previousActiveHeading: {
        color: theme.customerPalette.background.primary.primary400
    },
    mainHeading: {
        color: theme.customerPalette.text.primary.primary900,
    },
    subHeading: {
        color: theme.customerPalette.text.neutral.neutral700
    },
}));

export interface IConfigurationShape {
    name: string,
    label: string,
    prefix: string
}

const OwnProductReviewTemplate: React.FC<AddVendorOwnProductSectionProps> = ({ warehouses, formik, setCurrentSectionTo, handleCancel }) => {

    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const [attributes, setAttributes] = useState<IMaterialAttribute[]>([]);
    const [attributesOriginal, setAttributesOrigianl] = useState<IMaterialAttribute[]>([]);
    const [activeAccordion, setActiveAccordion] = useState<number>(0);
    const [standards, setStandards] = useState<IStandards[]>([]);
    const [standardList, setStandardList] = useState<number[]>([]);

    const handleBack = () => {
        setCurrentSectionTo(ADD_OWN_PRODUCT.PRODUCT_DETAILS);
    }

    const handleNext = () => {
        formik.handleSubmit()
    }

    const handleCurrentActiveAccordion = (index: number) => {
        setActiveAccordion(activeAccordion === index ? -1 : index);
    }

    const handleOnDelete = (id: number) => {
        const filteredMaterialRequirements = formik.values.productDetails.filter((productDetail, i) => productDetail.id != id)
        formik.setFieldValue('materialRequirement', filteredMaterialRequirements);
        formik.setFieldValue('noOfRows', filteredMaterialRequirements.length);
        formik.setFieldValue(`deletedRowIndex`, [...formik.values.deletedRowIndex as number[], id]);
    }

    const getFormStatus = (formik: FormikProps<IAddOwnProductFormik>, index: number): IStatus => {
        if (formik.values?.productDetails?.[index]?.isDuplicate) {
            return { message: "Duplicate combination, Please fill the unique combination", type: "error" }
        } else if (formik?.touched?.productDetails?.[index])
            if (formik.errors?.productDetails?.[index] === undefined)
                return { message: "Filled", type: "success" }
            else
                return { message: "Please fill the data", type: "error" }
        else {
            return { message: "", type: "error" }
        }
    }

    const checkValidSubmit = (errors: FormikErrors<IAddOwnProductFormik>) => {
        const error = (errors.productDetails as FormikErrors<IMaterialRequirement>[])?.filter((_, i) => !formik.values.deletedRowIndex?.includes(i))
        if (!formik.values.noOfRows) return true
        else if (!!error?.filter(el => el).length) return true
        else if (formik.values.noOfRows !== 0 && !!error?.filter(el => el).length) return true
        else return false
    }

    return (
        <ScrollToTop>
            <div className={`grid gap-y-3`}>
                {SnackBarComponent}
                <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm`}>
                    <div className="grid gap-y-3 w-1/2">
                        <div className='flex gap-x-2 mx-auto'>
                            <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                            <div className={`${classes.previousActiveHeading} my-auto`}>Select Product Type </div>
                        </div>
                        <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                    </div>
                    <div className="grid gap-y-3 w-1/2">
                        <div className='flex gap-x-2 mx-auto'>
                            <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                            <div className={`${classes.previousActiveHeading} my-auto`}>Add Product Details</div>
                        </div>
                        <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                    </div>
                    <div className="grid gap-y-3 w-1/2">
                        <div className='flex gap-x-2 mx-auto'>
                            <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                            <div className={`${classes.barHeading} my-auto font-semibold`}>Review</div>
                        </div>
                        <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='flex justify-between mt-5'>
                        <div className={`${classes.mainHeading} text-base font-medium`}>Review</div>
                        <div className='flex justify-between'>
                            <div className={`text-2xl font-medium ${classes.highlightedText}`}>{""}</div>
                            <div className={`text-case font-normal my-auto ${classes.darkHeading}`}>No of product added: <span>{formik.values.noOfRows}</span></div>
                        </div>
                    </div>
                    <div className={`${classes.subHeading} text-xs mb-3`}>Please review the data you have entered</div>
                </div>
                <form onSubmit={formik.handleSubmit} className={`grid gap-y-6`}>
                    <div className={`flex flex-col gap-y-8 `}>
                        {attributes && (formik.values.productDetails as IMaterialRequirement[] ?? [])?.map((materialRequirement: IMaterialRequirement, index: number) =>
                        (
                            <CustomAccordionV2
                                index={materialRequirement?.id as number}
                                expanded={activeAccordion}
                                component={
                                    <div className={`${classes.borderTop} flex flex-col gap-6 mx-6 mb-6 pt-3 -mt-6`}>
                                        <OwnProductReviewDetailsTemplate formik={formik} warehouses={warehouses} setCurrentSectionTo={setCurrentSectionTo} handleCancel={handleCancel} index={index} attributes={attributes ?? []} attributesOriginal={attributesOriginal ?? []} setAttributes={setAttributes} standards={standards} mode={MODE.ADD} />
                                    </div>}
                                heading={`Product Detail ${index + 1}`}
                                onChange={handleCurrentActiveAccordion}
                                onDelete={handleOnDelete}
                                isDeletable={false}
                                status={getFormStatus(formik, materialRequirement?.id as number)}
                            />))}
                    </div>
                </form>
                <div className='flex justify-between pt-6'>
                    <ButtonV2
                        variant="tertiaryContained"
                        label="Cancel"
                        onClick={handleCancel}
                    />
                    <div className='flex gap-6 '>
                        <ButtonV2
                            variant="tertiaryContained"
                            label="Back"
                            onClick={handleBack}
                        />
                        {/* <ButtonV2
                        variant="secondaryContained"
                        label="Save As Draft"
                        // onClick={handleBack}
                    /> */}
                        <ButtonV2
                            variant="primaryContained"
                            label="Add"
                            onClick={handleNext}
                        />
                    </div>
                </div>
            </div>
        </ScrollToTop>
    )
}

export default OwnProductReviewTemplate