import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export interface IProductsParams {
    page: number;
    size: number;
    classType: string;
    status?: string;
    search?: string;
    category?: string;
    productIdString?: string;
    sort?: string;
}

interface WarehouseWiseProductDetailsRequestBody {
    minimumOrderQuantity: number;
    warehouseId: number;
    deliveryTimeFrame: string;
}

export interface ISkuCodeParams {
    skuCode: string;
    page: number;
    size: number;
    sort: string;
}

export interface IVendorProductRequestBodyDTO {
    productId: number;
    classType: string;
    status: string;
    warehouseWiseProductDetails: WarehouseWiseProductDetailsRequestBody[];
}

export const PRODUCT_URLS = {
    GET_ALL_PRODUCTS: "/vendor/:vendorId/products", 
    GET_PRODUCT_BY_ID: "/vendor/:vendorId/product/:vendorProductId", 
    UPSERT_VENDOR_PRODUCT: "/vendor/:vendorId/product",
    VENDOR_PRODUCT_STATE: "/vendor/:vendorId/stats/:statType",
    GET_SKU_CODE: "/secondary-products",
    GET_PRODUCT_DETAILS: "/brand-upc/:id"
};

export const usePostProductService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();
    const v2BaseUrl=process.env.REACT_APP_API_V2_URL;

    const getAllProducts = (params: IProductsParams, vendorId: number | null) => {
        return request(API_METHOD.GET, replaceUrlParams(PRODUCT_URLS.GET_ALL_PRODUCTS, { vendorId }), authenticatedUser, null, { params });
    };

    const getProductById = (vendorId: number | null, vendorProductId: number) => {
        return request(API_METHOD.GET, replaceUrlParams(PRODUCT_URLS.GET_PRODUCT_BY_ID, { vendorId, vendorProductId }), authenticatedUser);
    };

    const upsertVendorProduct = (vendorId: number, data: IVendorProductRequestBodyDTO) => {
        return request(API_METHOD.POST, replaceUrlParams(PRODUCT_URLS.UPSERT_VENDOR_PRODUCT, { vendorId }), authenticatedUser, data);
    };

    const getVendorProductStats = (vendorId: number, statType: string) => {
        return request(API_METHOD.GET, replaceUrlParams(PRODUCT_URLS.VENDOR_PRODUCT_STATE, { vendorId, statType }), authenticatedUser);
    };

    const getAllSkuCodes = (params: ISkuCodeParams) => {
        return request(API_METHOD.POST, PRODUCT_URLS.GET_SKU_CODE, authenticatedUser, {}, { params });
    };

    const getProductDetailsById = (id: number | null) => {
        return request(API_METHOD.GET, replaceUrlParams(PRODUCT_URLS.GET_PRODUCT_DETAILS, { id }), authenticatedUser, null, null, null, v2BaseUrl);
    }

    return {
        getAllProducts,
        getProductById,
        upsertVendorProduct,
        getVendorProductStats,
        getAllSkuCodes,
        getProductDetailsById
    };
};