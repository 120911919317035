import React from 'react'
import { createUseStyles } from 'react-jss';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { MODE, SERVICE_STATUS_OPTIONS } from '../../../../utils/constant';
import ButtonV2 from '../../../atoms/ButtonV2';
import { BUNDLE_FORM_STATE } from '../../../pages/CatalogueBundling/Bundle/BundleCreate.page';
import StatusV2 from '../../../atoms/Status/StatusV2';
import TableV2, { ColumnType } from '../../../organisms/TableV2';
import { snakeCaseToTitleCase, toInr } from '../../../../utils/helper';
import moment from 'moment';

export interface ReviewFormTemplateProps {
    mode: string;
    onClose: () => void;
    formik: any;
    setFormStateTo: (state: BUNDLE_FORM_STATE) => () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
        lineHeight: "22px"
    },
    desc: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    property: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    previousProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary600
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    location: {
        color: theme.palette.textV2.tertiary.tertiary900,
        "& span": {
            color: theme.palette.textV2.tertiary.tertiary600
        }
    },
}));

const ReviewFormTemplate: React.FC<ReviewFormTemplateProps> = ({ mode, onClose, formik, setFormStateTo }) => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const handleNext = () => {
        formik.setTouched({ name: true, moq: true, price: true, startDate: true, expiryDate: true }, true)
        const errors = Object.keys(formik.errors).find(key => ['name', 'moq', 'price', 'startDate', 'expiryDate'].includes(key))
        if (errors) return;
        formik.handleSubmit()
    }

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: 0,
            currentPage: 0,
            isVisible: false,
            limit: 0,
        },
        columns: [
            { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Product Code", key: "productCode", type: "string" as ColumnType, props: { className: '' } },
            { label: "Product Name", key: "productCode", type: "string" as ColumnType, props: { className: '' } },
            { label: "MOQ", key: "moq", type: "string" as ColumnType, props: { className: '' } },
            { label: "Available Stock", key: "availableStock", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "PDP Price (Ex. tax)", key: "price", type: "string" as ColumnType, props: { className: '' } },
            { label: "Bundle MOQ", key: "bundleMoq", type: "string" as ColumnType, props: { className: '' } },
        ],
    };

    const records = formik.values.productDetails?.map((product: any, index: number) => [
        index + 1,
        product?.productCode,
        product?.productName,
        `${product?.moq} MT`,
        `${product?.availableStock} MT`,
        toInr(product?.price),
        `${product?.bundleMoq} MT`,
    ]);

    return (
        <div className="grid gap-y-8" >
            {SnackBarComponent}
            <div className={`w-full m-auto flex justify-between gap-x-2 text-center text-sm `}>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.previousProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto text-sm font-semibold`}>Bundle Details</div>
                    </div>
                    <div className={`${classes.previousProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.previousProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Edit Products</div>
                    </div>
                    <div className={`${classes.previousProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/3">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className='grid gap-y-4'>
                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className='flex justify-between'>
                        <div className={`${classes.heading} text-base font-medium`}>Bundle Details</div>
                        {mode === MODE.ADD &&
                            <ButtonV2 variant="secondaryContained" size="small" label={'Edit'} onClick={setFormStateTo(BUNDLE_FORM_STATE.BUNDLE_DETAILS)} />
                        }
                    </div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Bundle Name</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.name}</div>
                        </div>

                        {
                            mode === MODE.UPDATE &&
                            <div className="grid gap-y-1">
                                <div className={`${classes.property}`}>Bundle Code</div>
                                <div className={`${classes.heading} text-base font-medium`}>{formik.values?.bundleCode}</div>
                            </div>
                        }
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className='flex justify-between'>
                        <div className='grid gap-y-1'>
                            <div className={`${classes.heading} text-base font-medium`}>Selected Products</div>
                            <div className={`${classes.location} text-base font-medium`}>
                                Source Location: <span className='text-sm '>New Delhi</span>
                            </div>
                        </div>
                        <ButtonV2 variant="secondaryContained" size="small" label={'Edit'} onClick={setFormStateTo(BUNDLE_FORM_STATE.EDIT_PRODUCTS)} />
                    </div>
                    <TableV2 schema={schema} records={records} />
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className='flex justify-between'>
                        <div className={`${classes.heading} text-base font-medium`}>Bundle Details</div>
                        <ButtonV2 variant="secondaryContained" size="small" label={'Edit'} onClick={setFormStateTo(BUNDLE_FORM_STATE.EDIT_PRODUCTS)} />
                    </div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Bundle MOQ</div>
                            <div className={`${classes.heading} text-base font-medium`}>{formik.values?.moq} MT</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Bundle Price</div>
                            <div className={`${classes.heading} text-base font-medium`}>{toInr(formik?.values?.price)}</div>
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className='flex justify-between'>
                        <div className={`${classes.heading} text-base font-medium`}>Valid From & Valid Until</div>
                        <ButtonV2 variant="secondaryContained" size="small" label={'Edit'} onClick={setFormStateTo(BUNDLE_FORM_STATE.EDIT_PRODUCTS)} />
                    </div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Start Date</div>
                            <div className={`${classes.heading} text-base font-medium`}>{moment(formik.values?.startDate).format('DD-MM-YYYY HH:mm:ss')}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Expiry Date</div>
                            <div className={`${classes.heading} text-base font-medium`}>{moment(formik.values?.expiryDate).format('DD-MM-YYYY HH:mm:ss')}</div>
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-4 rounded-xl`}>
                    <div className='flex justify-between'>
                        <div className={`${classes.heading} text-base font-medium`}>Other Details</div>
                        <ButtonV2 variant="secondaryContained" size="small" label={'Edit'} onClick={setFormStateTo(BUNDLE_FORM_STATE.EDIT_PRODUCTS)} />
                    </div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Packaging Type</div>
                            <div className={`${classes.heading} text-base font-medium`}>{snakeCaseToTitleCase(formik.values?.packagingType)}</div>
                        </div>
                        <div className="grid gap-y-1">
                            <div className={`${classes.property}`}>Delivery Term</div>
                            <div className={`${classes.heading} text-base font-medium`}>{snakeCaseToTitleCase(formik.values?.deliveryTerm)}</div>
                        </div>
                    </div>
                </div>

                <StatusV2 heading="Bundle Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" />
            </div>

            <div className='flex justify-between'>
                <ButtonV2 variant="tertiaryContained" label='Cancel' onClick={onClose} />
                <div className="flex justify-end gap-4">
                    <ButtonV2 variant="tertiaryContained" label='Back' onClick={setFormStateTo(BUNDLE_FORM_STATE.EDIT_PRODUCTS)} />
                    {mode !== MODE.VIEW && <ButtonV2 variant="primaryContained" onClick={handleNext} label={"Save Bundle"} />}
                </div>
            </div>
        </div>
    )
}

export default ReviewFormTemplate;