import { Route, Routes } from 'react-router-dom';
import RfqListPage from '../../../components/pages/Rfq/RfqList.page';
import RfqViewPage from '../../../components/pages/Rfq/RfqView.page';

const RfqRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<RfqListPage />} />
            <Route path=":id" element={<RfqViewPage />} />
        </Routes>
    )
}

export default RfqRoutes