import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import RfqListTemplate from '../../templates/Rfq/RfqList.template';
import { HTTP_STATUS, IPagination } from '../../../utils/types';
import { initialPaginationValues } from '../../../utils/constant';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { IRfqRequestParams, IRfqSearchParams, useRfqService } from '../../../services/useRfqService';

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary950,
    },
}));

const initialRfqFiltersValues: IRfqSearchParams = {
    search: "",
    sort: "createdAt,desc",
}

const initialRfqRequestFilters: IRfqRequestParams = {
    productCategoryIds: [],
};

const RfqListPage: React.FC = () => {
    const classes = useStyles();
    const rfqService = useRfqService();
    const [filters, setFilters] = useState<IRfqSearchParams>(initialRfqFiltersValues);
    const [rfqRequestFilters, setRfqRequestFiltersTo] = useState<any>(initialRfqRequestFilters);
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const [rfqs, setRfqs] = useState<any>([])
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const refreshRfq = async (page: number, size: number) => {
        // const payload: IRfqRequestParams = {
        //     productCategoryIds: rfqRequestFilters?.productCategoryIds?.length > 0 ? rfqRequestFilters?.productCategoryIds : null,
        // };
        await rfqService.getRfqs(
            {
                page: page,
                size: size,
                sort: filters?.sort,
                search: filters?.search?.trim(),
            },
            // payload
        )
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    const { totalElements, totalPages } = res?.data?.data;
                    setPagination({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setRfqs(res?.data?.data?.content)
                }
            }).catch(error => {
                showSnackbar('error', error);
                setRfqs([]);
            })
    }

    const handleFiltersChange = (name: string, value: any) => {
        setPagination({ ...pagination, currentPage: 0 });
        setFilters({ ...filters, [name]: value ?? "" });
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPagination((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    useEffect(() => {
        refreshRfq(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize, filters, rfqRequestFilters]);

    return (
        <div className='grid gap-y-4'>
            {SnackBarComponent}
            <div className='flex justify-between'>
                <div className={`${classes.heading} text-base font-semibold my-auto`}>RFQ</div>
            </div>
            <RfqListTemplate
                rfqs={rfqs}
                pagination={pagination}
                handlePaginationChange={handlePaginationChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handleFiltersChange={handleFiltersChange}
                filters={filters}
                rfqRequestFilters={rfqRequestFilters}
                setRfqRequestFiltersTo= {setRfqRequestFiltersTo}
            />
        </div>
    )
}

export default RfqListPage;