import { createRoot } from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import { defaultTheme } from './utils/theme';
import { theme } from './utils/theme';
import { SnackbarProvider } from './contexts/SnackBarContext';
import {
  AuthenticatedUserContext,
  AuthenticatedUserProvider,
} from './contexts/AuthenticatedUserContext';
import Authentication from './components/pages/Authentication/Authentication.page';
// *********************************TODO- NewInventoryProvider to be removed after catalogue refactor KASHIF************************************ */
import NewInventoryProvider from './App/Core/Context/inventory/NewInventoryProvider';
import { AdminRouter } from './routes';
// *********************************TODO- NewInventoryProvider to be removed after catalogue refactor KASHIF************************************ */
import { StyledEngineProvider } from '@mui/material';

const root = createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={defaultTheme}>
    <StyledEngineProvider injectFirst>
      <AuthenticatedUserProvider>
        <NewInventoryProvider>
          <SnackbarProvider>
            <AuthenticatedUserContext.Consumer>
              {({ user }) => {
                return (
                  <BrowserRouter>
                    <div>
                      {user?.token && user?.email ? (
                        <AdminRouter />
                      ) : (
                        <Authentication />
                      )}
                    </div>
                  </BrowserRouter>
                );
              }}
            </AuthenticatedUserContext.Consumer>
          </SnackbarProvider>
        </NewInventoryProvider>
      </AuthenticatedUserProvider>
    </StyledEngineProvider>
  </ThemeProvider>
);
