import { request } from ".";
import { API_METHOD } from '../utils/constant';
import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { replaceUrlParams } from "../utils/helper";

export const UPC_URLS = {
  GET_PRODUCT_UPC_BRAND_MAPPING_BY_ID: "/product-upc-brands",
  GET_SIMILAR_BRANDS: "/products/:brandUpcId/similar-brands",
  GET_PRODUCT_DETAILS:"/brand-upc/:id"
};

export interface IProductUPCBrandMappingRequest {
  upcId: number;
  page?: number;
  size?: number;
}

export interface IUpcData {
  id: number;
  brandName: string;
  upcCode: string;
  brandCode: string;
  masterUpcCode: string;
  brandId: number;
}


export const useProductUPCBrandMappingService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();
  const BASE_URL_V2 = process.env.REACT_APP_API_V2_URL;

  const getProductUPCBrandMapping = (params: IProductUPCBrandMappingRequest) => {
    return request(API_METHOD.POST, UPC_URLS.GET_PRODUCT_UPC_BRAND_MAPPING_BY_ID, authenticatedUser, {}, { params });
  };

  const fetchSimilarCataloguesByBrandUpcId = (brandUpcId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(UPC_URLS.GET_SIMILAR_BRANDS, { brandUpcId }), authenticatedUser, null);
  }

  const getProductDetailsById = (id: number | null) => {
    return request(API_METHOD.GET, replaceUrlParams(UPC_URLS.GET_PRODUCT_DETAILS, { id }), authenticatedUser, null, undefined, null, BASE_URL_V2);
  }

  return {
    getProductUPCBrandMapping,
    fetchSimilarCataloguesByBrandUpcId,
    getProductDetailsById
  }
}
