import { createUseStyles } from "react-jss";
import React, { useEffect, useState } from "react";
import { PaymentTermsSectionProps } from "../../../../utils/types";
import { PAYMENT_METHODS, PAYMENT_SELECTION, PAYMENT_TERMS, PAYMENT_TERMS_SECTION, PAYMENT_TERMS_SECTION_V2 } from "../../../../utils/constant";

import PaymentIcon from '../../../../assets/images/payment.svg';
import RadioActive from '../../../../assets/images/radioActive.svg';

const useStyles = createUseStyles((theme: any) => ({
    headingContainer: {
        display: "flex",
        justifyContent: "space-between"
    },
    mainheading: {
        color: theme.customerPalette.text.neutral.neutral900,
        lineHeight: "20px"
    },
    mainCointainer: {
        display: "flex"
    },
    container: {
        display: "flex",
        padding: "16px",
        border: `1px solid ${theme.customerPalette.border.neutral.neutral100}`,
        cursor: "pointer",
    },
    paymentWrapper: {
        borderRadius: "4px",
        background: theme.customerPalette.background.success.success50,
        padding: "4px 8px",
    },
    paymentSelection: {
        color: theme.customerPalette.text.success.success500,
    },
    selectedMethod: {
        // border: `2px solid ${theme.customerPalette.v4.border.secondaryLightAccent}`,
    },
    comingSoon: {
        border: `1px solid ${theme.customerPalette.border.secondary.secondary100}`,
        background: theme.customerPalette.background.secondary.secondary50,
        color: theme.customerPalette.text.secondary.secondary500,
    },
    radio: {
        borderRadius: "100px",
        border: `2px solid ${theme.customerPalette.border.primary.primary400}`,
    },
    selectedRadio: {
        backgroundColor: theme.customerPalette.background.primary.primary300,
        borderRadius: "100px",
        border: `2px solid ${theme.customerPalette.border.primary.primary800}`,
    },
    disableRadio: {
        borderRadius: "100px",
        border: `2px solid ${theme.customerPalette.border.neutral.neutral200}`,
        background: theme.customerPalette.background.neutral.neutral50,
    },
    details: {
        color: theme.customerPalette.text.primary.primary800,
        fontSize: "16px"
    },
    subText: {
        color: theme.customerPalette.text.neutral.neutral600,
        fontSize: "14px"
    },
    or: {
        color: theme.customerPalette.text.neutral.neutral900,
        margin: "auto 12px"
    },
    "@media (max-width: 480px)": {
        mainCointainer: {
            display: "grid"
        },
        or: {
            margin: "12px auto"
        },
        container: {
            padding: "16px",
        },
        subText: {
            fontSize: "14px"
        },
        heading: {
            fontSize: "16px"
        },
    },
}))

interface IPaymentMethod {
    paymentType?: any;
    handlePaymentTypeChange: (value: string) => void;
}

const PaymentDetailsTemplateV2: React.FC<IPaymentMethod> = ({ paymentType, handlePaymentTypeChange }) => {

    const classes = useStyles();

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>("CASH_PAYMENT");

    useEffect(() => {
        if (paymentType != null) {
            if (Object.values(PAYMENT_METHODS).includes(paymentType)) {
                setSelectedPaymentMethod(paymentType);
            }
        }
    }, [paymentType])

    const handlePaymentTermsClick = (value: string) => () => {
        setSelectedPaymentMethod(value);
        handlePaymentTypeChange(value);
    };

    const ComingSoonTemplate = (title: string) => {
        return (
            <div className={`px-2 py-1 ${classes.comingSoon} text-xs font-semibold text-center rounded`}>
                {title}
            </div>)
    }

    const paymentSelection = (isAvailable: boolean, value: any) => {
        if (isAvailable) {
            if (selectedPaymentMethod === value) {
                return <img src={RadioActive} className="w-[18px] h-[18px] my-auto" />
            } else {
                return <span className={`${classes.radio} w-[18px] h-[18px] my-auto`} />
            }
        } else {
            return <span className={`${classes.disableRadio} w-[18px] h-[18px] my-auto`} />
        }

    }

    return (
        <div className={`grid gap-y-3`}>
            <div className={`${classes.headingContainer}`}>
                <div className={`${classes.mainheading} my-auto text-base font-bold`}>{PAYMENT_TERMS.PAYMENT_TERMS}</div>
                {selectedPaymentMethod &&
                    <div className={`${classes.paymentWrapper} flex gap-x-1.5 align-center`}>
                        <img src={PaymentIcon} alt="payment" className="w-8 h-8 my-auto" />
                        <span className={`${classes.paymentSelection} text-xs font-medium my-auto`}>{selectedPaymentMethod === PAYMENT_METHODS.CASH_PAYMENT ? PAYMENT_SELECTION.CASH_DOWN_PAYMENT : PAYMENT_SELECTION.CREDIT_PAYMENT}</span>
                    </div>}
            </div>
            <div className={`${classes.mainCointainer} items-start`}>
                {PAYMENT_TERMS_SECTION.map(({ value, heading, subHeading, hasNext, isAvailable }) => (
                    <>
                        <div key={value} className={`${classes.container} flex flex-1 justify-between rounded-xl ${selectedPaymentMethod === value ? classes.selectedMethod : "border"}`} onClick={isAvailable ? handlePaymentTermsClick(value) : () => null}>
                            <div className="w-full">
                                <div className="flex justify-between w-full h-8">
                                    {paymentSelection(isAvailable, value)}
                                    <div>{isAvailable === false ? ComingSoonTemplate("Coming Soon") : ""}</div>
                                </div>
                                <div className={`${classes.details} mb-1`}>{heading}</div>
                                <p className={`${classes.subText}`}>{subHeading}</p>
                            </div>
                        </div>
                        {hasNext ? <div className={`${classes.or} text-base font-semibold w-6 text-center `}>{PAYMENT_TERMS.OR}</div> : ""}
                    </>
                ))}
            </div>
        </div>
    )
}
export default PaymentDetailsTemplateV2;