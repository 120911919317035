import CloseIcon from '../../../assets/images/cross.svg';
import { useFormik } from "formik";
import * as yup from "yup";
import { toCamelCase } from "../../../utils/helper";
import moment from "moment";
import Dialog from '@mui/material/Dialog';
import { createUseStyles } from "react-jss";
import ButtonV2 from "../../atoms/ButtonV2";
import TextFieldV2 from "../../atoms/TextFieldV2";
import { MODE } from "../../../utils/constant";
import { useEffect } from 'react';

interface ChemicalCompositionFormTemplate {
    type: string;
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    record: any;
    handleAction: any;
    associatedGrade: any;
    fullData: any;
}

const useStyles = createUseStyles((theme: any) => ({
    mainContainer: {
        "& .MuiDialog-paper": {
            width: "917px !important",
            maxWidth: "917px !important",
            borderRadius: "16px"
        }
    },
    container: {
        padding: "24px"
    },
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
}));

const ChemicalCompositionFormTemplate: React.FC<ChemicalCompositionFormTemplate> = ({ type, dialogOpen, setDialogOpen, record, handleAction, associatedGrade = null, fullData = [] }) => {
    const classes = useStyles();
    const maxId = fullData.reduce((max: any, obj: any) => (obj.id > max ? obj.id : max), 0);

    const validateFormikFields = yup.object({
        name: yup.string().required("Name is required"),
        maximum: yup.number().typeError("Maximum value must be a number").required("Maximum value is required"),
        minimum: yup.number().typeError("Minimum value must be a number").required("Minimum value is required"),
    });

    const chemicalCompositionFormik = useFormik<any>({
        initialValues: {
            type: "CHEMICAL",
            id: record?.id || maxId + 1,
            name: record?.name || "",
            maximum: record?.maximum || "",
            minimum: record?.minimum || "",
            createdAt: record?.createdAt || moment().format('YYYY-MM-DD'),
            updatedAt: moment().format('YYYY-MM-DD'),
        },
        validationSchema: validateFormikFields,
        onSubmit: (values) => {
            handleAction(values, values?.id);
        },
    });

    useEffect(() => {
        chemicalCompositionFormik.setValues({
            type: "CHEMICAL",
            id: record?.id || maxId + 1,
            name: record?.name || "",
            maximum: record?.maximum || "",
            minimum: record?.minimum || "",
            createdAt: record?.createdAt || moment().format('YYYY-MM-DD'),
            updatedAt: moment().format('YYYY-MM-DD'),
        });
    }, [record, dialogOpen]);

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <Dialog fullWidth className={classes.mainContainer} open={dialogOpen} onClose={handleDialogClose}>
            <form onSubmit={chemicalCompositionFormik.handleSubmit} onKeyDown={handleKeyDown}    >
                <div className="grid p-6 gap-y-6">
                    <div className="flex justify-between pb-3 border-b border-solid border-input-border">
                        <div className={`${classes.mainHeading} text-lg font-semibold`}>{type === MODE.EDIT ? "Edit" : type === MODE.VIEW ? "View" : "Add New"} Element</div>
                        <img src={CloseIcon} alt="icon" className='w-6 h-6 cursor-pointer' onClick={handleDialogClose} />
                    </div>

                    <div className="grid gap-y-4">
                        <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                            <div className={`${classes.heading} text-base font-medium`}>Element Details</div>
                            <div className='grid grid-cols-2 gap-x-4'>
                                <TextFieldV2
                                    label="Grade"
                                    fullWidth
                                    value={associatedGrade?.name}
                                    disabled={true}
                                />

                                <TextFieldV2
                                    label="Element Name/Code"
                                    variant="outlined"
                                    placeholder="Enter Element Name/Code"
                                    fullWidth
                                    name="name"
                                    value={chemicalCompositionFormik.values.name}
                                    onChange={chemicalCompositionFormik.handleChange}
                                    onBlur={(event) => {
                                        const camelCasedValue = toCamelCase(
                                            event.target.value
                                        );
                                        chemicalCompositionFormik.setFieldValue("name", camelCasedValue);
                                        chemicalCompositionFormik.handleBlur(event);
                                    }}
                                    error={
                                        chemicalCompositionFormik.touched.name &&
                                        Boolean(chemicalCompositionFormik.errors.name)
                                    }
                                    InputProps={{ readOnly: type === MODE.VIEW }}
                                />
                            </div>
                        </div>

                        <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                            <div className={`${classes.heading} text-base font-medium`}>Percentage Value</div>
                            <div className='grid grid-cols-2 gap-x-4'>
                                <TextFieldV2
                                    label="Minimum (0 - 99.9999)"
                                    fullWidth
                                    name="minimum"
                                    value={chemicalCompositionFormik.values.minimum}
                                    onChange={(event) => {
                                        if (/^\d{0,2}(\.\d{0,4})?$/.test(event.target.value)) {
                                            chemicalCompositionFormik.handleChange(event);
                                        } else {
                                            chemicalCompositionFormik.setErrors({
                                                ...chemicalCompositionFormik.errors,
                                                [event.target.name]: 'Invalid format',
                                            });
                                        }
                                    }}
                                    onBlur={chemicalCompositionFormik.handleBlur}
                                    error={
                                        chemicalCompositionFormik.touched.minimum &&
                                        Boolean(chemicalCompositionFormik.errors.minimum)
                                    }
                                    helperText={
                                        chemicalCompositionFormik.touched.minimum ? chemicalCompositionFormik.errors.minimum as any : ""
                                    }
                                    InputProps={{ readOnly: type === MODE.VIEW }}
                                />

                                <TextFieldV2
                                    label="Maximum (0 - 99.9999)"
                                    fullWidth
                                    name="maximum"
                                    value={chemicalCompositionFormik.values.maximum}
                                    onChange={(event) => {
                                        if (/^\d{0,2}(\.\d{0,4})?$/.test(event.target.value)) {
                                            chemicalCompositionFormik.handleChange(event);
                                        } else {
                                            chemicalCompositionFormik.setErrors({
                                                ...chemicalCompositionFormik.errors,
                                                [event.target.name]: 'Invalid format',
                                            });
                                        }
                                    }}
                                    onBlur={chemicalCompositionFormik.handleBlur}
                                    error={
                                        chemicalCompositionFormik.touched.maximum &&
                                        Boolean(chemicalCompositionFormik.errors.maximum)
                                    }
                                    helperText={
                                        chemicalCompositionFormik.touched.maximum ? chemicalCompositionFormik.errors.maximum as any : ""
                                    }
                                    InputProps={{ readOnly: type === MODE.VIEW }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end gap-x-3">
                        <ButtonV2 variant="tertiaryContained" onClick={handleDialogClose} label={"Cancel"} />
                        {type !== "VIEW" && (<ButtonV2 variant="secondaryContained" type="submit" label={type === "ADD" ? "Add Element" : "Update Element"} />)}
                    </div>
                </div>
            </form>
        </Dialog >
    );
};

export default ChemicalCompositionFormTemplate;