import React from 'react';
import { createUseStyles } from 'react-jss';
import ButtonV2 from '../../../atoms/ButtonV2';
import { STATIC_PAGES_ROUTES } from '../../../../utils/constant';

interface TermsConditionsProps{
    content?:string
}

const useStyles = createUseStyles((theme: any) => ({
    mainContainer: {
        rowGap: "12px",
    },
    container: {
        border: `1px solid ${theme.customerPalette.border.neutral.neutral100}`
    },
    title: {
        color: theme.customerPalette.text.neutral.neutral900
    },
    content: {
        color: theme.customerPalette.text.neutral.neutral600
    },
    webText: {
        display: "flex",
        marginLeft:"-20px"
    },
    mobileText: {
        display: "none"
    },
    icon: {
        marginRight: "-16px",
    },
    "@media (max-width: 480px)": {
        mainContainer: {
            rowGap: "12px",
        },
        webText: {
            display: "none"
        },
        mobileText: {
            display: "flex"
        },

    },
}));

const TermsConditionsTemplate: React.FC<TermsConditionsProps> = ({content}) => {

    const classes = useStyles();

    return (
        <div className={`${classes.mainContainer} grid`}>
            <div className={`${classes.title} text-base font-bold`}>Terms and Conditions</div>
            <div className={`${classes.container} flex flex-col rounded-xl p-4 items-start gap-1`}>
                <div className={`${classes.content} text-sm font-normal`}>{content ? content : `The actual product may vary from the image shown on this page. We reserve the right to modify product specifications, prices, or availability without prior notice. Please get in touch with us if you have any questions or require further clarification regarding our steel products. Your satisfaction is our priority, and we are here to assist you with any inquiries or concerns.`}</div>

                <div className={classes.webText}>
                    {/**NEEDS TO BE CHANGED */}
                    {/* <ButtonV2 className='' variant="secondaryText" iconPosition='16px'  label="View Terms and Conditions" size='medium' onClick={() => window.open(STATIC_PAGES_ROUTES.TERMS_AND_CONDITIONS, "_blank")} /> */}
                    {/* <ButtonV2 className='' variant="secondaryText" label="View Terms and Conditions" size='medium' onClick={() => window.open(STATIC_PAGES_ROUTES.TERMS_AND_CONDITIONS, "_blank")} /> */}

                </div>

                <div className={classes.mobileText}>
                    <ButtonV2 className='' variant="secondaryText"  label="View More" size='medium' onClick={() => window.open(STATIC_PAGES_ROUTES.TERMS_AND_CONDITIONS, "_blank")} />
                </div>
            </div>
        </div>
    );
};

export default TermsConditionsTemplate;