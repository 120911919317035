import React, { useState } from 'react'
import { IPagination } from '../../../utils/types';
import { ColumnType } from "../../organisms/Table";
import { useNavigate } from 'react-router-dom';
import viewEyeIcon from "./../../../assets/icons/viewEyeIcon.svg"
import editPenIcon from "./../../../assets/icons/editPenIcon.svg";
import { ADMIN_ROUTES, sortOptions, statusOptions } from "../../../utils/constant";
import { enumToString, makeRoute } from "../../../utils/helper";
import moment from "moment";
import Select from '../../atoms/Select';
import ResourceStatus from '../../atoms/ResourceStatus/ResourceStatus';
import { IGrades, IQualityGradeFilter } from '../../../services/useQualityGradesService';
import GradePropertiesListTemplate from '../Grade/GradePropertiesList.template';
import TableV2 from '../../organisms/TableV2';
import TextFieldV2 from '../../atoms/TextFieldV2';
import ResourceStatusV2 from '../../atoms/ResourceStatus/ResourceStatusV2';

interface IGradeListTemplateProps {
    grades: IGrades[];
    pagination: IPagination;
    handleFiltersChange?: any;
    handlePaginationChange: any;
    handleRowsPerPageChange: any;
    gradeFilters: IQualityGradeFilter
}

const GradeListTemplate: React.FC<IGradeListTemplateProps> = ({ grades, pagination, handlePaginationChange, handleRowsPerPageChange, handleFiltersChange, gradeFilters }) => {
    const navigate = useNavigate();
    const [showProperties, setShowProperties] = useState<boolean>(false);
    const [associatedGrade, setAssociatedGrade] = useState<number | null>(null);

    const schema = {
        id: 'standard-list',
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange
        },
        columns: [
            { label: "Sr No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Grade Name", key: "name", type: "string" as ColumnType, props: { className: '' } },
            { label: "Grade Code", key: "prefix", type: "string" as ColumnType, props: { className: '' } },
            { label: "Associated Standard", key: "standardName", type: "string" as ColumnType, props: { className: '' } },
            { label: "Created Date", key: "createdAt", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Last Modified", key: "updatedAt", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ]
    }

    const handleViewClick = (id: number) => (e: any) => {
        navigate(makeRoute(ADMIN_ROUTES.GRADE_VIEW, { params: { id: id } }));
    };

    const handleEditClick = (id: number) => (e: any) => {
        navigate(makeRoute(ADMIN_ROUTES.GRADE_UPDATE, { params: { id: id } }));
    };

    const Action = (id: number) => {
        return (<div className='flex justify-center gap-x-2'>
            <button className={`text-center font-bold ${showProperties && associatedGrade === id ? 'text-orange' : 'text-blue'}`} onClick={() => updatePropertiesAndGrade(id)}>CC/MP</button>
            <button onClick={handleViewClick(id)}><img src={viewEyeIcon} alt={viewEyeIcon} /></button>
            <button onClick={handleEditClick(id)}><img src={editPenIcon} alt={editPenIcon} /></button>
        </div>)
    }

    const records = grades.map((grade: IGrades, index: number) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        grade.name,
        grade.prefix !== null ? grade.prefix : "",
        grade.standardName,
        moment(grade.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        moment(grade.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
        enumToString(grade.status),
        Action(grade.id)
    ]);

    const updatePropertiesAndGrade = (id: number) => {
        if (showProperties && associatedGrade === id) {
            setShowProperties(false);
            setAssociatedGrade(null);
        } else {
            setShowProperties(true);
            setAssociatedGrade(id);
            const label = document.getElementById('scrollToProperties');
            if (label) {
                label.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    return (
        <div className='grid gap-y-3'>
            <div className="flex justify-between">
                <div className="flex gap-x-3">
                    <div>
                        <Select
                            variant="outlined"
                            className="w-44"
                            label="Status"
                            name="status"
                            id="status"
                            value={gradeFilters.status}
                            onChange={(e) => handleFiltersChange(e)}
                            options={statusOptions}
                        />
                    </div>
                    <div>
                        <Select
                            variant="outlined"
                            className='w-64'
                            id="sort"
                            label="Sort"
                            name="sort"
                            value={gradeFilters.sort}
                            onChange={(e) => handleFiltersChange(e)}
                            options={sortOptions}
                        />
                    </div>
                </div>
                <TextFieldV2
                    className="w-72"
                    type="text"
                    placeholder='Search...'
                    name="search"
                    value={gradeFilters.search}
                    onChange={handleFiltersChange}
                />
            </div>
            <TableV2 schema={schema} records={records} />

            <div id="scrollToProperties">
                {showProperties && (
                    <div className="grid" >
                        <GradePropertiesListTemplate gradeId={associatedGrade} />
                    </div>
                )}
            </div>

        </div>
    )
}

export default GradeListTemplate
