import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

const CATALOGUE_APPROVAL_URLS = {
    GET_ALL_CATALOGUE_APPROVAL_STATS: "/vendor-catalogue-stats",
    GET_VENDOR_BY_ID: "/business/vendor/:id",
    GET_VENDOR_CATALOGUES_TO_BE_APPROVAL: "/vendor-catalogue-approvals",
    UPDATE_VENDOR_CATALOGUE_APPROVAL: "/approval/:id",
    GET_APPROVAL_HISTORY: "/approval/approval-history",
    GET_USN_DETAILS: "/vendors/:vendorId/products/:vendorProductId",
};

export interface ICatalogueApprovalListParams {
    page?: number;
    size?: number;
    productCategory: number | null;
    status: string;
    totalProductCount: string;
    search: string;
    sort?: string;
}

export interface ICatalogueVendorApprovalListingParams {
    page?: number;
    size?: number;
    status: string;
    vendorId?: number;
    sort?: string;
}

export interface ICatalogueApprovalRequestBody {
    status: string;
    vendorComment: string;
}

export interface IApprovalHistoryParams {
    vendorId?: number;
    catalogueId: number | bigint;
    resourceType: string;
}

export const useCatalogueApprovalService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();
    const v2BaseUrl = process.env.REACT_APP_API_V2_URL;

    const getCataloguesToBeApproved = (params: ICatalogueApprovalListParams) => {
        return request(API_METHOD.GET, CATALOGUE_APPROVAL_URLS.GET_ALL_CATALOGUE_APPROVAL_STATS, authenticatedUser, null, { params }, null, v2BaseUrl);
    };

    const getVendorDetails = (id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_APPROVAL_URLS.GET_VENDOR_BY_ID, { id }), authenticatedUser);
    };

    const getVendorCatalogues = (params: ICatalogueVendorApprovalListingParams) => {
        return request(API_METHOD.GET, CATALOGUE_APPROVAL_URLS.GET_VENDOR_CATALOGUES_TO_BE_APPROVAL, authenticatedUser, null, { params }, null, v2BaseUrl);
    };

    const updateCatalogueApprovalForVendor = (id: number, data: ICatalogueApprovalRequestBody) => {
        return request(API_METHOD.PUT, replaceUrlParams(CATALOGUE_APPROVAL_URLS.UPDATE_VENDOR_CATALOGUE_APPROVAL, { id }), authenticatedUser, data);
    };

    const getApprovalHistory = (params: IApprovalHistoryParams) => {
        return request(API_METHOD.GET, CATALOGUE_APPROVAL_URLS.GET_APPROVAL_HISTORY, authenticatedUser, null, { params }, null);
    };

    const getVendorUsnDetails = (vendorId: number, vendorProductId: number) => {
        return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_APPROVAL_URLS.GET_USN_DETAILS, {vendorId, vendorProductId}), authenticatedUser, null, null, null, v2BaseUrl);
    };


    return {
        getCataloguesToBeApproved,
        getVendorDetails,
        getVendorCatalogues,
        updateCatalogueApprovalForVendor,
        getApprovalHistory,
        getVendorUsnDetails
    }
};
