import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import closeRoundIcon from '../../../assets/images/closeRoundIcon.svg';
import { createUseStyles } from 'react-jss';
import VendorViewHistoryTemplate from './VendorViewHistory.template';
import { useCatalogueApprovalService } from '../../../services/useCatalogueApprovalService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import CatalogueDetails from '../../pages/Vendor/Catalogue/CatalogueDetails.page';


interface IViewCatalogueDetailsDialogTemplateProps {
    viewCatalogueDetailsDialogOpen: boolean;
    setViewCatalogueDetailsDialogOpen: (open: boolean) => void;
    catalogueId?: number;
    vendorId?: number;
}


const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "1500px !important",
            maxWidth: "1500px !important",
            height: "800px",
            borderRadius: "16px",
            padding: "24px"
        }
    },
    closeRoundIcon: {},
    heading: {
        color: "#142252"
    },
    border: {
        borderBottom: `1px solid #D6DDF5 !important`,
    },

}));

const ViewCatalogueDetailsDialogTemplate: React.FC<IViewCatalogueDetailsDialogTemplateProps> = ({ viewCatalogueDetailsDialogOpen, setViewCatalogueDetailsDialogOpen, catalogueId, vendorId }) => {
    const classes = useStyles();


    const handleDialogClose = () => {
        setViewCatalogueDetailsDialogOpen(false);
    };


    return (
        <Dialog fullWidth className={classes.container}
            open={viewCatalogueDetailsDialogOpen} onClose={handleDialogClose}>
            <div style={{ position: 'relative', display: 'inline-block' }}>
                <div
                    className="cursor-pointer"
                    onClick={handleDialogClose}
                    style={{ position: 'absolute', right: '0px' }}
                >
                    <img src={closeRoundIcon} alt="closeIcon" />
                </div>
                <div className={`${classes.heading} text-3xl font-semibold`}>View Catalogue</div>
            </div>
            <div className={`${classes.border} my-8`}></div>

          <CatalogueDetails vendorId={vendorId} catalogueId={catalogueId}/>

        </Dialog>
    );
};

export default ViewCatalogueDetailsDialogTemplate;
