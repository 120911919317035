import { Route, Routes } from 'react-router-dom';
import VendorProfileTemplate from '../../../components/templates/CatalogueApproval/VendorProfile.template';
import CatalogueApprovalListPage from '../../../components/pages/CatalogueApproval/CatalogueApprovalList.page';

const CatalogueApprovalRouter = () => {

  return (
    <div>
      <Routes>
        <Route path="" element={<CatalogueApprovalListPage />} />
        <Route path="/:id" element={<VendorProfileTemplate />} />
      </Routes>
    </div>
  )
}

export default CatalogueApprovalRouter