import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import closeRoundIcon from '../../../assets/images/closeRoundIcon.svg';
import { createUseStyles } from 'react-jss';
import { HTTP_STATUS } from '../../../utils/types';
import { useCatalogueApprovalService } from '../../../services/useCatalogueApprovalService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import StockAndPriceOverviewDetailsTemplate from './StockAndPriceOverviewDetails.template';
import TabsV2, { ITabsSchema } from '../../molecules/TabsV2/TabsV2';
import { capitalizeFirstLetter } from '../../../utils/helper';


interface IStockPriceOverviewDialogTemplateProps {
    stockPriceDialogOpen: boolean;
    setstockPriceDialogOpen: (open: boolean) => void;
    productId: number | undefined;
    vendorId: number | undefined;
}

export interface IVendorCatalogueUsnDetails {
    locationId: number;
    locationName: string;
    remainingStock: number;
    totalStock: number;
    deliveryInformation: string;
    minimumOrderQuantity: number;
    reserveSalesPrice: number;
    priceValidityFrom: string;
    priceValidityTo: string;
    marketPrice: number;
}


const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "1122px !important",
            maxWidth: "1297px !important",
            height: "900px",
            borderRadius: "16px",
            padding: "24px"
        }
    },
    closeRoundIcon: {},
    heading: {
        color: "#142252"
    },
    border: {
        borderBottom: `1px solid #D6DDF5 !important`,
    },
    selectBox: {
        border: `1px solid #E5E6E6`,
    },
    subHeading: {
        color: "#4A4D4F"
    }
}));

const StockPriceOverviewDialogTemplate: React.FC<IStockPriceOverviewDialogTemplateProps> = ({ stockPriceDialogOpen, setstockPriceDialogOpen, productId, vendorId }) => {
    const classes = useStyles();
    const catalogueApprovalService = useCatalogueApprovalService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [vendorUsnDetails, setVendorUsnDetails] = useState<IVendorCatalogueUsnDetails[]>([]);
    const [activeTab, setActiveTab] = useState('1');

    const handleDialogClose = () => {
        setstockPriceDialogOpen(false);
    };

    const schema: ITabsSchema[] = vendorUsnDetails?.map((details: IVendorCatalogueUsnDetails, index: number) => ({
        label: capitalizeFirstLetter(details.locationName),
        component: <StockAndPriceOverviewDetailsTemplate vendorUsnDetails={details} />
    })) ?? [];

    const fetchVendorUsnDetails = async () => {
        if (vendorId && productId) {
            try {
                const usnDetailsResponse = await catalogueApprovalService.getVendorUsnDetails(vendorId, productId);
                if (usnDetailsResponse.status === HTTP_STATUS.OK) {
                    const details = usnDetailsResponse?.data?.data;
                    setVendorUsnDetails(details);
                }
            } catch (error) {
                showSnackbar('error', 'Vendor USN Details fetched failed');
            }
        }
    }
    useEffect(() => {
        fetchVendorUsnDetails();
    }, [productId, vendorId]);

    return (
        <Dialog fullWidth className={classes.container}
            open={stockPriceDialogOpen} onClose={handleDialogClose}>
            <div style={{ position: 'relative', display: 'inline-block' }}>
                <div
                    className="cursor-pointer"
                    onClick={handleDialogClose}
                    style={{ position: 'absolute', right: '0px' }}
                >
                    <img src={closeRoundIcon} alt="closeIcon" />
                </div>
                <div className={`${classes.heading} text-3xl font-semibold`}>Stock & Price Overview</div>
            </div>
            <div className={`${classes.border} my-8`}></div>
            <div className={`${classes.heading} mb-4 text-lg font-bold`}>View Inventory, Pricing, Delivery and Order Details</div>
            <div className={`${classes.subHeading} font-medium mb-3`}>Total Number of Location: {vendorUsnDetails.length}</div>
            <div className={`${classes.selectBox} w-full rounded-xl p-4`}>
                <div className='font-medium mb-4'>USN Details</div>
                <TabsV2 schema={schema} value={(activeTab).toString()} setValue={setActiveTab} />
            </div>

        </Dialog>
    );
};

export default StockPriceOverviewDialogTemplate;
