import { IBrandUpcs, IMasterUpcs } from "../components/pages/UpcListing/UpcListing.page";

export enum AUTH_STATES {
    LOGIN_WITH_EMAIL = "LOGIN_WITH_EMAIL",
    LOGIN_WITH_MOBILE = "LOGIN_WITH_MOBILE",
    USER_SIGNUP = "USER_SIGNUP",
    USER_FORM = "USER_FORM",
    FORGOT_PASSWORD = "FORGET_PASSWORD",
    OTP_VERIFICATION = "OTP_VERIFICATION"
}

export enum HTTP_STATUS {
    OK = 200,
    ACCEPTED = 202,
    NO_CONTENT = 204,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    METHOD_NOT_ALLOWED = 405,
    INTERNAL_SERVER_ERROR = 500
}

export enum KEY_PERSON_DETAIL_STATES {
    VIEW_KEY_PERSON_DETAILS = "VIEW_KEY_PERSON_DETAILS",
    ADD_KEY_PERSON_NO = "ADD_KEY_PERSON_NO",
    ADD_KEY_PERSON_DETAILS = "ADD_KEY_PERSON_DETAILS",
}

export enum INVENTORY_SECTIONS {
    UPC_CODE = "UPC_CODE",
    INVENTORY_DETAILS = "INVENTORY_DETAILS",
    IMAGES_UPLOAD = "IMAGES_UPLOAD",
    PREVIEW = "PREVIEW",
}

export enum SECONDARY_INVENTORY_SECTIONS {
    CATEGORY = "CATEGORY",
    DEFECT_DETAILS = "DEFECT_DETAILS",
    WAREHOUSE = "WAREHOUSE",
    IMAGES_UPLOAD = "IMAGES_UPLOAD",
}

export enum SECONDARY_CATALOGUE_SECTIONS {
    SECONDARY_INVENTORY = "SECONDARY_INVENTORY",
    CATALOGUE_DETAILS = "CATALOGUE_DETAILS",
    PRICE_DETAILS = "PRICE_DETAILS",
    IMAGES_UPLOAD = "IMAGES_UPLOAD",
    PREVIEW = "PREVIEW",
}
export interface IWarehouse {
    id: number;
    name: string;
    shortName: string;
    address: string;
    type: string;
    updatedAt: string;
    updatedBy: number;
}

export enum HONORIFICS {
    MR = "Mr.",
    MRS = "Mrs."
}

export enum WHATSAPP_LINK {
    PRIMARY = "primary",
    SECONDARY = "secondary"
}

export interface IPagination {
    currentPage: number;
    pageSize: number;
    totalPages: number;
    totalRecords: number;
};

export enum MANUFACTURER_SECTIONS {
    MANUFACTURER_DETAILS = "MANUFACTURER_DETAILS",
    MANUFACTURING_LOCATION_DETAILS = "MANUFACTURING_LOCATION_DETAILS",
    POINT_OF_CONTACT = "POINT_OF_CONTACT"
}

export enum PRODUCT_CATEGORY_FORM_STATE {
    PRODUCT_CATEGORY = 1,
    ATTRIBUTE_MANAGEMENT = 2,
    STANDARD_MANAGEMENT = 3
}
export interface option {
    value: string;
    label: string;
}

export type MakeRouteParams = {
    params?: { [key: string]: any } | null;
    query?: { [key: string]: any } | null;
};

export enum CLASS_TYPE {
    STANDARD = "STANDARD",
    SECONDARY = "SECONDARY",
    MAKE_TO_ORDER = "MAKE_TO_ORDER",
    NONSTANDARD = "NONSTANDARD"
}

export enum CATEGORY_TYPE {
    SUPER_CATEGORY = 0,
    MAIN_CATEGORY = 1,
    PRODUCT_CATEGORY = 2
}

export enum RESOURCE_TYPE {
    PRODUCT_UPCS = "PRODUCT_UPCS",
    PRODUCT_MPCS = 'PRODUCT_MPCS',
    DOWNLOAD_BRAND_UPC = "DOWNLOAD_BRAND_UPC"
}

export enum LOCATION_STATES {
    VIEW_LOCATIONS = "VIEW_LOCATIONS",
    ADD_LOCATION_NO = "ADD_LOCATION_NO",
    ADD_LOCATIONS = "ADD_LOCATIONS",
    PRODUCT_UPCS = "PRODUCT_UPCS",
    PRODUCT_MPCS = "PRODUCT_MPCS"
}

export enum CUSTOMER_GROUP_SECTIONS {
    CUSTOMER_GROUP_MASTER_FORM = "CUSTOMER_GROUP_MASTER_FORM",
    CUSTOMER_GROUP_CONFIGURATION_FORM = "CUSTOMER_GROUP_CONFIGURATION_FORM",
    UPC_SEARCH_FORM = "UPC_SEARCH_FORM",
    ADDITIONAL_CUSTOMER_FORM = "ADDITIONAL_CUSTOMER_FORM",
    CUSTOMER_GROUP_PREVIEW = "CUSTOMER_GROUP_PREVIEW"
}

export enum GST_STATUS_CODE {
    DUPLICATE_GST = "DUPLICATE_GST",
    INVALID_GST = "INVALID_GST"
}

export enum MY_MATERIAL {
    MY_MATERIAL_LISTING = "MY_MATERIAL_LISTING",
    CATEGORY_SELECTION = "CATEGORY_SELECTION",
    MY_MATERIAL_FORM = "MY_MATERIAL_FORM",
    PRODUCT_REQUIREMENT = "PRODUCT_REQUIREMENT",
    MY_MATERIAL_PRODUCT = "MY_MATERIAL_PRODUCT",
    FINISHED_PRODUCT_LISTING = "FINISHED_PRODUCT_LISTING",
    MAIN_RAW_MATERIAL = "MAIN_RAW_MATERIAL",
    FINISHED_PRODUCT = "FINISHED_PRODUCT",
    SCRAP = "SCRAP",
    SCRAP_LISTING = "SCRAP_LISTING",
    MY_MATERIAL_VIEW = "MY_MATERIAL_VIEW"
}

export enum MY_MATERIAL_STATE {
    MY_MATERIAL_VIEW = "MY_MATERIAL_VIEW",
    MY_MATERIAL_CREATE = "MY_MATERIAL_CREATE",
    SCRAP_LISTING = "SCRAP_LISTING",
    SCRAP_CREATE = "SCRAP_CREATE",
    SCRAP_UPDATE = "SCRAP_UPDATE",
    FINISHED_PRODUCT_LISTING = "FINISHED_PRODUCT_LISTING",
    FINISHED_PRODUCT_CREATE = "FINISHED_PRODUCT_CREATE",
    FINISHED_PRODUCT_UPDATE = "FINISHED_PRODUCT_UPDATE"
}

export enum SCRAP_STATE {
    SCRAP_LISTING = "SCRAP_LISTING",
    SCRAP_CREATE = "SCRAP_CREATE",
    SCRAP_UPDATE = "SCRAP_UPDATE"
}

export enum MY_MATERIAL_LISTING {
    MAIN_RAW_MATERIAL = 1,
    FINISHED_PRODUCT = 2,
    SCRAP = 3
}

export enum MODE {
    VIEW = "VIEW",
    ADD = "ADD",
    UPDATE = "UPDATE",
    EDIT = "EDIT",
}

export enum CLASS_STATES {
    STANDARD = "STANDARD",
    NONSTANDARD = "NONSTANDARD",
    SECONDARY = "SECONDARY"
}

export enum USER_DETAILS_DUPLICATION_CODE {
    MOBILE_NUMBER_DUPLICATION = "MOBILE_NUMBER_DUPLICATION",
    EMAIL_DUPLICATION = "EMAIL_DUPLICATION",
    VENDOR_EXISTS_WITH_DIFFERENT_MOBILE_NUMBER = "VENDOR_EXISTS_WITH_DIFFERENT_MOBILE_NUMBER",
    VENDOR_EXISTS_WITH_DIFFERENT_EMAIL = "VENDOR_EXISTS_WITH_DIFFERENT_EMAIL"
}

export enum VendorTypeEnum {
    PRIMARY_MANUFACTURER = 'PRIMARY_MANUFACTURER',
    SECONDARY_MANUFACTURER = 'SECONDARY_MANUFACTURER',
    FINISHED_PRODUCT_MANUFACTURER = 'FINISHED_PRODUCT_MANUFACTURER',
    PROCESSOR = 'PROCESSOR',
    FABRICATORS = 'FABRICATORS',
    CHANNEL_PARTNER = 'CHANNEL_PARTNER',
    BRAND_DISTRIBUTOR_STOCKIST_IMPORTANT = 'BRAND_DISTRIBUTOR_STOCKIST_IMPORTANT',
}

export enum VAS_MASTER_CATEGORY_TYPE {
    SUPER_SERVICE = 0,
    MAIN_SERVICE = 1,
    SERVICE_CATEGORY = 2
}

export enum PRODUCT_SERVICE_FORM_STATE {
    SERVICE_CATEGORY_DETAILS = 1,
    ATTRIBUTE_MANAGEMENT_FORM_1 = 2,
    ATTRIBUTE_MANAGEMENT_FORM_2 = 3,
    REVIEW = 4
}

export enum CATALOGUE_BUNDLING_TYPE {
    CATALOGUE_BUNDLE = 1,
    CATALOGUE_BUNDLE_LOT = 2,
}

export enum RESOURCES {
    CATALOGUE = "CATALOGUE",
    DASHBOARD = "DASHBOARD",
    USERS_MANAGEMENT = "USERS_MANAGEMENT",
    VENDOR_MANAGEMENT = "VENDOR_MANAGEMENT",
    CUSTOMER_MANAGEMENT = "CUSTOMER_MANAGEMENT",
    CUSTOMER_MANAGEMENT_V2 = "CUSTOMER_MANAGEMENT_V2",

    USER_MANAGEMENT = "USER_MANAGEMENT",
    ROLES_PERMISSION = "ROLES_PERMISSION",
    PROCUREMENT = "PROCUREMENT",
    PURCHASE_DASHBOARD = "PURCHASE_DASHBOARD",
    PURCHASE_REQUISITION = "PURCHASE_REQUISITION",
    PURCHASE_INDENT = "PURCHASE_INDENT",
    REVERSE_AUCTION = "REVERSE_AUCTION",
    REVERSE_AUCTION_RFQ = "REVERSE_AUCTION_RFQ",
    VENDOR_DISCOVERY = "VENDOR_DISCOVERY",
    LIVE_AUCTION = "LIVE_AUCTION",
    PURCHASE_ORDER = "PURCHASE_ORDER",
    MATERIAL_INWARD = "MATERIAL_INWARD",
    GATE_ENTRY = "GATE_ENTRY",
    GRN = "GRN",
    QUALITY_CHECK = "QUALITY_CHECK",
    PUTAWAY = "PUTAWAY",
    VENDOR_PAYMENTS = "VENDOR_PAYMENTS",
    MISCELLANEOUS = "MISCELLANEOUS",
    BULK_UPLOAD_TRACKING = "BULK_UPLOAD_TRACKING",
    DOWNLOAD_FORMAT = "DOWNLOAD_FORMAT",
    BULK_UPLOAD = "BULK_UPLOAD",
    SALES = "SALES",
    SALES_DASHBOARD = "SALES_DASHBOARD",
    SALES_INDENT = "SALES_INDENT",
    FORWARD_AUCTION = "FORWARD_AUCTION",
    SALES_RFQ = "SALES_RFQ",
    CUSTOMER_DISCOVERY = "CUSTOMER_DISCOVERY",
    FORWARD_LIVE_AUCTION = "FORWARD_LIVE_AUCTION",
    SALES_LEAD = "SALES_LEAD",
    WHATSAPP_SALE = "WHATSAPP_SALE",
    SALES_ORDER = "SALES_ORDER",
    MATERIAL_OUTWARD = "MATERIAL_OUTWARD",
    DELIVERY_ORDER = "DELIVERY_ORDER",
    SALES_INVOICE = "SALES_INVOICE",
    GATE_OUT_ENTRY = "GATE_OUT_ENTRY",
    CUSTOMER_PAYMENT = "CUSTOMER_PAYMENT",
    INVENTORY = "INVENTORY",
    STANDARD_PRIME = "STANDARD_PRIME",
    NON_STANDARD_PRIME = "NON_STANDARD_PRIME",
    SECONDARY = "SECONDARY",
    NON_STANDARD = "NON_STANDARD",
    STANDARD = "STANDARD",
    SECONDARY_LOTS = "SECONDARY_LOTS",
    STOCK_TRANSFER = "STOCK_TRANSFER",
    PROCESSING = "PROCESSING",
    CURRENT_INVENTORY = "CURRENT_INVENTORY",
    MAKE_TO_ORDER = "MAKE_TO_ORDER",
    OFFER_MANAGEMENT = "OFFER_MANAGEMENT",
    COMBO_OFFERS = "COMBO_OFFERS",
    PAYMENT_DISCOUNT = "PAYMENT_DISCOUNT",
    COUPON_MANAGEMENT = "COUPON_MANAGEMENT",
    REPORT_ANALYTICS = "REPORT_ANALYTICS",
    INVENTORY_AS_ON_DATE = "INVENTORY_AS_ON_DATE",
    INVENTORY_BIN_REPORT = "INVENTORY_BIN_REPORT",
    INVENTORY_AGING_REPORT = "INVENTORY_AGING_REPORT",
    ITEM_LEDGER_REPORT = "ITEM_LEDGER_REPORT",
    PURCHASE = "PURCHASE",
    PURCHASE_CYCLE_REPORT = "PURCHASE_CYCLE_REPORT",
    GRN_REPORT = "GRN_REPORT",
    PO_REPORT = "PO_REPORT",
    SALES_CYCLE_REPORT = "SALES_CYCLE_REPORT",
    ORDER_DELIVERY_REPORT = "ORDER_DELIVERY_REPORT",
    AUCTION = "AUCTION",
    PURCHASE_AUCTION_REPORT = "PURCHASE_AUCTION_REPORT",
    SALES_AUCTION_REPORT = "SALES_AUCTION_REPORT",
    MASTERS = "MASTERS",
    COMPANY = "COMPANY",
    PRODUCTS = "PRODUCTS",
    STANDARD_GRADE = "STANDARD_GRADE",
    ATTRIBUTE = "ATTRIBUTE",
    CATEGORIES = "CATEGORIES",
    MANUFACTURER = "MANUFACTURER",
    BRAND = "BRAND",
    UPC = "UPC",
    MPC = "MPC",
    WHATSAPP_TEMPLATE = "WHATSAPP_TEMPLATE",
    WAREHOUSE = "WAREHOUSE",
    LOCATION = "LOCATION",
    ZONE = "ZONE",
    BIN = "BIN",
    WAREHOUSE_EMPLOYEE = "WAREHOUSE_EMPLOYEE",
    TRANSPORT = "TRANSPORT",
    TRANSPORT_METHOD = "TRANSPORT_METHOD",
    LOGISTIC_MANAGEMENT = "LOGISTIC_MANAGEMENT",
    QC = "QC",
    QC_MASTER = "QC_MASTER",
    TAXES = "TAXES",
    GST = "GST",
    HSN = "HSN",
    TERMS_CONDITION = "TERMS_CONDITION",
    FOR_CATALOG = "FOR_CATALOG",
    FOR_SALES = "FOR_SALES",
    FOR_PROCUREMENT = "FOR_PROCUREMENT",
    FOR_ITEM = "FOR_ITEM",
    LEAD_MANAGEMENT = "LEAD_MANAGEMENT",
    MESSAGE_CENTER = "MESSAGE_CENTER",
    LOG_OUT = "LOG_OUT",
    IMAGE_MASTER = "IMAGE_MASTER",
    CUSTOMER_GROUP_MASTER = "CUSTOMER_GROUP_MASTER",
    UPC_LISTING = "UPC_LISTING",
    MANAGE_QUOTATION = "MANAGE_QUOTATION",
    VAS_MASTER = "VAS_MASTER",
    VAS_MASTER_CATEGORIES = "VAS_MASTER_CATEGORIES",
    VAS_MASTER_USCs = "VAS_MASTER_USCs",
    VAS_MASTER_MACHINES = "VAS_MASTER_MACHINES",
    REPORTS_AND_ANALYTICS = "REPORTS_AND_ANALYTICS",
    VENDOR_COMPARISON = "VENDOR_COMPARISON"
}

export enum CATALOGUE_TYPE_STATES {
    CURRENT_INVENTORY= "CURRENT_INVENTORY",
    MAKE_TO_ORDER= "MAKE_TO_ORDER"
}

export interface ContactUsPopupProps {
    icon: string, 
    text: string, 
    country?: string,
    mobile?: string, 
    email?: string, 
    description?: string,
}

export interface PaymentTermsSectionProps {
    value: string,
    heading: string,
    subHeading: string,
    hasNext?: boolean,
    isAvailable:boolean
}

export interface DeliveryAndTransportationSectionProps {
    text: string,
    icon: string,
    description: string,
}

export interface IColorPalette {
    [key: string]: {
      bgColor: string;
      labelColor: string;
    };
};

export enum VENDOR_PRODUCT_DETAILS_TYPE {
    VENDOR_PRODUCT_USN_DETAILS = "VENDOR_PRODUCT_USN_DETAILS"
}

export interface ResourceOption{
    id: number | string;
    name: string;
    value?: any;
}

export enum ADD_EXISTING_PRODUCT {
    SELECT_PRODUCT = "SELECT_PRODUCT",
    PRODUCT_DETAILS = "PRODUCT_DETAILS",
    PRODUCT_REVIEW = "PRODUCT_REVIEW"
}

export enum STATUS {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    DRAFT = "DRAFT"
}

export enum DELIVERY_INFORMATION {
    IMMEDIATE = "IMMEDIATE",
    SEVEN_TO_TEN_DAYS = "SEVEN_TO_TEN_DAYS",
    FIFTEEN_TO_THIRTY_DAYS = "FIFTEEN_TO_THIRTY_DAYS",
    THIRTY_TO_SIXTY_DAYS = "THIRTY_TO_SIXTY_DAYS",
    SIXTY_PLUS_DAYS = "SIXTY_PLUS_DAYS"
}

export enum ADVANCE_FILTER_TYPE {
    VENDOR_PRODUCTS = "VENDOR_PRODUCTS",
    PRODUCTS = "PRODUCTS"
}

export enum ADD_OWN_PRODUCT {
    SELECT_PRODUCT = "SELECT_PRODUCT",
    NO_OF_PRODUCTS = "NO_OF_PRODUCTS",
    PRODUCT_DETAILS = "PRODUCT_DETAILS",
    PRODUCT_REVIEW = "PRODUCT_REVIEW"
}

export enum ADD_VENDOR_PRICE_STATES {
    PRODUCT_TYPE = "PRODUCT_TYPE",
	PRODUCT = "PRODUCT",
	ADD_PRICE = "ADD_PRICE",
    REVIEW = "REVIEW"
}

export enum PRODUCT_SELECTION_TYPE {
    EXISTING_PRODUCT = "EXISTING_PRODUCT",
    ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT"
}

export enum VENDOR_PRODUCTS_STATES {
    PRODUCT_TYPE = "PRODUCT_TYPE",
	PRODUCT = "PRODUCT",
	ADD_USN = "ADD_USN",
    REVIEW = "REVIEW"
}