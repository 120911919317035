import { ContactUsPopupProps, DeliveryAndTransportationSectionProps, IColorPalette, IPagination, PaymentTermsSectionProps, option } from "./types";
import { IPriceDetails } from "../components/templates/Lead/PaymentInfo.template";
import CallIcon from '../assets/icons/call.svg';
import MailIcon from '../assets/icons/mail.svg';
import WriteToUsIcon from '../assets/icons/writeToUs.svg';
import WarehouseIcon from '../assets/icons/warehouse.svg';
import TransportationIcon from '../assets/icons/transportation.svg';
import { FAQ } from "../components/templates/Vendor/Catalogue/FAQ.template";

export const ADDRESS_TYPE = {
    CONTACT_PERSON: "CONTACT_PERSON"
};

export const API_METHOD = {
    POST: "POST",
    GET: "GET",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH"
}

export const AUTH_PARAMS = {
    KEY: "86482624b33891447d84dade95c301d318d63fad91f420e7525ef190c1f5a745",
}

export const REGEX = {
    EMAIL: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    PASSWORD: /^(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*_\-+=|\\(){}[\]:;'"<>,.?/])(.{8,})$/,
    FULL_NAME: /^[\w'\-,.][^0-9_!¡?÷?¿\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
    PHONE_NUMBER: /^\d{10}$/,
    GST_NUMBER: /^[\w\d]{15}$/,
    TAGS_VALIDATION: /^[a-zA-Z0-9\s\-!"#$&'*,./:?@_`~]+$/,
    HSN_VALIDATION: /^\d{8}$/,
    NAME: /^[A-Za-z',.]+(?:\s+[A-Za-z',.]+)*$/,
    GST: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9,A-Z]{1}?$/,
    PINCODE: /^\d{6}$/,
    IFSC: /^[A-Z]{4}[0-9]{7}$/,
    ACCOUNT_NUMBER: /^\d{8,16}$/,
    CIN: /^[LU][0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/,
    UDHYAM: /^[A-Za-z0-9]{12}$/,
    PAN: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    GSTIN: /(?=^.{15}$)[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/,
    UPPERCASE: /[A-Z]/,
    LOWERCASE: /[a-z]/,
    SYMBOL: /[!@#$%^&*(),.?":{}|<>]/,
    NUMBER: /[1-9][0-9]*/,
    URL: /^(ftp|http|https):\/\/[^ "]+$/,
    QUANTITY: /^(\d*\.?\d{0,3})?$/,
}

export const ADMIN_ROUTES = {
    INVENTORY_LIST: "/inventory/list",
    INVENTORY_VIEW: "/inventory/view",
    INVENTORY_UPDATE: "/inventory/update",
    INVENTORY_CREATE: "/inventory/add",
    BRAND_LIST: "/brand",
    BRAND_VIEW: "/brand/:id",
    BRAND_UPDATE: "/brand/:id/update",
    BRAND_CREATE: "/brand/add",
    MANUFACTURER_VIEW: "/manufacturer/:id",
    MANUFACTURER_UPDATE: "/manufacturer/:id/update",
    MANUFACTURER_CREATE: "/manufacturer/add",
    MANUFACTURER_LIST: "/manufacturer",
    SECONDARY_INVENTORY_LIST: "/inventory/secondary",
    SECONDARY_INVENTORY_VIEW: "/inventory/secondary/:id",
    SECONDARY_INVENTORY_UPDATE: "/inventory/secondary/:id/update",
    SECONDARY_INVENTORY_CREATE: "/inventory/secondary/add",
    SECONDARY_CATALOGUE_LIST: "/catalogue/secondary",
    SECONDARY_CATALOGUE_VIEW: "/catalogue/secondary/:id",
    SECONDARY_CATALOGUE_UPDATE: "/catalogue/secondary/:id/update",
    SECONDARY_CATALOGUE_CREATE: "/catalogue/secondary/add",
    LEAD_VIEW: "/lead",
    QUOTATION: "/lead/quotation",
    LEAD_QUOTATION: "/quotation",
    QUOTATION_UPDATE: "/lead/quotation/:id/update",
    QUOTATION_CREATE: "/lead/:id/quotation/create",
    SECONDARY_QUOTATION_UPDATE: "/lead/quotation/:id/secondary-update",
    CATALOGUE: "/catalogue",
    SECONDARY_QUOTATION_CREATE: "/lead/:id/quotation/secondary-create",
    ORDER_LIST: "/order",
    STANDARD_LIST: "/standard",
    STANDARD_VIEW: "/standard/:id",
    STANDARD_UPDATE: "/standard/:id/update",
    STANDARD_CREATE: "/standard/add",
    BULK_UPLOAD: "/bulk-upload",
    CATEGORY: "/category",
    CATEGORY_UPDATE: (categoryId: number) => `/category/${categoryId}`,
    CATEGORY_CREATE: "/category/create",
    GRADE_LIST: "/grade",
    GRADE_CREATE: "/grade/add",
    GRADE_UPDATE: "/grade/:id/update",
    GRADE_VIEW: "/grade/:id",
    WHATSAPP_TEMPLATE_LIST: "/whatsapp-template",
    WHATSAPP_TEMPLATE_CREATE: "/whatsapp-template/add",
    WHATSAPP_TEMPLATE_VIEW: "/whatsapp-template/:id",
    WHATSAPP_TEMPLATE_UPDATE: "/whatsapp-template/:id/update",
    MPC_LIST: "/mpc",
    CUSTOMER_LIST: "/customer-management",
    USER_PROFILE: "/user-profile",
    ATTRIBUTE_LIST: "/attribute",
    ATTRIBUTE_CREATE: "/attribute/add",
    ATTRIBUTE_UPDATE: "/attribute/:id/update",
    ATTRIBUTE_VIEW: "/attribute/:id",
    MTO_QUOTATION_CREATE: "/lead/:id/quotation/secondary-lot-create",
    MTO_QUOTATION_UPDATE: "/lead/quotation/:id/secondary-lot-update",
    UPC_LIST: "/upc",
    UPC_CREATE: "/upc/add",
    UPC_VIEW: "/upc/:id",
    UPC_UPDATE: "/upc/:id/update",
    IMAGE_MASTER_LIST: "/image-master",
    IMAGE_MASTER_ADD: "/image-master/add",
    IMAGE_MASTER_VIEW: "/image-master/:id",
    IMAGE_MASTER_UPDATE: "/image-master/:id/update",
    MPC_CREATE: "/mpc/add",
    MPC_UPDATE: "/mpc/:id/update",
    MPC_VIEW: "/mpc/:id",
    CUSTOMER_GROUP_MASTER_LIST: "/customer-group-master",
    CUSTOMER_GROUP_MASTER_CREATE: "/customer-group-master/add",
    CUSTOMER_GROUP_MASTER_UPDATE: "/customer-group-master/:id/update",
    UPC_LISTING: "/upc-listing-page",
    CATALOGUE_APPROVAL: "/catalogue-approval",
    CUSTOMER_MANAGEMENT_VIEW: "/customer-management/customers/:id",
    CUSTOMER_MANAGEMENT_LIST: "/customer-management/customers",
    CUSTOMER_MANAGEMENT_CREATE: "/customer-management/customers/add",
    VENDOR_MANAGEMENT_LIST: "/vendors",
    VENDOR_MANAGEMENT_VIEW: "/vendors/:id",
    VENDOR_MANAGEMENT_CREATE: "/vendors/add",
    INSTANT_QUOTATION_LIST: "/instant-quotations",
    INSTANT_QUOTATION_CREATE: "/instant-quotations/add",
    INSTANT_QUOTATION_MODE: "/instant-quotations/:id/:mode",
    VAS_MASTER_CATEGORIES_LIST: "/vas-master-service",
    VAS_MASTER_CATEGORIES_CREATE: "/vas-master-service/add",
    VAS_MASTER_CATEGORIES_UPDATE: (serviceId: number) => `/vas-master-service/${serviceId}/update`,
    VAS_MASTER_CATEGORIES_VIEW: (serviceId: number) => `/vas-master-service/${serviceId}`,
    VAS_MASTER_USCS_LIST: "/vas-master-USCs",
    VAS_MASTER_USCS_CREATE: "/vas-master-USCs/add",
    VAS_MASTER_USCS_UPDATE: "/vas-master-USCs/:id/update",
    VAS_MASTER_USCS_VIEW: "/vas-master-USCs/:id",
    VAS_MASTER_MACHINES_LIST: "/vas-master-machines",
    VAS_MASTER_MACHINES_CREATE: "/vas-master-machines/add",
    VAS_MASTER_MACHINES_UPDATE: "/vas-master-machines/:id/update",
    VAS_MASTER_MACHINES_VIEW: "/vas-master-machines/:id",
    RFQ_LIST: "/rfq",
    RFQ_VIEW: "/rfq/:id",
}

export const CATEGORIES = {
    PRODUCT_CATEGORY: "productCategory",
}

export const MODE = {
    VIEW: "VIEW",
    ADD: "ADD",
    UPDATE: "UPDATE",
    EDIT: "EDIT",
    REVIEW: "REVIEW",
}

export const STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    DRAFT: "DRAFT"
}

export const initialPaginationValues: IPagination = {
    currentPage: 0,
    pageSize: 10,
    totalPages: 1,
    totalRecords: 0,
}

export const initialPaginationValuesV2: IPagination = {
    currentPage: 0,
    pageSize: 50,
    totalPages: 1,
    totalRecords: 0,
}

export const CUSTOMER_MANAGEMENT_TABS = {
    PROFILE: '1',
    BUSINESS_PROFILE: '2',
    BUSINESS_LOCATION: '3',
    KEY_PERSON_DETAIL: '4',
    MATERIALS: '5',
    STATUTORY_DOCUMENTS: '6',
    PREFERENCES: '7',
    CHANGE_PASSWORD: '8',
    CUSTOMER_MAPPING: '9'
}

export const sortOptions: option[] = [
    { value: "name,asc", label: "Name (Ascending)" },
    { value: "name,desc", label: "Name (Descending)" },
    { value: "createdAt,asc", label: "Created Date(Ascending)" },
    { value: "createdAt,desc", label: "Created Date(Descending)" },
];

export const CLASS_TYPES: option[] = [
    { value: "STANDARD", label: "Standard" },
    { value: "NON_STANDARD", label: "Non Standard" },
    { value: "SECONDARY", label: "Secondary" },
    { value: "SECONDARY_LOTS", label: "Secondary Lots" },
    { value: "MAKE_TO_ORDER", label: "Make To Order" },
];

export const secondaryInventorySortOptions: option[] = [
    { value: "createdAt,asc", label: "Created Date(Ascending)" },
    { value: "createdAt,desc", label: "Created Date(Descending)" },
];

export const secondaryCatalogueSortOptions: option[] = [
    { value: "catalogueName,asc", label: "Name (Ascending)" },
    { value: "catalogueName,desc", label: "Name (Descending)" },
    { value: "createdAt,asc", label: "Created Date(Ascending)" },
    { value: "createdAt,desc", label: "Created Date(Descending)" },
];

export const statusOptions: option[] = [
    { value: "ALL", label: "All" },
    { value: "ACTIVE", label: "Active" },
    { value: "INACTIVE", label: "Inactive" },
];

export const PACKAGING_TYPES: option[] = [
    { value: "WITHOUT_PACKAGING", label: "Bare Packaging" },
    { value: "PLASTIC_PACKAGING", label: "Plastic Packaging" },
    { value: "METAL_PACKAGING", label: "Metal Packaging" },
    { value: "WOOD_PACKAGING", label: "Wood Packaging" },
];

export const CLASS_TYPE = {
    STANDARD_NONSTANDARD: "STANDARD_NONSTANDARD",
    STANDARD: "standard",
    NONSTANDARD: "nonStandard",
    SECONDARY: "secondary"
}

export const PRODUCT_SECTION = {
    MASTER_UPC: "MASTER_UPC",
    BRAND_UPC: "BRAND_UPC",
    SECONDARY: "SECONDARY",
    INVENTORY: "INVENTORY",
    VENDOR_PRODUCTS: "VENDOR_PRODUCTS"
}

export const LEAD_STATUS = {
    ALL: "ALL",
    ONGOING: "ONGOING",
    REJECTED: "REJECTED",
    ACCEPTED: "ACCEPTED",
    PUBLISHED: "PUBLISHED",
    NOTPUBLISHED: "NOTPUBLISHED"
}

export const RESOURCE_TYPE = {
    BRAND: "BRAND",
    CATALOGUE: "CATALOGUE",
    INVENTORY: "INVENTORY",
    MANUFACTURER: "MANUFACTURER",
    STANDARD_GRADE: "STANDARD_GRADE",
    PRODUCT_CATEGORY: "PRODUCT_CATEGORY",
    SECONDARY_INVENTORY: "SECONDARY_INVENTORY",
    SECONDARY_CATALOGUE: "SECONDARY_CATALOGUE",
    STANDARD_INVENTORY: "STANDARD_INVENTORY",
    STANDARD_CATALOGUES: "STANDARD_CATALOGUES",
    NONSTANDARD_CATALOGUES: "NONSTANDARD_CATALOGUES",
    PRODUCT_UPCS: "PRODUCT_UPCS",
    NON_STANDARD_INVENTORY: "NON_STANDARD_INVENTORY",
    BULK_UPLOAD: "BULK_UPLOAD",
    SECONDARY_LOT_CATALOGUES: "SECONDARY_LOT_CATALOGUES",
    IMAGE_MASTER: "IMAGE_MASTER",
    STEEL_BAZAAR: "STEEL_BAZAAR",
    VAS_MACHINES: "VAS_MACHINES",
    DOWNLOAD_MTO_CATALOGUE: "DOWNLOAD_MTO_CATALOGUE"
}

export const CATALOGUE_TYPES = {
    CURRENT_INVENTORY: "currentInventory",
    MAKE_TO_ORDER: "makeToOrder",
    STANDARD_CURRENT_INVENTORY_CATALOGUES: "STANDARD_CURRENT_INVENTORY_CATALOGUES",
    STANDARD_MAKE_TO_ORDER_CATALOGUES: "STANDARD_MAKE_TO_ORDER_CATALOGUES",
}

export const RESOURCE_STATUS = {
    PUBLISHED: "Published",
    ACTIVE: "Active",
    COMPLETED: "Completed",
    PARTIALLY_COMPLETED: "Partially Completed",
    ACCEPTED: "Accepted",
    PENDING: "Pending",
    ONGOING: "Ongoing",
    INACTIVE: "Inactive",
    UNPUBLISHED: "Unpublished",
    REJECTED: "Rejected",
    CANCELLED: "Cancelled",
    FAILED: "Failed",
    INVALID_SHEET: "Invalid Sheet",
    NOTPUBLISHED: "Not Published",
    BLACK_LISTED: "Black Listed",
    DRAFT: 'Draft',
    IN_PROGRESS: "In Progress",
    REVISED: "Revised",
    CLOSED_WIN: "Closed Win",
    SENT: "Sent",
    CLOSED_LOST: "Closed Lost",
}

export interface IChargesProps {
    title: string;
    subTitle?: string | null;
    price: number;
    icon?: React.ReactNode;
    iconInfo?: string | null;
    showFullQuantity: boolean;
};

export const PRICE_DETAILS = (iconOverrides: Partial<IPriceDetails> = {}): IPriceDetails => {
    const defaultDetails: IPriceDetails = {
        subTotal: {
            title: "Sub Totals",
            subTitle: null,
            price: 0,
            icon: null,
            iconInfo: null,
            showFullQuantity: false,
        },
        warehouseCharges: {
            title: "Warehousing Charges",
            subTitle: null,
            price: 0,
            icon: null,
            iconInfo: null,
            showFullQuantity: true,
        },
        handlingCharges: {
            title: "Handling Charges",
            subTitle: null,
            price: 0,
            icon: null,
            iconInfo: null,
            showFullQuantity: true,
        },
        packagingCharges: {
            title: "Packaging Charges",
            subTitle: null,
            price: 0,
            icon: null,
            iconInfo: null,
            showFullQuantity: true,
        },
        otherCharges: {
            title: "Other Charges",
            subTitle: null,
            price: 0,
            icon: null,
            iconInfo: null,
            showFullQuantity: false,
        },
        GST: {
            title: "Add: GST",
            subTitle: null,
            price: 0,
            icon: null,
            iconInfo: null,
            showFullQuantity: false,
        },
        TCS: {
            title: "Less: TCS (if applicable)",
            subTitle: null,
            price: 0,
            icon: null,
            iconInfo: null,
            showFullQuantity: false,
        },
    };

    return {
        ...defaultDetails,
        ...iconOverrides,
    };
};

export const ORDER_STATUS = {
    ALL: "ALL",
    ONGOING: "ONGOING",
    CANCELLED: "CANCELLED",
    COMPLETED: "COMPLETED",
    PENDING: "PENDING",
}

export const RESOURCES: {
    [key: string]: string
} = {
    CATALOGUE: "Catalogue",
    DASHBOARD: "Dashboard",
    USERS_MANAGEMENT: "Users Management",
    VENDOR_MANAGEMENT: "Vendor Management",
    CUSTOMER_MANAGEMENT: "Customer Management",
    CUSTOMER_MANAGEMENT_V2: "Customer Management",
    USER_MANAGEMENT: "User Management",
    ROLES_PERMISSION: "Roles & Permission",
    PROCUREMENT: "Procurement",
    PURCHASE_DASHBOARD: "Purchase Dashboard",
    PURCHASE_REQUISITION: "Purchase Requisition",
    PURCHASE_INDENT: "Purchase Indent",
    REVERSE_AUCTION: "Reverse Auction",
    REVERSE_AUCTION_RFQ: "Reverse Auction RFQ",
    VENDOR_DISCOVERY: "Vendor Discovery",
    LIVE_AUCTION: "Live Auction",
    PURCHASE_ORDER: "Purchase Order",
    MATERIAL_INWARD: "Material Inward",
    GATE_ENTRY: "Gate Entry",
    GRN: "GRN",
    QUALITY_CHECK: "Quality Check",
    PUTAWAY: "Putway",
    VENDOR_PAYMENTS: "Vendor Payments",
    MISCELLANEOUS: "Miscellaneous",
    BULK_UPLOAD_TRACKING: "Bulk Upload Tracking",
    DOWNLOAD_FORMAT: "Download Format",
    BULK_UPLOAD: "Bulk Upload",
    SALES: "Sales",
    SALES_DASHBOARD: "Sales Dashboard",
    SALES_INDENT: "Sales Indent",
    FORWARD_AUCTION: "Forward Auction",
    SALES_RFQ: "Sales RFQ",
    CUSTOMER_DISCOVERY: "Customer Discovery",
    FORWARD_LIVE_AUCTION: "Forward Live Auction",
    SALES_LEAD: "Sales Lead",
    WHATSAPP_SALE: "Whatsapp Sale",
    SALES_ORDER: "Sales Order",
    MATERIAL_OUTWARD: "Material Outward",
    DELIVERY_ORDER: "Delivery Order",
    SALES_INVOICE: "Sales Invoice",
    GATE_OUT_ENTRY: "Gate Out Entry",
    CUSTOMER_PAYMENT: "Customer Payment",
    INVENTORY: "Inventory",
    STANDARD_PRIME: "Standard Prime",
    NON_STANDARD_PRIME: "Non-Standard Prime",
    SECONDARY: "Secondary",
    NON_STANDARD: "Non Standard",
    STANDARD: "Standard",
    SECONDARY_LOTS: "Secondary lots",
    STOCK_TRANSFER: "Stock Transfer",
    PROCESSING: "Processing",
    CURRENT_INVENTORY: "Current Inventory",
    MAKE_TO_ORDER: "Make To Order",
    OFFER_MANAGEMENT: "Offer Management",
    COMBO_OFFERS: "Combo Offers",
    PAYMENT_DISCOUNT: "Payment Discount",
    COUPON_MANAGEMENT: "Coupon Management",
    REPORT_ANALYTICS: "Report & Analytics",
    INVENTORY_AS_ON_DATE: "Inventory as on Date",
    INVENTORY_BIN_REPORT: "Inventory Bin Report",
    INVENTORY_AGING_REPORT: "Inventory Aging Report",
    ITEM_LEDGER_REPORT: "Item Ledger Report",
    PURCHASE: "Purchase",
    PURCHASE_CYCLE_REPORT: "Purchase Cycle Report",
    GRN_REPORT: "GRN Report",
    PO_REPORT: "PO Report",
    SALES_CYCLE_REPORT: "Sales Cycle Report",
    ORDER_DELIVERY_REPORT: "Order Delivery Report",
    AUCTION: "Auction",
    PURCHASE_AUCTION_REPORT: "Purchase Auction Report",
    SALES_AUCTION_REPORT: "Sales Auction Report",
    MASTERS: "Masters",
    COMPANY: "Company",
    PRODUCTS: "Products",
    GRADE: "Grade",
    ATTRIBUTE: "Attribute",
    CATEGORIES: "Categories",
    MANUFACTURER: "Manufacturer",
    BRAND: "Brand",
    UPC: "UPC",
    MPC: "MPC",
    WHATSAPP_TEMPLATE: "Whatsapp Templates",
    WAREHOUSE: "Warehouse",
    LOCATION: "Location",
    ZONE: "Zone",
    BIN: "Bin",
    WAREHOUSE_EMPLOYEE: "Warehouse Employee",
    TRANSPORT: "Transport",
    TRANSPORT_METHOD: "Transport Method",
    LOGISTIC_MANAGEMENT: "Logistic Management",
    QC: "QC",
    QC_MASTER: "QC Master",
    TAXES: "Taxes",
    GST: "GST",
    HSN: "HSN",
    TERMS_CONDITION: "Terms & Condition",
    FOR_CATALOG: "For Catalog",
    FOR_SALES: "For Sales",
    FOR_PROCUREMENT: "For Procurement",
    FOR_ITEM: "For Item",
    LEAD_MANAGEMENT: "Lead Management",
    MESSAGE_CENTER: "Message Center",
    LOG_OUT: "Log out",
    IMAGE_MASTER: "Image Master",
    CUSTOMER_GROUP_MASTER: "Customer Group Master",
    UPC_LISTING: "UPC Listing Page",
    MANAGE_QUOTATION: 'Instant Quotation',
    VAS_MASTER: "VAS Master",
    VAS_MASTER_CATEGORIES: "Categories",
    VAS_MASTER_USCs: "USCs",
    VAS_MASTER_MACHINES: "Machines",
    REPORTS_AND_ANALYTICS: "Reports & Analytics",
    VENDOR_COMPARISON: "Vendor Comparison",
    RFQ: "Custom RFQ Leads",
    DASHBOARDV2: "Dashboard",
    CATALOGUE_APPROVAL: "Catalogue Approval",
    CATALOGUE_BUNDLING: "Catalogue Bundling",
    DOWNLOAD_UPCS: "Download Upcs",
    ALL_VENDORS: "All Vendors",
    MY_STEEL_BAZAAR: "My SteelBazaar",
    POST_PRODUCT: "Post Product",
    ADD_INVENTORY: "Add Inventory",
    PRICE_MANAGEMENT: "Price Management",
    VALUE_ADDED_SERVICES: "Value Added Services",
    MAKE_CATALOG: "Make Catalog",
    CATALOGUE_MASTER: "Catalogue Master"
}

export const STANDARD_SECTION_TYPE = {
    VIEW_STANDARD: "View Standard",
    EDIT_STANDARD: "Edit Standard",
    ADD_STANDARD: "Add New Standard",
}

export const BULK_UPLOAD_TEMPLATE_TYPE = {
    APPLICATION_OCTET_STREAM: 'application/octet-stream',
    APPLICATION_JSON: 'application/json',
}

export const BULK_UPLOAD_TEMPLATE_POPUP = {
    PLEASE_WAIT: "Please wait",
    UPLOAD_IN_PROGRESS: "Upload in progress",
    SUCCESS: "Success!",
    UPLOAD_PROCESS_SUCCESS: "Upload Process is completed successfully.",
    TRACK_PROGRESS_SUCCESS: "Please track the progress in the Bulk Upload Tracking screen",
    UPLOAD_FAILURE: "Upload Failure",
    FAILURE_ERROR: "There are some errors, For more detail please check the file.",
    DONE: "Done",
}

export const BULK_UPLOAD_FILE_TYPE = {
    EXCEL_XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
}

export const LANGUAGE_CODE: option[] = [
    {
        "label": "Afrikaans",
        "value": "af"
    },
    {
        "label": "Albanian",
        "value": "sq"
    },
    {
        "label": "Arabic",
        "value": "ar"
    },
    {
        "label": "Azerbaijani",
        "value": "az"
    },
    {
        "label": "Bengali",
        "value": "bn"
    },
    {
        "label": "Bulgarian",
        "value": "bg"
    },
    {
        "label": "Catalan",
        "value": "ca"
    },
    {
        "label": "Chinese (CHN)",
        "value": "zh_CN"
    },
    {
        "label": "Chinese (HKG)",
        "value": "zh_HK"
    },
    {
        "label": "Chinese (TAI)",
        "value": "zh_TW"
    },
    {
        "label": "Croatian",
        "value": "hr"
    },
    {
        "label": "Czech",
        "value": "cs"
    },
    {
        "label": "Danish",
        "value": "da"
    },
    {
        "label": "Dutch",
        "value": "nl"
    },
    {
        "label": "English",
        "value": "en"
    },
    {
        "label": "English (UK)",
        "value": "en_GB"
    },
    {
        "label": "English (US)",
        "value": "en_US"
    },
    {
        "label": "Estonian",
        "value": "et"
    },
    {
        "label": "Filipino",
        "value": "fil"
    },
    {
        "label": "Finnish",
        "value": "fi"
    },
    {
        "label": "French",
        "value": "fr"
    },
    {
        "label": "Georgian",
        "value": "ka"
    },
    {
        "label": "German",
        "value": "de"
    },
    {
        "label": "Greek",
        "value": "el"
    },
    {
        "label": "Gujarati",
        "value": "gu"
    },
    {
        "label": "Hausa",
        "value": "ha"
    },
    {
        "label": "Hebrew",
        "value": "he"
    },
    {
        "label": "Hindi",
        "value": "hi"
    },
    {
        "label": "Hungarian",
        "value": "hu"
    },
    {
        "label": "Indonesian",
        "value": "id"
    },
    {
        "label": "Irish",
        "value": "ga"
    },
    {
        "label": "Italian",
        "value": "it"
    },
    {
        "label": "Japanese",
        "value": "ja"
    },
    {
        "label": "Kannada",
        "value": "kn"
    },
    {
        "label": "Kazakh",
        "value": "kk"
    },
    {
        "label": "Kinyarwanda",
        "value": "rw_RW"
    },
    {
        "label": "Korean",
        "value": "ko"
    },
    {
        "label": "Kyrgyz (Kyrgyzstan)",
        "value": "ky_KG"
    },
    {
        "label": "Lao",
        "value": "lo"
    },
    {
        "label": "Latvian",
        "value": "lv"
    },
    {
        "label": "Lithuanian",
        "value": "lt"
    },
    {
        "label": "Macedonian",
        "value": "mk"
    },
    {
        "label": "Malay",
        "value": "ms"
    },
    {
        "label": "Malayalam",
        "value": "ml"
    },
    {
        "label": "Marathi",
        "value": "mr"
    },
    {
        "label": "Norwegian",
        "value": "nb"
    },
    {
        "label": "Persian",
        "value": "fa"
    },
    {
        "label": "Polish",
        "value": "pl"
    },
    {
        "label": "Portuguese (BR)",
        "value": "pt_BR"
    },
    {
        "label": "Portuguese (POR)",
        "value": "pt_PT"
    },
    {
        "label": "Punjabi",
        "value": "pa"
    },
    {
        "label": "Romanian",
        "value": "ro"
    },
    {
        "label": "Russian",
        "value": "ru"
    },
    {
        "label": "Serbian",
        "value": "sr"
    },
    {
        "label": "Slovak",
        "value": "sk"
    },
    {
        "label": "Slovenian",
        "value": "sl"
    },
    {
        "label": "Spanish",
        "value": "es"
    },
    {
        "label": "Spanish (ARG)",
        "value": "es_AR"
    },
    {
        "label": "Spanish (SPA)",
        "value": "es_ES"
    },
    {
        "label": "Spanish (MEX)",
        "value": "es_MX"
    },
    {
        "label": "Swahili",
        "value": "sw"
    },
    {
        "label": "Swedish",
        "value": "sv"
    },
    {
        "label": "Tamil",
        "value": "ta"
    },
    {
        "label": "Telugu",
        "value": "te"
    },
    {
        "label": "Thai",
        "value": "th"
    },
    {
        "label": "Turkish",
        "value": "tr"
    },
    {
        "label": "Ukrainian",
        "value": "uk"
    },
    {
        "label": "Urdu",
        "value": "ur"
    },
    {
        "label": "Uzbek",
        "value": "uz"
    },
    {
        "label": "Vietnamese",
        "value": "vi"
    },
    {
        "label": "Zulu",
        "value": "zu"
    }
]

export const LEAD_CATALOGUE_TYPES = {
    ALL: "ALL",
    STANDARD: "STANDARD",
    SECONDARY: "SECONDARY",
    MAKE_TO_ORDER: "MAKE_TO_ORDER",
}

export const DOCUMENT_TYPES = {
    IMAGE_JPEG: 'image/jpeg',
    IMAGE_PNG: 'image/png',
    IMAGE_WEBP: 'image/webp',
    IMAGE_SVG: 'image/svg'
}

export const RESOLUTIONS = {
    SECONDARY_INVENTORY: { width: 640, height: 680, tolerance: 50 },
    BRAND: { width: 3000, height: 3000, tolerance: 3000 }
}

export const SHAPE_TYPES = [
    // {
    //     label: "Single Length Coil",
    //     id: 'SINGLE_LENGTH_COIL'
    // },
    // {
    //     label: "Multi Welded Coil ",
    //     id: 'MULTI_WELDED_COIL'
    // },
    // {
    //     label: "Leader End Coil",
    //     id: 'LEADER_END_COIL'
    // },
    // {
    //     label: "Uniform Assorted Bundle Sheets",
    //     id: 'UNIFORM_ASSORTED_BUNDLE_SHEETS'
    // },
    // {
    //     label: "Mix Assorted Bundle Sheets",
    //     id: 'MIX_ASSORTED_BUNDLE_SHEETS'
    // },
    // {
    //     label: "Unassorted Bundle Sheets",
    //     id: 'UNASSORTED_BUNDLE_SHEETS'
    // },
    {
        label: "Scroll",
        id: 'SCROLL'
    },
    {
        label: "Bar",
        id: 'BAR'
    },
    {
        label: "Plate",
        id: 'PLATE'
    },
    {
        label: "Coil",
        id: "COIL"
    },
    {
        label: "Sheet",
        id: "SHEET"
    },
    {
        label: "Strip",
        id: "STRIP"
    },
    {
        label: "Angle",
        id: "ANGLE"
    },
    {
        label: "Channel",
        id: "CHANNEL"
    },
    {
        label: "Beam",
        id: "BEAM"
    },
    {
        label: "Pipe",
        id: 'PIPE'
    },
    {
        label: "Tube",
        id: 'TUBE'
    },
    {
        label: "Round",
        id: 'ROUND'
    },
    {
        label: "Square",
        id: 'SQUARE'
    },
    {
        label: "Rectangle",
        id: 'RECTANGLE'
    },
    {
        label: "Others",
        id: 'OTHERS'
    }
]

export const ATTRIBUTES = {
    SPECIFICATION: "SPECIFICATION",
    GENERALIZATION: "GENERALIZATION",
    CLASSIFICATION: "CLASSIFICATION"
}

export const RANGE_ATTRIBUTES = ["length", "width", "thickness"]

export const EXCEPTION_CODE = {
    DUPLICATE_ENTRY: "DUPLICATE_ENTRY",
    NULL_POINTER: "NULL_POINTER",
    DATABASE_ERROR: "DATABASE_ERROR",
    MAPPING_ERROR: "MAPPING_ERROR",
    UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
    INVALID_ARGUMENT: "INVALID_ARGUMENT",
    MAX_LIMIT_EXCEED: "MAX_LIMIT_EXCEED"
}

export const BRANDS = {
    SECONDARY_BRAND: "secondary brand",
}

export const TAB_MAPPING_BRAND_UPC = {
    UPC_OVERVIEW: '1',
    PRICE_MANAGEMENT: '2',
    CUSTOMER_WITH_UPC: '3',
    SALES_ORDER: '4',
    VENDOR_WITH_UPC: '5',
    PURCHASE_ORDER: '6',
    RELATED_UPC: '7',
    OTHER: '8',
    INVENTORY: '9'
}

export const TAB_MAPPING_MASTER_UPC = {
    UPC_OVERVIEW: '1',
    PRICE_MANAGEMENT: '2',
    CUSTOMER_WITH_UPC: '3',
    SALES_ORDER: '4',
    VENDOR_WITH_UPC: '5',
    PURCHASE_ORDER: '6',
    RELATED_UPC: '7',
    OTHER: '8',
    INVENTORY: '9'
}

export const COMMUNICATION_CHANNEL = [
    {
        label: "Email",
        value: "Email"
    },
    {
        label: "Phone",
        value: "Phone"
    },
    {
        label: "WhatsApp",
        value: "WhatsApp"
    }
];

export const CONTACT_PREFERENCE = [
    {
        label: "Email",
        value: "Email"
    },
    {
        label: "Phone",
        value: "Phone"
    },
    {
        label: "In person Meeting",
        value: "In person Meeting"
    }
];

export const LANGUAGE = [
    {
        label: "English",
        value: "English"
    },
    {
        label: "Hindi",
        value: "Hindi"
    }
];

export const CREDIT_PERIOD = [
    {
        label: "3 DAYS",
        value: "3 DAYS"
    },
    {
        label: "7 DAYS",
        value: "7 DAYS"
    },
    {
        label: "10 DAYS",
        value: "10 DAYS"
    },
    {
        label: "15 DAYS",
        value: "15 DAYS"
    }
];

export const PAYMENT_PREFERENCE = [
    {
        label: "Credit",
        value: "Credit"
    },
    {
        label: "Debit",
        value: "Debit"
    },
    {
        label: "Cash on Delivery",
        value: "Cash on Delivery"
    }
];

export const OTHER_PREFERENCE = [
    { id: 1, value: 'Receive updates via Whatsapp' },
    { id: 2, value: 'Would you like to join our Whatsapp community?' },
    { id: 3, value: 'Receive updates via email' },
    { id: 4, value: 'Grant permission for sharing documents for KYC and credit check' },
];

export const COUNTRY_CODES = [
    { name: "IND", code: "+91" }
];

export const HONORIFICS = [
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." }
]

export const SOCIAL_LINKS = [
    { label: 'Facebook', value: 'FACEBOOK' },
    { label: 'Twitter', value: 'TWITTER' },
    { label: 'Instagram', value: 'INSTAGRAM' },
    { label: 'LinkedIn', value: 'LINKEDIN' }
];

export const DOCUMENT_RESOURCE_TYPE = {
    BUSINESS_PROFILE_STATUTORY: "business_profile/statutory",
    BUSINESS_PROFILE_KEY_PERSON_DETAILS: "business_profile/key_person_details",
    BUSINESS_PROFILE_FINISHED_PRODUCT: (userId: string) => `business_profile/${userId}/finished_product`,
    BUSINESS_PROFILE_SCRAP: (userId: string) => `business_profile/${userId}/scrap`,
    VAS_MACHINE: "vas_machines"
};

export const certificateTypeOptions = [
    {
        label: "GSTIN",
        value: "GSTIN"
    },
    {
        label: "MSME",
        value: "MSME"
    },
    {
        label: "UDYAM",
        value: "UDYAM"
    }
];

export const ENTITY_TYPES = [
    { id: 'Large Corporate', name: 'Large Corporate' },
    { id: 'Sole Proprietorship', name: 'Sole Proprietorship' },
    { id: 'Medium Enterprise', name: 'Medium Enterprise' },
    { id: 'Small Enterprise', name: 'Small Enterprise' },
    { id: 'Micro Enterprise', name: 'Micro Enterprise' },
    { id: 'Mid Corporate', name: 'Mid Corporate' },
];

export const INDUSTRY_TYPES = [
    { label: 'Infrastructure', value: 'INFRASTRUCTURE' },
    { label: 'Construction', value: 'CONSTRUCTION' },
    { label: 'Automotive', value: 'AUTOMOTIVE' },
    { label: 'Engineering', value: 'ENGINEERING' },
    { label: 'Trade', value: 'TRADE' },
    { label: 'Distributor', value: 'DISTRIBUTOR' },
    { label: 'Energy Electricals', value: 'ENERGY_ELECTRICALS' },
    { label: 'Transportation', value: 'TRANSPORTATION' },
    { label: 'Equipments', value: 'EQUIPMENTS' },
    { label: 'Plant Machinery', value: 'PLANT_MACHINERY' },
    { label: 'Oil and Gas', value: 'OIL_AND_GAS' },
    { label: 'Consumer Durables', value: 'CONSUMER_DURABLES' },
    { label: 'Packaging', value: 'PACKAGING' }
];

export const INDUSTRY_STATES = {
    INFRASTRUCTURE: 'Infrastructure',
    CONSTRUCTION: 'Construction',
    AUTOMOTIVE: 'Automotive',
    ENGINEERING: 'Engineering',
    ENERGY_ELECTRICALS: 'Energy Electricals',
    DISTRIBUTOR: 'Distributor',
    TRADE: 'Trade',
    PLANT_MACHINERY: 'Plant Machinery',
    TRANSPORTATION: 'Transportation',
    EQUIPMENTS: 'Equipments',
    OIL_AND_GAS: 'Oil and Gas',
    CONSUMER_DURABLES: 'Consumer Durables',
    PACKAGING: 'Packaging'
};

export const BUSINESS_PROFILE_SECTIONS = {
    GST: "GST",
    UDHYAM: "UDHYAM",
    CIN: "CIN",
    TURN_OVERS: "TURN_OVERS",
    OTHER_INFORMATION: "OTHER_INFORMATION"
}

export const BUSINESS_CLASSIFICATION = [
    { id: 'SME', name: 'SME' },
    { id: 'MSME', name: 'MSME' },
];

export const TURNOVER_DROP_DOWN_OPTIONS = [
    { value: "Below 2 CR", label: "Below 2 CR" },
    { value: "2-50 CR", label: "2-50 CR" },
    { value: "50-250 CR", label: "50-250 CR" },
    { value: "250-500 CR", label: "250-500 CR" },
    { value: "Above 500 CR", label: "Above 500 CR" },
];

export const BUSINESS_TYPES = [
    { id: 'Distributor', name: 'Distributor' },
    { id: 'Partnership', name: 'Partnership' },
    { id: 'Limited Liability Company', name: 'Limited Liability Company' },
    { id: 'Sole proprietorship', name: 'Sole proprietorship' },
];
export const USER_STATUS_OPTIONS = [
    { value: 'ACTIVE', label: 'Active' },
    { value: 'INACTIVE', label: 'Inactive' },
    { value: 'REJECTED', label: 'Rejected' },
    { value: 'BLACK_LISTED', label: 'Blacklisted' }
];

export const DEBOUNCE_TIME = {
    USER_STATUS_UPDATE: 2000,
    DEFAULT: 300
}

export const CLASSES = [
    {
        label: "Standard",
        id: 'STANDARD'
    },
    {
        label: "Secondary",
        id: 'SECONDARY'
    },
    // {
    //   label: "Non Standard",
    //   id: 'NONSTANDARD'
    // },
]

export const STATUS_OPTION = [
    {
        label: "Active",
        id: 'ACTIVE'
    },
    {
        label: "Inactive",
        id: 'INACTIVE'
    },
]

export const SORT_OPTION = [
    // {
    //   id: "name,asc",
    //   label: 'Name (Ascending)'
    // },
    // {
    //   id: "name,desc",
    //   label: 'Name (Descending)'
    // },
    {
        id: "createdAt,asc",
        label: 'Created Date(Ascending)'
    },
    {
        id: "createdAt,desc",
        label: 'Created Date(Descending)'
    },
]

export const cityOptions = [
    { id: "DELHI", label: "Delhi" },
    { id: "MUMBAI", label: "Mumbai" },
    { id: "NOIDA", label: "Noida" },
    { id: "BANGALORE", label: "Bangalore" },
    { id: "CHENNAI", label: "Chennai" },
    { id: "KOLKATA", label: "Kolkata" },
    { id: "HYDERABAD", label: "Hyderabad" },
    { id: "PUNE", label: "Pune" },
    { id: "AHMEDABAD", label: "Ahmedabad" },
    { id: "SURAT", label: "Surat" },
    { id: "LUCKNOW", label: "Lucknow" },
    { id: "JAIPUR", label: "Jaipur" },
    { id: "KANPUR", label: "Kanpur" },
    { id: "NAGPUR", label: "Nagpur" },
    { id: "BHOPAL", label: "Bhopal" },
    { id: "PATNA", label: "Patna" },
    { id: "LUDHIANA", label: "Ludhiana" },
    { id: "AGRA", label: "Agra" },
    { id: "NASHIK", label: "Nashik" },
    { id: "VADODARA", label: "Vadodara" },
    { id: "INDORE", label: "Indore" },
    { id: "COIMBATORE", label: "Coimbatore" },
    { id: "THANE", label: "Thane" },
    { id: "RANCHI", label: "Ranchi" },
    { id: "JABALPUR", label: "Jabalpur" },
    { id: "GHAZIABAD", label: "Ghaziabad" },
    { id: "VARANASI", label: "Varanasi" },
    { id: "MEERUT", label: "Meerut" },
    { id: "RAIPUR", label: "Raipur" },
    { id: "RAJKOT", label: "Rajkot" },
    { id: "JODHPUR", label: "Jodhpur" },
    { id: "KOCHI", label: "Kochi" },
    { id: "MYSORE", label: "Mysore" },
    { id: "TRIVANDRUM", label: "Trivandrum" },
    { id: "GURGAON", label: "Gurgaon" },
    { id: "FARIDABAD", label: "Faridabad" },
    { id: "ALLAHABAD", label: "Allahabad" },
    { id: "AMRITSAR", label: "Amritsar" },
    { id: "VIJAYAWADA", label: "Vijayawada" },
    { id: "AURANGABAD", label: "Aurangabad" },
    { id: "SOLAPUR", label: "Solapur" },
    { id: "GUWAHATI", label: "Guwahati" },
    { id: "TIRUCHIRAPPALLI", label: "Tiruchirappalli" },
    { id: "MADURAI", label: "Madurai" },
    { id: "SALEM", label: "Salem" },
    { id: "THIRUVANANTHAPURAM", label: "Thiruvananthapuram" },
    { id: "KOTA", label: "Kota" },
    { id: "KURUKSHETRA", label: "Kurukshetra" },
    { id: "AJMER", label: "Ajmer" },
    { id: "ALIGARH", label: "Aligarh" },
    { id: "AMBIKAPUR", label: "Ambikapur" },
    { id: "AMRAVATI", label: "Amravati" },
    { id: "ANAND", label: "Anand" },
    { id: "ANANTAPUR", label: "Anantapur" },
    { id: "ASANSOL", label: "Asansol" },
    { id: "AURANGABAD", label: "Aurangabad" },
    { id: "BILASPUR", label: "Bilaspur" },
    { id: "BOKARO", label: "Bokaro" },
    { id: "CALICUT", label: "Calicut" },
    { id: "CUDDALORE", label: "Cuddalore" },
    { id: "CUTTACK", label: "Cuttack" },
    { id: "DHANBAD", label: "Dhanbad" },
    { id: "DHARWAD", label: "Dharwad" },
    { id: "DIMAPUR", label: "Dimapur" },
    { id: "DURGAPUR", label: "Durgapur" },
    { id: "ELURU", label: "Eluru" },
    { id: "ETAWAH", label: "Etawah" },
    { id: "GANDHINAGAR", label: "Gandhinagar" },
    { id: "GAYA", label: "Gaya" },
    { id: "GORAKHPUR", label: "Gorakhpur" },
    { id: "GULBARGA", label: "Gulbarga" },
    { id: "GUNTUR", label: "Guntur" },
    { id: "GURDASPUR", label: "Gurdaspur" },
    { id: "HARIDWAR", label: "Haridwar" },
    { id: "HUBLI", label: "Hubli" },
    { id: "IMPHAL", label: "Imphal" },
    { id: "JALGAON", label: "Jalgaon" },
    { id: "JAMMU", label: "Jammu" },
    { id: "JAMSHEDPUR", label: "Jamshedpur" },
    { id: "JHANSI", label: "Jhansi" },
    { id: "JUNAGADH", label: "Junagadh" },
    { id: "KALYAN", label: "Kalyan" },
    { id: "KANCHIPURAM", label: "Kanchipuram" },
    { id: "KANNUR", label: "Kannur" },
    { id: "KARNAL", label: "Karnal" },
    { id: "KHARAGPUR", label: "Kharagpur" },
    { id: "KHARGHAR", label: "Kharghar" },
    { id: "KOLHAPUR", label: "Kolhapur" },
    { id: "KOLLAM", label: "Kollam" },
    { id: "KOTTAYAM", label: "Kottayam" },
    { id: "KURNOOL", label: "Kurnool" },
    { id: "LATUR", label: "Latur" },
    { id: "MANGALORE", label: "Mangalore" },
    { id: "MATHURA", label: "Mathura" },
    { id: "MOHALI", label: "Mohali" },
    { id: "NAGAPATTINAM", label: "Nagapattinam" },
    { id: "NANDED", label: "Nanded" },
    { id: "NELLORE", label: "Nellore" },
    { id: "PALAKKAD", label: "Palakkad" },
    { id: "PANIPAT", label: "Panipat" },
    { id: "PONDICHERRY", label: "Pondicherry" },
    { id: "ROHTAK", label: "Rohtak" },
    { id: "ROURKELA", label: "Rourkela" },
    { id: "SAHARANPUR", label: "Saharanpur" },
    { id: "SANGLI", label: "Sangli" },
    { id: "SILIGURI", label: "Siliguri" },
    { id: "SRINAGAR", label: "Srinagar" },
    { id: "THANJAVUR", label: "Thanjavur" },
    { id: "TIRUNELVELI", label: "Tirunelveli" },
    { id: "UDAIPUR", label: "Udaipur" },
    { id: "UDUPI", label: "Udupi" },
    { id: "ULHASNAGAR", label: "Ulhasnagar" },
    { id: "UTTARKASHI", label: "Uttarkashi" },
    { id: "WARANGAL", label: "Warangal" },
    { id: "YAMUNANAGAR", label: "Yamunanagar" },
    { id: "YAVATMAL", label: "Yavatmal" }
]

// export const businessClassificationOptions = [
//   { id: "LARGE_CORPORATE", label: "Large Corporate" },
//   { id: "MID_CORPORATE", label: "Mid Corporate" },
//   { id: "MEDIUM_ENTERPRISE", label: "Medium Enterprise" },
//   { id: "SMALL_ENTERPRISE", label: "Small Enterprise" },
//   { id: "MICRO_ENTERPRISE", label: "Micro Enterprise" },
//   { id: "SOLE_PROPRIETORSHIP", label: "Sole Proprietorship" },
// ]

export const businessClassificationOptions = [
    { id: "Large Corporate", label: "Large Corporate" },
    { id: "Mid Corporate", label: "Mid Corporate" },
    { id: "Medium Enterprise", label: "Medium Enterprise" },
    { id: "Small Enterprise", label: "Small Enterprise" },
    { id: "Micro Enterprise", label: "Micro Enterprise" },
    { id: "Sole Proprietorship", label: "Sole Proprietorship" },
]

export const turnOverOptions = [
    { id: "Below 2 CR", label: "Below 2 CR" },
    { id: "2-50 CR", label: "2-50 CR" },
    { id: "50-250 CR", label: "50-250 CR" },
    { id: "250-500 CR", label: "250-500 CR" },
    { id: "Above 500 CR", label: "Above 500 CR" },
]

export const industryOptions = [
    { label: 'Infrastructure', value: 'INFRASTRUCTURE' },
    { label: 'Construction', value: 'CONSTRUCTION' },
    { label: 'Automotive', value: 'AUTOMOTIVE' },
    { label: 'Engineering', value: 'ENGINEERING' },
    { label: 'Trade', value: 'TRADE' },
    { label: 'Distributor', value: 'DISTRIBUTOR' },
    { label: 'Energy Electricals', value: 'ENERGY_ELECTRICALS' },
    { label: 'Transportation', value: 'TRANSPORTATION' },
    { label: 'Equipments', value: 'EQUIPMENTS' },
    { label: 'Plant Machinery', value: 'PLANT_MACHINERY' },
    { label: 'Oil and Gas', value: 'OIL_AND_GAS' },
    { label: 'Consumer Durables', value: 'CONSUMER_DURABLES' },
    { label: 'Packaging', value: 'PACKAGING' }
]

export const groupTypeOptions = [
    { id: "PRIORITY", label: "Priority" },
    { id: "NORMAL", label: "Normal" },
    { id: "LEAST", label: "Least" },
]

export const importExportOptions = [
    { id: "IMPORT", label: "Import" },
    { id: "EXPORT", label: "Export" },
    { id: "BOTH", label: "Both" },
]

export const TRADE_TYPE = [
    { value: 'IMPORT', label: 'Import' },
    { value: 'EXPORT', label: 'Export' },
    { value: 'BOTH', label: 'Both' },
]

export const PREFERRED_DELIVERY_DATE = [
    { value: '1 Day', label: '1 Day' },
    { value: '3 Days', label: '3 Days' },
    { value: '5 Days', label: '5 Days' },
    { value: '7 Days', label: '7 Days' },
    { value: '9 Days', label: '9 Days' },
]

export const GENERIC_EXCEPTION_CODE = {
    DATA_NOT_FOUND: "DATA_NOT_FOUND",
    DUPLICATE_ENTRY: "DUPLICATE_ENTRY"
}

export const MATERIAL_REQUIREMENT_CONSTANT = {
    OTHER_PREFERENCE: "OTHER_PREFERENCE",
    BRAND_MANUFACTURER: "BRAND_MANUFACTURER",
    PRODUCT_CATEGORY: "PRODUCT_CATEGORY"
}

export const SCRAP_PRODUCTS = {
    MY_MATERIALS: "My Materials",
    SCRAP_PRODUCTS_LISTING: "Scrap Products Listing",
    CURRENT_SALES_MODE: "Current Sales Mode",
    ADD_NEW_SCRAP: "Add New Scrap",
    UPLOAD_IMAGE: "Upload Image",
    SCRAP_CATEGORY: "Scrap Category",
    TYPE_OF_SCRAP: "Type of Scrap",
    MONTHLY_GENERATED_QUANTITY: "Monthly generated quantity (MT)",
};

export const SCRAP_PRODUCTS_CATEGORY = [
    {
        value: "HMS",
        label: "Hms",
    },
    {
        value: "HOME_SCRAP",
        label: "Home Scrap",
    },
    {
        value: "INTERNAL_SCRAP",
        label: "Internal Scrap",
    },
    {
        value: "NEW_SCRAP",
        label: "New Scrap",
    },
    {
        value: "OBSOLETE_SCRAP",
        label: "Obsolete Scrap",
    },
    {
        value: "OLD_SCRAP",
        label: "Old Scrap",
    },
    {
        value: "POST_CONSUMER_SCRAP",
        label: "Post Consumer Scrap",
    },
    {
        value: "PRE_CONSUMER_SCRAP",
        label: "Pre Consumer Scrap",
    },
    {
        value: "PROMPT_SCRAP",
        label: "Prompt Scrap",
    },
    {
        value: "RECYCLING",
        label: "Recycling",
    },
    {
        value: "SCRAP_PROCESSING",
        label: "Scrap Processing",
    },
    {
        value: "STEEL_MAKING",
        label: "Steel Making",
    },
    {
        value: "STEEL_SCRAP",
        label: "Steel Scrap",
    },
]

export const SCRAP_PRODUCTS_TYPE = [
    {
        value: "ALUMINUM",
        label: "Aluminum",
    },
    {
        value: "STEEL",
        label: "Steel",
    },
    {
        value: "COPPER",
        label: "Copper",
    },
]

export const SCRAP_PRODUCTS_SALES = [
    {
        value: "ONLINE_SALES",
        label: "Online Sales",
    },
    {
        value: "ONLINE_MARKETPLACE",
        label: "Online Marketplace",
    },
    {
        value: "DIRECT_SALES",
        label: "Direct Sales",
    },
]

export const FINISHED_PRODUCTS = {
    MY_MATERIALS: "My Materials",
    FINISHED_PRODUCTS_LISTING: "Finished Products Listing",
    APPLICATION_TYPE: "Application Type",
    FINISHED_PRODUCT: "Finished Product",
    UPLOAD_IMAGE: "Upload Image",
    PRODUCT_NAME: "Product Name",
    PRODUCT_DESCRIPTION: "Product Description",
};

export const FINISHED_PRODUCTS_APPLICATION_TYPE = [
    {
        value: "FINISHED_PRODUCT",
        label: "Finished Product",
    },
    {
        value: "AUTOMOTIVE_PARTS",
        label: "Automotive Parts",
    },
    {
        value: "PACKAGING_MATERIAL",
        label: "Packaging Material",
    },
    {
        value: "CONSTRUCTION_MATERIAL",
        label: "Constructive Material",
    },
    {
        value: "CONSUMER_GOODS",
        label: "Consumer Goods",
    },
    {
        value: "ELECTRONIC_COMPONENTS",
        label: "Electronic Components",
    },
];

export const PASSWORD_CHECKLIST = {
    uppercase: "Includes a Uppercase Letter",
    minLength: "Minimum 8 Characters",
    lowercase: "Includes a Lowercase Letter",
    symbol: "Includes a Symbol",
    number: "Includes a Number"
}

export const MATERIAL_REQUIREMENT_APPLICATION_TYPE = [
    { id: "RAW_MATERIAL", name: "Raw Material" },
    { id: "RECYCLED_PRODUCT", name: "Recycled Product" },
    { id: "MANUFACTURING_COMPONENT", name: "Manufacturing Component" },
]

export const SOURCING_PURPOSE = [
    { id: "MANUFACTURING", name: "Manufacturing" },
    { id: "RESALE", name: "Resale" },
    { id: "EXPORT", name: "Export" },
]

export const FREQUENCY = [
    { id: "DAILY", name: "Daily" },
    { id: "WEEKLY", name: "Weekly" },
    { id: "MONTHLY", name: "Monthly" },
    { id: "QUARTERLY", name: "Quarterly" },
    { id: "ANNUALLY", name: "Annually" },
];

export const VALUE_ADDING_SERVICES = [
    { value: 'Shearing', label: 'SHEARING' },
    { value: 'Slitting', label: 'SLITTING' },
    { value: 'Blanking', label: 'BLANKING' },
    { value: 'Metal Printing', label: 'METAL_OFFSET_PRINTING' },
];

export const UPC_APPLICATIONS = [
    { label: "Aerospace", value: "AEROSPACE" },
    { label: "Appliances", value: "APPLIANCES" },
    { label: "Construction", value: "CONSTRUCTION" },
    { label: "Defense and Military", value: "DEFENSE_AND_MILITARY" },
    { label: "Automotive", value: "AUTOMOTIVE" },
    { label: "Pipelines", value: "PIPELINES" },
    { label: "Railways", value: "RAILWAYS" },
    { label: "Shipbuilding", value: "SHIPBUILDING" },
    { label: "Tinplate", value: "TINPLATE" },
    { label: "Tools and Machinery", value: "TOOLS_AND_MACHINERY" }
];

export const GENERIC_FILTER_SECTIONS = {
    UPC: "UPC",
    MATERIAL_FILTER: "MATERIAL_FILTER",
    OTHER: "OTHER",
    VENDOR_FILTER: "VENDOR_FILTER",
    WAREHOUSE: "WAREHOUSE"
}

export const MY_MATERIAL_CUSTOMIZATION_ATTRIBUTE = ["THICKNESS", "WIDTH"]

export const VENDOR_TYPE = [
    {
        value: "PRIMARY_MANUFACTURER",
        label: "Primary Manufacturer",
    },
    {
        value: "SECONDARY_MANUFACTURER",
        label: "Secondary Manufacturer",
    },
    {
        value: "DISTRIBUTOR",
        label: "Distributor",
    },
    {
        value: "TRADER",
        label: "Trader",
    }, 
    {
        value: "CHANNEL_PARTNER",
        label: "Channel Partner",
    },
    {
        value: "IMPORTERS",
        label: "Importers",
    },
    {
        value: "STEEL_SERVICE_CENTER",
        label: "Steel Service Center",
    },  
]

export const LEAD_SOURCE = [
    {
        value: "WHATSAPP",
        label: "WhatsApp",
    },
    {
        value: "FACE_TO_FACE_MEETINGS",
        label: "Face To Face Meetings",
    },
    {
        value: "COLD_OUTREACH",
        label: "Cold OutReach",
    },
    {
        value: "COLD_CALLING",
        label: "Cold Calling",
    },
    {
        value: "COLD_EMAILING",
        label: "Cold Emailing",
    },
    {
        value: "WEBSITE",
        label: "Website",
    },
    {
        value: "REFERRALS",
        label: "Referrals"
    },
    {
        value: "ORGANIC_SEARCH",
        label: "Organic Search"
    }, {
        value: "B2B_MARKETPLACES",
        label: "B2b MarketPlaces"
    }, {
        value: "DIRECT_MAIL_CAMPAIGNS",
        label: "Direct Mail Campaigns"
    },
]

export const LEAD_PRIORITY = [
    {
        value: "CRITICAL",
        label: "Critical",
    },
    {
        value: "HIGH",
        label: "High",
    },
    {
        value: "MEDIUM",
        label: "Medium",
    },
    {
        value: "LOW",
        label: "Low",
    },
]

export const QUOTATION_STATUS = [
    {
        value: "",
        label: "All",
    },
    {
        value: "DRAFT",
        label: "Draft",
    },
    {
        value: "SENT",
        label: "Sent",
    },
    {
        value: "IN_PROGRESS",
        label: "In Progress",
    },
    {
        value: "CLOSED_WIN",
        label: "Closed Win",
    },
    {
        value: "CLOSED_LOST",
        label: "Closed Lost",
    },
]

export const STATUS_CONST = {
    DRAFT: "DRAFT",
    SENT: "SENT",
    IN_PROGRESS: "IN_PROGRESS",
    CLOSED_WIN: "CLOSED_WIN",
    CLOSED_LOST: "CLOSED_LOST",
    REVISED: "REVISED",
}

export const SUCCESS_FAILURE_POPUP = {
    SUCCESS: "Success!",
    DELETE_PRODUCT: "Delete Product?",
    DELETE_SERVICE: "Delete Service?",
    QUOTATION_SENT: "Your quotation has been sent!",
    WANT_TO_DELETE: "Are you sure you want to delete this?",
    DOWNLOAD_PDF: "Download PDF",
    CONTINUE: "Continue",
    YES: "Yes",
    NO: "No",
}

export const TERMS_CONDITION_CONSTANT = `
1. A reference to Trader means FUTURECOM TECHNOLOGIES PRIVATE LIMITED.
2. A reference to Buyers means the party who purchases the goods from the Trader.
3. A reference to Warehouse means the storage unit of the Trader.
4. The goods shall be as per the specifications of the Trader. All warranties or conditions, statutory or otherwise, as to quality or fitness for any particular purpose, whether known to the Trader or not, are excluded. The Buyers shall give the Trader written notice within seven days of receipt of goods, of any claim if goods are not of stated quality, to enable the Trader to investigate the complaint before the remainder of the consignment is used or returned to the Trader.
5. The Trader reserves the right to revise the price and its terms without notice or reason, even after acceptance of the Purchase order and/or payment. The price applicable shall be the price prevailing on the date and time of delivery.
6. Goods once sold shall not be taken back or exchanged by the Trader, except as stated in point 4 above.
7. Claims for shortages in the consignment must be settled directly with the carrier if arranged by the Buyer. The Trader will not be responsible for such claims once delivery is taken.
8. Any dispute arising from the contract shall be subject to the jurisdiction of courts of Delhi only.
9. All prices quoted are for delivery ex-warehouse/factory. The responsibility to arrange transport to collect the goods from the Trader's warehouse is per agreed sale terms. The Trader's responsibility ends once delivery is accepted by the Buyer. Transit Insurance and liability for transit losses are to the Buyer's account if the transport is arranged by the Buyer.
10. The Trader will make efforts to have goods ready at the warehouse per the D.O., based on best estimates, but shall not be liable for damages arising from delay in delivery. The Trader is not responsible for delays in dispatching goods.
11. Any taxes (including GST), cess, or duties levied on the sale, transportation, or delivery of goods shall be paid by the Buyer or reimbursed to the Trader if directly paid by the Trader.
12. All orders placed by the Buyer are subject to the Trader's acceptance, and the Trader may accept or refuse any order in full or part without assigning any reason.
13. The weights recorded in the Trader's dispatch certificate are final. Buyers may verify weights, but the Trader's recorded weights are conclusive.
14. The Trader shall not be liable for delays in dispatching goods due to causes beyond their control, such as Acts of God, war, strikes, Force Majeure, etc. In such cases, delivery may be suspended or canceled at the Trader's option without liability for damages.
15. If the Buyer fails to take delivery of goods when due, the Trader may cancel the deliveries in arrears or sell the remaining goods in the open market and claim any difference as well as damages.
16. If the Buyer fails to make payment when due, or breaches any terms, the Trader may cancel the contract or refuse further deliveries.
17. Cheque bouncing charges are Rs. 500 per instance.
18. A penalty of 30% p.a. would be charged if the payment is delayed beyond the committed date.`;

export const ADDRESS_TYPES = {
    BILLING: 'billing',
    SHIPPING: 'shipping',
}

export const LOCAL_PREVIOUS_URL_SECTIONS = {
    SECONDARY_INVENTORY_PREVIOUS_URL: "SecondaryInventoryLastVisitedUrl",
    NON_STANDARD_INVENTORY_PREVIOUS_URL: "NonStandardInventoryLastVisitedUrl",
    STANDARD_INVENTORY_PREVIOUS_URL: "StandardInventoryLastVisitedUrl",
}

export const SERVICE_STATUS_OPTIONS = [
    { value: 'ACTIVE', label: 'Active' },
    { value: 'INACTIVE', label: 'Inactive' },
];

export const SLITTING_HEADING = [
    "Sno.",
    "Width (MM)",
    "Weight of Coil (MT)",
    "CTW Charges/MT",
    "Total Charges",
    "Action"
]

export const SHEARING_HEADING = [
    "Sno.",
    "Length (MM)",
    "No. of Pieces",
    "Weight/Pc (MT)",
    "Weight (MT)",
    "CTL Charges/MT",
    "Total Charges",
    "Action"
]

export const CUSTOMIZTION_TYPE = {
    SLITTING: "SLITTING",
    SHEARING: "SHEARING",
    BLANKING: "BLANKING",
    METAL_OFFSET_PRINTING: "METAL_OFFSET_PRINTING"
}

export const SELECT_VAS_CUSTOMIZATION = {
    SELECT_CUSTOMIZATION: "Select Customization",
    SELECT_THE_VALUE: "Please select the value adding services you want",
    SELECTED_SERVICES: "You've selected following services:",
}

export const uomMapping: any = {
    KG: { shortcode: "kg", fullDescription: "kilograms" },
    LBS: { shortcode: "lbs", fullDescription: "pounds" },
    G: { shortcode: "g", fullDescription: "grams" },
    CM: { shortcode: "cm", fullDescription: "centimeters" },
    M: { shortcode: "m", fullDescription: "meters" },
    TON: { shortcode: "ton", fullDescription: "tons" },
    MM: { shortcode: "mm", fullDescription: "millimeters" },
    MT: { shortcode: "mt", fullDescription: "metric tons" },
    FT: { shortcode: "ft", fullDescription: "feet" },
    IN: { shortcode: "in", fullDescription: "inches" },
    GSM: { shortcode: "gsm", fullDescription: "Grams per square meter" },
};

export const ATTRIBUTE_TYPES = [
    {
        id: "SPECIFICATION",
        name: "Specification"
    },
];

export const ATTRIBUTE_FEILD_TYPES = [
    {
        id: "DROPDOWN",
        name: "Drop Down"
    },
];

export const industryOptionsV2 = [
    { label: 'Infrastructure', id: 'INFRASTRUCTURE' },
    { label: 'Construction', id: 'CONSTRUCTION' },
    { label: 'Automotive', id: 'AUTOMOTIVE' },
    { label: 'Engineering', id: 'ENGINEERING' },
    { label: 'Trade', id: 'TRADE' },
    { label: 'Distributor', id: 'DISTRIBUTOR' },
    { label: 'Energy Electricals', id: 'ENERGY_ELECTRICALS' },
    { label: 'Transportation', id: 'TRANSPORTATION' },
    { label: 'Equipments', id: 'EQUIPMENTS' },
    { label: 'Plant Machinery', id: 'PLANT_MACHINERY' },
    { label: 'Oil and Gas', id: 'OIL_AND_GAS' },
    { label: 'Consumer Durables', id: 'CONSUMER_DURABLES' },
    { label: 'Packaging', id: 'PACKAGING' }]
    

export const DELIVERY_INFORMATION = [
    { value: "IMMEDIATE", label: "Immediate" },
    { value: "SEVEN_TO_TEN_DAYS", label: "7-10 Days" },
    { value: "FIFTEEN_TO_THIRTY_DAYS", label: "15-30 days" },
    { value: "THIRTY_TO_SIXTY_DAYS", label: "30-60 Days" },
    { value: "SIXTY_PLUS_DAYS", label: "60+ Days" },
];

export const APPROVAL_STATUS = [
    { value: "PENDING", label: "Pending" },
    { value: "PUBLISHED", label: "Publish" },
    { value: "UNPUBLISHED", label: "Unpublish" },
    { value: "REJECTED", label: "Awaiting" },
];

export const statusOptionsApproval: option[] = [
    { value: "", label: "All" },
    { value: "ACTIVE", label: "Active" },
    { value: "INACTIVE", label: "Inactive" },
    { value: "DRAFT", label: "Draft" },
];

export const PAYMENT_METHODS = {
    CASH_PAYMENT: "CASH_PAYMENT",
    CREDIT_PAYMENT: "CREDIT_PAYMENT"
}

export const PAYMENT_METHODS_V2 = {
    CASH: "CASH",
    CREDIT: "CREDIT"
}

export const CONNECT_WITH_US_POPUP: ContactUsPopupProps[] = [
    {
        icon: CallIcon,
        text: "Call Us",
        country: "+91",
        mobile: "9654555510",
        description: "Mon-Fri  10am to 7pm",
    },
    {
        icon: MailIcon,
        text: "Write to us",
        email: "info@abainfotech.com",
    },
    {
        icon: WriteToUsIcon,
        text: "Write to us",
        country: "+91",
        mobile: "9654555510",
    }
]

export const CONNECT_WITH_US = {
    TALK_TO_US: "Talk to Us",
    HELP_SUPPORT: "Help & Support",
}

export const SELECT_CUSTOMIZATION = {
    SELECT_CUSTOMIZATION: "Select Customization",
    SELECT_THE_VALUE: "Please select the value adding services you want",
    SELECTED_SERVICES: "You've selected following services:",
}

export const PAYMENT_TERMS = {
    PAYMENT_TERMS: "Payment Terms",
    OR: "OR",
}

export const PAYMENT_SELECTION = {
    CASH_DOWN_PAYMENT: "You’ve selected cash down payment.",
    CREDIT_PAYMENT: "You’ve selected convenient credit payment."
}

export const PAYMENT_TERMS_SECTION: PaymentTermsSectionProps[] = [
    {
        value: PAYMENT_METHODS.CASH_PAYMENT,
        heading: "Avail Attractive Offers for Cash Down Payment",
        subHeading: "Click view to find further information on our payment terms to avail the best plan for you",
        hasNext: true,
        isAvailable: true
    },
    {
        value: PAYMENT_METHODS.CREDIT_PAYMENT,
        heading: "Avail Flexible and Convenient Credit Payment",
        subHeading: "Click view to find further information on our payment terms to avail the best plan for you",
        isAvailable: false
    }
]

export const DELIVERY_AND_TRANSPORTATION_SECTION: DeliveryAndTransportationSectionProps[] = [
    {
        text: "Delivery",
        icon: WarehouseIcon,
        description: "Ex- Warehouse (as selected above)",
    },
    {
        text: "Transportation",
        icon: TransportationIcon,
        description: "To Be Managed by Customer",
    }
]

export const ATTRIBUTES_TO_HIDE = ["HARDNESS"]

export const MAX_DISPLAY_ITEMS = {
    SPECIFICATIONS: 5,
    FAQS: 3,
}

export const ATTRIBUTE_LABEL = {
    COLOR: "Color",
    COATING_GRADE: "Coating Grade",
    HARDNESS: "Hardness",
    TEMPER: "Temper",
}

export const COLOR_DATA: IColorPalette = {
    "RAL1021": {
        "bgColor": "#F8B700",
        "labelColor": "#FFFFFF"

    },
    "RAL2008": {
        "bgColor": "#EC681A",
        "labelColor": "#FFFFFF"
    },
    "RAL5012": {
        "bgColor": "#3481B8",
        "labelColor": "#FFFFFF"
    },
    "RAL5015": {
        "bgColor": "#2874B2",
        "labelColor": "#FFFFFF"
    },
    "RAL5018": {
        "bgColor": "#21888F",
        "labelColor": "#FFFFFF"
    },
    "RAL6011": {
        "bgColor": "#6D7D5A",
        "labelColor": "#FFFFFF"
    },
    "RAL6018": {
        "bgColor": "#609839",
        "labelColor": "#FFFFFF"
    },
    "RAL7015": {
        "bgColor": "#4E5258",
        "labelColor": "#FFFFFF"
    },
    "RAL7037": {
        "bgColor": "#7D7E7C",
        "labelColor": "#FFFFFF"

    },
    "RAL9002": {
        "bgColor": "#D7D5CA",
        "labelColor": "#000000"

    },
    "RAL9003": {
        "bgColor": "#ECECE8",
        "labelColor": "#000000"
    },
    "RAL9011": {
        "bgColor": "#37393B",
        "labelColor": "#FFFFFF"
    },
    "RAL0404040": {
        "bgColor": "#8E4130",
        "labelColor": "#FFFFFF"
    },
    "RAL0709005": {
        "bgColor": "#EDE4D9",
        "labelColor": "#000000"
    }
}

export const SEE_MORE_LESS = {
    SEE_MORE: "See More",
    SEE_LESS: "See Less",
}

export const faqItems: FAQ[] = [
    {
        question: "What product categories does your company deal in?",
        answer: "We can provide customers with different kinds of metal products, such as HR, CR, Galvanized Steel, Colour Coated Steel, Electrolytic Tinplate, Tin Free Steel, CRNO, CRGO, etc",
        expanded: false
    },
    {
        question: 'How long is your delivery time?',
        answer: 'Typically, delivery is instant for in-stock items, or 7-10 days if items are out of stock.',
        expanded: false
    },
    {
        question: 'How can I contact your company?',
        answer: 'You can reach us via email, WhatsApp, or phone. Additionally, you can visit our warehouses located in Taloja and Delhi.',
        expanded: false
    },
    {
        question: 'What types of processing services does your company provide?',
        answer: 'We offer a range of processing services in our warehouses, including shearing, slitting, blanking, printing, and many others. Please feel free to contact us for any special requirements',
        expanded: false
    },
    {
        question: 'What are your payment terms?',
        answer: 'We offer attractive payment discounts for advance and on-time delivery payments and flexible payment options. Please do not hesitate to contact us for further details',
        expanded: false
    },
];

export const STATIC_PAGES_ROUTES = {
    PRIVACY_POLICY: "/privacy-policy",
    TERMS_AND_CONDITIONS: "/terms-and-conditions",
    SUPPORT_CARE: '/support-and-care',
    STEEL_BAZAAR_SERVICES: '/services',
    CONTACT_US: '/contact-us',
    ABOUT_US: 'about-us',
    SELL_ON_STEELBAZAAR: 'sell-on-steelbazaar',
    BUY_ON_STEELBAZAAR: 'buy-on-steelbazaar',
    HELP_CENTER: ' help-center',
    TRADE_ASSURANCE: 'trade-assurance',
    B2B_MARKETPLACE: 'b2b-marketplace',
    SELLER_CENTRAL: 'seller-central',
    PROCUREMENT: 'procurement',
    VENDOR_CENTRAL: 'vendor-central',
    CUSTOMER_BUYER: 'customer-buyer',
}

export const UNIT = {
    UOM: "MT"
}

export const SUBJECT_TO_TERMS_DISCLAIMER = {
    SUBJECT_TO: "*Subject to",
    TERMS_CONDITION: "Terms & Condition",
    AND: "and",
    DISCLAIMER: "Disclaimer",
    SALES: "of sales"
}

export const PAYMENT_TERMS_SECTION_V2: PaymentTermsSectionProps[] = [
    {
        value: PAYMENT_METHODS_V2.CASH,
        heading: "Avail Attractive Offers for Cash Down Payment",
        subHeading: "Click view to find further information on our payment terms to avail the best plan for you",
        isAvailable: true
    },
    {
        value: PAYMENT_METHODS_V2.CREDIT,
        heading: "Avail Flexible and Convenient Credit Payment",
        subHeading: "Click view to find further information on our payment terms to avail the best plan for you",
        isAvailable: true
    }
]

export const NUMBER_URL = {
    WHATSAPP: 'https://api.whatsapp.com/send/?phone=919654555531',
    STEEL_BAZAR_NEWS: 'https://chat.whatsapp.com/EDo65eMrfcdErvheDmbCNo'
};

export const SECONDARY_CATALOGUE_TYPES = [
    {
        id: "CURRENT_INVENTORY",
        name: "In Stock"
    },
    {
        id: "MAKE_TO_ORDER",
        name: "Lots"
    }
];

export const NON_STANDARD_CATALOGUE_TYPES = [
    {
        id: "CURRENT_INVENTORY",
        name: "In Stock"
    }
    // ,
    // {
    //     id: "MAKE_TO_ORDER",
    //     name: "Make to Order"
    // }
];

export const ATTRIBUTES_WITH_RANGE = ['LENGTH', 'WIDTH', 'THICKNESS'];

export const VENDOR_CATALOGUE_TYPES = [
    {
        id: "SINGLE_PRODUCT",
        name: "In Stock"
    },
    {
        id: "MAKE_TO_ORDER",
        name: "Make to Order"
    }
];

export const VENDOR_NON_STANDARD_CATALOGUE_TYPES = [
    {
        id: "SINGLE_PRODUCT",
        name: "In Stock"
    }
    // ,
    // {
    //     id: "MAKE_TO_ORDER",
    //     name: "Make to Order"
    // }
];

export const VENDOR_PRODUCT_ROUTES = {
    POST_PRODUCT: "/dashboard/product",
    ADD_PRODUCT: "/dashboard/product/add",
    EDIT_PRODUCT: "/dashboard/product/edit",
}

export const CUSTOMER_ROUTES = {
    ONBOARDING: "/onboarding",
    CATALOGUE_LISTING: "/catalogue/list",
    CATALOGUE_DESCRIPTION: "/catalogue/view",
    CATALOGUE_MTO_VIEW: "/mto-catalogue/view",
    SECONDARY_CATALOGUE_DESCRIPTION: "/secondary-catalogue/view",
    SECONDARY_CATALOGUE_LOT_VIEW: "/secondary-catalogue-lot/view",
    CART: "/cart",
    WISHLIST: "/wishlist",
    DASHBOARD: "/dashboard",
    TERMS_AND_CONDITIONS: "/terms-conditions",
    MY_PROFILE: '/my-profile',
    STORE_FRONT: '/store-front',
    STORE_FRONT_CATALOGUE_VIEW: '/store-front/view',
    CHANGE_PASSWORD: "/change-password",
    KEY_PERSON_DETAILS: "/key-person-details",
    BUSINESS_LOCATION: "/business-location",
    BUSINESS_PROFILE: "/business-profile",
    STATUTORY_DOCUMENTS: "/statutory-documents",
    PREFRENCES: "/preferences",
    MY_MATERIAL_LISTING: "/dashboard/my-material",
    FINISHED_PRODUCT: "/dashboard/finished-product",
    SCRAP_PRODUCT: "/dashboard/scrap-product",
    VENDOR_PRODUCT_PRICE_LISTING: '/dashboard/vendor/price-management/list',
    VENDOR_PRODUCT_PRICE_CREATE: '/dashboard/vendor/price-management/add',
    VENDOR_PRODUCT_PRICE_UPDATE: '/dashboard/vendor/price-management/edit',
    VENDOR_PRODUCT_PRICE_VIEW: '/dashboard/vendor/price-management/view',
    LEAD: "/lead",
    QUOTATION: "/quotation",
    LEAD_SECONDARY: "/lead-secondary",
    LEAD_SECONDARY_LOT: "/lead-secondary-lot",
    ORDER: "/order",
    ORDER_SECONDARY: "/order-secondary",
    ORDER_SECONDARY_LOT: "/order-secondary-lot",
    ONBOARDING_WELCOME: "/onboarding-welcome",
    CATALOGUE_BUNDLE: "/catalogue-bundle/view",
    BUYER_DASHBOARD: "/buyer-dashboard",
    VENDOR_CATALOGUE_DESCRIPTION: "/vendor/catalogue/view",
    VENDOR_CATALOGUE_MTO_VIEW: "/vendor/mto-catalogue/view",
}

export const PRODUCTS_TYPES = {
    SINGLE_LENGTH_COIL: "Single Length Coil",
    MULTI_WELDED_COIL: "Multi Welded Coil",
    LEADER_END_COIL: "Leader End Coil",
    UNIFORM_ASSORTED_BUNDLE_SHEETS: "Uniform Assorted Bundle Sheets",
    MIX_ASSORTED_BUNDLE_SHEETS: "Mix Assorted Bundle Sheets",
    UNASSORTED_BUNDLE_SHEETS: "Unassorted Bundle Sheets",
    SCROLL: "Scroll",
    STANDARD: "Standard Prime",
    NON_STANDARD: "Non Standard Prime",
    SECONDARY: "Secondary",
    MAKE_TO_ORDER: "Make To Order",
    PLATE: 'Plate',
    BAR: 'Bar',
    COIL: 'Coil',
    SHEET: 'Sheet',
    STRIP: 'Strip',
    ANGLE: 'Angle',
    CHANNEL: 'Channel',
    BEAM: 'Beam',
    PIPE: 'Pipe',
    TUBE: 'Tube',
    ROUND:  'Round',
    SQUARE: 'Square',
    RECTANGLE: 'Rectangle',
    OTHERS: 'Others'
};

export const steelBazaarstatusOptions: option[] = [
    { value: "ACTIVE", label: "Active" },
    { value: "INACTIVE", label: "Inactive" },
    { value: "DRAFT", label: "Draft" },
];


export const PriceInformationstatusOptions: option[] = [
    { value: "ACTIVE", label: "Active" },
    { value: "INACTIVE", label: "Inactive" },
];

export const CATALOGUES_TYPE = {
    STANDARD: 'Standard',
    NONSTANDARD: 'Non Standard',
    SECONDARY: 'Commercial Scrap',
    MELTING_SCRAP: 'Melting Scrap'
}

export const VENDOR_PRODUCT_CLASS_TYPE = [
    { value: "STANDARD", label: "Standard Prime" },
    { value: "NONSTANDARD", label: "Non Standard Prime" },
    { value: "SECONDARY", label: "Commercial Scrap" },
    { value: "MELTING_SCRAP", label: "Melting Scrap" },
]

export const VENDOR_PRODUCT_CLASS_SELECTION_TYPE = [
    { value: "EXISTING_PRODUCT", label: "Select from Existing Product" },
    // { value: "ADD_NEW_PRODUCT", label: "Add your own Product" }
]

export const VendorProductSections = {
    VENDOR_PRODUCT_PRICING: 'VENDOR_PRODUCT_PRICING',
};

export const TAB_MAPPING_CATALOGUES = {
    STANDARD_CATALOG: '1',
    NONSTANDARD_CATALOG: '2',
    PROCESSED_PRIME: '3',
    MELTING_SCRAP: '4'
}

export const CATALOGUES_TYPES = {
    STANDARD_CATALOG: 'STANDARD_CATALOG',
    NONSTANDARD_CATALOG: 'NONSTANDARD_CATALOG',
    PROCESSED_PRIME: 'PROCESSED_PRIME',
    MELTING_SCRAP: 'MELTING_SCRAP'
}