import { Route, Routes } from 'react-router-dom';
import AttributeListPage from '../../../components/pages/Attribute/AttributeList.page';
import AttributeCreatePage from '../../../components/pages/Attribute/AttributeCreate.page';
import AttributeUpdatePage from '../../../components/pages/Attribute/AttributeUpdate.page';
import AttributeViewPage from '../../../components/pages/Attribute/AttributeView.page';

const AttributeRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<AttributeListPage />} />
            <Route path="/add" element={<AttributeCreatePage />} />
            <Route path="/:id" element={<AttributeViewPage />} />
            <Route path="/:id/update" element={<AttributeUpdatePage />} />
        </Routes>
    )
}

export default AttributeRoutes