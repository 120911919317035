import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { capitalizeFirstLetter, titleModification } from '../../../utils/helper';
import { ATTRIBUTE_FEILD_TYPES, ATTRIBUTE_TYPES, MODE, SERVICE_STATUS_OPTIONS, uomMapping } from '../../../utils/constant';
import { createUseStyles } from 'react-jss';
import ButtonV2 from '../../atoms/ButtonV2';
import TextFieldV2 from '../../atoms/TextFieldV2';
import StatusV2 from '../../atoms/Status/StatusV2';
import SelectV2 from '../../atoms/SelectV2';
import AutoCompleteInputV2 from '../../molecules/AutoCompleteInput/AutoCompleteInputV2';
import CustomAttributeInputTags from '../../../App/Shared/Chips/CustomAttributeInputTags';

interface AttributeFormTemplateProps {
    mode: string,
    formik: any;
    onBack: () => void,
    existingTags?: any[]
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    checkBox: {
        color: theme.palette.textV2.tertiary.tertiary300,
        '&.Mui-checked': {
            color: theme.palette.textV2.primary.primary400,
        },
    },
    value: {
        color: theme.palette.textV2.primary.primary950
    },

}));

const convertUOMSuggestions = (suggestions: any) => {
    return Object.keys(suggestions).map((key, index) => ({
        label: key,
        id: index + 1,
        shortcode: suggestions[key].shortcode,
        fullDescription: suggestions[key].fullDescription,
    }));
};

const AttributeFormTemplate: React.FC<AttributeFormTemplateProps> = ({ mode, formik, onBack, existingTags = [] }) => {
    const classes = useStyles();
    const [UOMSuggestions, setUOMSuggestions] = useState([]);

    const debounce = (func: any, delay: any) => {
        let timeoutId: any;
        return (...args: any) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const fetchUOMData = async () => {
        try {
            var convertedDetails: any = convertUOMSuggestions(uomMapping)
            setUOMSuggestions(convertedDetails);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchUOMData()
    }, []);

    const delayedSearchUOMFetch = debounce(fetchUOMData, 700);

    const updateFieldValues = (fieldsValues: any) => {
        formik.setFieldValue('attributeOptions', fieldsValues);
    }

    const updateAttributeOptionText = (event: any) => {
        var addedValue = event.target.value
        formik.setFieldValue('attributeOptions', [addedValue]);
    }

    const updateUOMValues = (uomData: any) => {
        formik.setFieldValue('uomValue', uomData);
        formik.setFieldValue('uom', uomData?.label);
    }

    const handleUomChange = () => {
        const uomCheckSelected = !formik.values.uomCheck;
        formik.setFieldValue('uomCheck', uomCheckSelected);
        formik.setFieldValue('uomValue', null);
        formik.setFieldValue('uom', "");
    };

    interface AttributeDataProps {
        type: string;
        value: string;
    }

    const AttributeData: React.FC<AttributeDataProps> = ({ type, value }) => {
        return (
            <div className="flex flex-col items-start justify-start gap-[6px]">
                <div className="relative font-medium" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{type}</div>
                <div className="relative">{value}</div>
            </div>
        );
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const handleSubmit = () => formik.handleSubmit();

    return (
        <form onKeyDown={handleKeyDown} className="grid gap-y-8">
            <div className={`${classes.mainHeading} text-lg font-semibold`}>{mode === MODE.UPDATE ? "Update" : mode === MODE.VIEW ? "View" : "Add New"} Attribute</div>
            <div className='grid gap-y-4'>
                <div className={`${classes.border} grid  gap-y-3 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Attribute Details</div>
                    <div className={` grid gap-y-4`}>
                        <div className={` grid grid-cols-3 gap-x-4`}>
                            <div className="grid gap-y-1">
                                <TextFieldV2
                                    label="Attribute Name"
                                    placeholder='Enter Attribute Name'
                                    {...formik.getFieldProps("name")}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    inputProps={{ readOnly: mode !== MODE.ADD, maxLength: 30 }}
                                    onBlur={(event) => {
                                        const newValue = titleModification(event.target.value);
                                        formik.setFieldValue('name', newValue);
                                    }}
                                />
                                {formik.errors.name && formik.touched.name && (
                                    <div className={`${classes.errorMessage} text-xs`}>{formik.errors.name}</div>
                                )}
                            </div>

                            <div className='grid'>
                                <SelectV2
                                    placeholder='Select Attribute Type'
                                    label="Select Attribute Type"
                                    fullWidth
                                    {...formik.getFieldProps('attributeType')}
                                    error={
                                        formik.touched.attributeType &&
                                        Boolean(formik.errors.attributeType)
                                    }
                                    options={
                                        ATTRIBUTE_TYPES?.map((option: any) => ({
                                            value: (option.id),
                                            label: option.name,
                                        })) || []
                                    }
                                />
                                {formik.touched.attributeType && formik.errors && formik.errors.attributeType && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors.attributeType}</small>
                                    </div>
                                )}
                            </div>

                            <div className="grid gap-y-1">
                                <TextFieldV2
                                    label="Attribute Rank"
                                    placeholder='Enter Attribute Rank'
                                    {...formik.getFieldProps("attributeRank")}
                                    error={formik.touched.attributeRank && Boolean(formik.errors.attributeRank)}
                                    inputProps={{ readOnly: mode === MODE.VIEW }}
                                />
                                {formik.errors.attributeRank && formik.touched.attributeRank && (
                                    <div className={`${classes.errorMessage} text-xs`}>{formik.errors.attributeRank}</div>
                                )}
                            </div>
                        </div>


                        <div className='grid'>
                            <SelectV2
                                placeholder='Select Feild Type'
                                label="Select Feild Type"
                                fullWidth
                                {...formik.getFieldProps('fieldType')}
                                error={
                                    formik.touched.fieldType &&
                                    Boolean(formik.errors.fieldType)
                                }
                                options={
                                    ATTRIBUTE_FEILD_TYPES?.map((option: any) => ({
                                        value: (option.id),
                                        label: option.name,
                                    })) || []
                                }
                            />
                            {formik.touched.fieldType && formik.errors && formik.errors.fieldType && (
                                <div className={classes.errorMessage}>
                                    <small>{formik.errors.fieldType}</small>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid  gap-y-3 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Attribute Option</div>

                    <div className="">
                        {formik.values.fieldType === 'TEXT' ? (
                            <div>
                                <TextFieldV2
                                    label='Enter Attribute Options'
                                    placeholder='Enter Attribute Options'
                                    fullWidth
                                    name='attributeOptions'
                                    value={formik.values.attributeOptions}
                                    onChange={(event) => updateAttributeOptionText(event)}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.attributeOptions && Boolean(formik.errors.attributeOptions)}
                                />
                            </div>
                        ) : (
                            <CustomAttributeInputTags
                                updateFieldData={updateFieldValues}
                                displayLabel={'Add Attribute Options'}
                                initialValue={formik.values}
                                existingValues={existingTags}
                                mode={mode === MODE.VIEW ? true : false}
                                validationRegex={/^.*$/}
                                error={{ error: formik.touched.attributeOptions && formik.values.attributeOptions.length === 0, msg: "Options is required" }}
                                setError={() => { }}
                                errorMessage={""}
                            />
                        )}
                    </div>
                </div>

                <div className={`${classes.border} grid  gap-y-3 p-6 rounded-xl`}>
                    <div className="grid gap-y-2">
                        <div className={`${classes.heading} text-base font-medium`}>Whether Any UOM Parent attribute type?</div>
                        <div className="flex gap-x-2">
                            <div className=''>
                                <Checkbox
                                    sx={{ padding: 0, width: "18px", height: "18px", }}
                                    className={classes.checkBox}
                                    checked={formik.values.uomCheck}
                                    // {...formik.getFieldProps('uomCheck')}
                                    onChange={handleUomChange}
                                    onBlur={formik.handleBlur}
                                    inputProps={{ readOnly: mode === MODE.VIEW }}
                                />
                            </div>
                            <div className={`${classes.value} my-auto text-base font-normal`}>Yes</div>
                        </div>
                    </div>

                    {formik.values.uomCheck && (
                        <div className="grid gap-y-1">
                            <AutoCompleteInputV2
                                options={UOMSuggestions}
                                label="Select UOM"
                                onChange={updateUOMValues}
                                onSearch={delayedSearchUOMFetch}
                                isDisabled={mode === MODE.VIEW}
                                value={formik.values?.uomValue}
                                error={!!formik.errors.uomValue && formik.touched.uomValue}
                            />
                            {formik.errors.uom && formik.touched.uom && (
                                <div className={`${classes.errorMessage}`}>
                                    <small>{formik?.errors?.uom}</small>
                                </div>
                            )}
                        </div>
                    )}
                    {formik.values.uomCheck && (
                        <div className="flex items-start gap-6 text-base text-text-color">
                            <AttributeData type={'Unit of Measurement(UOM)'} value={formik.values.uomValue?.fullDescription} />
                            <AttributeData type={'Short Code'} value={formik.values.uomValue?.shortcode} />
                        </div>
                    )}
                </div>

                <div className={`${classes.border} grid  gap-y-3 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Attribute Description</div>
                    <div className="grid gap-y-1">
                        <TextFieldV2
                            variant="outlined"
                            label="Add Description"
                            placeholder='Enter Here'
                            multiline
                            maxRows={4}
                            {...formik.getFieldProps("description")}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 500 }}
                            onBlur={(event) => {
                                const newValue = titleModification(event.target.value);
                                formik.setFieldValue('description', newValue);
                            }}
                        />
                        {formik.errors.description && formik.touched.description && (
                            <div className={`${classes.errorMessage} text-xs`}>{formik.errors.description}</div>
                        )}
                    </div>
                </div>

                <StatusV2 heading="Service Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" />
            </div>

            <div className="flex justify-end gap-4">
                <ButtonV2 variant="tertiaryContained" label='Cancel' onClick={onBack} />
                {mode !== MODE.VIEW && <ButtonV2 variant="secondaryContained" onClick={handleSubmit} label={`+${capitalizeFirstLetter(mode)}`} />}
            </div>
        </form>
    )
}

export default AttributeFormTemplate;