import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../utils/types';
import { ADMIN_ROUTES, MODE, STATUS } from '../../../utils/constant';
import { IAttributePayload, useAttributeService } from '../../../services/useAttributeService';
import AttributeFormTemplate from '../../templates/Attribute/AttributeForm.template';

const validationSchema = Yup.object().shape({
    name: Yup.string().max(30, "Attribute Name cannot be more than 30 characters").required("Attribute Name is required"),
    attributeOptions: Yup.array().of(Yup.string()).min(1, "Field Types must not be empty").required("Field Types is required"),
    attributeType: Yup.string().typeError("Attributes Type must be a string").required("Attributes Type is required"),
    uomCheck: Yup.boolean(),
    uom: Yup.string().when('uomCheck', ([uomCheck], schema) => {
        return uomCheck ? schema.required("Please select UOM value") : schema;
    }),
    fieldType: Yup.string().typeError("Feild Type must be a string").required("Feild Type is required"),
    description: Yup.string().max(500, "Description cannot be more than 500 characters").required("Description is required"),
    attributeRank: Yup.number().typeError('Attribute rank must be a number').positive('Attribute rank must be a positive number').integer('Attribute rank must be an integer').required('Please Enter Attribute rank')
});

const AttributeCreatePage: React.FC = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const attributeService = useAttributeService();
    const navigate = useNavigate();

    const onBack = () => {
        navigate(ADMIN_ROUTES.ATTRIBUTE_LIST);
    }

    const createMachine = async (machine: IAttributePayload) => {
         const attributeRequestBody: any = {
            name: machine.name.trim(),
            attributeType: machine.attributeType,
            fieldType: machine.fieldType,
            attributeOptions: machine.attributeOptions,
            isUpc: machine.isUpc,
            isMpc: machine.isMpc,
            description: machine.description,
            uom: machine.uom,
            status: machine.status,
            parentId: null,
            attributeRank: machine.attributeRank
         }
         attributeService.createAttribute(attributeRequestBody)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "New Attribute Created");
                    onBack();
                } else if (res.data.exceptionCode === "DUPLICATE_ATTRIBUTE") {
                    showSnackbar('error', "Attribute already exists. Please try again");
                }
            }).catch((error: any) => {
                showSnackbar('error', "Failed to create Machine");
            })
    }

    const formik = useFormik<IAttributePayload>({
        initialValues: {
            name: '',
            attributeType: '',
            fieldType: '',
            attributeOptions: [],
            isUpc: false,
            isMpc: false,
            description: '',
            uom: '',
            status: STATUS.ACTIVE,
            parentId: null,
            attributeRank: 1,
            uomCheck: false
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            createMachine({ ...values, status: values.status })
        }
    })

    return (
        <div>
            {SnackBarComponent}
            <AttributeFormTemplate mode={MODE.ADD} onBack={onBack} formik={formik} />
        </div>
    )
}

export default AttributeCreatePage;