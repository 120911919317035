import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import TabsV2, { ITabsSchema } from '../../molecules/TabsV2/TabsV2';
import { createUseStyles } from 'react-jss';
import { CATALOGUE_BUNDLING_TYPE } from '../../../utils/types';
import BundleListPage from './Bundle/BundleList.page';
import BundleLotListPage from './BundleLot/BundleLotList.page';

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary950,
        lineHeight: "22px"
    },
}));

const CatalogueBundlingListPage: React.FC = () => {
    const classes = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState(CATALOGUE_BUNDLING_TYPE[searchParams.get('type') as keyof typeof CATALOGUE_BUNDLING_TYPE] ?? CATALOGUE_BUNDLING_TYPE.CATALOGUE_BUNDLE);

    useEffect(() => {
        if (isNaN(activeTab)) return;
        const level = Object.keys(CATALOGUE_BUNDLING_TYPE).filter(key => isNaN(parseInt(key)))[activeTab - 1];
        setSearchParams((params: URLSearchParams) => {
            params.set('type', level)
            return params;
        })
    }, [activeTab])

    useEffect(() => {
        setActiveTab(CATALOGUE_BUNDLING_TYPE[searchParams.get('type') as keyof typeof CATALOGUE_BUNDLING_TYPE] ?? CATALOGUE_BUNDLING_TYPE.CATALOGUE_BUNDLE)
    }, [searchParams.get('type')])

    const schema: ITabsSchema[] = [
        { label: 'Catalogue Bundle', component: <BundleListPage /> },
        // { label: 'Catalogue Bundle Lot', component: <BundleLotListPage /> },
    ]

    return (
        <div className='grid gap-y-6'>
            <div className={`${classes.heading} text-lg font-medium`}>Catalogue Bundling</div>
            <TabsV2 schema={schema} value={(activeTab).toString()} setValue={setActiveTab} />
        </div>
    )
}

export default CatalogueBundlingListPage;