import React, { useEffect, useState } from 'react'
import { AttributeSpecification, CategoryPayload } from '../../../services/useCategoryService';
import { PRODUCT_CATEGORY_FORM_STATE } from '../../../utils/types';
import { FormikProps } from 'formik';
import Button from '../../atoms/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { useAttributeService } from '../../../services/useAttributeService';
import { mapAttributeStatusEnumToString } from '../../../App/Constants/Enums/AttributeTypeEnum';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { MODE } from '../../../utils/constant';
import { createUseStyles } from 'react-jss';

interface AttributeManagementFormTemplateProps {
  mode: string;
  onClose: () => void;
  formik: FormikProps<CategoryPayload>;
  setFormStateTo: (state: PRODUCT_CATEGORY_FORM_STATE) => () => void;
}

interface Attribute {
  [key: string]: AttributeSpecification[] | [];
};

const useStyles = createUseStyles((theme: any) => ({
  textColor: {
      color: theme.palette.general.seperator
  },
  border: {
    borderBottom: `1px solid ${theme.palette.border.primaryDark}`
},
}));

const AttributeManagementFormTemplate: React.FC<AttributeManagementFormTemplateProps> = ({ mode, formik, setFormStateTo }) => {
  const classes = useStyles();
  const [attributeValues, setAttributeValues] = useState<Attribute>({});
  const [selectedAttributeType, setSelectedAttributeType] = useState<Attribute>(convertOptionsToArray(formik.values.attributes ?? {}) ?? {});
  const [prevAttributeData, setPrevAttributeData] = useState<Attribute>(convertOptionsToArray(formik.values.attributes ?? {}) ?? {});
  const { showSnackbar, SnackBarComponent } = useSnackbar();

  const attributeService = useAttributeService();

  const convertStringToArray = (input: string | any[]): any[] => {
    if (Array.isArray(input))
      return input;

    if (typeof input === 'string') {
      try {
        const parsedArray = JSON.parse(input);
        if (Array.isArray(parsedArray)) {
          return parsedArray;
        }
      } catch (error) {
        console.error('Error parsing input:', error);
      }
    }
    return [];
  };

  const fetchAllAttributeValues = async (fullData: Attribute) => {
    for (const attributeType of Object.keys(fullData)) {
      await fetchAttributeValues(attributeType);
    }
  };

  function convertOptionsToArray(data: Attribute) {
    const result = { ...data };
    for (const key in result) {
      if (Array.isArray(result[key])) {
        result[key] = result[key].map((item: any) => {
          if (item.hasOwnProperty('attributeOptions') && !Array.isArray(item.attributeOptions)) {
            try {
              const parsedOptions: string[] = JSON.parse(item.attributeOptions);
              if (Array.isArray(parsedOptions)) {
                item.attributeOptions = parsedOptions;
              } else {
                console.error('attributeOptions is not an array:', parsedOptions);
                item.attributeOptions = [];
              }
            } catch (error) {
              console.error(`Error parsing attributeOptions: ${error}`);
              item.attributeOptions = [];
            }
          }
          return item;
        });
      }
    }
    return result;
  };

  const convertOptionsToString = (data: Attribute) => {
    const result: Attribute = { ...data };

    for (const key in result) {
      if (result.hasOwnProperty(key) && Array.isArray(result[key])) {
        result[key] = result[key].map((item) => {
          if (item.hasOwnProperty('attributeOptions')) {
            try {
              item.attributeOptions = JSON.stringify(item.attributeOptions);
            } catch (error) {
              console.error('Error parsing attributeOptions:', error);
              item.attributeOptions = JSON.stringify([]);
            }
          }
          return item;
        });
      }
    }
    return result;
  };

  useEffect(() => {
    const attributeDetails = formik.values.attributes;
    if (!attributeDetails) return;
    const parsedAttributeData: Attribute = convertOptionsToArray(attributeDetails);
    setSelectedAttributeType(parsedAttributeData);
    setPrevAttributeData(parsedAttributeData);
    fetchAllAttributeValues(parsedAttributeData);
  }, [formik.values.attributes])

  const checkAttributeOptions = (jsonData: Attribute) => {
    const errors: string[] = [];

    if (Object.keys(jsonData).length === 0)
      errors.push('Please Select Atleast One Attribute Type.');

    for (const key in jsonData) {
      if (jsonData.hasOwnProperty(key) && Array.isArray(jsonData[key])) {
        if (jsonData[key].length === 0) {
          errors.push(`Please Select Atleast One in ${key} Attribute`);
        } else {
          jsonData[key].forEach((attribute) => {
            if (!Array.isArray(attribute.attributeOptions) || attribute.attributeOptions.length === 0) {
              errors.push(`Please select at least one attribute option in "${attribute.name}" (${attribute.attributeType})`);
            }
          });
        }
      }
    }
    return errors;
  };

  function handleSubmit() {
    if (Object.keys(selectedAttributeType)) {
      let errorMessage = checkAttributeOptions(selectedAttributeType);
      if (!errorMessage.length) {
        formik.setFieldValue('attributes', convertOptionsToString(selectedAttributeType));
        setFormStateTo(PRODUCT_CATEGORY_FORM_STATE.STANDARD_MANAGEMENT)();
      } else {
        if (Array.isArray(errorMessage))
          errorMessage.forEach(error => showSnackbar('error', error));
        else showSnackbar('error', errorMessage);
      }
    } else {
      showSnackbar('error', 'Fill complete details')
    }
  }

  const parseAttributeOptionsArray = (jsonArray: AttributeSpecification[]) => {
    if (!Array.isArray(jsonArray) || jsonArray.length === 0)
      return jsonArray;

    const processedArray = jsonArray.map((jsonData: AttributeSpecification) => {
      if (jsonData.hasOwnProperty('attributeOptions')) {
        const attributeOptionsValue = jsonData.attributeOptions;

        if (typeof attributeOptionsValue === 'string') {
          try {
            const parsedArray: string[] = JSON.parse(attributeOptionsValue);
            if (Array.isArray(parsedArray)) {
              return {
                ...jsonData,
                attributeOptions: parsedArray,
              };
            }
          } catch (error) {
            console.error('Error parsing attributeOptions:', error);
          }
        }
      }
      return {
        ...jsonData,
        attributeOptions: [],
      };
    });

    return processedArray;
  };

  const fetchAttributeValues = async (attributeType: string) => {
    attributeService.getAllAttributes({ page: 0, size: 100, attributeType })
      .then(res => {
        setAttributeValues((prev: Attribute) => ({
          ...prev, [attributeType]: parseAttributeOptionsArray(res?.data?.data?.content)
        }))
        return res?.data?.data?.content ?? [];
      }).catch(error => {
        console.error("Error fetching attribute values: ", error);
        return [];
      })
  };

  const containsId = (array: AttributeSpecification[] | undefined, id: number) => {
    return array?.some(item => item?.id === id) ?? false;
  };

  const containsOptionId = (jsonData: AttributeSpecification[], id: number, value: string) => {
    return jsonData?.some(item => {
      if (item?.id === id && Array.isArray(item.attributeOptions)) {
        const stringValue = String(value);
        return item.attributeOptions.includes(stringValue);
      }
      return false;
    }) ?? false;
  };

  const updateSelectedAttributeType = (idToRemove: number, type: string, record: AttributeSpecification) => () => {
    if (containsId(prevAttributeData[type], idToRemove)) {
      return;
    }

    if (selectedAttributeType[type].some((item) => item?.name?.toLocaleLowerCase()?.includes("hardness")) && record?.name?.toLocaleLowerCase()?.includes("temper")) {
      alert(`You can select Temper only when Hardness is not selected`);
      return;
    } else if (selectedAttributeType[type].some((item) => item?.name?.toLocaleLowerCase()?.includes("temper")) && record?.name?.toLocaleLowerCase()?.includes("hardness")) {
      alert(`You can select Hardness only when Temper is not selected`);
      return;
    }

    setSelectedAttributeType((prevSelectedAttributeType: Attribute) => {
      const updateRecord = { ...record };
      if (selectedAttributeType[type].length > 0) {
        const isIdPresent = selectedAttributeType[type].some((item) => item.id === idToRemove);
        if (isIdPresent) {
          const updatedArray = selectedAttributeType[type].filter((item) => item.id !== idToRemove);
          return {
            ...prevSelectedAttributeType,
            [type]: updatedArray,
          };
        } else {
          updateRecord.attributeOptions = [];
          return {
            ...prevSelectedAttributeType,
            [type]: [...selectedAttributeType[type], updateRecord],
          };
        }
      } else {
        updateRecord.attributeOptions = [];
        return {
          ...prevSelectedAttributeType,
          [type]: [updateRecord],
        };
      }
    });
  };

  const updateSelectedAttributeTypeOptions = (id: number, type: string, optionValue: string, event: React.ChangeEvent<HTMLInputElement>) => {
    !containsOptionId(prevAttributeData[type], id, optionValue) &&
      setSelectedAttributeType((prevSelectedAttributeType: Attribute) => {
        const existingType: AttributeSpecification[] = prevSelectedAttributeType[type];
        if (existingType && existingType.length > 0) {
          const updatedType: AttributeSpecification | undefined = existingType.find((item: AttributeSpecification) => item.id === id);
          if (updatedType && Array.isArray(updatedType.attributeOptions)) {
            if (!event.target.checked) {
              const updatedTypeWithNewOptions: AttributeSpecification = {
                ...updatedType,
                attributeOptions:
                  updatedType.attributeOptions
                    ? updatedType.attributeOptions.includes(optionValue)
                      ? updatedType.attributeOptions.filter((value) => value !== optionValue)
                      : [...updatedType.attributeOptions, optionValue]
                    : [optionValue],
              };
              return {
                ...prevSelectedAttributeType,
                [type]: existingType.map((item: AttributeSpecification) => (item.id === id ? updatedTypeWithNewOptions : item)),
              };
            } else {
              const updatedTypeWithNewOptions: AttributeSpecification = {
                ...updatedType,
                attributeOptions: event.target.checked ?
                  [...updatedType.attributeOptions, optionValue] :
                  [],
              };
              return {
                ...prevSelectedAttributeType,
                [type]: existingType.map((item: AttributeSpecification) => (item.id === id ? updatedTypeWithNewOptions : item)),
              };
            }
          } else return prevSelectedAttributeType;
        }
        return prevSelectedAttributeType;
      });
  };

  const AttributeOptionValuesComponent: React.FC<{ attributeType: string }> = ({ attributeType }) => {

    const valuesForAttributeType = attributeValues[attributeType];
    if (!valuesForAttributeType || valuesForAttributeType.length === 0) {
      return null;
    }

    const hasTrueValue = valuesForAttributeType.some((attributeValue) =>
      containsId(selectedAttributeType[attributeType], attributeValue.id)
    );

    if (!hasTrueValue)
      return null;
    
    const isAllSelected = (id: number) => {
      return valuesForAttributeType.every(attributeValue => {
        if (attributeValue.id === id) {
          return convertStringToArray(attributeValue?.attributeOptions).every(optionValue =>
            containsOptionId(selectedAttributeType[attributeType], attributeValue.id, optionValue)
          );
        }
        return true;
      });
    };

    const handleSelectAllChange = (id: number, type: string, event: React.ChangeEvent<HTMLInputElement>) => {
      valuesForAttributeType.forEach(attributeValue => {
        if (attributeValue.id === id)
          convertStringToArray(attributeValue?.attributeOptions).forEach(optionValue => {
            updateSelectedAttributeTypeOptions(id, type, optionValue, event);
          });
      });
    };
              
    return (
      <Box border="1px solid gray" padding="0 16px" margin="1px 0px" borderRadius="6px">
        <div className="mt-6">
          {(valuesForAttributeType).map((attributeValue, index) => (
            <>
              {containsId(selectedAttributeType[attributeType], attributeValue.id) && (
                <div className="mb-2">
                <div className='font-semibold flex justify-between items-center'>
                  <span>Select {attributeValue.uom !== null ? `${attributeValue.name} (${attributeValue.uom})` : attributeValue.name}</span>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAllSelected(attributeValue.id)}
                        onChange={(event) => handleSelectAllChange(attributeValue.id, attributeType, event)}
                        color="primary"
                      />
                    }
                    label={<b>Select All</b>}
                  />
                </div>
                  {convertStringToArray(attributeValue?.attributeOptions).map((optionValue, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={containsOptionId(selectedAttributeType[attributeType], attributeValue.id, optionValue)}
                          onChange={(event) => updateSelectedAttributeTypeOptions(attributeValue.id, attributeType, optionValue, event)}
                          value={optionValue}
                        />
                      }
                      label={optionValue}
                    />
                  ))}
                </div>
              )}
            </>
          ))}
        </div>
      </Box>
    )
  }

  const AttributeValuesComponent: React.FC<{ attributeType: string }> = ({ attributeType }) => {
    const valuesForAttributeType = attributeValues[attributeType];
    if (!valuesForAttributeType || valuesForAttributeType.length === 0)
      return null;

    return (
      <div className="mt-6">
        <h3 className={`text-xl ${classes.textColor} font-medium`}>
          {mapAttributeStatusEnumToString(attributeType)}
        </h3>
        <div className="flex flex-wrap gap-x-2">
          {(valuesForAttributeType).map((attributeValue) => (
            <div key={attributeValue.id} className="select">
              <FormControlLabel
                key={attributeValue.id}
                control={
                  <Checkbox
                    key={attributeValue.id}
                    checked={containsId(selectedAttributeType[attributeType], attributeValue.id)}
                    onChange={updateSelectedAttributeType(attributeValue.id, attributeType, attributeValue)}
                    value={attributeValue.id}
                  />
                }
                label={attributeValue.uom !== null ? `${attributeValue.name} (${attributeValue.uom})` : attributeValue.name}          
                 disabled={mode === "VIEW"}
    
                />
            </div>
          ))}
        </div>
        <AttributeOptionValuesComponent attributeType={attributeType} />
      </div>
    )
  }

  const AttributeValuesListComponent: React.FC = () => (
    <div className={`${mode !== MODE.ADD && ''} select`}>
      {Object.keys(selectedAttributeType).map((attributeType) => (
        <AttributeValuesComponent key={attributeType} attributeType={attributeType} />
      ))}
    </div>
  )

  const handleCheckboxChange = async (attributeType: string) => {
    if (selectedAttributeType[attributeType]) {
      setSelectedAttributeType((prevSelectedAttributes: Attribute) => {
        const updatedSelectedAttributes = { ...prevSelectedAttributes };
        delete updatedSelectedAttributes[attributeType];
        return updatedSelectedAttributes;
      });
    } else {
      await fetchAttributeValues(attributeType);
      setSelectedAttributeType((prevAttributeValues: Attribute) => ({
        ...prevAttributeValues,
        [attributeType]: [],
      }));
    }
  };

  return (
    <div>
      {SnackBarComponent}
      <Box>

        <div>
          <div className={`flex justify-between pb-4 ${classes.border} ${classes.textColor} select-none !text-2xl font-semibold`}>
            <h3>Select Attribute Type</h3>
          </div>
        </div>

        <div className="mt-6">
          <div className={`flex flex-wrap gap-x-5 ${mode !== MODE.ADD && 'pointer-events-none select-none'}`}>
            <div className="select">
              <FormControlLabel
                control={<Checkbox checked={!!selectedAttributeType['SPECIFICATION']}
                  onChange={() => handleCheckboxChange('SPECIFICATION')} />}
                label="Specification"
              />
            </div>
            {/* <div className="select">
              <FormControlLabel
                control={<Checkbox checked={!!selectedAttributeType['GENERALIZATION']}
                  onChange={() => handleCheckboxChange('GENERALIZATION')} />}
                label="Generalization"
              />
            </div> */}
          </div>
        </div>

        {Object.keys(selectedAttributeType).length > 0 ? (
          <>
              <div className={`flex justify-between pb-4 ${classes.border} ${classes.textColor} !text-2xl font-semibold select-none`}>
                <h3>Select Attribute</h3>
              </div>
            <AttributeValuesListComponent />
          </>
        ) : null}
        <div className="flex justify-end gap-4 mt-4">
          <Button variant="outlined" label='Back' onClick={setFormStateTo(PRODUCT_CATEGORY_FORM_STATE.PRODUCT_CATEGORY)} />
          <Button variant="contained" onClick={handleSubmit} label={"next"} />
        </div>
      </Box>
    </div>
  )
}

export default AttributeManagementFormTemplate
