import React from 'react';
import ButtonV2 from '../../atoms/ButtonV2';
import TableV2, { ColumnType } from '../../organisms/TableV2';
import { IVendorCatalogue } from '../../pages/CatalogueApproval/CatalogueApprovalList.page';
import ResourceStatusV2 from '../../atoms/ResourceStatus/ResourceStatusV2';
import { enumToString } from '../../../utils/helper';
import { IPagination } from '../../../utils/types';
import { useNavigate } from 'react-router-dom';

interface ICatalogueApprovalListProps {
	handlePaginationChange: (event: any, newPage: number) => void;
	handleRowsPerPageChange: (event: any) => void;
    catalogueToBeApproved: IVendorCatalogue[] | [];
    pagination: IPagination;
}

const CatalogueApprovalListTemplate: React.FC<ICatalogueApprovalListProps> = ({ pagination, handlePaginationChange, handleRowsPerPageChange, catalogueToBeApproved }) => {
    
    const navigate = useNavigate();

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: 'S No.', key: 'id', type: 'number' as ColumnType, props: { className: '' } },
            { label: 'Vendor Name', key: 'vendorName', type: 'string' as ColumnType, props: { className: '' } },
            { label: "Vendor Status", key: "vendorStatus", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
            { label: 'Category Linked', key: 'categoryLinkedCount', type: 'number' as ColumnType, props: { className: '' } },
            { label: 'Total Product Linked', key: 'totalProductLinkedCount', type: 'number' as ColumnType, props: { className: '' } },
            { label: 'Active Product Count', key: 'activeProductLinkedCount', type: 'number' as ColumnType, props: { className: '' } },
            { label: 'Pending Product Count', key: 'pendingProductLinkedCount', type: 'number' as ColumnType, props: { className: '' } },
            { label: 'Awaiting Product Count', key: 'awaitingProductLinkedCount', type: 'number' as ColumnType, props: { className: '' } },
            { label: 'Action', key: 'action', type: 'string' as ColumnType, props: { className: '' } },
        ],
    };

    const Action = (id: number) => {
        return (
            <div className={` flex gap-x-2 justify-center`}>
                <ButtonV2 variant="tertiaryText"
                    label="More Details"
                    size="small"
                    onClick={() => navigate(`/catalogue-approval/${id}`)}
                    />
            </div>
        );
    };

    const records = Array.isArray(catalogueToBeApproved)
        ? catalogueToBeApproved.map((stats: IVendorCatalogue, index: number) => [
            pagination.currentPage * pagination.pageSize + index + 1,
            stats.vendorName,
            enumToString(stats.vendorStatus),
            stats.categoryLinkedCount ? stats.categoryLinkedCount.split(',').length : 0,
            stats.totalProductLinkedCount,
            stats.activeProductLinkedCount,
            stats.pendingProductLinkedCount,
            stats.awaitingProductLinkedCount,
            Action(stats.vendorId)
        ])
        : [];

    return (
        <div className="mt-5">
            <TableV2 schema={schema} records={records} />
        </div>
    );
};

export default CatalogueApprovalListTemplate;
