import { request } from ".";
import { IProductDetails } from "../components/templates/CatalogueBundling/Bundle/BundleDetailForm.template";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const CATALOGUE_BUNDLING_URLS = {
    CREATE_BUNDLE: "/catalog-bundles/create",
    GET_BUNDLES: "/catalog-bundles",
    GET_BUNDLE: "/catalog-bundles/:id",
    UPDATE_BUNDLE: "/catalog-bundles/:id",

    CREATE_LOT: "/catalog-lot",
    GET_LOTS: "/catalog-lots",
    GET_LOT: "/catalog-lot/:id",
    UPDATE_LOT: "/catalog-lot/:id",
};

export interface IBundleSearchParams {
    page?: number;
    size?: number;
    sort?: string;
    search?: string;
    status?: string;
}

export interface IBundleRequestParams {
    catalogBundleIds?: number[] | null;
    productIds?: number[] | null;
}

export interface IBundleFormikPayload {
    name: string;
    bundleCode?: string;
    moq: number | null,
    price: number | null,
    startDate: string,
    expiryDate: string,
    productDetails: IProductDetails[] | [],
    status: string;
    packagingType: string;
    deliveryTerm: string;
    warehouseId: number | null;
    warehouse: string;
}

export interface IBundleRequestPayload {
    name: string;
    referenceDetails: IReferenceDetails[] | []
    type: string;
    moq: number | null,
    price: number | null,
    startDate: string,
    expiryDate: string,
    status: string;
    packagingType: string;
    deliveryTerm: string;
    warehouseId: number | null;
}

export interface IReferenceDetails {
    productId: number | null;
    classType: string;
    moq: number | null
}

export interface IBundles {
    id: number;
    name: string
    bundleCode: string;
    type: string;
    moq: number;
    price: number
    startDate: string;
    expiryDate: string;
    status: string;
    referenceDetails: any[] | null;
    packagingType: string;
    deliveryTerm: string;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    updatedBy: string;
}

export interface IBundle {
    id: number;
    name: string
    bundleCode: string;
    type: string;
    moq: number;
    price: number;
    startDate: string;
    expiryDate: string;
    referenceDetails: any[] | null;
    status: string;
    packagingType: string;
    deliveryTerm: string;
    warehouse: any;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    updatedBy: string;
}

export interface ILotSearchParams {
    page?: number;
    size?: number;
    sort?: string;
    name?: string;
    status?: string;
}

export interface ILotRequestParams {
    catalogueIds?: number[] | null;
    productCodes?: number[] | null;
}

export interface ILotFormikPayload {
    name: string;
    bundleCode?: string;
    moq: number | null,
    price: number | null,
    startDate: string,
    expiryDate: string,
    productDetails: IBundle[] | [],
    status: string;
}

export interface ILotRequestPayload {
    name: string;
    bundleCode?: string;
    moq: number | null,
    price: number | null,
    startDate: string,
    expiryDate: string,
    productDetails: IBundle[] | [],
    status: string;
}

export interface ILots {
    id: number;
    name: string
    uniqueCode: string;
    warehouse: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    updatedBy: string;
}

export const useCatalogueBundlingService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const createBundle = (data: IBundleRequestPayload) => {
        return request(API_METHOD.POST, CATALOGUE_BUNDLING_URLS.CREATE_BUNDLE, authenticatedUser, data);
    };

    const getBundles = (params: IBundleSearchParams, data: IBundleRequestParams) => {
        return request(API_METHOD.POST, CATALOGUE_BUNDLING_URLS.GET_BUNDLES, authenticatedUser, data, { params });
    };

    const getBundleById = (id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_BUNDLING_URLS.GET_BUNDLE, { id }), authenticatedUser);
    };

    const updateBundle = (id: number, data: IBundleRequestPayload) => {
        return request(API_METHOD.PUT, replaceUrlParams(CATALOGUE_BUNDLING_URLS.UPDATE_BUNDLE, { id }), authenticatedUser, data);
    };

    const createLot = (data: ILotRequestPayload) => {
        return request(API_METHOD.POST, CATALOGUE_BUNDLING_URLS.CREATE_LOT, authenticatedUser, data);
    };

    const getLots = (params: ILotSearchParams, data: ILotRequestParams) => {
        return request(API_METHOD.POST, CATALOGUE_BUNDLING_URLS.GET_LOTS, authenticatedUser, data, { params });
    };

    const getLotById = (id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_BUNDLING_URLS.GET_LOT, { id }), authenticatedUser);
    };

    const updateLot = (id: number, data: ILotRequestPayload) => {
        return request(API_METHOD.PUT, replaceUrlParams(CATALOGUE_BUNDLING_URLS.UPDATE_LOT, { id }), authenticatedUser, data);
    };

    return {
        createBundle,
        getBundles,
        getBundleById,
        updateBundle,

        createLot,
        getLots,
        getLotById,
        updateLot
    };
};
