import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import closeRoundIcon from '../../../assets/images/closeRoundIcon.svg';
import { createUseStyles } from 'react-jss';
import VendorViewHistoryTemplate from './VendorViewHistory.template';
import { useCatalogueApprovalService } from '../../../services/useCatalogueApprovalService';
import { useSnackbar } from '../../../hooks/useSnackBar';


interface IViewHistoryDialogTemplateProps {
    viewHistoryDialogOpen: boolean;
    setViewHistoryDialogOpen: (open: boolean) => void;
    catalogueId?: number;
    vendorId?: number;
}

export interface IApprovalHistory {
    id: number;
    status: string;
    vendorComment: string;
    adminComment: string;
    createdAt: string;
    updatedAt: string;
  }

const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "819px !important",
            maxWidth: "1297px !important",
            height: "850px",
            borderRadius: "16px",
            padding: "24px"
        }
    },
    closeRoundIcon: {},
    heading: {
        color: "#142252"
    },
    border: {
        borderBottom: `1px solid #D6DDF5 !important`,
    },

}));

const ViewHistoryDialogTemplate: React.FC<IViewHistoryDialogTemplateProps> = ({ viewHistoryDialogOpen, setViewHistoryDialogOpen, catalogueId, vendorId }) => {
    const classes = useStyles();
    const [approvalHistoryData, setApprovalHistoryDataTo] = useState<IApprovalHistory[]>([]);
    const catalogueApprovalService = useCatalogueApprovalService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();


    const getApprovalHistory = () => {
        let queryParams = {
            vendorId: vendorId,
            catalogueId: catalogueId ? BigInt(catalogueId) : 0,
            resourceType: "CATALOGUE"
        };
        catalogueApprovalService.getApprovalHistory(queryParams)
            .then((response) => {
                if (response.data.data) {
                    setApprovalHistoryDataTo(response.data.data);
                }
            })
            .catch((error) => {
                showSnackbar("error", error)
            });
    };

    const handleDialogClose = () => {
        setViewHistoryDialogOpen(false);
    };

    useEffect(() => {
        getApprovalHistory();
    }, [catalogueId])

    return (
        <Dialog fullWidth className={classes.container}
            open={viewHistoryDialogOpen} onClose={handleDialogClose}>
            <div style={{ position: 'relative', display: 'inline-block' }}>
                <div
                    className="cursor-pointer"
                    onClick={handleDialogClose}
                    style={{ position: 'absolute', right: '0px' }}
                >
                    <img src={closeRoundIcon} alt="closeIcon" />
                </div>
                <div className={`${classes.heading} text-3xl font-semibold`}>View History</div>
            </div>
            <div className={`${classes.border} my-8`}></div>

            {approvalHistoryData?.map((historyItem: IApprovalHistory) => (
                <VendorViewHistoryTemplate key={historyItem.id} historyItem={historyItem} />
            ))}

        </Dialog>
    );
};

export default ViewHistoryDialogTemplate;
