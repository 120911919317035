import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import phoneIcon from '../../../assets/icons/phoneIcon.svg';
import messageIcon from '../../../assets/icons/messageIcon.svg';
import gstinIcon from '../../../assets/icons/gstinIcon.svg';
import webIcon from '../../../assets/icons/webIcon.svg';
import locationIcon from '../../../assets/icons/locationIcon.svg';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useCatalogueApprovalService } from '../../../services/useCatalogueApprovalService';
import { HTTP_STATUS } from '../../../utils/types';
import { IBusinessProfile } from '../../pages/CustomerManagement/ProfileOverview.page';
import { useVendorServiceV2 } from '../../../services/useVendorServiceV2';
import { Enum, enumToString, getProfileDisplayData } from '../../../utils/helper';

const useStyles = createUseStyles((theme: any) => ({
    body: {
        backgroundColor: "#d6ddf54d",
    },
    border: {
        borderBottom: `1px solid #D6DDF5 !important`,
    },
    heading: {
        color: "#636669",
        fontSize: "14px"
    },
    value: {
        color: "#4A4D4F"
    },
    name: {
        color: "#0A1129"
    },
    subHeading: {
        color: "#313335"
    },
    statusActive: {
        color: theme.palette.textV2.success.success500
    },
    statusInactive: {
        color: theme.palette.textV2.failure.failure500
    },
    statusActiveContainer: {
        borderRadius: "8px",
        backgroundColor: theme.palette.textV2.success.success50
    },
    statusInactiveContainer: {
        borderRadius: "8px",
        backgroundColor: theme.palette.textV2.failure.failure50
    },
    activeProgressBar: {
        backgroundColor: theme.palette.textV2.success.success500
    },
    inActiveProgressBar: {
        backgroundColor: theme.palette.textV2.failure.failure500
    },
    statusActiveDot: {
        borderRadius: "100px",
        backgroundColor: theme.palette.textV2.success.success500
    },
    statusInactiveDot: {
        borderRadius: "100px",
        backgroundColor: theme.palette.textV2.failure.failure500
    },
}));

interface IVendorDetailsTemplateProps {
    id?: number;
}

interface IAddress {
    id: number;
    type: string | null;
    email: string | null;
    city: string;
    state: string;
    country: string;
    pincode: string;
    line1: string;
    line2: string;
    mobileNumber: string | null;
}

interface IBusiness {
    id: number;
    name: string;
    gstin: string;
    userId: number;
    websiteUrl: string | null;
    whatsappNo: string | null;
}

interface IData {
    address: IAddress;
    business: IBusiness;
}


const VendorDetailsTemplate: React.FC<IVendorDetailsTemplateProps> = ({ id }) => {

    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const catalogueApprovalService = useCatalogueApprovalService();

    const [data, setData] = useState<IData>();

    const vendorService = useVendorServiceV2();

    const [businessProfile, setBusinessProfileTo] = useState<IBusinessProfile | null>(null);

    const getBusinessProfile = async () => {
        if (id) {
            try {
                const businessProfileResponse = await vendorService.getVendor(id);
                if (businessProfileResponse.status === HTTP_STATUS.OK) {
                    const profileData = businessProfileResponse?.data?.data;
                    setBusinessProfileTo(profileData);
                }
            } catch (error) {
                showSnackbar('error', 'Business Profile fetch failed');
            }
        }
    };


    useEffect(() => {
        getBusinessProfile();
    }, [id]);

    return (
        <div className={`${classes.body} p-6 rounded-xl`}>
            <div className='flex'>
                <div>
                    <div className={classes.heading}>Vendor Name</div>
                    <div className={`${classes.name} font-semibold text-2xl`}>{businessProfile?.name}</div>
                </div>
                {/* <div className='flex items-center ml-auto'>
                    <div className={`${businessProfile?.status === Enum.INACTIVE ? classes.statusInactiveContainer : classes.statusActiveContainer} ml-auto`}>
                        <div className='flex items-center'>
                            <div className={`${businessProfile?.status === Enum.INACTIVE ? classes.statusInactiveDot : classes.statusActiveDot} w-2 h-2 ml-4`} />
                            <div className={`${businessProfile?.status === Enum.INACTIVE ? classes.statusInactive : classes.statusActive} text-sm mr-4 ml-2 my-2`}>
                                {enumToString(businessProfile?.status ?? '')}
                            </div>
                        </div>

                    </div>
                </div> */}
            </div>
            <div className={`${classes.border} my-6`}></div>
            <div className='flex gap-x-32'>
                <div className='flex flex-col gap-y-3'>
                    <div className='flex items-center'>
                        <div className='mr-3'>
                            <img
                                src={phoneIcon}
                                alt="phoneIcon"
                            />
                        </div>
                        <div>
                            <div className={classes.heading}>Phone Number</div>
                            <div className={`${classes.value} font-medium text-base`}>{businessProfile?.user?.mobileNumber}</div>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <div className='mr-3'>
                            <img
                                src={messageIcon}
                                alt="messageIcon"
                            />
                        </div>
                        <div>
                            <div className={classes.heading}>Email</div>
                            <div className={`${classes.value} font-medium text-base`}>{businessProfile?.user?.email}</div>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <div className='mr-3'>
                            <img
                                src={gstinIcon}
                                alt="gstinIcon"
                            />
                        </div>
                        <div>
                            <div className={classes.heading}>GSTIN</div>
                            <div className={`${classes.value} font-medium text-base`}>{businessProfile?.gstin}</div>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <div className='mr-3'>
                            <img
                                src={webIcon}
                                alt="webIcon"
                            />
                        </div>
                        <div>
                            <div className={classes.heading}>Website</div>
                            <div className={`${classes.value} font-medium text-base`}>{getProfileDisplayData(businessProfile?.websiteUrl)}</div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <div className='flex items-center'>
                        <div className='mr-3'>
                            <img
                                src={locationIcon}
                                alt="locationIcon"
                            />
                        </div>
                        <div className={`${classes.value}  text-base`}>
                            Business Location
                        </div>
                    </div>
                    <div>
                        <div className={classes.heading}>Address Line 1</div>
                        <div className={`${classes.value} font-medium text-base`}>{getProfileDisplayData(businessProfile?.address?.line1 || "")}</div>
                    </div>
                    <div>
                        <div className={classes.heading}>Address Line 2</div>
                        <div className={`${classes.value} font-medium text-base`}>{getProfileDisplayData(businessProfile?.address?.line2 || "")}</div>
                    </div>
                    <div className='flex gap-x-16'>
                        <div>
                            <div className={classes.heading}>Pincode</div>
                            <div className={`${classes.value} font-medium text-base`}>{getProfileDisplayData(businessProfile?.address?.pincode || "")}</div>
                        </div>
                        <div>
                            <div className={classes.heading}>City</div>
                            <div className={`${classes.value} font-medium text-base`}>{getProfileDisplayData(businessProfile?.address?.city || "")}</div>
                        </div>
                        <div>
                            <div className={classes.heading}>State</div>
                            <div className={`${classes.value} font-medium text-base`}>{getProfileDisplayData(businessProfile?.address?.state || "")}</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default VendorDetailsTemplate;
