import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { EXCEPTION_CODE, MODE, STATUS } from '../../../../utils/constant';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../../utils/types';
import { ILotFormikPayload, useCatalogueBundlingService } from '../../../../services/useCatalogueBundlingService';
import { CATALOGUE_BUNDLING_ROUTES } from '../../../../App/Core/Routes/CatalogueBundlingRoutes';
import BundleLotViewTemplate from '../../../templates/CatalogueBundling/BundleLot/BundleLotView.template';
import { makeRoute } from '../../../../utils/helper';
import { validationSchema } from './BundleLotCreate.page';

const BundleLotViewPage: React.FC = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const catalogueBundlingService = useCatalogueBundlingService();
    const [bundleLot, setBundleLot] = useState<any | null>(null)
    const navigate = useNavigate();
    const params = useParams();

    const loadBundleLot = async () => {
        catalogueBundlingService.getBundleById(Number(params.id))
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    setBundleLot(res.data.data)
                }
            }).catch((error) => {
                setBundleLot(null);
                console.error("Error Fetching Catalogue Bundle Lot: ", error);
                showSnackbar('error', "Error while fetching Catalogue Bundle Lot data");
            })
    }

    useEffect(() => {
        loadBundleLot()
    }, [Number(params.id)])

    const onBack = () => {
        navigate(makeRoute(CATALOGUE_BUNDLING_ROUTES.CATALOGUE_BUNDLING_LIST, { query: { type: 'CATALOGUE_BUNDLE_LOT' } }));
    }

    const updateBundleLot = async (requestBody: ILotFormikPayload) => {
        const bundleLotRequestBody: any = {

        }
        catalogueBundlingService.updateLot(Number(params.id), bundleLotRequestBody)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "Status Updated");
                    onBack();
                } else if (res.data.exceptionCode === EXCEPTION_CODE.DUPLICATE_ENTRY) {
                    showSnackbar('error', "Catalogue Bundle Lot already exists. Please try again");
                }
            }).catch((error) => {
                showSnackbar('error', "Failed to update Catalogue Bundle Lot");
            })
    }

    const formik = useFormik<ILotFormikPayload>({
        initialValues: {
            name: bundleLot?.name ?? "",
            moq:  bundleLot?.moq ?? null,
            price:  bundleLot?.price ?? null,
            startDate:  bundleLot?.startDate ?? "",
            expiryDate:  bundleLot?.expiryDate ?? "",
            productDetails:  bundleLot?.productDetails ?? [],
            status: bundleLot?.status ?? STATUS.ACTIVE,
        },
        validationSchema,
        onSubmit: async (values) => {
            updateBundleLot({ ...values, status: values.status })
        }
    })

    useEffect(() => {
        formik.setValues({
            name: bundleLot?.name ?? "",
            moq:  bundleLot?.moq ?? null,
            price:  bundleLot?.price ?? null,
            startDate:  bundleLot?.startDate ?? "",
            expiryDate:  bundleLot?.expiryDate ?? "",
            productDetails:  bundleLot?.productDetails ?? [],
            status: bundleLot?.status ?? STATUS.ACTIVE,
        });
    }, [bundleLot]);

    return (
        <div>
            {SnackBarComponent}
            <BundleLotViewTemplate mode={MODE.VIEW} onBack={onBack} formik={formik} />
        </div>
    )
}

export default BundleLotViewPage