import React from "react";
import { createUseStyles } from "react-jss";
import { DELIVERY_INFORMATION } from "../../../../utils/constant";

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.customerPalette.text.primary.primary900,
    },
    subHeading: {
        color: theme.customerPalette.text.neutral.neutral700,
    },
    body: {
        border: `1px solid ${theme.customerPalette.text.neutral.neutral50}`,
        borderRadius: '12px'
    },
}));


const ProductWarehouseDetailsTemplate: React.FC<any> = ({ warehouseId, formik, index }) => {

    const classes = useStyles();

    return (
        <div>
            <div className={`${classes.heading} mb-4 font-medium text-base`}>Delivery and Order Details</div>
            <div className="flex gap-x-6">
                <div className={`${classes.body} p-6`}>
                    <div className={`${classes.subHeading} text-sm`}>Delivery Information</div>
                    <div className={`${classes.subHeading} font-medium text-base `}>
                        {DELIVERY_INFORMATION.find(info => info.value === formik?.values?.warehouseWiseProductDetails?.[index]?.deliveryTimeFrame)?.label || ""}
                    </div>
                </div> <div className={`${classes.body} p-6`}>
                    <div className={`${classes.subHeading} text-sm`}>Minimum Order Quantity</div>
                    <div className={`${classes.subHeading} font-medium text-base `}>{(formik?.values?.warehouseWiseProductDetails?.[index]?.minimumOrderQuanity)}</div>
                </div>
            </div>
        </div>
    );
};

export default ProductWarehouseDetailsTemplate;