import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../../hooks/useSnackBar";
import { HTTP_STATUS } from "../../../../utils/types";
import { VENDOR_PRODUCT_ROUTES } from "../../../../utils/constant";
import { IVendorProductRequestBodyDTO, usePostProductService } from "../../../../services/usePostProductService";
import ExistingProductUpdateTemplate from "../../../templates/MySteelBazaar/ExistingProduct/ExistingProductUpdate.template";

const PostProductUpdatePage = () => {

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();

    const postProductService = usePostProductService();

    const redirectToVendorProductList = () => {
        navigate(VENDOR_PRODUCT_ROUTES.POST_PRODUCT)
    }

    const updateVendorProductById = async (productId: number, vendorProductRequestBody: IVendorProductRequestBodyDTO) => {
        if (productId) {
            try {
                const vendorProductResponse = await postProductService.upsertVendorProduct(productId, vendorProductRequestBody);
                if (vendorProductResponse.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "Product updated successfully.")
                    redirectToVendorProductList();
                }
                else if (vendorProductResponse.status === HTTP_STATUS.BAD_REQUEST) {
                    showSnackbar('error', 'Product update failed.')
                }
            } catch (error) {
                showSnackbar('error', 'Product update failed.')
            }
        }
    }

    return (
        <div>
            <ExistingProductUpdateTemplate onVendorProductSubmit={updateVendorProductById} redirectToVendorProductList={redirectToVendorProductList} />
        </div>
    )
}

export default PostProductUpdatePage;
