import React, { useEffect, useMemo, useState } from 'react'
import viewEyeIcon from "../../../../assets/images/viewOutlined.svg";
import { sortOptions, statusOptions } from '../../../../utils/constant';
import { enumToString, makeRoute, toInr } from '../../../../utils/helper';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { HTTP_STATUS, IPagination, VAS_MASTER_CATEGORY_TYPE, option } from '../../../../utils/types';
import { useNavigate } from 'react-router-dom';
import { ColumnType } from '../../../organisms/Table';
import moment from 'moment';
import TableV2 from '../../../organisms/TableV2';
import ButtonV2 from '../../../atoms/ButtonV2';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import FilterChipV2 from '../../../molecules/FilterChip/FilterChipV2';
import { IMultiSelectOption } from '../../../atoms/MultiSelectInput2';
import FilterChipV3 from '../../../molecules/FilterChip/FilterChipV3';
import ResourceStatusV2 from '../../../atoms/ResourceStatus/ResourceStatusV2';
import { IBundles, useCatalogueBundlingService } from '../../../../services/useCatalogueBundlingService';
import { CATALOGUE_BUNDLING_ROUTES } from '../../../../App/Core/Routes/CatalogueBundlingRoutes';

interface BundleListTemplateProps {
    bundles: IBundles[];
    pagination: IPagination;
    handlePaginationChange: any;
    handleRowsPerPageChange: any;
    handleFiltersChange: (name: string, value: any) => void;
    filters: any;
    bundleRequestFilters: any;
    setBundleRequestFiltersTo: any;
}

const BundleListTemplate: React.FC<BundleListTemplateProps> = ({ bundles, pagination, handlePaginationChange, handleRowsPerPageChange, handleFiltersChange, filters, bundleRequestFilters, setBundleRequestFiltersTo }) => {

    const navigate = useNavigate();

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination?.totalRecords,
            currentPage: pagination?.currentPage,
            isVisible: true,
            limit: pagination?.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Name", key: "name", type: "string" as ColumnType, props: { className: '' } },
            { label: "Bundle Code", key: "bundleCode", type: "string" as ColumnType, props: { className: '' } },
            { label: "Price", key: "price", type: "string" as ColumnType, props: { className: '' } },
            { label: "Created By", key: "createdBy", type: "string" as ColumnType, props: { className: '' } },
            { label: "Created On", key: "createdAt", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ],
    };

    const handleActionRoute = (id: number) => () => {
        navigate(makeRoute(CATALOGUE_BUNDLING_ROUTES.CATALOGUE_BUNDLE_VIEW, { params: { id: id } }));
    }

    const Action = (id: number) => {
        return (
            <div>
                <ButtonV2 variant='primaryText' size='small' label={"View"} startIcon={<img src={viewEyeIcon} alt={viewEyeIcon} />} onClick={handleActionRoute(id)} />
            </div>
        );
    };

    const records = bundles?.map((bundle: IBundles, index: number) => [
        pagination.currentPage * pagination.pageSize + index + 1,
        bundle?.name,
        bundle?.bundleCode,
        toInr(bundle?.price),
        bundle.createdBy,
        moment(bundle.createdAt).format("DD-MM-YYYY HH:mm:ss"),
        enumToString(bundle?.status),
        Action(bundle?.id),
    ]);

    const catalogueBundlingService = useCatalogueBundlingService();
    const [bundleData, setBundleData] = useState<IBundles[]>([])
    // const [productData, setProductData] = useState<any[] | null>(null);

    const initialSelections = {
        status: [{ label: 'ALL', value: 'ALL' }],
        sortDate: [{ label: 'createdAt,desc', value: 'createdAt,desc' }]
    };

    const initialBundleRequestFilters: any = {
        catalogBundleIds: [],
        productIds: [],
    };

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedfilters, setSelectedFilters] = useState<any>(initialBundleRequestFilters);

    const [status, setStatus] = useState<IMultiSelectOption[]>(initialSelections.status);
    const [sortDate, setSortDate] = useState<IMultiSelectOption[]>(initialSelections.sortDate);

    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const refreshCatalogueBundle = (inputValue?: string) => {
        catalogueBundlingService.getBundles({ page: 0, size: 10, sort: 'createdAt,desc', search: inputValue ?? '' },{})
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                setBundleData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Service: ", error);
                showSnackbar('error', "Error while fetching Super Service data");
            })
    }

    const fetchProduct = (inputValue?: string) => {
        // vasMasterCategoryService.getAllService({ level: VAS_MASTER_CATEGORY_TYPE.MAIN_SERVICE, page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', status: 'ACTIVE' },{})
        //     .then(res => {
        //         if (res.status === HTTP_STATUS.OK)
        //             setProductData(res?.data?.data?.content)
        //     }).catch((error) => {
        //         console.error("Error Fetching Service: ", error);
        //         showSnackbar('error', "Error while fetching Product data");
        //     })
    }

    useEffect(() => {
        refreshCatalogueBundle(searchTerm);
    }, [searchTerm]);

    // useEffect(() => {
    //     fetchProduct(searchTerm);
    // }, [searchTerm]);

    const bundleOptions = useMemo(() => {
        return bundleData?.map((bundle: IBundles) => ({ label: bundle.name, value: bundle.id })) ?? []
    }, [bundleData])

    // const productOptions = useMemo(() => {
    //     return productData?.map((product: Service) => ({ label: product.name, value: product.id })) ?? []
    // }, [productData])

    const handleSelectionChangeStatus = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setStatus(selected);
        clearWithCrossIcon && handleStatusSaveClick(selected);
    };

    const handleSelectionChangeSort = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSortDate(selected);
        clearWithCrossIcon && handleSortSaveClick(selected);
    };

    const handleSelectionChange = (name: string, selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setSelectedFilters((previousSelectedFilters: any) => ({
            ...previousSelectedFilters,
            [name]: selected
        }));
        clearWithCrossIcon && handleDeleteClick(name, selected);
    };

    const handleStatusSaveClick = (status: IMultiSelectOption[]) => {
        const valueString = status.length > 0 ? status[0].value : 'ALL';
        handleFiltersChange("status", valueString);
    };

    const handleSortSaveClick = (sortDate: IMultiSelectOption[]) => {
        const sortString = sortDate.length > 0 ? sortDate[0].value : 'createdAt,desc';
        handleFiltersChange("sort", sortString);
    };

    const handleSaveClick = (name: string, selectedValues: IMultiSelectOption[]) => {
        const updatedFilters = { ...bundleRequestFilters };
        const newValues = selectedValues.map(option => option.value);

        if (updatedFilters[name]) {
            updatedFilters[name] = Array.from(new Set([...updatedFilters[name], ...newValues]));
        } else {
            updatedFilters[name] = newValues;
        }
        setBundleRequestFiltersTo(updatedFilters);
    };

    const handleDeleteClick = (name: string, selectedValues: IMultiSelectOption[]) => {
        const selectedValueIds = selectedValues.map(option => option.value);
        const updatedFilters = {
            ...bundleRequestFilters,
            [name]: bundleRequestFilters[name].filter((id: number) => selectedValueIds.includes(id))
        };
        setBundleRequestFiltersTo(updatedFilters);
    };

    const handleClearClick = (filterType: string) => {
        switch (filterType) {
            case 'status':
                setStatus([{ label: 'ALL', value: 'ALL' }]);
                handleFiltersChange("status", 'ALL');
                break;
            case 'sortDate':
                setSortDate([{ label: 'createdAt,desc', value: 'createdAt,desc' }]);
                handleFiltersChange("sort", 'createdAt,desc');
                break;
            case 'catalogBundleIds':
                setSelectedFilters((previousSelectedFilters: any) => ({
                    ...previousSelectedFilters,
                    catalogBundleIds: []
                }));
                setBundleRequestFiltersTo((prevState: any) => ({
                    ...prevState,
                    catalogBundleIds: []
                }));
                break;
            // case 'productIds':
            //     setSelectedFilters((previousSelectedFilters: any) => ({
            //         ...previousSelectedFilters,
            //         productIds: []
            //     }));
            //     setBundleRequestFiltersTo((prevState: any) => ({
            //         ...prevState,
            //         productIds: []
            //     }));

            //     break;
            default:
                setStatus([{ label: 'ALL', value: 'ALL' }]);
                setSortDate([{ label: 'createdAt,desc', value: 'createdAt,desc' }]);
                setSelectedFilters(initialBundleRequestFilters);
        }
    };

    return (
        <div className='grid gap-y-6'>
            <div className='flex justify-between'>
                <div className='flex flex-wrap gap-x-3 my-auto'>
                    <FilterChipV2
                        options={statusOptions}
                        label=" "
                        value={status}
                        onchange={handleSelectionChangeStatus}
                        placeholder="Status"
                        ClearButtonComponent={ButtonV2}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('status')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleStatusSaveClick(status)}
                        isSingleSelect={true}
                    />
                    <FilterChipV2
                        options={sortOptions}
                        label=" "
                        value={sortDate}
                        onchange={handleSelectionChangeSort}
                        placeholder="Sort Date"
                        ClearButtonComponent={ButtonV2}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('sortDate')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleSortSaveClick(sortDate)}
                        isSingleSelect={true}
                    />
                    <FilterChipV3
                        options={bundleOptions}
                        label=" "
                        value={selectedfilters?.catalogBundleIds}
                        onchange={handleSelectionChange}
                        name="catalogBundleIds"
                        placeholder="Catalogue Bundle"
                        ClearButtonComponent={ButtonV2}
                        textFieldPlaceholder='Search...'
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('catalogBundleIds')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleSaveClick('catalogBundleIds', selectedfilters?.catalogBundleIds)}
                    />
                    {/* <FilterChipV3
                        options={mainServiceOptions}
                        label=" "
                        value={selectedfilters?.productIds}
                        onchange={handleSelectionChange}
                        name="productIds"
                        placeholder="Product Code"
                        ClearButtonComponent={ButtonV2}
                        textFieldPlaceholder='Search...'
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        buttonLabel={"Clear all"}
                        buttonOnClick={() => handleClearClick('productIds')}
                        SaveButton={ButtonV2}
                        saveButtonLabel='Apply'
                        saveButtonOnClick={() => handleSaveClick('productIds', selectedfilters?.productIds)}
                    /> */}
                </div>

                <div className='w-[324px]'>
                    <TextFieldV2
                        className="w-full"
                        placeholder='Search'
                        type="text"
                        name="name"
                        value={filters.search}
                        onChange={(e) => handleFiltersChange('search', e.target.value)}
                    />
                </div>
            </div>
            <TableV2 schema={schema} records={records} />
        </div>
    )
}

export default BundleListTemplate;