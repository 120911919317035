import { useEffect, useState } from 'react'
import { useStandardService } from '../../../services/useStandardService';
import { HTTP_STATUS, IPagination } from '../../../utils/types';
import { ADMIN_ROUTES, initialPaginationValues } from '../../../utils/constant';
import StandardListTemplate from '../../templates/Standard/StandardList.template';
import Button from '../../atoms/Button';
import { useNavigate } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import TitleHeading from '../../atoms/CustomHeading/TitleHeading';
import { useSnackbar } from '../../../hooks/useSnackBar';

export interface IStandardFilter {
    status: string;
    search: string;
    sort: string;
}

export interface IStandard {
    id: number;
    name: string;
    prefix: string;
    yearInUse: string;
    yearLastUpdated: string;
    hsnCodes: string;
    bisHeading: string;
    equivalentTags: string;
    status: string;
    createdBy: string;
    updatedBy: string;
    application: string;
    features: string;
    description: string;
    createdAt: string;
    updatedAt: string;
}

const initialStandardFiltersValues: IStandardFilter = {
    search: "",
    sort: "createdAt,desc",
    status: "ALL",
}

const useStyles = createUseStyles((theme: any) => ({
    inActiveTab: {
        color: theme.palette.text.primaryLight,
    },
    activeTab: {
        color: theme.palette.button.primaryDark,
        borderBottom: `1px solid ${theme.palette.button.primaryDark}`,
    },
    title: {
        color: theme.palette.button.primaryDark,
    },
}));

const StandardListPage: React.FC = () => {

    const navigate = useNavigate();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const standardService = useStandardService();
    const [standards, setStandardsTo] = useState<IStandard[]>([]);
    const [standardFilters, setStandardFiltersTo] = useState<IStandardFilter>(initialStandardFiltersValues);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);

    const getAllStandards = (page: number, size: number, sort: string) => {
        const params = {
            search: standardFilters?.search.trim(),
            status: standardFilters?.status === "ALL" ? "" : standardFilters?.status,
            page: page,
            size: size,
            sort: sort,
        }
        standardService.getAllStandards(params)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    const { totalElements, totalPages } = res?.data?.data;
                    setPaginationTo({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setStandardsTo(res?.data?.data?.content)
                }
            }).catch(error => {
                showSnackbar('error', error);
                setStandardsTo([]);
            })
    }

    useEffect(() => {
        getAllStandards(pagination.currentPage, pagination.pageSize, standardFilters.sort);
    }, [pagination.currentPage, pagination.pageSize, standardFilters])

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    const handleCreateStandard = () => {
        navigate(ADMIN_ROUTES.STANDARD_CREATE);
    };

    const handleFiltersChange = (event: any) => {
        const { name, value } = event.target;
        setPaginationTo({ ...pagination, currentPage: 0 })
        setStandardFiltersTo({ ...standardFilters, [name]: value ?? "" });
    }

    return (
        <>
            {SnackBarComponent}
            <div className='grid gap-y-3'>
                <div className='flex justify-between'>
                    <TitleHeading text="Standard" />
                    <Button variant="contained" label={"Add New Standard"} onClick={handleCreateStandard} />
                </div>
                <StandardListTemplate standards={standards} pagination={pagination} handleFiltersChange={handleFiltersChange} handlePaginationChange={handlePaginationChange} standardFilter={standardFilters} handleRowsPerPageChange={handleRowsPerPageChange} />
            </div>
        </>
    )
}

export default StandardListPage;