import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const ATTRIBUTE_URLS = {
    CREATE_ATTRIBUTE: "/attribute",
    GET_ALL_ATTRIBUTE: "/attributes",
    GET_ATTRIBUTE: "/attribute/:id",
    UPDATE_ATTRIBUTE: "/attribute/:id",
};
export interface IAttributeFilterRequest {
    status?: string | null;
    parentId?: number;
    attributeType?: string;
    name?: string;
    page?: number;
    size?: number;
    sort?: string;
}

export interface IAttributes {
    id: number;
    name: string;
    attributeType: string;
    description: string;
    status: string;
    imageId: number | null;
    createdBy: number;
    updatedBy: number;
    uom: string;
    fieldType: string;
    isUpc: boolean;
    isMpc: boolean;
    attributeOptions: string[];
    parentId: number | null;
    prefix: string[];
    attributeTypeName: string | null;
    attributeRank: number;
    createdAt: string;
    updatedAt: string;
}

export interface IAttributePayload {
    name: string;
    attributeType: string;
    fieldType: string;
    attributeOptions: string[];
    isUpc: boolean;
    isMpc: boolean;
    description: string;
    uom: string;
    status: string;
    parentId: string | null;
    attributeRank: number;
    uomValue?: any;
    uomCheck?: boolean
  }

export const useAttributeService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const createAttribute = (data: IAttributePayload) => {
        return request(API_METHOD.POST, ATTRIBUTE_URLS.CREATE_ATTRIBUTE, authenticatedUser, data);
    };

    const getAllAttributes = (params: IAttributeFilterRequest) => {
        return request(API_METHOD.GET, ATTRIBUTE_URLS.GET_ALL_ATTRIBUTE, authenticatedUser, null, { params });
    };

    const getAttributeById = (id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(ATTRIBUTE_URLS.GET_ATTRIBUTE, { id }), authenticatedUser);
    };

    const updateAttribute = (id: number, data: IAttributePayload) => {
        return request(API_METHOD.PUT, replaceUrlParams(ATTRIBUTE_URLS.UPDATE_ATTRIBUTE, { id }), authenticatedUser, data);
    };

    return {
        createAttribute,
        getAllAttributes,
        getAttributeById,
        updateAttribute,
    };
};
