import React, { useEffect, useState } from 'react';
import StockPriceOverviewDialogTemplate from './StockPriceOverviewDialog.template';
import ViewHistoryDialogTemplate from './ViewHistoryDialog.template';
import { useCatalogueApprovalService } from '../../../services/useCatalogueApprovalService';
import { APPROVAL_STATUS, initialPaginationValues } from '../../../utils/constant';
import { HTTP_STATUS, IPagination } from '../../../utils/types';
import { useSnackbar } from '../../../hooks/useSnackBar';
import TableV2, { ColumnType } from '../../organisms/TableV2';
import ButtonV2 from '../../atoms/ButtonV2';
import moment from 'moment';
import SelectV2 from '../../atoms/SelectV2';
import Select from '../../atoms/Select';
import SuccessFailurePopup from '../../molecules/SuccessFailurePopup/SuccessFailurePopup';
import SuccessFailurePopupV2 from '../../molecules/SuccessFailurePopupV2/SuccessFailurePopupV2';
import { Enum, enumToString } from '../../../utils/helper';
import ViewCatalogueDetailsDialogTemplate from './ViewCatalogueDetailsDialog.template';

interface ICatalogueApprovalProductListingTemplateProps {
    type: string;
    id?: number;
}

interface ActionProps {
    id: number;
    currentStatus: string;
}

interface ICatalogueDetails {
    catalogueCode: number;
    vendorProductId: number;
    catalogueName: string;
    approvalId: number;
    status: string;
    requestDate: string;
    approvalDate: string;
}

const CatalogueApprovalProductListingTemplate: React.FC<ICatalogueApprovalProductListingTemplateProps> = ({ type, id }) => {

    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const catalogueApprovalService = useCatalogueApprovalService();

    const [stockPriceDialogOpen, setstockPriceDialogOpen] = useState<boolean>(false);
    const [viewHistoryDialogOpen, setViewHistoryDialogOpen] = useState<boolean>(false);
    const [viewCatalogueDialogOpen, setViewCatalogueDialogOpen] = useState<boolean>(false);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [catalogueDetails, setCatalogueDetailsTo] = useState<ICatalogueDetails>();
    const [catalogueId, setCatalogueId] = useState<number>();
    const [productId, setProductId] = useState<number>();


    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage,
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationTo((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage,
        }));
    };

    const getVendorCatalogues = (page: number, size: number) => {
        let queryParams = {
            page: page,
            size: size,
            status: type,
            vendorId: id,
            sort:'createdAt,desc'
        };
        catalogueApprovalService.getVendorCatalogues(queryParams)
            .then((response) => {
                if (response.data.data.content) {
                    setCatalogueDetailsTo(response.data.data.content);
                    const { totalElements, totalPages, content } = response.data.data;
                    setPaginationTo(prevPagination => ({
                        ...prevPagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    }));
                }
            })
            .catch((error) => {
                showSnackbar("error", error)
            });
    };

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.currentPage,
            isVisible: true,
            limit: pagination.pageSize,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: 'S No.', key: 'id', type: 'number' as ColumnType, props: { className: '' } },
            { label: 'Catalogue Code', key: 'catalogueCode', type: 'number' as ColumnType, props: { className: '' } },
            { label: 'Catalogue Title', key: 'catalogueName', type: 'string' as ColumnType, props: { className: '' } },
            { label: 'Stock & Price Overview', key: 'action', type: 'number' as ColumnType, props: { className: '' } },
            { label: 'Request Date', key: 'requestDate', type: 'string' as ColumnType, props: { className: '' } },
            { label: 'Approval Date', key: 'approvalDate', type: 'string' as ColumnType, props: { className: '' } },
            { label: 'History', key: 'action', type: 'number' as ColumnType, props: { className: '' } },
            { label: 'Action', key: 'action', type: 'string' as ColumnType, props: { className: '' } },
        ],
    };

    const Action: React.FC<ActionProps> = ({ id, currentStatus }) => {
        const [status, setStatus] = useState<string>(currentStatus);
        const [reason, setReason] = useState<string>("");
        const [filteredApprovalStatus, setFilteredApprovalStatus] = useState(APPROVAL_STATUS);
        const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
        const { showSnackbar, SnackBarComponent } = useSnackbar();


        const handleStatusChange = (newStatus: string) => {
            if (currentStatus === Enum.REJECTED && newStatus === Enum.PENDING) {
                showSnackbar('error', "Cannot change status from Rejected to Pending");
                return;
            } 
            setStatus(newStatus);
        };

        const handleDialogClose = () => {
            setConfirmDialogOpen(false);
            setStatus(currentStatus);
        };

        const handleSaveOnClick = () => {
            setConfirmDialogOpen(true);
        };

        const handleSubmit = async () => {
            const IRequestBody: any = {
                status: status,
                vendorComment: reason
            }
            if (status === Enum.UNPUBLISHED && reason === "") {
                showSnackbar('error', "Please enter comment");
                return;
            }
            catalogueApprovalService.updateCatalogueApprovalForVendor(id, IRequestBody)
                .then(res => {
                    if (res.status === HTTP_STATUS.OK) {
                        showSnackbar('success', "Updated Successfully");
                        getVendorCatalogues(0, 10)
                    } else if (res.data.exceptionCode === "DUPLICATE_ATTRIBUTE") {
                        showSnackbar('error', "Please try again");
                    }
                }).catch((error: any) => {
                    showSnackbar('error', "Failed to update Product");
                })
        }

        useEffect(() => {
            switch (currentStatus) {
                case Enum.UNPUBLISHED:
                    setFilteredApprovalStatus(
                        APPROVAL_STATUS.filter(
                            status => status.value === Enum.PUBLISHED || status.value === Enum.UNPUBLISHED || status.value === Enum.REJECTED
                        )
                    );
                    break;
                case Enum.PUBLISHED:
                    setFilteredApprovalStatus(
                        APPROVAL_STATUS.filter(
                            status => status.value === Enum.PUBLISHED || status.value === Enum.UNPUBLISHED
                        )
                    );
                    break;
                case Enum.REJECTED:
                    setFilteredApprovalStatus(
                        APPROVAL_STATUS.filter(
                            status => status.value === Enum.PUBLISHED || status.value === Enum.UNPUBLISHED || status.value === Enum.REJECTED
                        )
                    );
                    break;
                default:
                    setFilteredApprovalStatus(APPROVAL_STATUS);
            }
        }, [currentStatus]);
        
        return (
            <div>
                {confirmDialogOpen &&
                    <SuccessFailurePopupV2
                        width="564px"
                        height={status === Enum.UNPUBLISHED || status === Enum.REJECTED ? '580px' : '420px'}
                        variant={"Success"}
                        heading="Change Status?"
                        description={`Are you sure you want to change the status to ${enumToString(status)}?`}
                        setShowPopup={setConfirmDialogOpen}
                        button1={{
                            text: status === Enum.UNPUBLISHED || status === Enum.REJECTED ? 'Cancel' : 'No',
                            variant: "tertiaryContained",
                            size: "large",
                            onClick: () => handleDialogClose(),
                        }}
                        button2={{
                            text: status === Enum.UNPUBLISHED || status === Enum.REJECTED ? 'Submit' : 'Yes',
                            variant: "primaryContained",
                            size: "large",
                            onClick: () => handleSubmit()
                        }}
                        textField={
                            status === Enum.UNPUBLISHED || status === Enum.REJECTED
                            ? {
                                    label: "Please provide a reason for the change",
                                    value: reason,
                                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                        setReason(event.target.value);
                                    },
                                }
                                : undefined
                        }

                    />}
                <div className={`flex gap-x-2 items-center`}>
                    <Select
                        value={status}
                        options={filteredApprovalStatus}
                        disabled={status === Enum.UNPUBLISHED}
                        onChange={(e) => handleStatusChange(e.target.value as string)}
                        label=""
                        size='small'
                        className='h-7'
                    />
                    {status !== currentStatus && (
                        <ButtonV2
                            variant="primaryContained"
                            label="Save"
                            size="small"
                            onClick={handleSaveOnClick}
                        />
                    )}
                </div>
            </div>
        );
    };

    const handleViewClick = (actionType: string, id: number) => {
        setCatalogueId(id);
        switch (actionType) {
            case "history":
                setViewHistoryDialogOpen(true);
                break;
            case "catalogueDetails":
                setViewCatalogueDialogOpen(true);
                break;
        }
    };

    const handleOverviewViewClick = (id: number) => {
        setProductId(id);
        setstockPriceDialogOpen(true);
    };

    const ActionHistory = (id: number) => {
        return (
            <div className={` flex gap-x-2 justify-center`}>
                <ButtonV2 variant="tertiaryText"
                    label="View"
                    size="small"
                    onClick={() => handleViewClick("history", id)}
                />
            </div>
        );
    };

    const ActionStockOverview = (id: number) => {
        return (
            <div className={` flex gap-x-2 justify-center`}>
                <ButtonV2 variant="tertiaryText"
                    label="View"
                    size="small"
                    onClick={() => handleOverviewViewClick(id)}
                />
            </div>
        );
    };

    const ActionCatalogueDetails = (name: string , catalogueId: number) => {
        return (
            <div className={` flex gap-x-2 justify-center`}>
                <ButtonV2 variant="tertiaryText"
                    label={name}
                    size="small"
                    onClick={() => handleViewClick("catalogueDetails", catalogueId)}
                />
            </div>
        );
    };

    const records = Array.isArray(catalogueDetails)
        ? catalogueDetails.map((stats: ICatalogueDetails, index: number) => [
            pagination.currentPage * pagination.pageSize + index + 1,
            stats.catalogueCode,
            ActionCatalogueDetails(stats.catalogueName, stats.catalogueCode),
            ActionStockOverview(stats.vendorProductId),
            moment(stats.requestDate).format("DD-MM-YYYY"),
            stats.status === Enum.PUBLISHED ? moment(stats.approvalDate).format("DD-MM-YYYY") : "NA",
            ActionHistory(stats.catalogueCode) ?? "NA",
            <Action key={`action-${stats.approvalId}`} id={stats.approvalId} currentStatus={stats.status} />,
        ])
        : [];


    useEffect(() => {
        getVendorCatalogues(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize])

    return (
        <div>
            <ViewCatalogueDetailsDialogTemplate viewCatalogueDetailsDialogOpen={viewCatalogueDialogOpen} setViewCatalogueDetailsDialogOpen={setViewCatalogueDialogOpen} catalogueId={catalogueId} vendorId={id} />
            <StockPriceOverviewDialogTemplate stockPriceDialogOpen={stockPriceDialogOpen} setstockPriceDialogOpen={setstockPriceDialogOpen} productId={productId} vendorId={id} />
            <ViewHistoryDialogTemplate viewHistoryDialogOpen={viewHistoryDialogOpen} setViewHistoryDialogOpen={setViewHistoryDialogOpen} catalogueId={catalogueId} vendorId={id} />
            <div className="mt-3">
                <TableV2 schema={schema} records={records} />
            </div>
        </div>
    );
};

export default CatalogueApprovalProductListingTemplate;
