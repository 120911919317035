import { createUseStyles } from "react-jss";
import { DELIVERY_AND_TRANSPORTATION_SECTION } from "../../../../utils/constant";

const useStyles = createUseStyles((theme: any) => ({
    mainContainer: {
        display: "flex",
        justifyContent: "space-between",
        columnGap: "12px",
        rowGap: "0px",
    },
    imageWrapper: {
        width: "56px",
        height: "40px",
        background: "white",
        position: "absolute",
        top: "-43px",
        zIndex: 2,
    },
    description: {
        color: theme.customerPalette.text.neutral.neutral800,
        borderRadius: "12px",
        border: `1px solid ${theme.customerPalette.border.neutral.neutral100}`,
        padding: "32px 16px 16px",
    },
    heading: {
        color: theme.customerPalette.text.neutral.neutral900
    },
    "@media (max-width: 480px)": {
        mainContainer: {
            display: "flex",
            flexDirection: "column",
            rowGap: "24px",
        },
        description: {
            padding: "24px 20px 16px 20px",
        },
    },
}));

const DeliveryDetailsTemplate: React.FC = () => {

    const classes = useStyles();

    return (
        <div className={`${classes.mainContainer}`}>
            {DELIVERY_AND_TRANSPORTATION_SECTION.map(({ text, icon, description }) => (
                <div key={text} className= {`grid flex-1`}>
                    <div className={`${classes.heading} text-base w-full font-bold`}>{text}</div>
                    <div className="relative mt-10">
                        <div className={`${classes.imageWrapper} ml-8 mt-4`}>
                            <img src={icon} alt="icon" className={``} />
                        </div>
                        <p className={`${classes.description} text-base font-medium`}>{description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default DeliveryDetailsTemplate;