import { Grid, Button } from '@mui/material';
import { ProductAttributeHeading } from '../../../Shared/CustomHeading/ProductAttributeHeading';
import PurchaseAttributeCard from './PurchaseAttributeCard';
import { ProductDataType } from '../../../Constants/Procurement/PurchaseRequisition/types';
import {
  PROCUREMENT_TEXT,
  productDescription,
} from '../../../Constants/Procurement/PurchaseRequisition/constants';
import CustomImageComponent from '../../../Shared/CustomInputs/CustomImageComponent';

interface Props {
  item: ProductDataType;
  index: number;
  toggleInsight: () => void;
}

const ProductCardWithDescription = ({ item, index, toggleInsight }: Props) => {
  const attributes = item.attributesMap.CLASSIFICATION
    ? item.attributesMap.CLASSIFICATION.concat(
      item.attributesMap?.SPECIFICATION,
      item.attributesMap?.GENERALIZATION
    )
    : item.attributesMap?.SPECIFICATION || [];


  return (
    <Grid container alignItems={'center'} spacing={2}>
      <Grid xs={2.2} item className="flex gap-x-2 items-center">
        <div className="text-blue font-semibold text-2xl ">
          {index < 9 ? '0' + (index + 1) : index + 1}
        </div>

        <div className="w-max min-w-14">
          <CustomImageComponent
            imageUrl={item?.productCategoryImageURL}
            className=" w-14 h-12 rounded"
            />
        </div>
        <ProductAttributeHeading
          title={'UPC Code'}
          value={item.upcCode}
          className={'text-blue font-medium text-nowrap'}
        />
      </Grid>
      <Grid item xs={3.6}>
        <ProductAttributeHeading
          title={'UPC Description'}
          value={productDescription(item)}
          className={'text-sm mt-0.5'}
        />
      </Grid>

      <Grid xs={6.2} item className="flex items-center gap-x-4">
        <PurchaseAttributeCard attributes={attributes} />
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          sx={{
            color: '#1D3679',
            borderColor: '#1D3679',
            textTransform: 'none',
            fontWeight: 'bold',
          }}
          onClick={toggleInsight}
        >
          {PROCUREMENT_TEXT.insightButtonText}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProductCardWithDescription;
