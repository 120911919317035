import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import ButtonV2 from '../../atoms/ButtonV2';
import { IAttributeFilterRequest, IAttributes, useAttributeService } from '../../../services/useAttributeService';
import { HTTP_STATUS, IPagination } from '../../../utils/types';
import { ADMIN_ROUTES, initialPaginationValues } from '../../../utils/constant';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useNavigate } from 'react-router-dom';
import AttributeListTemplate from '../../templates/Attribute/AttributeList.template';

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary950,
    },
}));

const initialAttributeFiltersValues: IAttributeFilterRequest = {
    name: "",
    status: "ALL",
    sort: "createdAt,desc",
}

const AttributeListPage: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const vasMasterCategoryService = useAttributeService();
    const [filters, setFilters] = useState<IAttributeFilterRequest>(initialAttributeFiltersValues);
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const [attributes, setAttributes] = useState<IAttributes[]>([])
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const refreshAttributes = async (page: number, size: number) => {
        await vasMasterCategoryService.getAllAttributes(
            {
                page: page,
                size: size,
                sort: filters?.sort,
                name: filters?.name?.trim(),
                status: filters?.status === "ALL" ? null : filters?.status,
            }
        )
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    const { totalElements, totalPages } = res?.data?.data;
                    setPagination({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setAttributes(res?.data?.data?.content)
                }
            }).catch(error => {
                showSnackbar('error', error);
                setAttributes([]);
            })
    }

    const handleFiltersChange = (name: string, value: any) => {
        setPagination({ ...pagination, currentPage: 0 });
        setFilters({ ...filters, [name]: value ?? "" });
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPagination((prevPagination) => ({
            ...prevPagination,
            pageSize: newRowsPerPage
        }));
    };

    useEffect(() => {
        refreshAttributes(pagination.currentPage, pagination.pageSize);
    }, [pagination.currentPage, pagination.pageSize, filters]);

    const handleCreate = () => {
        navigate(`${ADMIN_ROUTES.ATTRIBUTE_CREATE}`);
    };

    return (
        <div className='grid gap-y-4'>
            <div className='flex justify-between'>
                <div className={`${classes.heading} text-base font-semibold my-auto`}>Attribute</div>
                <ButtonV2 variant='primaryContained' label={"+ Add New Attribute"} onClick={handleCreate}/>
            </div>
            {SnackBarComponent}

            <AttributeListTemplate
                attributes={attributes}
                pagination={pagination}
                handlePaginationChange={handlePaginationChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handleFiltersChange={handleFiltersChange}
                filters={filters}
            />
        </div>
    )
}

export default AttributeListPage