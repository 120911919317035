import React, { useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import Carousel from "react-multi-carousel";

import TransparentProcess from "../../../../assets/images/transparentProcess.svg";
import OriginalProduct from "../../../../assets/images/originalProduct.svg";
import BestPrices from "../../../../assets/images/bestPrices.svg";
import SecurePayments from "../../../../assets/images/securePayments.svg";

const useStyles = createUseStyles((theme: any) => ({
    carouselContainer: {
        width: "354px",
        margin: "0 auto",
        "& .react-multi-carousel-list": {
            width: "100%",
            overflow: 'hidden',
            "& .react-multi-carousel-track ": {
                width: "100%",
                display: 'flex',
                "& li": {
                    width: '400px !important',
                }
            },
        },
        "& .react-multiple-carousel__arrow": {
            display: "none",
        },
    },
    carouselItem: {
        textAlign: "center",
        width: '354px',
        "& img": {
            maxWidth: 'none !important',
            objectFit: "contain",
        },
        "& span": {
            fontSize: "14px",
            fontWeight: "500"
        },
    },
    "@media (max-width: 767px)": {
        mainContainer: {
            padding: "20px 0",
        },
        carouselContainer: {
            width: "100%",
        },
    },
}));

const carouselItems = [
    { title: "Secure Payments", image: SecurePayments },
    { title: "Transparent Process", image: TransparentProcess },
    { title: "100% Original Products", image: OriginalProduct },
    { title: "Best Pricing in the Market", image: BestPrices },
];

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,
    },
};

const TermsAndConditionsCarouselTemplate: React.FC = () => {
    const classes = useStyles();
    const carouselRef = useRef<Carousel | null>(null);

    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.goToSlide(0, true);
        }
    }, []);

    return (
        <div className={`w-max`}>
            <div className={`${classes.carouselContainer}`}>
                <Carousel
                    responsive={responsive}
                    infinite
                    autoPlay={true}
                    autoPlaySpeed={2000}
                    showDots={false}
                    ref={carouselRef}
                >
                    {carouselItems.map((item, index) => (
                        <div key={index} className={classes.carouselItem}>
                            <img src={item.image} alt={item.title} />
                            <span>{item.title}</span>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};
export default TermsAndConditionsCarouselTemplate;