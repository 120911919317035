import { FormikErrors, FormikProps } from 'formik';
import React, { memo, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';

import { isArray } from 'lodash';
import { IConfigurationShape } from './AddMaterialProduct.template';
import { HTTP_STATUS } from '../../../../utils/types';
import { FREQUENCY, MATERIAL_REQUIREMENT_APPLICATION_TYPE, MODE, MY_MATERIAL_CUSTOMIZATION_ATTRIBUTE, SHAPE_TYPES, SOURCING_PURPOSE } from '../../../../utils/constant';
import { IMaterialAttribute, IMaterialRequirement, IMaterialRequirementFormik } from '../../../pages/CustomerManagement/MyMaterialProduct.page';
import SelectV2 from '../../../atoms/SelectV2';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import AutoCompleteInputV2, { AutoCompleteOption } from '../../../molecules/AutoCompleteInput/AutoCompleteInputV2';
import { useQualtiyGradeService } from '../../../../services/useQualityGradesService';

interface AddMaterialFormTemplateProps {
  formik: FormikProps<IMaterialRequirementFormik>
  index: number
  attributes: IMaterialAttribute[]
  attributesOriginal?: IMaterialAttribute[]
  setAttributes?: (value: IMaterialAttribute[]) => void
  standards: IStandards[]
  loadStandard: (input?: string) => void;
  mode: string
}

export interface IStandards {
  id: number;
  name: string;
  ranking?: number
}

export interface IGrade {
  id: number;
  name: string;
}

const useStyles = createUseStyles((theme: any) => ({
  section: {
    color: theme.palette.textV2.secondary.secondary800,
  },
  highlightedText: {
    color: theme.palette.textV2.primary.primary500
  },
  divider: {
    height: '1px',
    background: theme.palette.textV2.tertiary.tertiary100
  },
  tertiary100Border: {
    border: `1px solid ${theme.palette.textV2.tertiary.tertiary100}`
  },
  darkHeading: {
    color: theme.palette.textV2.primary.primary900
  }
}));

const AddMaterialFormTemplate: React.FC<AddMaterialFormTemplateProps> = memo(({ formik, index, attributes, attributesOriginal = [], setAttributes, standards, loadStandard, mode }) => {
  const classes = useStyles();
  const qualityGradesService = useQualtiyGradeService();
  const [grades, setGrades] = useState<IGrade[]>([]);

  const handleFormikValueChange = (fieldName: string, value: any) => {
    formik.setFieldValue(fieldName, value);
  }

  const handleAttributeChange = (formIndex: number, attributeIndex: number, attributeName: string) => (value: string | AutoCompleteOption | null) => {
    if (typeof value === 'string' && value !== null && value !== "") {
      if (attributeName.trim().toUpperCase() == 'WIDTH' && (value.includes('.') || parseInt(value) > 9999)) return;
      const customAttribute = JSON.parse(JSON.stringify(attributes));
      const isStandard = customAttribute[attributeIndex].attributeOptions?.includes(value);
      if (!isStandard && isArray(customAttribute?.[attributeIndex]?.attributeOptions)) {
        (customAttribute?.[attributeIndex]?.attributeOptions as string[])?.push(value);
        if(parseInt(value) > 9999) return;
        handleFormikValueChange(`materialRequirement[${formIndex}].attributes[${attributeIndex}].isMpc`, true);
        handleFormikValueChange(`materialRequirement[${formIndex}].attributes[${attributeIndex}].attributeOptions`, value);
        setAttributes && setAttributes(customAttribute);
      }
    } else if (typeof value !== 'string') {
      const isStandard = attributesOriginal[attributeIndex].attributeOptions?.includes(value?.label as string);
      if (isStandard)
        handleFormikValueChange(`materialRequirement[${formIndex}].attributes[${attributeIndex}].isMpc`, false);
      handleFormikValueChange(`materialRequirement[${formIndex}].attributes[${attributeIndex}].attributeOptions`, value?.label);
    }
  }

  const handleOptionChange = (field: string) => (value: AutoCompleteOption | null) => {
    handleFormikValueChange(`materialRequirement[${index}].[${field}]`, value?.id)
    if (['standardId', 'gradeId'].includes(field)) {
      const fieldName = field === 'standardId' ? 'standard' : 'grade';
      handleFormikValueChange(`materialRequirement[${index}].[${fieldName}]`, value)
    }
  }

  const loadGrade = (inputValue?: string) => {
    if (formik?.values.materialRequirement[index]?.standardId) {
      qualityGradesService.getAllQualityGrades({ search: inputValue ?? '', type: 'GRADE', page: 0, size: 10, sort: 'createdAt,desc', standardId: formik?.values.materialRequirement[index]?.standardId as number })
        .then((res:any) => {
          if (res?.status === HTTP_STATUS.OK)
            setGrades(res?.data?.data?.content)
        }).catch((error:any) => {
          console.error("Error Fetching Category: ", error);
        })
    }
  }

  useEffect(() => {
    if(mode !== MODE.EDIT){
      formik.setFieldValue(`materialRequirement[${index}].grade`, null);
      formik.setFieldValue(`materialRequirement[${index}].gradeId`, null);
    }
    loadGrade();
  }, [formik?.values.materialRequirement?.[index]?.standardId]);

  return (
    <div className='flex flex-col gap-6'>
      <div>
        <div className={`pb-4 text-base font-medium ${classes.darkHeading}`}>Standard</div>
        <div className='grid grid-cols-3 gap-6'>
          {(mode === MODE.ADD || formik.values.materialRequirement[index]?.standard) &&
            <AutoCompleteInputV2
              {...formik.getFieldProps(`materialRequirement[${index}].standardId`)}
              label='Select Standard'
              options={standards.map(standard => ({ id: standard.id, label: standard.name }))}
              onSearch={loadStandard}
              onChange={handleOptionChange('standardId')}
              placeHolder='Select Standard'
              isDisabled={mode === MODE.EDIT}
              error={
                formik.touched.materialRequirement?.[index]?.standardId &&
                Boolean(
                  formik.errors.materialRequirement &&
                  Array.isArray(formik.errors.materialRequirement) &&
                  (formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index]?.standardId
                )
              }
              helperText={(formik.touched.materialRequirement?.[index]?.standardId &&
                formik.errors.materialRequirement &&
                Array.isArray(formik.errors.materialRequirement) &&
                ((formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index]?.standardId ?? "")) as string}
              value={formik.values.materialRequirement[index]?.standard}
              onBlur={() => formik.setFieldTouched(`materialRequirement[${index}].standardId`)}
            />}

          {(mode === MODE.ADD || formik.values.materialRequirement[index]?.grade) &&
            <AutoCompleteInputV2
              {...formik.getFieldProps(`materialRequirement[${index}].gradeId`)}
              label='Select Grade'
              options={grades.map(grade => ({ id: grade.id, label: grade.name }))}
              onSearch={loadGrade}
              onChange={handleOptionChange('gradeId')}
              placeHolder='Select Grade'
              isDisabled={!formik.values.materialRequirement[index]?.standardId || mode === MODE.EDIT}
              error={
                formik.touched.materialRequirement?.[index]?.gradeId &&
                Boolean(
                  formik.errors.materialRequirement &&
                  Array.isArray(formik.errors.materialRequirement) &&
                  (formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index]?.gradeId
                )
              }
              helperText={(formik.touched.materialRequirement?.[index]?.gradeId &&
                formik.errors.materialRequirement &&
                Array.isArray(formik.errors.materialRequirement) &&
                ((formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index]?.gradeId ?? "")) as string}
              value={formik.values.materialRequirement[index]?.grade}
              onBlur={() => formik.setFieldTouched(`materialRequirement[${index}].gradeId`)}
            />}

          <SelectV2
            {...formik.getFieldProps(`materialRequirement[${index}].shape`)}
            label='Shape'
            options={SHAPE_TYPES.map(shape => ({ value: shape?.id, label: shape?.label as string }))}
            placeholder='Select Shape'
            disabled={mode === MODE.EDIT}
            error={
              formik.touched.materialRequirement?.[index]?.shape &&
              Boolean(
                formik.errors.materialRequirement &&
                Array.isArray(formik.errors.materialRequirement) &&
                (formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index]?.shape
              )
            }
            helperText={(formik.touched.materialRequirement?.[index]?.shape &&
              formik.errors.materialRequirement &&
              Array.isArray(formik.errors.materialRequirement) &&
              ((formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index]?.shape ?? "")) as string}
          />

        </div>
      </div>
      <div>
        <div className={`pb-4 text-base font-medium ${classes.darkHeading}`}>Product Specification</div>
        <div className='grid grid-cols-3 gap-6'>
          {
            attributes.map(((attribute, attributeIndex) => (
              MY_MATERIAL_CUSTOMIZATION_ATTRIBUTE.includes(attribute.name?.trim()?.toUpperCase()) ? 
              <AutoCompleteInputV2
                {...formik.getFieldProps(`materialRequirement[${index}].attributes[${attributeIndex}].attributeOptions`)}
                label={`Select ${attribute.name} ${attribute.uom != null ? `(${attribute.uom.toLowerCase()})` : ''}`}
                options={isArray(attribute.attributeOptions) ? attribute.attributeOptions?.map((option: string) => ({ id: option, label: option })) : []}
                onSearch={handleAttributeChange(index, attributeIndex, attribute.name)}
                onChange={handleAttributeChange(index, attributeIndex, attribute.name)}
                placeHolder={`Select ${attribute.name}`}
                isDisabled={mode === MODE.EDIT}
                value={formik.values.materialRequirement[index]?.attributes[attributeIndex].attributeOptions ?
                  {
                    id: formik.values.materialRequirement[index]?.attributes[attributeIndex].attributeOptions as string ?? ""
                    , label: formik.values.materialRequirement[index]?.attributes[attributeIndex].attributeOptions as string
                  } : null}
                error={
                  formik.touched.materialRequirement?.[index]?.attributes?.[attributeIndex] &&
                  Boolean(
                    formik.errors.materialRequirement &&
                    Array.isArray(formik.errors.materialRequirement) &&
                    ((formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index] as FormikErrors<IMaterialRequirement>)?.attributes?.[attributeIndex]
                  )
                }
                helperText={(formik.touched.materialRequirement?.[index]?.attributes?.[attributeIndex] &&
                  formik.errors.materialRequirement &&
                  Array.isArray(formik.errors.materialRequirement) &&
                  (((formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index] as FormikErrors<IMaterialRequirement>)?.attributes?.[attributeIndex] as FormikErrors<IMaterialAttribute>)?.attributeOptions) as string}
                onBlur={() => formik.setFieldTouched(`materialRequirement[${index}].attributes[${attributeIndex}].attributeOptions`)}
              />
              : <SelectV2
              {...formik.getFieldProps(`materialRequirement[${index}].attributes[${attributeIndex}].attributeOptions`)}
              label={`Select ${attribute.name} ${attribute.uom != null ? `(${attribute.uom.toLowerCase()})` : ''}`}
              options={isArray(attribute.attributeOptions) ? attribute.attributeOptions?.map((option: string) => ({ value: option, label: option })) : []}
              placeholder={`Select ${attribute.name}`}
              disabled={mode === MODE.EDIT}
              error={
                formik.touched.materialRequirement?.[index]?.attributes?.[attributeIndex] &&
                Boolean(
                  formik.errors.materialRequirement &&
                  Array.isArray(formik.errors.materialRequirement) &&
                  ((formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index] as FormikErrors<IMaterialRequirement>)?.attributes?.[attributeIndex]
                )
              }
              helperText={(formik.touched.materialRequirement?.[index]?.attributes?.[attributeIndex] &&
                formik.errors.materialRequirement &&
                Array.isArray(formik.errors.materialRequirement) &&
                (((formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index] as FormikErrors<IMaterialRequirement>)?.attributes?.[attributeIndex] as FormikErrors<IMaterialAttribute>)?.attributeOptions) as string}
              onBlur={() => formik.setFieldTouched(`materialRequirement[${index}].attributes[${attributeIndex}].attributeOptions`)}
            />
            )))
          }
        </div>

      </div>
      <div>
        <div className={`pb-4 text-base font-medium ${classes.darkHeading}`}>Other Information</div>
        <div className='grid gap-6'>
          <div className='grid grid-cols-2 gap-6'>
            <SelectV2
              {...formik.getFieldProps(`materialRequirement[${index}].applicationType`)}
              label='Application Type'
              options={MATERIAL_REQUIREMENT_APPLICATION_TYPE.map(type => ({ value: type.id, label: type.name }))}
              placeholder='Select Application Type'
              error={
                formik.touched.materialRequirement?.[index]?.applicationType &&
                Boolean(
                  formik.errors.materialRequirement &&
                  Array.isArray(formik.errors.materialRequirement) &&
                  (formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index]?.applicationType
                )
              }
              helperText={(formik.touched.materialRequirement?.[index]?.applicationType &&
                formik.errors.materialRequirement &&
                Array.isArray(formik.errors.materialRequirement) &&
                ((formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index]?.applicationType ?? "")) as string}
            />
            <SelectV2
              {...formik.getFieldProps(`materialRequirement[${index}].primarySourcingPurpose`)}
              label='Primary Sourcing Purpose'
              options={SOURCING_PURPOSE.map(type => ({ value: type.id, label: type.name }))}
              placeholder='Select Primary Sourcing Purpose'
              error={
                formik.touched.materialRequirement?.[index]?.primarySourcingPurpose &&
                Boolean(
                  formik.errors.materialRequirement &&
                  Array.isArray(formik.errors.materialRequirement) &&
                  (formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index]?.primarySourcingPurpose
                )
              }
              helperText={(formik.touched.materialRequirement?.[index]?.primarySourcingPurpose &&
                formik.errors.materialRequirement &&
                Array.isArray(formik.errors.materialRequirement) &&
                ((formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index]?.primarySourcingPurpose ?? "")) as string}
            />
          </div>
          <TextFieldV2
            {...formik.getFieldProps(`materialRequirement[${index}].otherSpecificRequirement`)}
            label='Other Specific Requirement'
            placeholder='Enter your specific requirement'
            error={
              formik.touched.materialRequirement?.[index]?.otherSpecificRequirement &&
              Boolean(
                formik.errors.materialRequirement &&
                Array.isArray(formik.errors.materialRequirement) &&
                (formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index]?.otherSpecificRequirement
              )
            }
            helperText={(formik.touched.materialRequirement?.[index]?.otherSpecificRequirement &&
              formik.errors.materialRequirement &&
              Array.isArray(formik.errors.materialRequirement) &&
              ((formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index]?.otherSpecificRequirement ?? "")) as string}
          />
        </div>
      </div>
      <div>
        <div className={`pb-4 text-base font-medium ${classes.darkHeading}`}>Estimated Product Requirement</div>
        <div className='grid grid-cols-2 gap-6'>
          <SelectV2
            {...formik.getFieldProps(`materialRequirement[${index}].frequency`)}
            label='Enter Frequency'
            options={FREQUENCY.map(type => ({ value: type.id, label: type.name }))}
            placeholder='Select Frequency'
            error={
              formik.touched.materialRequirement?.[index]?.frequency &&
              Boolean(
                formik.errors.materialRequirement &&
                Array.isArray(formik.errors.materialRequirement) &&
                (formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index]?.frequency
              )
            }
            helperText={(formik.touched.materialRequirement?.[index]?.frequency &&
              formik.errors.materialRequirement &&
              Array.isArray(formik.errors.materialRequirement) &&
              ((formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])[index]?.frequency ?? "")) as string}
          />

          <TextFieldV2
            {...formik.getFieldProps(`materialRequirement[${index}].quantity`)}
            label='Enter Quantity (MT)'
            placeholder='Enter Quantity'
            error={
              formik.touched.materialRequirement?.[index]?.quantity &&
              Boolean(
                formik.errors.materialRequirement &&
                Array.isArray(formik.errors.materialRequirement) &&
                (formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])?.[index]?.quantity
              )
            }
            helperText={formik.touched.materialRequirement?.[index]?.quantity &&
              formik.errors.materialRequirement &&
              Array.isArray(formik.errors.materialRequirement) &&
              (formik.errors.materialRequirement as FormikErrors<IMaterialRequirement>[])?.[index]?.quantity}
          />
        </div>
      </div>
    </div>
  )
})

export default AddMaterialFormTemplate