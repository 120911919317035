import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { DELIVERY_INFORMATION } from "../../../utils/constant";
import moment from "moment";
import { IVendorCatalogueUsnDetails } from "./StockPriceOverviewDialog.template";

interface IStockAndPriceOverviewDetailsTemplateProps {
    vendorUsnDetails: IVendorCatalogueUsnDetails | undefined;
}

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: "#0A1129",

    },
    selectBox: {
        border: `1px solid #E5E6E6`,
    },
    subHeading: {
        color: "#4A4D4F",
    },
}));


const StockAndPriceOverviewDetailsTemplate: React.FC<IStockAndPriceOverviewDetailsTemplateProps> = ({ vendorUsnDetails }) => {
    const classes = useStyles();

    return (
        <div className={`p-3 `}>
            <div className='flex gap-x-6'>
                <div>
                    <div className={`${classes.selectBox} w-full rounded-xl p-4`}>
                        <div className={`${classes.heading} font-medium text-base mb-3`}>Inventory Details</div>
                        <div className="flex gap-x-6">
                            <div>
                                <div className={`${classes.subHeading} text-sm`}>Stock Available</div>
                                <div className={`${classes.subHeading} font-medium text-base `}>{vendorUsnDetails?.totalStock || 0} MT</div>
                            </div>
                            <div className={``}>
                                <div className={`${classes.subHeading} text-sm`}>Free Stocks</div>
                                <div className={`${classes.subHeading} font-medium text-base `}>{vendorUsnDetails?.totalStock || 0} MT</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={`${classes.selectBox} w-full rounded-xl p-4`}>
                        <div className={`${classes.heading} font-medium text-base mb-3`}>Delivery and Order Details</div>
                        <div className="flex gap-x-6">
                            <div>
                                <div className={`${classes.subHeading} w-full text-sm`}>Delivery Information</div>
                                <div className={`${classes.subHeading} font-medium text-base `}>{DELIVERY_INFORMATION.find(option => option.value === vendorUsnDetails?.deliveryInformation)?.label || vendorUsnDetails?.deliveryInformation}
                                </div>
                            </div>
                            <div className={``}>
                                <div className={`${classes.subHeading} text-sm`}>Minimum Order Quantity</div>
                                <div className={`${classes.subHeading} font-medium text-base `}>{vendorUsnDetails?.minimumOrderQuantity || 0} MT</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className={`${classes.selectBox} w-2/3 rounded-xl mt-6 p-4`}>
                    <div className={`${classes.heading} font-medium text-base mb-3`}>Pricing Details</div>
                    <div className="flex gap-x-6">
                        <div>
                            <div className={`${classes.subHeading} text-sm`}>Reserve Sales Price</div>
                            <div className={`${classes.subHeading} font-medium text-base `}>{`\u20B9`} {vendorUsnDetails?.reserveSalesPrice || 0}</div>
                        </div> 
                        <div className={``}>
                            <div className={`${classes.subHeading} text-sm`}>Market Price</div>
                            <div className={`${classes.subHeading} font-medium text-base `}>{`\u20B9`} {vendorUsnDetails?.marketPrice || 0}</div>
                        </div>
                        <div className={``}>
                            <div className={`${classes.subHeading} text-sm`}>Price Validity (To)</div>
                            <div className={`${classes.subHeading} font-medium text-base `}>{vendorUsnDetails?.priceValidityTo ? moment(vendorUsnDetails?.priceValidityTo).format("DD-MM-YYYY") : "-"}</div>
                        </div>
                        <div className={``}>
                            <div className={`${classes.subHeading} text-sm`}>Price Validity (From)</div>
                            <div className={`${classes.subHeading} font-medium text-base `}>{vendorUsnDetails?.priceValidityFrom ? moment(vendorUsnDetails?.priceValidityFrom).format("DD-MM-YYYY") : "-"}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default StockAndPriceOverviewDetailsTemplate;