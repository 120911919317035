import React, { useEffect, useState } from "react";
import axios from "axios";
import deleteBinIcon from "./../../../assets/icons/deleteBinIcon.svg";
import viewEyeIcon from "./../../../assets/icons/viewEyeIcon.svg"
import editPenIcon from "./../../../assets/icons/editPenIcon.svg";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { joinNameWithoutSpaces } from "../../../utils/helper";
import ButtonV2 from "../../atoms/ButtonV2";
import { createUseStyles } from "react-jss";
import TextFieldV2 from "../../atoms/TextFieldV2";
import Select from "../../atoms/Select";
import TableV2, { ColumnType } from "../../organisms/TableV2";
import ChemicalCompositionFormTemplate from "./ChemicalCompositionForm.template";
import { MODE } from "../../../utils/constant";
import { useQualtiyGradeService } from "../../../services/useQualityGradesService";

const BaseUrl = process.env.REACT_APP_API_V1_URL;

interface ChemicalCompositionListTemplateProps {
    records: any;
    associatedGrade: any;
    updateGradeProperties: any;
}

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary950,
    },
}));

const ChemicalCompositionListTemplate: React.FC<ChemicalCompositionListTemplateProps> = ({ records, associatedGrade, updateGradeProperties }) => {
    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const qualtiyGradeService = useQualtiyGradeService();
    const [chemicalCompositionData, setChemicalCompositionData] = useState(records);
    const [sorting, setSorting] = useState("name,desc");
    const [selectedCC, setSelectedCC] = useState({});
    const [searchQuery, setSearchQuery] = useState("");

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [type, setType] = useState<string>(MODE.ADD);
    const handleForm = (type: string) => {
        setType(type);
        setDialogOpen(true);
    }

    useEffect(() => {
        const newData = customFilterFunction(records, sorting, searchQuery);
        setChemicalCompositionData(newData);
    }, [sorting, searchQuery, associatedGrade]);

    const addFormDataToCCProperties = ( formData: any, associatedGrade: any, recordId = null ) => {
        const { name } = formData;
        const { id } = formData;
        if (associatedGrade.chemicalComposition === null || associatedGrade.chemicalComposition.length === 0) {
            associatedGrade.chemicalComposition = [];
        } else {
            if (typeof (associatedGrade.chemicalComposition) === "string") {
                associatedGrade.chemicalComposition = JSON.parse(
                    associatedGrade.chemicalComposition
                );
            }
        }
        let flag = true;
        /** TODO refactor the condition 
        **/
        if (associatedGrade.chemicalComposition.length === 0) {
            associatedGrade.chemicalComposition.push(formData);
        } else {
            const isAlreadyExists = associatedGrade.chemicalComposition.some(
                (property: any) => property.name === name || property.id === id
            );

            if (isAlreadyExists) {
                let index = associatedGrade.chemicalComposition.findIndex((element: any) => element.id === id);
                if (index > -1 && type === MODE.EDIT) {
                    let indexFromNameAndId = associatedGrade.chemicalComposition.findIndex((element: any) => element.name === name && element.id !== id);
                    if (indexFromNameAndId > -1) {
                        window.alert("Chemical Composition already exists")
                        return;
                    } else {
                        associatedGrade.chemicalComposition[index] = formData;
                    }
                } else if (type === MODE.ADD) {
                    window.alert("Chemical Composition already exists")
                    return;
                } else {
                    const maxId = associatedGrade.chemicalComposition.reduce((max: any, obj: any) => (obj.id > max ? obj.id : max), 0);
                    if ((maxId + 1) === recordId) {
                        associatedGrade.chemicalComposition.push(formData);
                    }
                }
            } else {
                associatedGrade.chemicalComposition.push(formData);
            }
        }
        associatedGrade.chemicalComposition = JSON.stringify(
            associatedGrade.chemicalComposition
        );

        return flag;
    };

    const handleViewElement = () => { };

    const updateTensileProperties = async (gradeData: any) => {
        const apiUrl = `${BaseUrl}/quality-grade/${gradeData.id}`;
        const httpMethod = "PUT";
        const response = await axios({
            method: httpMethod,
            url: apiUrl,
            data: gradeData,
            headers: {
                Authorization: `${user?.token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    };

    const handleEditElement = async (formData: any, recordId: any) => {
        try {
            const chemicalComposition = JSON.parse(associatedGrade?.chemicalComposition)

            chemicalComposition.forEach((composition: any) => {
                composition.name = joinNameWithoutSpaces(composition.name);
            });

            associatedGrade.chemicalComposition = JSON.stringify(chemicalComposition);
            if (!addFormDataToCCProperties(formData, associatedGrade, recordId)) {
                return;
            }

            var returnResponse = await updateTensileProperties(associatedGrade);
            if (returnResponse.status === 200) {
                updateGradeProperties(returnResponse.data.data);
                setDialogOpen(false);
            }
        } catch (error) {
            console.log(error)
            alert("Encountered Error in creating/Updating Properies");
            return false;
        }
    };

    const deleteChemicalProperty = async (chemicalIndex: any, chemicalRecords: any) => {
        try {
            const confirmDelete = window.confirm(
                "Are you sure you want to delete this Chemical Property?"
            );
            if (confirmDelete) {
                const ChemicalCompositionArray = chemicalRecords;
                const updatedChemicalComposition = ChemicalCompositionArray.filter(
                    (record: any, index: any) => record.id !== chemicalIndex
                );

                const updatedChemicalCompositionsString = JSON.stringify(
                    updatedChemicalComposition
                );

                const updatedGrade = {
                    ...associatedGrade,
                    chemicalComposition: updatedChemicalCompositionsString,
                };
                var returnResponse = await updateTensileProperties(updatedGrade);

                if (returnResponse.status === 200) {
                    updateGradeProperties(returnResponse.data.data);
                }
            }
        } catch (error) {
            return false;
        }
    };

    const customFilterFunction = (data: any, sorting: any, searchQuery: any) => {
        if (!Array.isArray(data) || data.length === 0) {
            return [];
        }
        const [field, order] = sorting.split(",");

        let filteredData = [...data];

        if (searchQuery) {
            filteredData = filteredData.filter((item) => {
                const nameMatches = item.name
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase());
                return nameMatches;
            });
        }

        const sortedData = [...filteredData].sort((a, b) => {
            if (order === "asc") {
                return a[field].localeCompare(b[field]);
            } else {
                return b[field].localeCompare(a[field]);
            }
        });

        return sortedData;
    };

    const handleCustomSearch = (value: any) => {
        setSearchQuery(value);
    };
    
    const handleCustomSort = (value: any) => {
        setSorting(value);
    };

    const schema = {
        id: 'standard-list',
        pagination: {
            total: 0,
            currentPage: 0,
            isVisible: false,
            limit: 0,
        },
        columns: [
            { label: "Sr No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Element Name", key: "name", type: "string" as ColumnType, props: { className: '' } },
            { label: "Min Value(%)", key: "minimum", type: "string" as ColumnType, props: { className: '' } },
            { label: "Max Value(%)", key: "maximum", type: "string" as ColumnType, props: { className: '' } },
            { label: "Created Date", key: "createdAt", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Last Modified", key: "updatedAt", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ]
    }

    const Action = (record: any, id: number) => {
        return (<div className='flex justify-center gap-x-1'>
            <button onClick={(e) => {
                handleForm(MODE.VIEW)
                setSelectedCC(record)
            }}>
                <img className="w-full h-full" alt="" src={viewEyeIcon} />
            </button>

            <button onClick={(e) => {
                handleForm(MODE.EDIT)
                setSelectedCC(record)
            }}>
                <img className="w-full h-full" alt="" src={editPenIcon} />
            </button>

            <button onClick={(e) => deleteChemicalProperty(id, records)} >
                <img className="w-full h-full" alt="" src={deleteBinIcon} />
            </button>
        </div>)
    }

    const recordsData = chemicalCompositionData.map((record: any, index: number) => [
        index + 1,
        record.name,
        record.minimum,
        record.maximum,
        record.createdAt,
        record.updatedAt,
        Action(record, record.id)
    ]);

    return (
        <div className='grid gap-y-3'>
            <div className='flex justify-between'>
                <div className={`${classes.heading} text-lg font-semibold my-auto`}>Chemical Composition</div>
                <ButtonV2 variant="contained" label={"Add New Chemical Composition"} onClick={(e) => handleForm(MODE.ADD)} />
            </div>

            <div className="flex justify-between">
                <Select
                    variant="outlined"
                    className='w-64'
                    id="sort"
                    label="Sort"
                    name="sort"
                    value={sorting}
                    onChange={(e) => handleCustomSort(e.target.value)}
                    options={[
                        { value: "name,asc", label: "Name (Ascending)" },
                        { value: "name,desc", label: "Name (Descending)" },
                    ]}
                />
                <TextFieldV2
                    className="w-72"
                    type="text"
                    placeholder='Search...'
                    name="search"
                    value={searchQuery}
                    onChange={(e) => handleCustomSearch(e.target.value)}
                />
            </div>

            <div className="grid">
                <TableV2 schema={schema} records={recordsData} />
                {recordsData?.length === 0 && <div className="text-center pt-4">No Chemical Composition Data Available</div>}
            </div>

            {
                type === MODE.ADD &&
                <ChemicalCompositionFormTemplate
                    dialogOpen={dialogOpen}
                    setDialogOpen={setDialogOpen}
                    type={type}
                    record={null}
                    handleAction={handleEditElement}
                    associatedGrade={associatedGrade}
                    fullData={chemicalCompositionData}
                />
            }

            {
                type === MODE.VIEW &&
                <ChemicalCompositionFormTemplate
                    dialogOpen={dialogOpen}
                    setDialogOpen={setDialogOpen}
                    type={type}
                    record={selectedCC}
                    handleAction={handleViewElement}
                    associatedGrade={associatedGrade}
                    fullData={chemicalCompositionData}
                />
            }

            {
                type === MODE.EDIT &&
                <ChemicalCompositionFormTemplate
                    dialogOpen={dialogOpen}
                    setDialogOpen={setDialogOpen}
                    type={type}
                    record={selectedCC}
                    handleAction={handleEditElement}
                    associatedGrade={associatedGrade}
                    fullData={chemicalCompositionData}
                />
            }
        </div>
    );
};

export default ChemicalCompositionListTemplate;