import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import MasterBrandListTemplate from "./MasterBrandList.Template";
import ButtonV2 from "../../atoms/ButtonV2";
import AddMasterBrandTemplate from "./AddMasterBrand.Template";
import { IBrandUpcs } from "./MasterbrandListModal.Template";
import { IUpdateBrandMasterUpcCreateRequest, useMasterUpcService } from "../../../services/useMasterUpcService";
import { IRelatedBrands } from "../MasterUPCListing/MasterRelatedBrands.template";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { IMultiSelectOption } from "../../molecules/FilterChip/FilterChip";

interface AddMasterBrandTemplateProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    selectedMasterUpcId: number;
    selectedMasterUpcCode: string;
    selectedMasterUpcTitle: string;
    brandUpcs: IBrandUpcs[] | IRelatedBrands[] ;
    refreshBrands: (page: number, size: number) => void;
    masterUpcId: number;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "783px !important",
            maxWidth: "1297px !important",
            height: "278px",
            borderRadius: "16px"
        }
    },
    image: {
        height: "687px"
    },
    heading: {
        color: theme.palette.textV2.primary.primary900
    },
    subHeading: {
        color: theme.palette.textV2.primary.primary870
    },
    closeIcon: {
        // color: "red"
    }
}));

const AddMasterBrandModalTemplate: React.FC<AddMasterBrandTemplateProps> = ({ dialogOpen, setDialogOpen, selectedMasterUpcId, selectedMasterUpcTitle, selectedMasterUpcCode, brandUpcs, refreshBrands, masterUpcId }) => {
    const classes = useStyles();
    const [selectedOption, setSelectedOption] = useState<IMultiSelectOption[]>([]);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const masterUpcService = useMasterUpcService();

    const handleAddBrand = () => {
        if(selectedOption.length <= 0 ){
            showSnackbar('error', 'Please Select an Option First');
            return;
        }
        const selectedValue = Number(selectedOption[0].value);
        if (selectedOption.length > 0) {
            const params: IUpdateBrandMasterUpcCreateRequest = {
                masterUpcId: selectedMasterUpcId
            };
            masterUpcService.addBrandToMasterUPC(selectedValue, params)
                .then((upcResponse) => {
                    if (upcResponse.data.data) {
                        refreshBrands(0, 10);
                        setDialogOpen(false);
                        setSelectedOption([]);
                    } else {
                        if(upcResponse.data.exceptionCode === "DUPLICATE_ENTRY"){
                            showSnackbar('error', 'Brand already Exists');
                        }
                    }
                })
                .catch((error) => {
                    console.error("Brands Adding error - ", error);
                });
        } else {
            console.error("Invalid selected option");
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <>
          {SnackBarComponent}
            <Dialog fullWidth className={classes.container}
                open={dialogOpen} onClose={handleDialogClose}>
                <div className={`flex gap-x-6 px-6 mb-6`}>
                    <div className="grid gap-y-6 mt-5 m-auto flex-1">
                        <div className="flex justify-between">
                            <div>
                                <div className={`${classes.heading} text-2xl font-medium`}>{selectedMasterUpcTitle}</div>
                                <div className={`${classes.subHeading} text-base`}>{selectedMasterUpcCode}</div>
                            </div>
                            <div className={`cursor-pointer`} onClick={handleDialogClose}>
                                <CloseIcon className={`${classes.closeIcon}`} />
                            </div>
                        </div>
                        <div>
                            <AddMasterBrandTemplate brandUpcs={brandUpcs} selectedOption={selectedOption} setSelectedOption={setSelectedOption} masterUpcId={masterUpcId}/>
                        </div>
                    </div>
                    <div className="flex absolute bottom-2 right-4">
                        <div className="mr-6">
                            <ButtonV2 variant="tertiaryContained"
                                onClick={handleDialogClose}
                                label={"Back"} />
                        </div>
                        <ButtonV2 variant="primaryContained"
                            onClick={handleAddBrand}
                            label={"+Add"} />
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default AddMasterBrandModalTemplate;
