import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { makeRoute } from '../../../../utils/helper';
import { EXCEPTION_CODE, MODE, STATUS } from '../../../../utils/constant';
import { HTTP_STATUS } from '../../../../utils/types';
import { IBundleFormikPayload, IBundleRequestPayload, useCatalogueBundlingService } from '../../../../services/useCatalogueBundlingService';
import { CATALOGUE_BUNDLING_ROUTES } from '../../../../App/Core/Routes/CatalogueBundlingRoutes';
import BundleFormTemplate from '../../../templates/CatalogueBundling/Bundle/BundleForm.template';
import BundleDetailFormTemplate from '../../../templates/CatalogueBundling/Bundle/BundleDetailForm.template';
import ProductFormTemplate from '../../../templates/CatalogueBundling/Bundle/ProductForm.template';
import ReviewFormTemplate from '../../../templates/CatalogueBundling/Bundle/ReviewForm.template';
import moment from 'moment';

export enum BUNDLE_FORM_STATE {
    BUNDLE_DETAILS = 1,
    EDIT_PRODUCTS = 2,
    REVIEW = 3
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
        lineHeight: "30px"
    },
    desc: {
        color: theme.palette.textV2.tertiary.tertiary700,
        lineHeight: "14px"
    }
}));

export const productSchema = Yup.object().shape({
    bundleMoq: Yup.number().moreThan(0, "Bundle MOQ should be more than zero").required('Bundle MOQ is required'),
});

export const validationSchema = Yup.object().shape({
    name: Yup.string().max(30, "Bundle Name cannot be more than 30 characters").required("Bundle Name is required"),
    moq: Yup.number().moreThan(0, "Minimum Order Quantity should be more than zero").required("Minimum Order Quantity is required"),
    price: Yup.number().moreThan(0, "Price should be more than zero").required("Price is required"),
    startDate: Yup.date().min(new Date(), "Start Date cannot be in the past").required('Start Date is required').nullable(),
    expiryDate: Yup.date().min(Yup.ref('startDate'), 'Expiry Date must be after Start Date').required('Expiry Date is required').nullable(),
    productDetails: Yup.array().of(productSchema),
    packagingType: Yup.string().required("Packaging Type is required"),
    deliveryTerm: Yup.string().required("Delivery Term  is required"),
});

const BundleCreatePage: React.FC = () => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const catalogueBundlingService = useCatalogueBundlingService();
    const navigate = useNavigate();

    const [formState, setFormState] = useState<BUNDLE_FORM_STATE>(1);

    const onClose = () => {
        navigate(makeRoute(CATALOGUE_BUNDLING_ROUTES.CATALOGUE_BUNDLING_LIST, { query: { type: 'CATALOGUE_BUNDLE' } }));
    }

    const createBundle = async (bundle: IBundleFormikPayload) => {
        const bundleRequestBody: IBundleRequestPayload = {
            name: bundle?.name,
            referenceDetails: bundle?.productDetails.map((product) => ({
                productId: product?.productId,
                classType: product?.classType,
                moq: product?.bundleMoq,
            })),
            type: "BUNDLE",
            moq: bundle?.moq,
            price: bundle?.price,
            startDate: moment(bundle?.startDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss"),
            expiryDate: moment(bundle?.expiryDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss"),
            status: bundle?.status,
            packagingType: bundle?.packagingType,
            deliveryTerm: bundle?.deliveryTerm,
            warehouseId: bundle?.warehouseId,
        }
        catalogueBundlingService.createBundle(bundleRequestBody)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    showSnackbar('success', "New Catalogue Bundle Created");
                    onClose();
                } else if (res.data.exceptionCode === EXCEPTION_CODE.DUPLICATE_ENTRY) {
                    showSnackbar('error', "Catalogue Bundle already exists. Please try again");
                }
            }).catch((error: any) => {
                showSnackbar('error', "Failed to create Catalogue Bundle");
            })
    }

    const formik = useFormik<IBundleFormikPayload>({
        initialValues: {
            name: "",
            moq: null,
            price: null,
            startDate: "",
            expiryDate: "",
            productDetails: [],
            status: STATUS.ACTIVE,
            packagingType: "",
            deliveryTerm: "",
            warehouseId: null,
            warehouse: ""
        },
        validationSchema,
        validateOnChange: true,
        validateOnMount: true,
        validateOnBlur: true,
        onSubmit: (values) => { createBundle(values) }
    })

    const setFormStateTo = (formState: BUNDLE_FORM_STATE) => () => {
        setFormState(formState)
    }

    const activeFormStep = useMemo(() => {
        const props = { mode: MODE.ADD, onClose, formik, setFormStateTo }
        switch (formState) {
            case BUNDLE_FORM_STATE.EDIT_PRODUCTS: return <ProductFormTemplate {...props} />;
            case BUNDLE_FORM_STATE.REVIEW: return <ReviewFormTemplate {...props} />;
            default: return <BundleDetailFormTemplate {...props} />;
        }
    }, [formState, formik])

    return (
        <div>
            {SnackBarComponent}
            <div className="grid gap-y-6" >
                <div className='grid gap-y-2'>
                    <div className={`${classes.mainHeading} text-lg font-semibold`}>Create New Bundle</div>
                    <div className={`${classes.desc} text-xs `}>The form will help you create a new product bundle by selecting and configuring product. Please fill out each section carefully.</div>
                </div>
                <BundleFormTemplate {...{ mode: MODE.ADD, onClose, component: activeFormStep }} />
            </div>
        </div>
    )
}

export default BundleCreatePage;