import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { ADMIN_ROUTES } from '../../../utils/constant';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { HTTP_STATUS } from '../../../utils/types';
import { useRfqService } from '../../../services/useRfqService';
import RfqViewTemplate from '../../templates/Rfq/RfqView.template';

const RfqViewPage: React.FC = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const rfqService = useRfqService();
    const [rfq, setRfq] = useState<any | null>(null)
    const navigate = useNavigate();
    const params = useParams();

    const loadRfq = async () => {
        rfqService.getRfqById(Number(params.id))
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    setRfq(res.data.data)
                }
            }).catch((error) => {
                setRfq(null);
                console.error("Error Fetching Rfq: ", error);
                showSnackbar('error', "Error while fetching Rfq data");
            })
    }

    useEffect(() => {
        loadRfq()
    }, [Number(params.id)])

    const onBack = () => {
        navigate(ADMIN_ROUTES.RFQ_LIST);
    }

    return (
        <div>
            {SnackBarComponent}
            <RfqViewTemplate onBack={onBack} rfq={rfq} />
        </div>
    )
}

export default RfqViewPage;