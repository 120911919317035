import React, { useEffect, useMemo, useState } from 'react';
import { capitalizeFirstLetter, titleModification } from '../../../utils/helper';
import { MODE, SERVICE_STATUS_OPTIONS } from '../../../utils/constant';
import { createUseStyles } from 'react-jss';
import ButtonV2 from '../../atoms/ButtonV2';
import TextFieldV2 from '../../atoms/TextFieldV2';
import StatusV2 from '../../atoms/Status/StatusV2';
import { FormControl } from '@mui/material';
import AutoCompleteInputV2, { AutoCompleteOption } from '../../molecules/AutoCompleteInput/AutoCompleteInputV2';
import { Standard, useStandardService } from '../../../services/useStandardService';
import { HTTP_STATUS } from '../../../utils/types';
import { useSnackbar } from '../../../hooks/useSnackBar';

interface GradeFormTemplateProps {
    mode: string,
    formik: any;
    onBack: () => void,
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    value: {
        color: theme.palette.textV2.primary.primary950
    },
}));

const GradeFormTemplate: React.FC<GradeFormTemplateProps> = ({ mode, formik, onBack }) => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const standardService = useStandardService();

    const [standardData, setStandardData] = useState<Standard[]>([]);

    const fetchStandards = (search: string = "") => {
        standardService.getAllStandards({ page: 0, size: 10, sort: "createdAt,desc", search })
            .then(response => {
                if (response.status === HTTP_STATUS.OK) {
                    setStandardData(response?.data?.data?.content)
                }
            }).catch(error => {
                showSnackbar('error', 'Failed to fetch standards')
            })
    }

    useEffect(() => {
        fetchStandards();
    }, []);

    const standardOptions = useMemo(() => {
        return standardData.map((option: Standard) => ({ label: option.name, id: option.id })) ?? []
    }, [standardData])

    const updateStandards = (key: string) => (option: AutoCompleteOption | null) => {
        formik.setFieldValue(key, option);
        if (option) {
            formik.setFieldValue('standardId', option.id);
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const handleSubmit = () => formik.handleSubmit();

    return (
        <form onKeyDown={handleKeyDown} className="grid gap-y-8">
            <div className={`${classes.mainHeading} text-lg font-semibold`}>{mode === MODE.UPDATE ? "Update" : mode === MODE.VIEW ? "View" : "Add New"} Quality Grade</div>
            <div className='grid gap-y-4'>
                <div className={`${classes.border} grid  gap-y-3 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Grade Details</div>
                    <div className={` grid gap-y-4`}>
                        <div className={` grid grid-cols-2 gap-x-4`}>
                            <div className="grid gap-y-1">
                                <TextFieldV2
                                    label="Quality Grade Name"
                                    placeholder='Enter Quality Grade Name'
                                    {...formik.getFieldProps("name")}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    inputProps={{ readOnly: mode === MODE.VIEW, maxLength: 30 }}
                                    onBlur={(event) => {
                                        const newValue = titleModification(event.target.value);
                                        formik.setFieldValue('name', newValue);
                                    }}
                                />
                                {formik.errors.name && formik.touched.name && (
                                    <div className={`${classes.errorMessage} text-xs`}>{formik.errors.name}</div>
                                )}
                            </div>

                            <div className="grid gap-y-1">
                                <TextFieldV2
                                    label="Associated Grade Name Code"
                                    fullWidth
                                    name="prefix"
                                    {...formik.getFieldProps("prefix")}
                                    inputProps={{ readOnly: mode === MODE.UPDATE }}
                                />
                            </div>
                        </div>
                        <div className={` grid grid-cols-1 gap-x-4`}>
                            <div className="grid gap-y-1">
                                <FormControl fullWidth>
                                    <AutoCompleteInputV2
                                        options={standardOptions}
                                        label="Select Standard"
                                        onChange={updateStandards("standard")}
                                        onSearch={fetchStandards}
                                        isDisabled={mode === MODE.VIEW}
                                        value={formik.values?.standard}
                                        error={!!formik.errors.standard && formik.touched.standard}
                                    />
                                    {formik.errors.standard?.id && formik.touched.standard?.id && (
                                        <div className={`${classes.errorMessage}`}>
                                            <small>{formik?.errors?.standard?.id}</small>
                                        </div>
                                    )}
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>

                <StatusV2 heading="Service Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" />
            </div>

            <div className="flex justify-end gap-4">
                <ButtonV2 variant="tertiaryContained" label='Cancel' onClick={onBack} />
                {mode !== MODE.VIEW && <ButtonV2 variant="secondaryContained" onClick={handleSubmit} label={`+${capitalizeFirstLetter(mode)}`} />}
            </div>
        </form>
    )
}

export default GradeFormTemplate;