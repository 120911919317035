import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { request } from './index';

const INDENT_URLs = {
  FETCH_AVIGHNA_WAREHOUSE: 'business/85',
  FETCH_WAREHOUSE: 'warehouse',
  GET_ALL_WAREHOUSE: "/warehouse/all"
};

export interface IWarehouseFilterRequest{
  page: number;
  size: number;
  sort?: string;
  search?: string;
}

export const useWarehouseService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getBillAddress = () => {
    return request(
      API_METHOD.GET,
      INDENT_URLs.FETCH_AVIGHNA_WAREHOUSE,
      authenticatedUser,
      null
    );
  };

  const getWarehouse = () => {
    return request(
      API_METHOD.GET,
      INDENT_URLs.FETCH_WAREHOUSE,
      authenticatedUser,
      null
    );
  };

  const getAllWarehouse = (params: IWarehouseFilterRequest) => {
    return request(API_METHOD.GET, INDENT_URLs.GET_ALL_WAREHOUSE, authenticatedUser, null, { params } );
  };


  return {
    getBillAddress,
    getWarehouse,
    getAllWarehouse
  };
};
