import { FormikProps } from 'formik';
import React, { memo, useEffect } from 'react'
import { createUseStyles } from 'react-jss';
import { IAddOwnProductFormik } from './AddOwnProduct.template';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { DELIVERY_INFORMATION } from '../../../../utils/constant';
import { IMaterialAttribute } from '../../../pages/CustomerManagement/MyMaterialProduct.page';
import { IStandards } from '../../Customer-Management/MyMaterials/AddMaterialForm.template';
import { ADD_OWN_PRODUCT } from '../../../../utils/types';

interface AddOwnProductReviewTemplateProps {
    formik: FormikProps<IAddOwnProductFormik>
    warehouses: { id: number, name: string }[];
    index: number
    attributes: IMaterialAttribute[]
    attributesOriginal?: IMaterialAttribute[]
    setAttributes?: (value: IMaterialAttribute[]) => void
    standards: IStandards[]
    mode: string;
    setCurrentSectionTo: (section: ADD_OWN_PRODUCT) => void;
    handleCancel: () => void
}

const useStyles = createUseStyles((theme: any) => ({
    highlightedText: {
        color: theme.customerPalette.text.primary.primary500
    },
    tertiary100Border: {
        border: `1px solid ${theme.customerPalette.border.neutral.neutral100}`
    },
    darkHeading: {
        color: theme.customerPalette.text.neutral.neutral700,
        lineHeight: "22px"
    },
    textFieldText: {
        color: theme.customerPalette.text.neutral.neutral400,
    },
    title: {
        color: theme.customerPalette.text.primary.primary900,
    },
    radioColor: {
        color: theme.customerPalette.text.primary.primary500,
        width: "18%",
        padding: "16px 20px",
        display: "block",
        " & .MuiFormControlLabel-label": {
            display: "block",
            color: theme.customerPalette.text.neutral.neutral400,
            fontSize: "18px",
            fontWeight: "600",
            marginTop: "12px"
        }
    },
    warningAsterisk: {
        marginTop: "-4px",
        color: theme.customerPalette.text.primary.primary500
    },
    container: {
        border: `1px solid ${theme.customerPalette.border.neutral.neutral100}`
    },
    errorMessage: {
        color: theme.customerPalette.text.secondary.secondary500,
    },
    keyword: {
        padding: "6px 4px",
        borderRadius: "60px",
        border: `1px solid ${theme.customerPalette.text.neutral.neutral300}`,
        background: theme.customerPalette.background.primary.primary50,
        minWidth: "70px"
    },
    image: {
        width: "141px",
        height: "159px"
    },
}));

const OwnProductReviewDetailsTemplate: React.FC<AddOwnProductReviewTemplateProps> = memo(({ formik, warehouses, setCurrentSectionTo, handleCancel, index, attributes, attributesOriginal = [], setAttributes, standards, mode }) => {

    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='flex flex-col gap-6'>
            {SnackBarComponent}
            <div className='flex gap-x-4'>
                <div className={`${classes.container} p-6 rounded-xl w-2/3`}>
                    <div className={`pb-4 text-lg font-medium ${classes.title}`}>Category</div>
                    <div className='flex justify-between'>
                        <div className='flex flex-col'>
                            <div className={`text-sm font-normal ${classes.darkHeading}`}>
                                Super Category
                            </div>
                            <div className={`${classes.darkHeading}`}>
                                {formik?.values?.productDetails[index]?.superCategory?.label}
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <div className={`text-sm font-normal ${classes.darkHeading}`}>
                                Main Category
                            </div>
                            <div className={`${classes.darkHeading}`}>
                                {formik?.values?.productDetails[index]?.mainCategory?.label}
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <div className={`text-sm font-normal ${classes.darkHeading}`}>
                                Product Category
                            </div>
                            <div className={`${classes.darkHeading}`}>
                                {formik?.values?.productDetails[index]?.productCategory?.label}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${classes.container} p-6 rounded-xl w-1/3`}>
                    <div className={`pb-4 text-lg font-medium ${classes.title}`}>Standards</div>
                    <div className='flex justify-between'>
                        <div className='flex flex-col'>
                            <div className={`text-sm font-normal ${classes.darkHeading}`}>
                                Standard
                            </div>
                            <div className={`${classes.darkHeading}`}>
                                {formik?.values?.productDetails[index]?.standard?.label}
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <div className={`text-sm font-normal ${classes.darkHeading}`}>
                                Grade
                            </div>
                            <div className={`${classes.darkHeading}`}>
                                {formik?.values?.productDetails[index]?.grade?.label}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} p-6 rounded-xl`}>
                <div className={`pb-4 text-lg font-medium ${classes.title}`}>Product Specification</div>
                <div className='flex justify-between pb-6'>
                    {formik?.values?.productDetails[index]?.attributes?.map((item) => (
                        <div>
                            <div className={`text-sm font-normal ${classes.darkHeading}`}>{item.name} <span>{item.uom ? ` (${item.uom.toLowerCase()})` : ""}</span></div>
                            <div className={`${classes.darkHeading}`}>{item.attributeOptions}</div>
                        </div>
                    ))}
                </div>
                <div className='flex flex-col gap-y-1'>
                    <div className={`text-sm font-normal ${classes.darkHeading}`}>Keywords</div>
                    <div className='flex gap-x-2'>
                        {formik?.values?.productDetails[index]?.keywords?.map((keyword) => (
                            <div className={`flex items-center justify-center ${classes.keyword}`}>
                                {keyword}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='flex gap-x-4'>
                <div className={`${classes.container} p-6 rounded-xl w-1/2`}>
                    <div className={`pb-4 text-lg font-medium ${classes.title}`}>Brand Details</div>
                    <div>
                        <div className={`text-sm font-normal ${classes.darkHeading}`}>
                            Select Brand
                        </div>
                        <div className={`${classes.darkHeading}`}>
                            {formik?.values?.productDetails[index]?.brand?.label}
                        </div>
                    </div>
                </div>
                <div className={`${classes.container} p-6 rounded-xl w-1/2`}>
                    <div className={`pb-4 text-lg font-medium ${classes.title}`}>Delivery and Order Details</div>
                    <div className='flex gap-x-[80px]'>
                        <div>
                            <div className={`text-sm font-normal ${classes.darkHeading}`}>
                                Delivery Information
                            </div>
                            <div className={`${classes.darkHeading}`}>
                                {formik?.values?.productDetails[index]?.warehouseWiseProductDetails?.map((detail, idx) => (
                                    <div key={idx} className={`${classes.darkHeading}`}>
                                        <p> {DELIVERY_INFORMATION.filter(({ value }) => value === detail?.deliveryTimeFrame)[0]?.label}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className={`text-sm font-normal ${classes.darkHeading}`}>
                                MOQ
                            </div>
                            <div className={`${classes.darkHeading}`}>
                                {formik?.values?.productDetails[index]?.warehouseWiseProductDetails?.map((detail, idx) => (
                                    <div key={idx} className={`${classes.darkHeading}`}>
                                        <p> {detail.minimumOrderQuanity}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} p-6 rounded-xl`}>
                <div className={`pb-4 text-lg font-medium ${classes.title}`}>Product Description</div>
                <div className={`${classes.darkHeading}`}>
                    {formik?.values?.productDetails[index]?.otherSpecificRequirement}
                </div>
            </div>
            {/* <div className={`${classes.container} p-6 rounded-xl`}>          ---------******* this may will be implement later currently don't have any requirement -----////
                <div className={`${classes.title} pb-4 text-lg font-medium`}>Product Images</div>
                <div className="flex gap-x-4">
                    {formik?.values?.images?.map((image: any, index: number) => (
                        <img className={`${classes.image} rounded-lg`} key={index} src={image instanceof File ?
                            URL?.createObjectURL(image as File)
                            :
                            (image as UploadedImageData)?.path} alt="machineImage" />
                    ))}
                </div>
            </div> */}
            <div>
            </div>
        </div>
    )
})

export default OwnProductReviewDetailsTemplate;