import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const BUSINESS_PROFILE_URLS = {
    GET: "/business/:id",
    UPDATE_SECTION: "/business/:section/:id",
    GET_CIN: "/cin-details",
    CREATE_BUSINESS_PROFILE: "/business",
    GET_ALL_BUSINESSES: "/businesses",
    UPDATE_CUSTOMER_STATUS: "/customer/:id",
    GET_CUSTOMER_STATS: "/customer/stats",
    GET_GST_IN_DETAILS: "/gstin",
    GET_ALL_CUSTOMERS: "/businesses",
    GET_ALL_CUSTOMERS_V3: "/businesses",
    GET_VENDOR: "/vendor/business/:id"
}

interface ICustomerFilterRequest {
    search?: string;
    customerType?: string;
    page?: number;
    size?: number;
    startDate?: string;
    endDate?: string;
}

export interface ICustomerManagementQueryParams {
    productCategoryIds?: number[] | null;
    groupName?: string[] | null;
    city?: string[] | null;
    industry?: string[] | null;
    turnOver?: string[] | null;
  }

export const useBusinessProfileService = () => {
    const { user } = useAuthenticatedUser();
    const BASE_URL_V2 = process.env.REACT_APP_API_V2_URL;

    const getBusinessProfile = (id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(BUSINESS_PROFILE_URLS.GET, { id }), user, null, undefined, null, BASE_URL_V2);
    }

    const updateBusinessProfile = (id: number, data: any, section: string) => {
        return request(API_METHOD.PUT, replaceUrlParams(BUSINESS_PROFILE_URLS.UPDATE_SECTION, { id, section }), user, data, undefined, null, BASE_URL_V2);
    }

    const getCinDetails = (data: any) => {
        return request(API_METHOD.POST, BUSINESS_PROFILE_URLS.GET_CIN, user, data);
    }

    const createBusinessProfile = (data: any) => {
        return request(API_METHOD.POST, BUSINESS_PROFILE_URLS.CREATE_BUSINESS_PROFILE, user, data, undefined, null, BASE_URL_V2);
    }

    const updateStatus = (id: number, data: any) => {
        return request(API_METHOD.PUT, replaceUrlParams(BUSINESS_PROFILE_URLS.UPDATE_CUSTOMER_STATUS, { id }), user, data, undefined, null, BASE_URL_V2);
    }

    const getGstinDetails = (data: any) => {
        return request(API_METHOD.POST, BUSINESS_PROFILE_URLS.GET_CUSTOMER_STATS, user);
    }

    const getAllCustomers = (params: ICustomerFilterRequest) => {
        return request(API_METHOD.GET, BUSINESS_PROFILE_URLS.GET_ALL_CUSTOMERS, user, null, { params }, null, BASE_URL_V2)
    }

    const getCustomerStatus = (params: ICustomerFilterRequest) => {
        return request(API_METHOD.GET, replaceUrlParams(BUSINESS_PROFILE_URLS.GET_CUSTOMER_STATS, { params }), user, null, undefined, null, BASE_URL_V2)
    }

    const getAllCustomerV3 = (params: ICustomerFilterRequest, data: ICustomerManagementQueryParams) => {
        return request(API_METHOD.POST, BUSINESS_PROFILE_URLS.GET_ALL_CUSTOMERS_V3, user, data, { params }, null, BASE_URL_V2 );
    };

    const getVendorProfile = (id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(BUSINESS_PROFILE_URLS.GET_VENDOR, { id }), user, null, undefined, null, BASE_URL_V2);
    }

    return {
        getBusinessProfile, updateBusinessProfile, getCinDetails, createBusinessProfile, getAllCustomers, getCustomerStatus, updateStatus, getGstinDetails, getAllCustomerV3, getVendorProfile
    }
}