import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { MODE, SERVICE_STATUS_OPTIONS } from '../../../../utils/constant';
import ButtonV2 from '../../../atoms/ButtonV2';
import TextFieldV2 from '../../../atoms/TextFieldV2';
import StatusV2 from '../../../atoms/Status/StatusV2';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TableV2, { ColumnType } from '../../../organisms/TableV2';
import { FormikErrors } from "formik";
import deleteIcon from '../../../../assets/icons/deleteIcon.svg';
import { BUNDLE_LOT_FORM_STATE } from '../../../pages/CatalogueBundling/BundleLot/BundleLotCreate.page';
import { toInr } from '../../../../utils/helper';
import { InputAdornment } from '@mui/material';

export interface ProductFormTemplateProps {
    mode: string;
    onClose: () => void;
    formik: any;
    setFormStateTo: (state: BUNDLE_LOT_FORM_STATE) => () => void;
}

export interface IBundle {
    id: number;
    bundleName: string;
    startDate: string;
    expiryDate: string;
    bundleMoq: number | null;
    price: number | null;
    bundleLotMoq: number | null;
}

const useStyles = createUseStyles((theme: any) => ({
    mainHeading: {
        color: theme.palette.textV2.primary.primary950,
        lineHeight: "22px"
    },
    desc: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    border: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`
    },
    heading: {
        color: theme.palette.textV2.primary.primary900,
    },
    errorMessage: {
        color: theme.palette.action.danger,
    },
    previousProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary600
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    inActiveProgressBar: {
        backgroundColor: theme.palette.backgroundV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    location: {
        border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
        color: theme.palette.textV2.tertiary.tertiary900,
        "& span": {
            color: theme.palette.textV2.tertiary.tertiary600
        }
    },
    label: {
        color: theme.palette.textV2.tertiary.tertiary600,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "16.1px"
    },
    datePicker: {
        "& .MuiInputBase-root": {
            border: "1px solid",
            borderColor: theme.palette.borderV2.tertiary.tertiary200,
            fontSize: "16px",
            fontWeight: 500,
            borderRadius: 8,
            color: theme.palette.textV2.primary.primary900,
            "&:hover": {
                borderColor: theme.palette.borderV2.primary.primary500,
                borderWidth: 2,
                outline: "none",
            },
            "& .MuiInputBase-input": {
                "&::placeholder": {
                    color: `${theme.palette.textV2.tertiary.tertiary900} !important`,
                    fontWeight: 400
                }
            },
            "& .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: theme.palette.border.secondaryDark,
            },
            "&:focus-within": {
                outline: "none",
                borderColor: theme.palette.borderV2.primary.primary500,
                borderWidth: 2,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: theme.palette.borderV2.primary.primary500,
            },
            "& .Mui-disabled": {
                backgroundColor: theme.palette.background.secondaryDarkAccent,
                fontWeight: 500,
                borderColor: theme.palette.textV2.tertiary.tertiary200,
                borderRadius: "8px",
                fontSize: "16px"
            },
        },
    },
    value:{
        "& .MuiTypography-root": {
            color: theme.palette.textV2.tertiary.neutral400
        }
    }
}));

const ProductFormTemplate: React.FC<ProductFormTemplateProps> = ({ mode, onClose, formik, setFormStateTo }) => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const handleNext = () => {
        formik.setTouched({ name: true, moq: true, price: true, startDate: true, expiryDate: true, productDetails: true }, true)
        const errors = Object.keys(formik.errors).find(key => ['name', 'moq', 'price', 'startDate', 'expiryDate', 'productDetails'].includes(key))
        if (errors) return;
        setFormStateTo(BUNDLE_LOT_FORM_STATE.REVIEW)();
    }

    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: 0,
            currentPage: 0,
            isVisible: false,
            limit: 0,
        },
        columns: [
            { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Bundle Name", key: "name", type: "string" as ColumnType, props: { className: '' } },
            { label: "Start Date", key: "startDate", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Expiry Date", key: "expiryDate", type: "datetime" as ColumnType, props: { className: '' } },
            { label: "Bundle MOQ", key: "bundleMoq", type: "number" as ColumnType, props: { className: '' } },
            { label: "Price", key: "price", type: "number" as ColumnType, props: { className: '' } },
            { label: "Bundle Lot MOQ", key: "bundleMoq", type: "custom" as ColumnType, props: { className: '' } },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } },
        ],
    };

    const Action = (id: number, index: number) => {
        return (
            <div className="flex justify-center">
                <img
                    src={deleteIcon}
                    alt="Actions"
                    className={ mode=== MODE.UPDATE ? "cursor-none": "cursor-pointer"}
                    onClick={() => deleteProduct(index)}
                />
            </div>
        );
    };

    const BundleLotMOQ = (id: number, index: number) => {
        return (
            <div className='grid gap-y-1'>
                <TextFieldV2
                    type='number'
                    placeholder='Enter Bundle MOQ'
                    {...formik.getFieldProps(`productDetails[${index}].bundleLotMoq`)}
                    inputProps={{ readOnly: mode === MODE.VIEW }}
                    error={formik.touched?.productDetails?.[index]?.bundleLotMoq &&
                        Boolean((formik.errors?.productDetails as FormikErrors<IBundle>[])?.[index]?.bundleLotMoq)
                    }
                />
                {formik.errors.productDetails?.[index]?.bundleLotMoq && formik.touched?.productDetails?.[index]?.bundleLotMoq && (
                    <div className={`${classes.errorMessage} text-xs`}>
                        {formik.errors.productDetails?.[index]?.bundleLotMoq}
                    </div>
                )}
            </div>
        );
    };

    const records = formik.values.productDetails?.map((product: any, index: number) => [
        index + 1,
        product?.name,
        product?.startDate,
        product?.expiryDate,
        `${product?.bundleMoq} MT`,
        toInr(product?.price),
        BundleLotMOQ(product?.id, index),
        Action(product?.id, index),
    ]);

    const deleteProduct = async (index: number) => {
        const updatedProductDetails = [...formik.values.productDetails];
        updatedProductDetails.splice(index, 1);
        formik.setFieldValue('productDetails', updatedProductDetails);
    };

    return (
        <div className="grid gap-y-8" >
            {SnackBarComponent}
            <div className={`w-full m-auto flex justify-between gap-x-2 text-center text-sm`}>
                <div className="grid gap-y-3 w-1/4">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.previousProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto text-sm font-semibold`}>Lot Details</div>
                    </div>
                    <div className={`${classes.previousProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/4">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.previousProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto text-sm font-semibold`}>Select Bundles for the Lot</div>
                    </div>
                    <div className={`${classes.previousProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/4">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Edit Bundle MOQ</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/4">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.inActiveProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} text-sm my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className='grid gap-y-4'>
                <div className={`${classes.mainHeading} text-lg font-semibold`}>Selected Bundles</div>
                <TableV2 schema={schema} records={records} />
            </div>

            <div className='grid gap-y-4'>
                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Bundle Details</div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className="grid gap-y-1">
                            <TextFieldV2
                                label="Bundle Lot MOQ*"
                                placeholder='Enter MOQ here'
                                {...formik.getFieldProps("moq")}
                                error={formik.touched.moq && Boolean(formik.errors.moq)}
                                inputProps={{ readOnly: mode === MODE.VIEW }}
                                onBlur={(event) => {
                                    formik.setFieldValue('moq', event.target.value);
                                }}
                                type="number"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" className={`${classes.value} text-base font-normal`}>
                                            MT
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {formik.errors.moq && formik.touched.moq && (
                                <div className={`${classes.errorMessage} text-xs`}>
                                    {formik.errors.moq}
                                </div>
                            )}
                        </div>

                        <div className="grid gap-y-1">
                            <TextFieldV2
                                label="Bundle Lot Price*"
                                placeholder='Enter Price here'
                                {...formik.getFieldProps("price")}
                                error={formik.touched.price && Boolean(formik.errors.price)}
                                inputProps={{ readOnly: mode === MODE.UPDATE }}
                                onBlur={(event) => {
                                    formik.setFieldValue('price', event.target.value);
                                }}
                                type="number"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" className={`${classes.value} text-base font-normal`}>
                                             Rs
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {formik.errors.price && formik.touched.price && (
                                <div className={`${classes.errorMessage} text-xs`}>
                                    {formik.errors.price}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className={`${classes.border} grid gap-y-4 p-6 rounded-xl`}>
                    <div className={`${classes.heading} text-base font-medium`}>Valid From & Valid Until</div>
                    <div className='grid grid-cols-2 gap-x-4'>
                        <div className='grid gap-y-1'>
                            <div className='grid gap-y-1 h-fit'>
                                <div className={`${classes.label}`}>Start Date*</div>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        value={formik?.values?.startDate ? moment(formik.values.startDate, 'YYYY-MM-DD HH:mm:ss') : null}
                                        format="DD-MM-YYYY HH:mm"
                                        onChange={(newValue) => {
                                            const formattedDate = moment(newValue).format('YYYY-MM-DD HH:mm:ss');
                                            formik.setFieldValue('startDate', formattedDate);
                                        }}
                                        className={classes.datePicker}
                                        disablePast
                                    />
                                </LocalizationProvider>
                            </div>
                            {formik.touched && formik.touched?.startDate && formik.errors?.startDate && (
                                <div className={classes.errorMessage}>
                                    <small>{formik.errors?.startDate}</small>
                                </div>
                            )}
                        </div>

                        <div className='grid gap-y-1'>
                            <div className='grid gap-y-1 h-fit'>
                                <div className={`${classes.label}`}>Expiry Date*</div>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        value={formik?.values?.expiryDate ? moment(formik.values.expiryDate, 'YYYY-MM-DD HH:mm:ss') : null}
                                        format="DD-MM-YYYY HH:mm"
                                        onChange={(newValue) => {
                                            const formattedDate = moment(newValue)
                                                .endOf('day')
                                                .format('YYYY-MM-DD HH:mm:ss');
                                            formik.setFieldValue('expiryDate', formattedDate);
                                        }}
                                        className={classes.datePicker}
                                        disablePast
                                    />
                                </LocalizationProvider>
                            </div>
                            {formik.touched && formik.touched?.expiryDate && formik.errors?.expiryDate && (
                                <div className={classes.errorMessage}>
                                    <small>{formik.errors?.expiryDate}</small>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <StatusV2 heading="Bundle Lot Status" options={SERVICE_STATUS_OPTIONS} formik={formik} name="status" />
            </div>

            <div className='flex justify-between'>
                <ButtonV2 variant="tertiaryContained" label='Cancel' onClick={onClose} />
                <div className="flex justify-end gap-4">
                    <ButtonV2 variant="tertiaryContained" label='Back' onClick={setFormStateTo(BUNDLE_LOT_FORM_STATE.BUNDLE_FOR_LOT)} />
                    {mode !== MODE.VIEW && <ButtonV2 variant="primaryContained" onClick={handleNext} label={"Review"} />}
                </div>
            </div>
        </div>
    )
}

export default ProductFormTemplate;