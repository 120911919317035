import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import DropdownIcon from '../../../../assets/icons/dropDownIcon.svg';
import CollapseIcon from '../../../../assets/icons/collapseIcon.svg';
import { MAX_DISPLAY_ITEMS, SEE_MORE_LESS, faqItems } from '../../../../utils/constant';
import ButtonV2 from '../../../atoms/ButtonV2';

export interface FAQ {
    question: string;
    answer: string;
    expanded: boolean;
}

const useStyles = createUseStyles((theme: any) => ({
    mainContainer:{
        rowGap: "12px",
    },
    faq: {
        color: theme.customerPalette.text.neutral.neutral900
    },
    cardContainer:{
        rowGap: "12px",
    },
    container: {
        border: `1px solid ${theme.customerPalette.border.neutral.neutral100}`,
        rowGap: "8px",
        padding: "16px"
    },
    question: {
        color: theme.customerPalette.text.neutral.neutral800,
        fontSize: "16px",
        lineHeight: "20px"
    },
    lastQuestion: {
        '&:nth-child(1)': {
            opacity: "40%",
        },
    },
    answer: {
        color: theme.customerPalette.text.neutral.neutral600,
        fontSize: "14px"
    },
    lastItemContainer: {
        '&:nth-child(3)': {
            borderBottom: "none",
        }
    },
    activeContainer: {
    },
    "@media (max-width: 480px)": {
        mainContainer:{
            rowGap: "12px",
        },
        cardContainer:{
            rowGap: "12px",
        },
        container: {
            rowGap: "12px",
            padding: "12px 16px"
        },
        question: {
            fontSize: "14px"
        },
        answer: {
            fontSize: "12px"
        },
    },
}));

const FAQTemplate: React.FC = () => {

    const classes = useStyles();

    const [faqs, setFaqsTo] = useState<FAQ[]>(faqItems);
    const [showAllSpecifications, setShowAllSpecifications] = useState(false);

    const toggleExpand = (index: number) => {
        setFaqsTo(prevItems =>
            prevItems.map((faq, idx) => {
                if (idx === index) {
                    return { ...faq, expanded: !faq.expanded };
                }
                return faq;
            })
        );
    };

    const toggleShowAllFaqs = () => {
        setShowAllSpecifications(!showAllSpecifications);
    };

    const getFaqsToDisplay = () => {
        if (showAllSpecifications) {
            return faqs;
        } else {
            return faqs && faqs.slice(0, MAX_DISPLAY_ITEMS.FAQS);
        }
    };

    return (
        <div className={`${classes.mainContainer} grid`}>
            <div className={`${classes.faq} text-base font-bold`}>FAQs</div>
            <div className={`${classes.cardContainer} grid`}>
                {getFaqsToDisplay()?.map((faq, index) => (
                    <div key={index} className={`${!showAllSpecifications && faqs.length > MAX_DISPLAY_ITEMS.FAQS ? `${classes.container} ${classes.lastItemContainer}` : `${classes.container}`} ${faq.expanded ? classes.activeContainer : ''} grid rounded-xl`}>
                        <div className='flex justify-between'>
                            <div className={`${classes.question} w-4/5 font-medium ${!showAllSpecifications && faqs.length > MAX_DISPLAY_ITEMS.FAQS && index === MAX_DISPLAY_ITEMS.FAQS - 1 ? classes.lastQuestion : ""}`}>{faq.question}</div>
                            <button onClick={() => toggleExpand(index)}>
                                {faq.expanded ? <img src={CollapseIcon} alt="Hide Answer" /> : <img src={DropdownIcon} alt="Show Answer" className={`${!showAllSpecifications && faqs.length > MAX_DISPLAY_ITEMS.FAQS && index === MAX_DISPLAY_ITEMS.FAQS - 1 ? classes.lastQuestion : ""}`} />}
                            </button>
                        </div>

                        {faq.expanded && (
                            <div className={`${classes.answer} w-full font-normal`}>
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {faqs && faqs.length > MAX_DISPLAY_ITEMS.FAQS && (
                <div className='flex justify-center'>
                    <ButtonV2 size='medium' variant='secondaryText'  onClick={toggleShowAllFaqs} label={showAllSpecifications ? SEE_MORE_LESS.SEE_LESS : SEE_MORE_LESS.SEE_MORE}/>
                </div>
            )}
        </div>
    );
};

export default FAQTemplate;

